import {searchResults} from '@src/api/results';
import {useTranslate} from '@src/i18n/useTranslate';
import {useCountDown} from '@src/shared/hooks/useCountdown';
import {addSeconds} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';

export const useSearchData = () => {
  const {t} = useTranslate('results');
  const [searchError, setSearchError] = useState<string>('');
  const [result, setResult] = useState<any>(null);
  const [expiredSeconds, setExpiredSeconds] = useState<number | null>(0);
  const formatTime = useCountDown(expiredSeconds || 0);
  const [errorTime, setErrorTime] = useState<string>('');

  useEffect(() => {
    if (expiredSeconds) {
      setErrorTime(t('TRY_AGAIN_IN', {time: formatTime}));
      setSearchError(t('YOU_HAVE_SPENT_ALL'));
    }
  }, [formatTime, t, expiredSeconds]);

  useEffect(() => {
    if (!formatTime) {
      setSearchError('');
      setErrorTime('');
    }
  }, [formatTime]);

  const searchResultHandler = useCallback(({lastName, key}: {lastName: string, key: string | null}) => {
    setSearchError('');
    searchResults({lastName, key: key || ''})
      .then((response) => {
        setResult(response.data);
        setSearchError('');
      })
      .catch((error) => {
        setExpiredSeconds(addSeconds(Date.now(), 10).getTime());
        switch (error.status) {
          case 429: {
            setExpiredSeconds(0);
            setSearchError(t('ATTEMPTS_ERROR_PLURAL', {count: error.data.attemptsLeft}));
            break;
          }
          case 403: {
            setExpiredSeconds(addSeconds(Date.now(), error.data.expiredSeconds).getTime());
            break;
          }
        }
      });
  }, [t, expiredSeconds]);

  return {searchError, result, searchResultHandler, errorTime};
};

export const useValidationSchema = () => {
  const {t} = useTranslate('results');
  return Yup.object().shape({
    lastName: Yup.string()
      .required(t('REQUIRED_FIELD'))
      .nullable(),
  });
};
