import {TableCell} from '@mui/material';

import {AddRelationTableAction} from '../../../AddRelationTableAction';

import {IActionsColumnProps} from './types';

export const ActionsColumn = ({row, relationType}: IActionsColumnProps) => {
  return (
    <>
      <TableCell align="left">
        <AddRelationTableAction
          id={row.id}
          relationError={row.relationError}
          relationshipType={relationType || null}
          newContactBirthDate={row.birthDateS}
        />
      </TableCell>
    </>
  );
};
