import {FC, ReactElement} from 'react';
import {Tooltip} from '@src/components';
import {ListItemText as MUIListItemText, Theme} from '@mui/material';
import {StyledBadge} from './SideMenuContainer.style';
import {IconView} from './Menu/styles';

const sx = {
  tooltip: {
    '.MuiTooltip-tooltip': (t: Theme) => ({
      typography: '16_24_700',
      backgroundColor: t.colors.secondary,
    }),
    '.MuiTooltip-arrow': (t: Theme) => ({
      color: t.colors.secondary,
    }),
  },
};

export const ListItemText = ({name, open}: {name: string, open: boolean}) => {
  return (
    <MUIListItemText
      primary={name}
      primaryTypographyProps={{
        fontSize: '16px',
        fontWeight: 700,
        whiteSpace: 'nowrap',
      }}
      sx={{
        opacity: open ? 1 : 0,
        transition: 'opacity 0.3s',
        '.mobile-layout &, .tablet-layout & ': {
          opacity: 1,
        },
      }}
    />
  );
};

export const TooltipWrapper: FC<{ name: string, open: boolean, children: ReactElement }> = ({
  open,
  name,
  children,
}) => {
  if (open) {
    return children;
  }
  return (
    <Tooltip
      title={name}
      placement="right"
      arrow
      color="white"
      sx={sx.tooltip}
    >
      {children}
    </Tooltip>
  );
};

interface MessagesProps {
  count: number
  open: boolean
  title: string
  icon: JSX.Element | undefined
}
export const Messages: FC<MessagesProps> = ({count, open, title, icon}) => {
  return (
    <StyledBadge
      badgeContent={count}
      variant={open ? 'standard' : 'dot'}
      color="primary"
      max={99}
      open={open}
    >
      {icon && (
        <IconView>
          {icon}
        </IconView>
      )}
      <ListItemText name={title} open={open} />
    </StyledBadge>
  );
};
