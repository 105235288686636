import {styled, TextField, TextFieldProps} from '@mui/material';

export const Input = styled(TextField)<TextFieldProps>(({theme, color, variant, size}) => ({
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  input: {
    padding: '11px 14px',
    paddingRight: '20px',
    textOverflow: 'ellipsis',
  },
  ...(color === 'secondary' &&
    variant === 'outlined' && {
  }),
  '& .MuiInputBase-root ': {
  },
  ...(size === 'small' && {
    '& .MuiInputBase-root ': {
      padding: '4px 0 5px',
      '& .MuiInputBase-input': {
        padding: 0,
      },
    },
    input: {height: '100%', minHeight: 24, padding: 0},
  }),
}));
