import {Typography, TypographyProps, SxProps, Theme} from '@mui/material';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useTranslate} from 'i18n/useTranslate';
import {ReactNode} from 'react';

import {sx} from './styles';

interface IRecaptchaNoteProps extends TypographyProps {
  stylesSX?: SxProps<Theme>
  children?: ReactNode
}

export function RecaptchaNote ({stylesSX, ...restProps}: IRecaptchaNoteProps) {
  const {t} = useTranslate('common');
  return (
    <Typography
      sx={[...spreadSx(sx.recaptcha), stylesSX]}
      dangerouslySetInnerHTML={{__html: t('RECAPTCHA_TEXT')}}
      {...restProps}
    />
  );
}
