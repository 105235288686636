import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogActions: {
    justifyContent: 'end !important',
    gap: 36,
    backgroundColor: 'success.main',
    margin: 0,
    padding: '16px 24px',
  },
  titleWrapper: {
    flexDirection: 'row',
    gap: 12,
  },
};
