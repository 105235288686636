import {useRef, useState} from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLElement | null>(null);

  const saveScrollPosition = () => {
    if (containerRef.current) {
      setScrollPosition(containerRef.current.scrollTop);
    }
  };

  const restoreScrollPosition = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: scrollPosition,
      });
    }
  };

  return {containerRef, saveScrollPosition, restoreScrollPosition};
};
