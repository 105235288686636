import {useAccountProfile} from '@src/store/account/hooks';
import {FormikValues, useFormikContext} from 'formik';
import {useEffect, useRef, useState} from 'react';

import {SelectParentRolDialog} from '../../../AddRelation/SelectParentRolDialog';

import {mapRequestRelationTypeToRelationTypeEnum, openModal} from './helpers';

export const ParentRoleModal = () => {
  const {values, setFieldValue} = useFormikContext<FormikValues>();

  const {accountProfile} = useAccountProfile();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const isFirstRender = useRef(true);

  const closeModal = () => setIsModalOpened(false);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (accountProfile) {
      openModal({
        values,
        birthDate: accountProfile?.contact?.birthDate,
        birthSex: accountProfile?.contact?.birthSex,
        setIsModalOpened,
      });
    }
  }, [values, accountProfile]);

  return (
    <>
      {isModalOpened && (
        <SelectParentRolDialog
          closeModal={closeModal}
          isModalOpened={isModalOpened}
          relationType={mapRequestRelationTypeToRelationTypeEnum(values.relationShipType)}
          setFieldValue={setFieldValue}
          birthDate={accountProfile?.contact?.birthDate}
          birthSex={accountProfile?.contact?.birthSex}
        />
      )}
    </>
  );
};
