export const Messages = () => {
  return (
    <web-message
      module="PATIENT"
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    />
  );
};
