import {useMQuery} from '@src/shared/hooks/useMQuery';

import {RelationDesktopLayout} from './fragments/RelationDesktopLayout';
import {RelationTabletAndMobileLayout} from './fragments/RelationTabletAndMobileLayout';

export const RelationMain = () => {
  const {mobile, tablet} = useMQuery();

  if (tablet || mobile) {
    return <RelationTabletAndMobileLayout/>;
  }

  return <RelationDesktopLayout />;
};
