import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  list: {
    overflowY: 'auto',
    flex: {xs: 1, sm: 'unset'},
    maxHeight: {xs: 'unset', sm: 252},
  },
  option: {
    p: {xs: '14px 18px', sm: 12},
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
    minHeight: 'unset !important',
  },
  searchInput: {
    height: 42,
    svg: {
      color: theme => theme.colors.all.grey8,
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'unset !important',
      borderBottom: '1px solid !important',
      borderColor: theme => theme.colors.secondary400 + '!important',
    },
  },
};
