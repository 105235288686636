import {Stack, Typography, Box, Avatar, IconButton} from '@mui/material';
import {AccessRequest} from '@src/api/accesses';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as AvatarIcon} from '@src/shared/assets/icons/avatar.svg';
import {ReactComponent as Trash} from '@src/shared/assets/icons/trash.svg';
import {useMQuery, useSortOfDate} from '@src/shared/hooks';
import {getUserInitials, dateFormatted} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useDeleteAccessesRequest} from '@src/store/accesses/hooks';
import {alpha} from '@src/theme/utils';

import {sx} from './styles';

export const OutgoingRequests = ({data}: { data: AccessRequest[] | null }) => {
  const {t} = useTranslate('accesses');
  const {deleteRequest} = useDeleteAccessesRequest();
  const sortedData = useSortOfDate(data, 'createdDate');
  const {mobile: isMobile} = useMQuery();

  if (!data?.length) {
    return (
      <Typography
        variant='14_18_500'
        sx={sx.emptyRequests}>
        {t('NO_OUTGOING_REQUESTS')}
      </Typography>
    );
  };

  return (
    <Stack sx={sx.wrapper}>
      {sortedData?.map((request, index) => (
        <Stack
          sx={(t) => ({
            flexDirection: 'row',
            padding: {xs: '12px 18px', lg: '18px 24px'},
            justifyContent: 'space-between',
            borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
            borderBottom:
              index === sortedData.length - 1
                ? `1px solid ${alpha(t.palette.secondary.main, 14)}`
                : 'none',
            height: {lg: 94},
          })}
          key={request.id}
        >
          <Box sx={sx.boxContainer}>
            <Avatar sx={sx.requestAvatar} src={request?.avatarUrl} alt={''}>
              {isMobile
                ? !request?.avatarUrl && <AvatarIcon />
                : <Typography variant="14_20_700">{getUserInitials(request?.contact)}</Typography>}
            </Avatar>
            <Stack sx={sx.widthLimitContainer}>
              <EllipsisTextTooltip
                variant={isMobile ? '16_20_700' : '18_24_700'}
                ellipsisText={t('TO_NAME', {name: getName(request?.contact, {short: true})})}
                sx={sx.personName}
              />
              <Typography variant="14_18_500" sx={sx.requestType}>
                {t(request?.access)}
              </Typography>
            </Stack>
          </Box>
          <Stack sx={sx.flexContainer}>
            <IconButton
              color="primary"
              sx={sx.trashButton}
              onClick={() => deleteRequest({requestId: request.id})}
            >
              <Trash width={24} height={24} />
            </IconButton>
            <Typography variant="14_18_500" sx={sx.date}>
              {dateFormatted(request.createdDate)}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
