import {Stack, Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Breadcrumbs, Link, Text} from '@src/components';
import {PulseLoader} from '@src/components/PulseLoader';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {AccessesCardsBlock} from '@src/pages/Connections/fragments/Accesses/AccessesCardsBlock';
import {AccessesDeleteModal} from '@src/pages/Connections/fragments/Accesses/AccessesDeleteModal';
import {ExpiredCheckBox} from '@src/pages/Connections/fragments/Accesses/common/ExpiredCheckBox';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useAccesses} from '@src/store/accesses/hooks';
import {AccountType} from '@src/types';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {sx} from './styles';

export const AccessesAcceptedMobileLayout = () => {
  const {t, ready} = useTranslate('accesses');
  const {accesses, isFetching} = useAccesses();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {mobile: isMobile} = useMQuery();
  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const currentAccessType: TKeys<'accesses'> =
    state.accessesTypeForMobile === AccountType.Incoming ? 'INCOMING' : 'OUTGOING';

  const visibleAccesses =
    state?.accessesTypeForMobile === AccountType.Incoming
      ? accesses?.incomingAccountAccesses
      : accesses?.outgoingAccountAccesses;

  const noAccesses = !accesses?.companyAccesses?.length && !visibleAccesses?.length;

  if (!isMobile) {
    navigate(ROUTERS_PATH.CONNECTIONS_ACCESSES);
  }

  if (!ready) {
    return null;
  }

  return (
    <Stack gap={18}>
      <Breadcrumbs>
        <Link
          sx={sx.link}
          to={ROUTERS_PATH.CONNECTIONS_ACCESSES}
          state={{currTabActive: state.accessesTypeForMobile}}
        >
          {t(currentAccessType)}
        </Link>
        <Text sx={sx.text}>{t('ACCEPTED')}</Text>
      </Breadcrumbs>
      <Box sx={sx.boxContainer}>
        <Stack sx={sx.buttonGroupsMobile} mb={noAccesses ? 0 : 24}>
          <Typography variant="22_26_500">{t('ACCEPTED')}</Typography>
          <ExpiredCheckBox checked={showExpired} onChange={() => setShowExpired(!showExpired)} />
        </Stack>
        {isFetching && (
          <Box sx={sx.fetchingBlock}>
            <PulseLoader loading />
          </Box>
        )}
        {!isFetching && (
          <>
            {!accesses?.companyAccesses?.length && !visibleAccesses?.length && (
              <Typography sx={sx.emptyAccessesText}>{t('NO_ACCESSES')}</Typography>
            )}
            <AccessesCardsBlock
              showExpired={showExpired}
              accesses={visibleAccesses}
              onOpenModal={setModalOpen}
              blockName={t('PERSONS')}
            />
            <AccessesCardsBlock
              showExpired={showExpired}
              accesses={accesses?.companyAccesses}
              onOpenModal={setModalOpen}
              blockName={t('ORGANIZATIONS')}
            />
          </>
        )}
        <AccessesDeleteModal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)} />
      </Box>
    </Stack>
  );
};
