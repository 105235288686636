import {BirthSex} from '@src/api';
import {RelationshipType, RequestRelationType, StateType} from '@src/api/relations';

export const touchedData = {
  relationShipType: true,
  firstName: true,
  lastName: true,
  birthDate: true,
  birthSex: true,
  gender: true,
  policyId: true,
  guarantorAddress1: true,
  guarantorFirstName: true,
  guarantorLastName: true,
  guarantorBirthDate: true,
  guarantorBirthSex: true,
  guarantorCity: true,
  guarantorState: true,
  guarantorPostalCode: true,
  insuranceCarrier: true,
  insurancePlan: true,
};

export const birthSexSelectOptions = Object.values(BirthSex).map((name) => ({name}));
export const statesSelectOptions = Object.values(StateType).map((name) => ({name}));
export const relationshipTypesSelectOptions = Object.values(RequestRelationType).map((name) => ({name}));
export const relationshipTypesGuarantorSelectOptions = Object.values(RelationshipType).map((name) => ({name}));
