import {MenuTypes, StorageFileCategory} from '@api';
import {TKeys} from '@src/i18n/useTranslate';

export const getMenuTypesOptions = (
  translate: (str: TKeys<'storage'> | null | undefined) => string,
) => {
  return [
    {label: translate('VIEW_ALL'), value: MenuTypes.ALL},
    {label: translate('RECENT'), value: MenuTypes.RECENT},
    {label: translate('FAVORITES'), value: MenuTypes.FAVORITE},
  ];
};

export const getCategoryOptions = (
  translate: (str: TKeys<'storage'> | null | undefined) => string,
) => {
  return [
    {
      label: translate(StorageFileCategory.LABORATORY_REPORT),
      value: StorageFileCategory.LABORATORY_REPORT,
    },
    {
      label: translate(StorageFileCategory.MEDICAL_RECORD),
      value: StorageFileCategory.MEDICAL_RECORD,
    },
    {
      label: translate(StorageFileCategory.IMAGING_REPORT),
      value: StorageFileCategory.IMAGING_REPORT,
    },
    {
      label: translate(StorageFileCategory.MEDICAL_IMAGE),
      value: StorageFileCategory.MEDICAL_IMAGE,
    },
    {
      label: translate(StorageFileCategory.PRESCRIPTION),
      value: StorageFileCategory.PRESCRIPTION,
    },
    {
      label: translate(StorageFileCategory.MISCELLANEOUS),
      value: StorageFileCategory.MISCELLANEOUS,
    },
  ];
};
