import {Stack, Typography, Box, Avatar, IconButton} from '@mui/material';
import {RelationRequest} from '@src/api/relations';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as AvatarIcon} from '@src/shared/assets/icons/avatar.svg';
import {ReactComponent as Trash} from '@src/shared/assets/icons/trash.svg';
import {useSortOfDate} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted, getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useDeleteRelationRequest} from '@src/store/relations/hooks';
import {alpha} from '@src/theme/utils';

import {sx} from './styles';

export const OutgoingRequests = ({data}: { data: RelationRequest[] | null }) => {
  const {t} = useTranslate('connections');
  const {deleteRequest} = useDeleteRelationRequest();
  const sortedData = useSortOfDate(data, 'createdDate');
  const {mobile: isMobile} = useMQuery();

  if (!data?.length) {
    return (
      <Typography
        sx={sx.emptyRequests}>
        {t('NO_OUTGOING_REQUESTS')}
      </Typography>
    );
  };
  return (
    <Stack sx={sx.container}>
      {sortedData?.map((request, index) => (
        <Stack
          sx={(t) => ({
            flexDirection: 'row',
            padding: {xs: '12px 18px', sm: '16px 18px'},
            justifyContent: 'space-between',
            borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
            borderBottom:
              index === sortedData.length - 1
                ? `1px solid ${alpha(t.palette.secondary.main, 14)}`
                : 'none',
          })}
          key={request.id}
        >
          <Box sx={sx.boxContainer}>
            <Avatar sx={sx.requestAvatar} src={request?.avatarUrl} alt={''}>
              {!isMobile
                ? <Typography variant="14_20_700">{getUserInitials(request?.contact)}</Typography>
                : !request?.avatarUrl && <AvatarIcon />
              }
            </Avatar>
            <Stack sx={sx.flexContainer}>
              <EllipsisTextTooltip
                variant={isMobile ? '16_20_700' : '18_24_700'}
                ellipsisText={t('TO_NAME', {name: getName(request?.contact, {short: true})})}
              />
              <Typography sx={sx.requestType}>
                {t(request?.type)}
              </Typography>
            </Stack>
          </Box>
          <Stack sx={sx.iconBthAndDateContainer}>
            <IconButton
              color="inherit"
              sx={sx.trashButton}
              onClick={() => deleteRequest({requestId: request.id})}
            >
              <Trash width={24} height={24} />
            </IconButton>
            <Typography variant="12_16_500" sx={sx.date}>
              {dateFormatted(request.createdDate)}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
