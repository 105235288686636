import {DateTime, Settings} from 'luxon';

export const startOfWeek = (dateTime: DateTime) => {
  if (/-us/i.test(Settings.defaultLocale)) {
    if (dateTime.weekday === 7) {
      return dateTime.plus({day: 1}).startOf('week').minus({day: 1});
    }
    return dateTime.startOf('week').minus({day: 1});
  }
  return dateTime.startOf('week');
};
