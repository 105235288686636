import {apiClient} from 'client/ApiClient';
import {ProfessionalSlotsRequest, ProfessionalSlots} from './models';
import {UUID} from '@src/types';

export const getProfessionalSlots = async ({id, data}: { id: UUID, data: ProfessionalSlotsRequest }) => {
  const response = await apiClient.post<ProfessionalSlots[]>(`/api/professionals/${id}/fn/get-slots`, data);
  return response.data;
};

export * as professionalsApi from './';
export * from './models';
