/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {InputLabel, MenuItem, Select, SelectChangeEvent, Typography, SxProps, Theme, Checkbox, FormControl} from '@mui/material';
import {alpha} from '@src/theme/utils';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const sx: Partial<Record<string, SxProps<Theme>>> = {
  selectItem: (t) => ({
    '&.Mui-selected': {
      backgroundColor: `${alpha(t.palette.secondary.main, 7)} !important`,
      '&:hover': {
        backgroundColor: `${alpha(t.palette.secondary.main, 7)} !important`,
      },
    },
  }),
  label: {
    color: 'grey.700',
    transform: 'translate(14px, 55%)',
    width: 'auto !important',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    '&.Mui-focused': {
      color: 'grey.700',
      fieldset: {
        borderColor: 'blue !important',
      },
    },
  },
};

export default function MultipleSelectCheckmarks ({value, onChange, options, label, contentPaddingLeft, placeholder = 'Select'}: { value: string | any, onChange: ((event: SelectChangeEvent<any>) => void) | any, options: any, label?: string, contentPaddingLeft?: string, placeholder?: string }) {
  return (
    <FormControl sx={{m: 1, width: '100%'}}>
      <InputLabel id="demo-simple-select-helper-label" sx={sx.label}>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        displayEmpty
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected: any) => {
          if (!selected.length) {
            return <Typography variant='14_20_500'>{placeholder}</Typography>;
          }
          return (
            <div>{`${selected[0].name} ${selected.length > 1 ? `+ ${selected.length - 1}` : ''}`}</div>
          );
        }}
        MenuProps={MenuProps}
        sx={(t) => ({
          '&.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
            padding: 0,
          },
          ' .MuiSelect-select': {
            padding: `11px 11px 11px ${contentPaddingLeft ?? '85px'}`,
            fontWeight: 500,
          },
          ' .Mui-focused': {
            borderColor: 'initial !important',
          },
          svg: {
            path: {
              d: 'path("M7 10L12 15L17 10")',
              stroke: t.palette.grey[400],
              fill: 'none',
            },
          },
          ' .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0.8px !important',
            top: 0,
            legend: {display: 'none'},
          },
          fieldset: {
            borderColor: `${t.palette.grey[300]} !important`,
          },
        })}
      >
        {options.map((item: any) => (
          <MenuItem
            key={item.name}
            value={item}
            sx={sx.selectItem}
            selected={false}
            disableRipple
            disableTouchRipple
            disableGutters>
            <Checkbox checked={!!value.find((value: any) => value.name === item.name)} />
            <Typography variant='14_20_500'>{item.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
