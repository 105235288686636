import {
  RelationHealthcareInsurancePolicyCreateRequest,
} from '@src/api/relations';
import {RelationshipTypes} from '@src/types';
import {Nullable} from '@src/types/NullableModel';

import {SaveDataHealthPolicies} from '../types';

export const saveData = ({
  relationship,
  birthDate,
  birthSex,
  firstName,
  lastName,
  middleName,
  ssnTail,
  state,
  city,
  postalCode,
  address1,
  address2,
  phone,
  endDate,
  insurancePlanId,
  policyId,
  edit,
  isPrimaryInsurance,
  relationId,
}: Nullable<SaveDataHealthPolicies>) => {
  const getValueData = <T>(data: T) => ({
    value: data || null,
  });

  const data: RelationHealthcareInsurancePolicyCreateRequest[] = [
    {
      endDate: endDate
        ? {
          value: endDate || '',
        }
        : null,
      guarantor: {
        address1: address1 || '',
        address2: getValueData(address2),
        birthDate: birthDate || '',
        birthSex: birthSex || null,
        city: city || '',
        firstName: firstName || '',
        lastName: lastName || '',
        middleName: getValueData(middleName),
        phone: getValueData(phone),
        postalCode: postalCode || '',
        relationType: relationship === RelationshipTypes.SELF ? null : relationship,
        ssnTail: getValueData(ssnTail),
        state: state || null,
      },
      insurancePlanId: insurancePlanId || '',
      number: policyId || '',
      primary: !isPrimaryInsurance,
    },
  ];
  if (edit && relationId) {
    edit({
      relationId,
      data: {
        deletedHealthcareInsurancePolicyIds: [],
        healthcareInsurancePoliciesCreate: data || undefined,
        healthcareInsurancePoliciesEdit: [],
      },
    });
  }
};
