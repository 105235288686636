import {TempSelect} from '@src/components/TempSelect';
import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {FormTempSelectProps} from './types';

export const FormTempSelect: FC<FormTempSelectProps> = ({name, showErrorText, ...props}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const errorTextHandler = () => {
    if (showErrorText) {
      return error?.message;
    }
    return error?.message ? ' ' : error?.message;
  };

  return (
    <TempSelect
      {...props}
      error={errorTextHandler()}
      value={value}
      onChange={onChange}
    />
  );
};
