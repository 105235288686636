import {ReactComponent as HighX24} from '../24x24/priority-high.svg';
import {ReactComponent as MediumX24} from '../24x24/priority-medium.svg';

export const Priority = {
  High: {
    X24: HighX24,
  },
  Medium: {
    X24: MediumX24,
  },
};
