import {DynamicTestTransformed} from '@src/store/results/helpers';
import {addMonths, addWeeks, addYears} from 'date-fns';

export const dataWithoutEmptyValue = (data: DynamicTestTransformed[], tests: string[]) => {
  const allIndexesRemove: number[] = [];
  data.forEach((item) => {
    item.resultLaboratoryAnalyses?.forEach((res, indexRes) => {
      if (res.value === null || res.value === undefined) allIndexesRemove.push(indexRes);
    });
  });
  const indexes = [...new Set(allIndexesRemove)];
  const indexEmptyInAllTests = indexes.filter(
    (inx) =>
      allIndexesRemove.filter((allIndex) => allIndex === inx).length === data.length,
  );

  const setOfIndexes = new Set(indexEmptyInAllTests);

  const result = data.map((testItem) => {
    return {
      ...testItem,
      resultLaboratoryAnalyses:
        testItem.resultLaboratoryAnalyses?.filter(
          (item: any, indexRes: number) => !setOfIndexes.has(indexRes),
        ) || [],
    };
  });

  return result;
};

export const getTimePeriod = (period: string) => {
  switch (period) {
    case 'One week':
      return {
        start: addWeeks(new Date(), -1),
        end: new Date(),
      };
    case 'One month':
      return {
        start: addMonths(new Date(), -1),
        end: new Date(),
      };
    case 'Six month':
      return {
        start: addMonths(new Date(), -6),
        end: new Date(),
      };
    case 'One year':
      return {
        start: addYears(new Date(), -1),
        end: new Date(),
      };
    default:
      return {
        start: new Date(),
        end: new Date(),
      };
  }
};
