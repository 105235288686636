import React, {FC, useCallback, useEffect, useState} from 'react';
import {Country} from 'react-phone-number-input';
import {StyledReactPhoneNumberInput} from './styles';

export interface PhoneInputProps {
  id?: string
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  name?: string
  value: string
  country?: Country
  className?: string
  disabled?: boolean
}

export const PhoneInput: FC<PhoneInputProps> = ({onChange, value, id, name, placeholder, className, disabled}) => {
  const [tel, setTel] = useState(value);
  const phoneInputHandler = useCallback((value: string) => {
    setTel(value);
    onChange?.({target: {value, name}} as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [name, onChange]);

  useEffect(() => {
    setTel(value);
  }, [value]);
  return (
    <StyledReactPhoneNumberInput
      className={className}
      disabled={disabled}
      onChange={phoneInputHandler}
      value={value || tel}
      id={id}
      placeholder={placeholder}
    />
  );
};
