import {ExpandedState, getCoreRowModel, getExpandedRowModel, getSortedRowModel, useReactTable} from '@tanstack/react-table';
import React, {FC, MouseEvent, useCallback, useEffect, useRef, useState} from 'react';

import {TableBody} from './TableBody/TableBody';
import {TableHead} from './TableHead/TableHead';
import {StyledTable, TableWrapper} from './styles';
import {ITableProps} from './types';

export const Table: FC<ITableProps> = ({
  data,
  columns,
  onRowClick,
  isError,
  isPaddingFirstColumn,
  isPaddingLastColumn,
  heightColumns = '52px',
  variant,
  minHeightColumns,
  isDisabled,
  isHiddenColumn,
  fetchNextPage,
  isFetching,
  isNew,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [isHorizontalScrolled, setIsHorizontalScrolled] = useState(false);
  const [isVerticallyScrolled, setIsVerticallyScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.tests || row.products,
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      sorting: [],
      expanded: true,
    },
  },
  );

  const fetchMoreOnBottomReached = useCallback((containerRefElement?: HTMLDivElement | null) => {
    if (!fetchNextPage) return;

    if (containerRefElement) {
      const {scrollTop, scrollHeight, clientHeight} = containerRefElement;
      if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching) {
        setScrollPosition(containerRefElement.scrollTop);
        fetchNextPage();
      }
    }
  }, [fetchNextPage, isFetching]);

  const handleOnRowClick = (
    event: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    value: string,
  ) => {
    if ((event.target as HTMLElement).tagName === 'INPUT') return;
    event.stopPropagation();
    onRowClick?.(value);
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = tableContainerRef.current;
      if (container) {
        requestAnimationFrame(() => {
          const scrollLeft = container.scrollLeft;
          const scrollTop = container.scrollTop;
          setIsHorizontalScrolled(scrollLeft > 0);
          setIsVerticallyScrolled(scrollTop > 0);
        });
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (tableContainerRef.current && scrollPosition !== 0) {
      tableContainerRef.current.scrollTop = scrollPosition;
    }
    setScrollPosition(0);
  }, [data.length]);

  useEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  return (
    <TableWrapper
      ref={tableContainerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}>
      <StyledTable>
        <TableHead
          table={table}
          isHorizontalScrolled={isHorizontalScrolled}
          isVerticallyScrolled={isVerticallyScrolled}
          isPaddingFirstColumn={isPaddingFirstColumn}
          isPaddingLastColumn={isPaddingLastColumn}
          variant={variant}
        />
        <TableBody
          table={table}
          isHorizontalScrolled={isHorizontalScrolled}
          handleOnRowClick={handleOnRowClick}
          isPointer={!!onRowClick}
          isError={isError}
          isDisabled={isDisabled}
          height={heightColumns}
          minHeight={minHeightColumns}
          isHiddenColumn={isHiddenColumn}
          isNew={isNew}
        />
      </StyledTable>
    </TableWrapper>
  );
};
