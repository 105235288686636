import {SxProps, Theme} from '@mui/material/styles';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    flexDirection: 'row',
  },
  alert: (t) => ({
    textAlign: 'left',
    padding: '4px 0px',
    height: 'auto',
    color: t.palette.grey[600],
    whiteSpace: 'break-spaces',
    display: 'flex',
    minHeight: 42,
    alignItems: 'center',
  }),
  btn: (t) => ({
    [t.breakpoints.only('xs')]: {
      p: 0,
      fontWeight: 600,
    },
    height: {
      xs: 18,
      sm: 32,
    },
    ml: {
      xs: 0,
      sm: 30,
    },
  }),
  bthModal: {
    p: 0,
    fontWeight: 600,
    height: 42,
  },
  sendRequestDisabledBth: (t) => ({
    height: {
      sm: 32,
    },
    [t.breakpoints.only('xs')]: {
      p: 0,
      fontWeight: 600,
    },
  }),
  sendRequestDisabledBthModal: {
    height: 42,
    p: 0,
    fontWeight: 600,
  },
  viewPatientBth: (t) => ({
    height: {
      sm: 32,
    },
    ml: {
      xs: 0,
      sm: 30,
    },
    minWidth: 160,
    [t.breakpoints.only('xs')]: {
      p: 0,
      fontWeight: 600,
    },
  }),
  viewPatientBthModal: {
    height: 42,
    p: 0,
    fontWeight: 600,
  },
  duplicateErrorWeapper: (t) => ({
    pl: {sm: 28},
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& svg path:nth-of-type(1)': {
      fill: t.palette.grey[300],
    },
    '& svg path:nth-of-type(2)': {
      fill: t.palette.grey[500],
    },
  }),
  duplicateErrorWeapperModal: (t) => ({
    pl: {sm: 0},
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& svg path:nth-of-type(1)': {
      fill: t.palette.grey[300],
    },
    '& svg path:nth-of-type(2)': {
      fill: t.palette.grey[500],
    },
  }),
  creationRequestDate: (t) => ({
    pl: {sm: 28},
    mt: {
      xs: 2,
      sm: 0,
    },
    color: t.palette.grey[500],
  }),
  creationRequestDateModal: (t) => ({
    pl: {sm: 0},
    mt: {
      xs: 2,
      sm: 6,
    },
    color: t.palette.grey[500],
  }),
};
