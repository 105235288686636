import {SxProps, Theme} from '@mui/system';
import {initialColors} from '@src/theme';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  emptySearch: (t) => ({
    borderTop: `1px solid ${initialColors.blueLight}`,
    height: 'calc(100vh - 420px)',
    paddingTop: 12,
    [t.breakpoints.down('xl')]: {
      height: 'calc(100vh - 385px)',
    },
    [t.breakpoints.down('lg')]: {
      height: 'calc(100vh - 430px)',
    },
    [t.breakpoints.down('sm')]: {
      height: 'calc(100vh - 450px)',
      border: `1px solid ${initialColors.blueLight}`,
      padding: 12,
    },
  }),
};
