import {useCallback, useEffect, useState} from 'react';

import {useGoogleLoader} from '../GoogleLoaderProvider';

export const useGeocoder = () => {
  const loader = useGoogleLoader();
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | undefined>();
  useEffect(() => {
    void loader?.importLibrary('geocoding').then(({Geocoder}) => {
      const geocoder = new Geocoder();
      setGeocoder(geocoder);
    });
  }, [loader]);

  const getGeocoderResult = useCallback(
    async (gcr: google.maps.GeocoderRequest) => {
      try {
        const {results} = (await geocoder?.geocode(gcr)) ?? {};
        return results?.[0];
      } catch (e) {
        console.error('[getGeocoderResult -> Geocoder] Error', e);
      }
    },
    [geocoder],
  );

  return geocoder ? getGeocoderResult : undefined;
};
