import {Box, ListItemText, SelectChangeEvent, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {MenuTypes, StorageFileCategory} from '@src/api';
import {DatePickerRange} from '@src/components';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {MultiSelect} from '@src/components/MultiSelect';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {getCategoryOptions} from '@src/pages/HealthCase/utils/getCategoryOptions';
import {getMenuTypesOptions} from '@src/pages/HealthCase/utils/getMenuTypesOptions';
import {FiltersContainer} from '@src/pages/HealthCases/components/Filters/styles';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {useAppDispatch, useAppSelector} from '@src/store';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {storageActions} from '@src/store/storage/slice';
import {StorageFilters} from '@src/store/storage/types';
import {debounce} from 'lodash-es';
import {ChangeEvent, FC, MouseEvent, useMemo, useEffect, useState} from 'react';
import {IconButton, Option, SearchControl, SelectControl} from 'ui-kit';

import {sx} from './styles';
import {DocumentFiltersProps} from './types';

export const DocumentsFilters: FC<DocumentFiltersProps> = ({
  isOpen,
  handleCloseFilter,
  handleOpenFilter,
}) => {
  const {t} = useTranslate('healthCases');
  const filters = useAppSelector((state) => state.storage.files.filters);
  const files = useAppSelector((state) => state.storage.files);
  const [tempSelectedHealthCases, setTempSelectedHealthCases] = useState<string[]>([]);
  const [dialogFilters, setDialogFilters] = useState(filters);
  const dispatch = useAppDispatch();
  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = (mobile || tabletPortrait);

  const data = files.items;

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: StorageFilters
    updatedValue?: { key: keyof StorageFilters, value: any }
  }) => {
    dispatch(storageActions.setFilters({newState, updatedValue}));
  };

  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('')
  };

  const debouncedHandleChangeSearch = useMemo(() => debounce((value: string) => {
    handleDispatchSetFilters({
      updatedValue: {
        key: 'phrase',
        value,
      },
    });
  }, 500), [handleDispatchSetFilters]);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedHandleChangeSearch(value);
  };

  const handleChangeMenu = (_: MouseEvent<HTMLElement>, value: MenuTypes) => {
    handleDispatchSetFilters({updatedValue: {key: 'menu', value}});
  };

  const handleOpenDialog = () => {
    handleOpenFilter();
  };

  const handleClearAllFilters = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
    dispatch(storageActions.clearSelectedHealthCases());
    setTempSelectedHealthCases([]);
  };

  const handleChangeDate = (name: 'from' | 'to') => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
  };

  const handleClearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...INITIAL_DATES}));
  };

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    setDialogFilters((prevState) => ({...prevState, category: value}));
  };

  const healthCasesOptions = useMemo(() => {
    const allHealthCases = data?.flatMap((item) => item?.healthCases) || [];

    const uniqueHealthCasesMap = new Map(
      allHealthCases.map((healthCase) => [healthCase?.name, healthCase]),
    );

    const uniqueHealthCases = Array.from(uniqueHealthCasesMap.values());

    return uniqueHealthCases.map((healthCase) => ({
      value: healthCase?.id || '',
      name: healthCase?.name || '',
    }));
  }, [data]);

  const handleHealthCaseSelect = (value: string[]) => {
    const selectedHealthCases = value.map((id) => {
      const healthCase = healthCasesOptions.find(hc => hc.value === id);
      return healthCase ? {value: healthCase.value, name: healthCase.name} : null;
    }).filter(Boolean) as Array<{ value: string, name: string }>;

    setTempSelectedHealthCases(value);
    setDialogFilters((prevFilter) => ({
      ...prevFilter,
      healthCase: selectedHealthCases,
    }));
  };

  const handleUpdateFilters = (newFilters: StorageFilters) => {
    handleDispatchSetFilters({newState: newFilters});
  };

  const handleClearFilters = () => {
    setDialogFilters(INITIAL_FILTERS);
    setTempSelectedHealthCases([]);
  };

  const handleApply = () => {
    handleCloseFilter();
    handleUpdateFilters(dialogFilters);
    dispatch(storageActions.setSelectedHealthCases(tempSelectedHealthCases));
  };

  useEffect(() => {
    return () => {
      dispatch(storageActions.setFilters({ newState: INITIAL_FILTERS }));
      dispatch(storageActions.clearSelectedHealthCases());
      setTempSelectedHealthCases([]);
    };
  }, [dispatch]);

  const renderFilterDialog = () => {
    return (
      <FiltersDialogContainer
        isOpen={isOpen}
        onClose={handleCloseFilter}
        title={t('FILTERS')}
        onApply={handleApply}
        onClear={handleClearFilters}
      >
        <Stack gap={24}>
          <Stack gap={2}>
            <SelectControl
              placeholder={t('SELECT')}
              label={t('CATEGORY')}
              compact={true}
              value={dialogFilters.category || ''}
              onChange={handleChangeCategory}
              renderValue={(val: string) => t(val as TKeys<'healthCases'>)}
            >
              {getCategoryOptions(t).map((option) => (
                <Option key={option.label} value={option.value}>
                  <ListItemText primary={option.label} />
                </Option>
              ))}
            </SelectControl>
          </Stack>
          <Stack gap={2}>
            <MultiSelect
              options={healthCasesOptions}
              value={tempSelectedHealthCases}
              onChange={handleHealthCaseSelect}
              label={t('HEALTH_CASES')}
              placeholder={t('SELECT')}
              onChangeSearchValue={handleChangeSearchValue}
              searchValue={searchValueHealthCase}
              onClear={handleClearSearchValue}
              renderValue={(val) => (val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || '')}
              />
          </Stack>
          <Stack>
            <DatePickerRange
              sx={{mb: '0 !important'}}
              isIconVisible={false}
              variant="outlined"
              label={t('DATE_RANGE')}
              startDate={dialogFilters.from}
              endDate={dialogFilters.to}
              setStartDate={handleChangeDate('from')}
              setEndDate={handleChangeDate('to')}
              clearDate={handleClearDate}
              labelTop
              startRangeWithCurrentMonth
            />
          </Stack>
        </Stack>
      </FiltersDialogContainer>
    );
  };

  return (
    <>
      <FiltersContainer>
        <Stack sx={sx.searchToggleContainer}>
          <Box sx={sx.searchWrapper}>
            <SearchControl
              placeholder={t('SEARCH')}
              value={filters.phrase}
              onChange={handleChangeSearch}
            />
            {onPortrait && (
              <IconButton color={'secondary'} variant={'outlined'} onClick={handleOpenDialog}>
                <SettingsIcon />
              </IconButton>
            )}
          </Box>
          <ToggleButtonGroup
            color={'secondary'}
            fullWidth={onPortrait}
            value={filters.menu}
            exclusive
            onChange={handleChangeMenu}
          >
            {getMenuTypesOptions(t).map((option) => (
              <ToggleButton
                sx={sx.toggleButton}
                key={option.value}
                value={option.value}>
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {!onPortrait && (
            <>
              <IconButton color={'secondary-light'} variant={'outlined'} onClick={handleOpenDialog}>
                <SettingsIcon />
              </IconButton>
              <IconButton color={'secondary-light'} onClick={handleClearAllFilters}>
                <RefreshIcon />
              </IconButton>
            </>
          )}
        </Stack>
      </FiltersContainer>
      {renderFilterDialog()}
    </>
  );
};
