import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  option: {
    p: {xs: '14px 18px', sm: 12},
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
    minHeight: 'unset !important',
    '.CheckboxContainer': {
      height: 18,
    },
  },
  notFound: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    p: 12,
  },
  buttons: {
    flexDirection: 'row',
    gap: 12,
    p: 12,
    borderTop: '1px solid',
    borderColor: 'secondary.300',
  },
  searchInput: {
    height: 42,
    svg: {
      color: theme => theme.colors.all.grey8,
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'unset !important',
      borderBottom: '1px solid !important',
      borderColor: theme => theme.colors.secondary400 + '!important',
    },
  },
};
