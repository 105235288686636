import MuiGrid from '@mui/material/Unstable_Grid2';
import {styled} from '@mui/material';

export const Grid = styled(MuiGrid)`
.MuiGrid2-container {
  padding: 0;
}
`;

export default Grid;
