
import {Tabs, styled, Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  wrapper: {
    backgroundColor: t => t.palette.common.white,
    p: 18,
    flexGrow: 1,
    gap: {
      xs: 24,
      sm: 36,
    },
  },
};

export const StyledTabs = styled(Tabs)(({theme}) => ({
  padding: 0,
  '.MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    '.Mui-selected': {
      color: 'unset !important',
    },
    '.MuiButtonBase-root': {
      padding: 18,
      justifyContent: 'space-between',
      minHeight: 62,
      border: '1px solid',
      borderColor: theme.palette.secondary._14,
      fontSize: 22,
      fontWeight: 500,
      lineHeight: '26px',
      color: theme.palette.grey[800],
      svg: {
        path: {
          fill: theme.palette.grey[400],
        },
      },
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: 'unset',
  },
}));
