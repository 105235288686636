import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dateRange: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 12,
  },
  otherDates: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: 12,
  },
};
