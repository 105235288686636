import {Stack, Typography} from '@mui/material';
import {RowInformation} from '@src/components';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {MEDIUM_SIZED_DASH, NON_BREAKING_SPACE, NO_VALUE, dateFormatted} from '@src/shared/utils';

import {sx} from './styles';
import {IDateRangeProps} from './types';

export const DateRange = ({beginDate, endDate, spaceBetween}: IDateRangeProps) => {
  const {t} = useTranslate('accesses');
  const {mobile: isMobile} = useMQuery();

  const formattedBeginDate = dateFormatted(beginDate) ?? NO_VALUE;
  const formattedEndDate = dateFormatted(endDate) ?? NO_VALUE;

  const calculatedSpaceBetween = spaceBetween ?? (isMobile ? 8 : 0);

  if (beginDate && endDate) {
    return (
      <Stack sx={sx.dateRange}>
        <RowInformation
          spaceBetween={calculatedSpaceBetween}
          variant={isMobile ? '12_16_700' : '14_18_700'}
          valueTypographyVariant={isMobile ? '12_16_500' : '14_18_500'}
          name={t('DATE_RANGE')}
          value={`${formattedBeginDate}${NON_BREAKING_SPACE}${MEDIUM_SIZED_DASH}${NON_BREAKING_SPACE}${formattedEndDate}`}
          noMargin
        />
      </Stack>
    );
  }

  if (beginDate) {
    return (
      <Stack sx={sx.otherDates}>
        <RowInformation
          spaceBetween={calculatedSpaceBetween}
          variant={isMobile ? '12_16_700' : '14_18_700'}
          valueTypographyVariant={isMobile ? '12_16_500' : '14_18_500'}
          name={t('DATE_FROM')}
          value={`${formattedBeginDate}`}
          noMargin
        />
      </Stack>
    );
  }

  if (endDate) {
    return (
      <Stack sx={sx.otherDates}>
        <RowInformation
          spaceBetween={calculatedSpaceBetween}
          variant={isMobile ? '12_16_700' : '14_18_700'}
          valueTypographyVariant={isMobile ? '12_16_500' : '14_18_500'}
          name={t('DATE_TO')}
          value={`${formattedEndDate}`}
          noMargin
        />
      </Stack>
    );
  }

  return (
    <Stack sx={sx.otherDates}>
      <Typography variant={isMobile ? '12_16_700' : '14_18_700'}>{t('PERMANENT')}</Typography>
    </Stack>
  );
};
