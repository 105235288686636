import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    height: 124,
    padding: {
      xs: 18,
      md: 24,
    },
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    pb: {xs: 36, sm: 24},
    alignItems: 'center',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 124,
    padding: {
      xs: 18,
      md: 24,
    },
  },
  btn: {
    '& .MuiButton-startIcon': {
      width: 18,
      height: 18,
    },
  },
  description: (t) => ({
    color: t.palette.grey[500],
  }),
};
