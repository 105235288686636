import {isWithinInterval, isAfter, isBefore, isDate} from 'date-fns';

export const dateInInterval = (
  start: Date | null,
  end: Date | null,
  current?: string | Date | null,
) => {
  if (current) {
    const currentDate = (isDate(current) ? current : new Date(current)) as Date;
    if (start && end) {
      return isWithinInterval(currentDate, {
        start,
        end,
      });
    }
    if (start) return isAfter(currentDate, start);
    if (end) return isBefore(currentDate, end);
  }
};

export const sortByDate = (a: any, b: any, sort: any) => {
  const aDate = Date.parse(a[sort.sortBy] as string);
  const bDate = Date.parse(b[sort.sortBy] as string);
  return !sort.sortDesc ? bDate - aDate : aDate - bDate;
};
