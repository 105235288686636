import {Breadcrumbs, Stack, Typography} from '@mui/material';
import {Link} from '@src/components';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowBackIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as MobileComingSoon} from '@src/shared/assets/images/coming-soon_mobile.svg';
import {ReactComponent as ComingSoon} from '@src/shared/assets/images/coming-soon_tablet.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';

import {sx} from './styles';

export const AccessesResults = () => {
  const {mobile: isMobile} = useMQuery();
  const {t} = useTranslate('common');

  return (
    <Stack sx={sx.container}>
      <Breadcrumbs>
        <Link sx={sx.link} to={ROUTERS_PATH.CONNECTIONS_ACCESSES}>
          <ArrowBackIcon />
          <Typography sx={sx.linkText}>{t('BACK')}</Typography>
        </Link>
      </Breadcrumbs>
      {!isMobile && <ComingSoon width='100%' />}
      {isMobile && <MobileComingSoon width='100%' height='100%' />}
    </Stack>
  );
};
