/* eslint-disable no-comments/disallowComments */
import {configureStore} from '@reduxjs/toolkit';
import {accessesReducer, accessesStoreName} from '@src/store/accesses/slice';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import {cookieReducer, cookieStateName} from '../features/cookies/redux/slice';

import {accountReducer, accountStoreName} from './account/slice';
import {appointmentsReducer, appointmentsStoreName} from './appointments/slice';
import {bmiReducer, bmiStoreName} from './bmi/slice';
import {favoritesReducer, favoritesStoreName} from './favorites/slice';
import {globalNotificationsReducer, globalNotificationsStoreName} from './globalNotifications/slice';
import {healthCasesReducer, healthCasesStoreName} from './healthCases/slice';
import {insuranceReducer, insuranceStoreName} from './insurance/slice';
import {messengerStoreName, messengerReducer} from './messenger/slice';
import {notifyStateName, notifyReducer} from './notifications/slice';
import {relationsStoreName, relationsReducer} from './relations/slice';
import {resultsStateName, resultsReducer} from './results/slice';
import {rootSaga} from './sagas';
import {storageReducer, storageStoreName} from './storage/slice';
import {userReducer, userStoreName} from './user/slice';
const sagaMiddleware = createSagaMiddleware();

const reducer = {
  [notifyStateName]: notifyReducer,
  [accountStoreName]: accountReducer,
  [resultsStateName]: resultsReducer,
  [messengerStoreName]: messengerReducer,
  [userStoreName]: userReducer,
  [relationsStoreName]: relationsReducer,
  [cookieStateName]: cookieReducer,
  [accessesStoreName]: accessesReducer,
  [favoritesStoreName]: favoritesReducer,
  [appointmentsStoreName]: appointmentsReducer,
  [insuranceStoreName]: insuranceReducer,
  [bmiStoreName]: bmiReducer,
  [storageStoreName]: storageReducer,
  [healthCasesStoreName]: healthCasesReducer,
  [globalNotificationsStoreName]: globalNotificationsReducer,
};

export const store = configureStore({
  reducer,
  // @ts-expect-error
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV === 'development',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
