import {BMIDataType} from '@src/api';

export enum ERANGES {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
}

export enum RangeName {
  WEEK = 'week',
  MONTH = 'month',
}

export interface IStatisticsProps {
  data: BMIDataType[]
  isChangeGrid: boolean
  isLoading: boolean
}

export interface BrushChangeEvent {
  startIndex?: number
  endIndex?: number
}

export enum StepHandlers {
  PREV = 'prev',
  NEXT = 'next'
}
