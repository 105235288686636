import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  gridWrapper: {
    p: {
      xs: '0px 24px 18px 24px',
      lg: 0,
    },
  },
  gridWrapperMobile: {
    margin: '0px 18px 24px 18px',
    display: 'flex',
    flexFlow: 'row nowrap',
    overflowX: 'auto',
  },
  title: {
    typography: '18_24_700',
    mb: {
      xs: 12,
      lg: 24,
    },
    ml: {
      xs: 18,
      sm: 24,
      lg: 0,
    },
  },
  container: {
    p: {
      lg: '0px 24px 36px 24px',
    },
    '&:last-child': {
      p: {
        lg: '0px 24px 24px 24px',
      },
    },
    '&:last-of-type': {
      mt: 6,
    },
  },
  gridContainer: {
    display: 'grid',
    minWidth: 'auto',
  },
};
