import {Contact} from '@api';
import {Box, Stack, SxProps, Theme} from '@mui/material';
import Grid from '@src/components/Grid';
import {useTranslate} from '@src/i18n/useTranslate';
import {NO_VALUE} from '@src/shared/utils';
import {alpha} from '@src/theme/utils';
import {Nullable} from '@src/types/NullableModel';
import {FC} from 'react';

import {RowInformation} from './RowInformation';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  rowInformation: {
    typography: '16_20_700',
    color: (t: Theme) => t.colors.defaultTextColor,
    mb: 0,
  },
  rowInformationValue: {
    typography: '16_20_500',
    whiteSpace: 'nowrap',
  },
  container: (t: Theme) => ({
    position: {lg: 'relative'},
    marginTop: 18,
    [t.breakpoints.only('xl')]: {
      justifyContent: 'space-between',
    },
    [t.breakpoints.only('lg')]: {
      justifyContent: 'space-between',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: -20,
      left: 0,
      width: '100%',
      borderBottom: `1px solid ${alpha(t.palette.secondary.main, 21)}`,
    },
  }),
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentContainerLeft: (t: Theme) => ({
    [t.breakpoints.only('xl')]: {
      width: '48.5%',
      pr: 24,
    },
    [t.breakpoints.only('lg')]: {
      width: '48.5%',
      pr: 24,
    },
  }),
  contentContainerRight: (t: Theme) => ({
    [t.breakpoints.only('xl')]: {
      width: '46.5%',
    },
    [t.breakpoints.only('lg')]: {
      width: '46.5%',
    },
  }),
  rowInformationContainer: (t: Theme) => ({
    minWidth: '10%',
    [t.breakpoints.only('xl')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    [t.breakpoints.only('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
};

interface Props {
  contact: Nullable<Contact>
}

export const GeneralSubInfoDesktop: FC<Props> = ({
  contact,
}) => {
  const {
    birthSex,
    gender,
    sexualOrientation,
    race,
    ethnicity,
    ssnTail,
  } = contact;
  const {t} = useTranslate('overview');

  return (
    <Grid container sx={sx.container}>
      <Stack sx={sx.rowInformationContainer}>
        <Box sx={sx.contentContainerLeft}>
          <Grid xs={12}>
            <RowInformation
              ellipsisText
              ellipsisTextSx={sx.rowInformationValue}
              value={t(birthSex) || NO_VALUE}
              name={t('BIRTH_SEX')}
              sx={sx.rowInformation}
            />
          </Grid>
          <Grid xs={12}>
            <RowInformation
              ellipsisText
              ellipsisTextSx={sx.rowInformationValue}
              value={t(gender) || NO_VALUE}
              name={t('GENDER')}
              sx={sx.rowInformation}
            />
          </Grid>
          <Grid xs={12}>
            <RowInformation
              ellipsisText
              ellipsisTextSx={sx.rowInformationValue}
              value={t(sexualOrientation) || NO_VALUE}
              name={t('SEXUAL_ORIENTATION')}
              sx={sx.rowInformation}
            />
          </Grid>
        </Box>
        <Box sx={sx.contentContainerRight}>
          <Grid xs={12}>
            <RowInformation
              ellipsisText
              ellipsisTextSx={sx.rowInformationValue}
              value={t(race) || NO_VALUE}
              name={t('RACE')}
              sx={sx.rowInformation}
            />
          </Grid>
          <Grid xs={12}>
            <RowInformation
              ellipsisText
              ellipsisTextSx={sx.rowInformationValue}
              value={t(ethnicity) || NO_VALUE}
              name={t('ETHNICITY')}
              sx={sx.rowInformation}
            />
          </Grid>
          <Grid xs={12}>
            <RowInformation
              ellipsisText
              ellipsisTextSx={sx.rowInformationValue}
              value={ssnTail || NO_VALUE}
              name={t('SSN_LAST_4')}
              sx={sx.rowInformation}
            />
          </Grid>
        </Box>
        <Box width={42} height={42} />
      </Stack>
    </Grid>
  );
};
