import {styled} from '@mui/material';
import {Button} from 'ui-kit';

export const SexButton = styled(Button)<{checked?: boolean}>(({theme, checked}) => ({
  minWidth: 93,
  borderWidth: checked ? 2 : 1,
  color: checked ? theme.palette.primary[900] : theme.palette.grey[800],
  borderColor: checked ? theme.palette.primary[900] : theme.palette.grey[300],
  svg: {
    '--icon-fill': checked ? theme.palette.primary[900] : theme.palette.grey[800],
  },
  ':hover': {
    borderWidth: checked ? 2 : 1,
    color: theme.palette.primary[800],
    borderColor: checked ? theme.palette.primary[800] : theme.palette.grey[400],
    svg: {
      '--icon-fill': `${checked ? theme.palette.primary[800] || '' : theme.palette.primary[800] || ''} !important`,
    },
  },
  ':disabled': {
    color: theme.palette.grey[800],
    svg: {
      color: theme.palette.grey[800],
      '--icon-fill': theme.palette.grey[800],
    },
  },
}));
