/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {alpha} from '@src/theme/utils';
import {DetailedHTMLProps} from 'react';

export const UlContainer = styled('ul')`
    z-index: 1;
    position: absolute;
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    border: 1px solid ${({theme}) => theme.colors.borderLight};
    width: 247px;
    filter: drop-shadow(0px 8px 12px rgba(77, 77, 77, 0.07)) drop-shadow(0px 0px 6px rgba(77, 77, 77, 0.07));
    .tablet-layout &, .mobile-layout & {
        width: 300px;
        right: 0 !important;
    }
`;
export const ListItem = styled('li')`
    box-sizing: border-box;
    text-align: left;
    display: flex;
    align-items: center;
    background-color: white;
    &:first-child {
        border-bottom: 1px solid ${({theme}) => alpha(theme.palette.secondary.main, 14)};
    }
`;
export const MenuLink = styled(({isIconRight: _, ...restProps}: DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {isIconRight: boolean}) => {
  return <a {...restProps}/>;
})<{isIconRight?: boolean, isIconLeft?: boolean}>`
    cursor: pointer;
    word-break: break-word;
    padding: 10px 42px 10px 16px;
    padding-right: ${({isIconRight: icon}) => icon ? 42 : 16}px !important;
    padding-left: ${({isIconLeft: icon}) => icon ? 44 : 16}px !important; 
    min-height: 44px; 
    color: ${({theme}) => theme.colors.defaultTextColor};
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    >strong {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &:hover {
        background-color: ${({theme}) => theme.colors.secondary5};
        color: ${({theme}) => theme.colors.secondary};
        svg {
            path {
                ${({isIconLeft, theme}) => !isIconLeft && `fill: ${theme.colors.secondary}`}
            }
        }
    }
    &:active {
        background-color: ${({theme}) => theme.colors.secondary10};
        color: ${({theme}) => theme.colors.secondary};
        svg {
            path {
                fill: ${({theme}) => theme.colors.secondary}
            }
        }
    }
    svg {
        path {
            ${({isIconLeft, theme}) => !isIconLeft && `fill: ${theme.colors.defaultTextColor}`}
        }
    }
    .mobile-layout &, .tablet-layout & {
        padding-left: 24px;
        padding-right: ${({isIconRight: icon}) => icon ? 60 : 24}px !important;
        padding-left: ${({isIconLeft: icon}) => icon ? 60 : 24}px !important;
    }
`;

export const MenuIcon = styled('span')<{isIconLeft?: boolean}>`
    position: absolute;
    right: ${({isIconLeft: icon}) => icon ? 'auto' : 0}px !important;
    left: ${({isIconLeft: icon}) => icon ? 0 : 'auto'}px !important;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    .mobile-layout &, .tablet-layout & {
        padding: 0 24px;
    }
`;
