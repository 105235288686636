import {useState, useEffect, useRef, useCallback} from 'react';

interface IBreakpoints {
  xxl?: number
  xl?: number
  lg?: number
  sm?: number
  md?: number
}

interface IColumns extends IBreakpoints {}

interface IUseColumnsNumberProps {
  breakpoints: IBreakpoints
  defaultColumns: IColumns
  customColumns: IColumns
}

export const useColumnsNumber = ({breakpoints, defaultColumns, customColumns}: IUseColumnsNumberProps) => {
  const {xxl, xl, lg, md, sm} = breakpoints;
  const [colsNumberXxl, setColsNumberXxl] = useState(defaultColumns.xxl || 0);
  const [colsNumberXl, setColsNumberXl] = useState(defaultColumns.xl || 0);
  const [colsNumberLg, setColsNumberLg] = useState(defaultColumns.lg || 0);
  const [colsNumberMd, setColsNumberMd] = useState(defaultColumns.md || 0);
  const [colsNumberSm, setColsNumberSm] = useState(defaultColumns.sm || 0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleContainerResize = useCallback(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;

      if (sm) setColsNumberSm(width < sm ? (customColumns.sm || 0) : (defaultColumns.sm || 0));
      if (md) setColsNumberMd(width < md ? (customColumns.md || 0) : (defaultColumns.md || 0));
      if (lg) setColsNumberLg(width < lg ? (customColumns.lg || 0) : (defaultColumns.lg || 0));
      if (xl) setColsNumberXl(width < xl ? (customColumns.xl || 0) : (defaultColumns.xl || 0));
      if (xxl) setColsNumberXxl(width > xxl ? (customColumns.xxl || 0) : (defaultColumns.xxl || 0));
    }
  }, [xxl, xl, lg, md, sm, customColumns, defaultColumns]);

  useEffect(() => {
    handleContainerResize();
    const resizeObserver = new ResizeObserver(handleContainerResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, breakpoints, customColumns, defaultColumns, handleContainerResize]);

  return {colsNumberXxl, colsNumberXl, colsNumberLg, colsNumberMd, colsNumberSm, containerRef};
};
