// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Box, Stack, Theme, Typography} from '@mui/material';
import {Dl, RowInformation} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {PopoverTooltip, usePopoverTooltip} from '@src/components/PopoverTooltip';
import {Checkbox, CheckboxControl} from '@src/components/form/CheckboxControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {getReferenceText, getResultColor} from '@src/pages/Details/helpers';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC, Fragment, useMemo} from 'react';

import {Accordion, AccordionDetails} from '../StyledAccordionSummary/StyledAccordionSummary';

import {stylesSx} from './styles';
import {TestRowType} from './types';

export const TableRow: FC<TestRowType> = ({
  resultTests,
  className,
  changeCodesForDynamics,
  changeChildrenStatus,
  childrenStatus,
  sx,
}) => {
  const {mobile, tabletPortrait, desktop: isDesktop, lg: isDesktopLg} = useMQuery();
  const {t} = useTranslate('details');
  const {onOpen: handleClick, open, ...forPopover} = usePopoverTooltip();
  const {isSidebarOpen} = useSidebarClassObserver();

  const handleChangeCheckbox = (checked: boolean, loinc: string, index: number) => {
    if (changeCodesForDynamics) {
      changeCodesForDynamics(loinc, checked);
    }
    changeChildrenStatus(index, checked);
  };

  const gridTemplateColumns = useMemo(() => {
    if (isDesktop && !isDesktopLg) return '250px auto 100px 160px 140px';
    if (isDesktopLg && !isSidebarOpen) return '250px minmax(211px, 1fr) 100px 160px 140px';
    if (isDesktopLg && isSidebarOpen) return '190px minmax(188px, 1fr) 100px 160px 140px';
    return '24.89% 28.17% 6.05% 12.59% 18.25%';
  }, [isDesktop, isDesktopLg, isSidebarOpen]);

  return (
    <>
      {resultTests.map((result, index) => {
        const isCritical = result.criticalReference?.isOutOfRef;
        const critical = isCritical ? '#FCF2F5' : 'initial';
        const colorInconsiderable = '#4b5563';

        const {name, value, comment, reference, criticalReference, code, loinc, units} = result;

        const resultColor = getResultColor(result);

        const valueNode = (
          <>
            <div
              style={{color: resultColor, fontWeight: resultColor ? 700 : 'initial'}}
            >
              {value || '--'}
            </div>
          </>
        );

        if (mobile || tabletPortrait) {
          return (
            <Fragment key={`${code}/tests/${index}`}>
              <Accordion
                sx={(t: Theme) => ({
                  borderBottom: `1px solid ${t.colors.borderLight}`,
                })}
                key={`${code}/tests/${index}`}
              >
                <AccordionDetails>
                  <Box sx={stylesSx.boxPaddings}>
                    <Dl sx={stylesSx.dlPosition}>
                      <Checkbox
                        sx={stylesSx.checkboxPosition}
                        checked={childrenStatus[index]}
                        color="secondary"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        onChange={(_, checked) => handleChangeCheckbox(checked, loinc, index)}
                      />
                      <dt>
                        <Typography variant="12_16_700">{t('TEST')}</Typography>
                      </dt>
                      <dd>
                        <Typography variant="12_16_500">{name}</Typography>
                      </dd>
                    </Dl>
                    <Dl sx={{flexDirection: !valueNode.props.children[1] ? 'row' : 'column'}}>
                      <dt>
                        <RowInformation
                          valueTypographyVariant={'12_16_500'}
                          variant={'12_16_700'}
                          value={valueNode}
                          name={t('RESULTS')}
                          noMargin
                          noWrap
                          valueSx={{
                            whiteSpace: 'break-spaces',
                          }}
                        />
                      </dt>
                    </Dl>
                    <Dl>
                      <dt>
                        <Typography variant="12_16_700">{t('UNITS')}</Typography>
                      </dt>
                      <dd>
                        <Typography variant="12_16_500">{units}</Typography>
                      </dd>
                    </Dl>
                    <Dl>
                      <RowInformation
                        valueTypographyVariant={'12_16_500'}
                        variant={'12_16_700'}
                        value={comment || t('NO_COMMENTS')}
                        name={t('COMMENTS')}
                        noMargin
                        noWrap
                        valueSx={{
                          whiteSpace: 'break-spaces',
                        }}
                      />
                    </Dl>
                    <Dl
                      sx={{
                        flexDirection: !reference && !criticalReference ? 'row' : 'column',
                        mb: '0px !important',
                      }}
                    >
                      <dt>
                        <Typography variant="12_16_700">{t('REFERENCE_INTERVAL')}</Typography>
                      </dt>
                      <dd>
                        <Box sx={{whiteSpace: 'pre-wrap'}}>
                          <Typography variant="12_16_500" color={colorInconsiderable}>
                            {getReferenceText(result, t)}
                          </Typography>
                        </Box>
                      </dd>
                    </Dl>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <PopoverTooltip {...forPopover} open={open && Boolean(comment)}>
                {valueNode}
                <br />
                {comment}
              </PopoverTooltip>
            </Fragment>
          );
        }
        return (
          <>
            <Box
              minWidth={{lg: 1033}}
              paddingX={{xs: 24, md: 69, lg: 126}}
              paddingRight={{lg: 36, xs: 36, md: 24}}
              paddingY={{lg: 12, xs: 8, md: 12}}
              sx={sx}
              key={`${code}/tests/${index}`}
              className={className}
              style={{
                gridTemplateColumns,
                backgroundColor: critical,
              }}
            >
              <Box>
                <Box sx={stylesSx.boxContainer}>
                  <CheckboxControl
                    noMargin
                    sx={{padding: '0px !important'}}
                    checked={childrenStatus[index]}
                    color="secondary"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(_, checked) => {
                      if (changeCodesForDynamics) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        changeCodesForDynamics(loinc, checked);
                      }
                      changeChildrenStatus(index, checked);
                    }}
                    label={name || '--'}
                  />
                </Box>
              </Box>
              <Stack sx={{gap: 6}} onClick={handleClick}>
                {valueNode}
              </Stack>
              <Box>{units}</Box>
              {!mobile && !tabletPortrait && (
                <>
                  <Box>
                    <EllipsisTextTooltip
                      ellipsisText={comment || t('NO_COMMENTS')}
                      heightCheck
                      variant={'14_18_500'}
                      sx={stylesSx.commentText}
                    />
                  </Box>
                  <Box sx={{whiteSpace: 'pre-wrap'}}>{getReferenceText(result, t)}</Box>
                </>
              )}
            </Box>
            <PopoverTooltip {...forPopover} open={open && Boolean(comment)}>
              {valueNode}
              <br />
              {comment}
            </PopoverTooltip>
          </>
        );
      })}
    </>
  );
};
