import {Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  checkExpired: (t) => ({
    display: 'flex',
    gap: 18,
    mr: {lg: 36},
    [t.breakpoints.between('sm', 'lg')]: {
      height: 24,
    },
  }),
};
