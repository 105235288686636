import {Theme} from '@mui/material';

export const sx = {
  mainContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    [t.breakpoints.down('lg')]: {
      gap: 24,
    },
    [t.breakpoints.down('sm')]: {
      pb: 120,
      gap: 18,
    },
    flexGrow: 1,
  }),
  buttonsContainer: (t: Theme) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    boxShadow: '0px -2px 12px 0px #0000001A',
    background: t.colors.background,
    gap: 12,
    display: 'grid',
  }),
  boxPaddings: {
    padding: {
      xs: '18px 16px',
      md: '24px',
      lg: '24px 36px',
    },
  },
  wBoxContainer: {
    flexGrow: 1,
    pb: {lg: 24},
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bthContainer: {
    display: 'flex',
    gap: 36,
  },
  wBoxContent: {
    height: {lg: 'auto'},
    padding: {
      xs: '12px 18px',
      sm: '18px 24px',
      lg: '30px 36px',
    },
  },
  headerSx: {
    mb: {xs: 18, sm: 24},
  },
  breadcrumbsText: (t: Theme) => ({
    color: t.colors.breadcrumbsText,
  }),
  rowInformation: {
    mb: {lg: 12},
  },
  boxHeader: {
    mb: 24,
    typography: {xs: '22_30_500', sm: '24_30_500'},
  },
  healthCase: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    '&:hover': {
      textDecoration: 'none',
      color: (t: Theme) => t.palette.secondary[800],
    },
    '&:active': {
      color: (t: Theme) => t.palette.secondary.dark,
    },
    typography: {xs: '14_18_500', sm: '16_24_500'},
  },
  healthCasesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 18,
    rowGap: 12,
  },
  healthCasesBtn: {
    p: 0,
    minWidth: 'auto',
    fontWeight: 600,
    fontSize: {xs: 14, sm: 16},
    alignItems: 'center',
    '& .MuiButton-startIcon': {
      width: 18,
      height: 18,
      mr: 6,
      mb: 2,
    },
  },
};
