import {InfiniteScrollContainer} from '@components';
import {Box, styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconButton: {
    svg: {
      width: 24,
      height: 24,
    },
  },
  fixedButtonContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    boxShadow: '0px -2px 12px 0px #0000001A',
    background: (theme) => theme.colors.background,
    display: 'flex',
    padding: {xs: 18, sm: 24},
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: {xs: 12, sm: 24},
    '& > *': {
      width: {xs: '100%', sm: '50%'},
    },
  },
  generalContainer: {
    flex: 1,
  },
  dialog: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitle: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  btnOk: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
  dialogActions: (t) => ({
    backgroundColor: t.palette.error.main,
    margin: 0,
    p: '16px 24px',
    justifyContent: 'flex-end !important',
    alignItems: 'end',
  }),
  dialogContent: {
    p: '36px 24px !important',
  },
  loadingContainer: {flex: 1, justifyContent: 'center', alignItems: 'center'},
  emptyText: (t) => ({
    color: t.palette.grey[500],
    fontStyle: 'italic',
    typography: '14_18_500',
  }),
};

export const MainWrapper = styled(InfiniteScrollContainer)<{
  isSidebarOpen: boolean
  isScrolled: boolean
}>(({theme, isScrolled}) => ({
  // TODO: Need to change the layout of parent components and get rid of calc(...) everywhere
  [theme.breakpoints.up('xs')]: {
    paddingTop: 18,
    paddingBottom: 18,
    height: isScrolled
      ? `calc(100vh - 120px - 78px - ${theme.header.height.sm}px)`
      : `calc(100vh - 167px - 48px - 42px - 24px - ${theme.header.height.sm}px)`,
    marginTop: isScrolled && -6,
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 24,
    height: `calc(100vh - 113px - 42px - 36px - 73px - ${theme.header.height.lg}px)`,
  },
}));

export const MainContainer = styled(Box)<{ isSidebarOpen: boolean }>(
  ({theme, isSidebarOpen}) => ({
    display: 'grid',
    gap: 18,
    [theme.breakpoints.only('xs')]: {
      ':last-child': {
        marginBottom: 18,
      },
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: isSidebarOpen ? '1fr' : '1fr 1fr',
      gap: 24,
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: isSidebarOpen ? '1fr 1fr' : '1fr 1fr 1fr',
    },
    [theme.breakpoints.up('xxl')]: {
      gridTemplateColumns: isSidebarOpen ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
    },
  }),
);
