import {styled} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
interface ITextProps {
  mt?: number
  mb?: number
  pt?: number
  pb?: number
  lh?: number
  fz?: number
  weight?: number
}

export const H2 = styled('h2')<ITextProps>`
  font-weight: 700;
  font-size: ${({fz}) => `${fz ?? ''}px`};
  line-height: ${({lh}) => `${lh ?? ''}px`};
  margin-top: ${({mt}) => `${mt ?? 0}px`};
  margin-bottom: ${({mb}) => `${mb ?? 0}px`};
`;
H2.defaultProps = {
  mt: 0,
  mb: 48,
  fz: 32,
  lh: 36,
};

export const H3 = styled('h3')<ITextProps>`
  font-weight: ${({weight}) => weight ?? 700};
  font-size: ${({fz}) => `${fz ?? ''}px`};
  line-height: ${({lh}) => `${lh ?? ''}px`};
  margin-top: ${({mt}) => `${mt ?? 0}px`};
  margin-bottom: ${({mb}) => `${mb ?? 0}px ${mb != null ? '!important' : ''}`};
`;
export const H4 = styled('h4')<ITextProps>`
  font-weight: ${({weight}) => weight ?? 700};
  font-size: ${({fz}) => `${fz ?? '18'}px`};
  line-height: ${({lh}) => `${lh ?? ''}px`};
  margin-top: ${({mt}) => `${mt ?? 0}px`};
  margin-bottom: ${({mb}) => `${mb ?? 0}px`};
`;
export const H5 = styled('h5')<ITextProps>`
  font-weight: ${({weight}) => weight ?? 700};
  font-size: ${({fz}) => `${fz ?? '16'}px`};
  line-height: ${({lh}) => `${lh ?? ''}px`};
  margin-top: ${({mt}) => `${mt ?? 0}px`};
  margin-bottom: ${({mb}) => `${mb ?? 0}px`};
`;
H5.defaultProps = {
  mb: 0,
  mt: 0,
  lh: 20,
};
export const Text = styled('p')<ITextProps>`
  padding: ${({pb, pt}) => `${pt ?? 0}px 0 ${pb ?? 0}px`};
  margin: ${({mb, mt}) => `${mt ?? 0}px 0 ${mb ?? 0}px`};
  font-size: ${({fz}) => `${fz ?? 0}px`};
  color: ${({color}) => color};
  line-height: ${({lh}) => `${lh ?? 0}px`};
  font-weight: ${({weight}) => weight ?? 0};
`;
Text.defaultProps = {
  mb: 0,
  mt: 0,
  fz: 16,
  lh: 20,
  weight: 400,
};
export const Nothing = styled('p')<Omit<ITextProps, 'color'>>`
  padding: ${({pb, pt}) => `${pt ?? 0}px 0 ${pb ?? 0}px`};
  margin: ${({mb, mt}) => `${mt ?? 0}px 0 ${mb ?? 0}px`};
  font-size: ${({fz}) => `${fz ?? ''}px`};
  color: ${({theme}) => theme.colors.all.grey4};
  line-height: ${({lh}) => `${lh ?? 0}px`};
  font-weight: ${({weight}) => weight ?? 0};
`;
Nothing.defaultProps = {
  mb: 0,
  mt: 0,
  fz: 16,
  lh: 20,
  weight: 400,
};
export const Link = styled(RouterLink)<ITextProps>`
  text-decoration: none;
  &:visited {
    color: ${({theme}) => theme.colors.link.color}
  }
  &:active {
    color: ${({theme}) => theme.colors.link.active}
  }
  &:hover {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.link.hover}
  }
  padding: ${({pb, pt}) => `${pt ?? 0}px 0 ${pb ?? 0}px`};
  margin: ${({mb, mt}) => `${mt ?? 0}px 0 ${mb ?? 0}px`};
  font-size: ${({fz}) => `${fz ?? ''}px`};
  color: ${({theme}) => theme.colors.link.color};
  line-height: ${({lh}) => `${lh ?? ''}px`};
  font-weight: ${({weight}) => weight ?? ''};
  display: inline-flex;
  align-items: center;
  svg {padding-right: 10px;}
  white-space: nowrap;
`;
Link.defaultProps = {
  fz: 16,
  lh: 20,
  weight: 500,
};

export const Nowrap = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: 100%;
`;

export const Dl = styled('dl')`
    margin: 0;
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 20px;
    dt,dd {
        display: inline-block;
        word-break: break-word;
        font-size: 14px;
        font-family: inherit
    }
    dt {
        white-space: nowrap;
        flex: 0 1 auto;
    }
    dd {
        margin-left: 0;
    }
    .mobile-layout &, .tablet-layout & {
        gap: 12px;
        row-gap: 6px;
    }
`;
