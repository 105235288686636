import {RelationProfile} from '@src/api/relations';
import {TKeys} from '@src/i18n/useTranslate';
import {ssnTailValidator} from '@src/shared/utils/validators';
import {TFunction} from 'i18next';
import * as Yup from 'yup';

interface validationSchemaProps {
  t: TFunction
  accesses: Record<string, string>
  relation: RelationProfile | null
  validateField: (operations: string, baseValue?: string | null, changedValue?: string | null) => boolean
};

export const validationSchema = ({t, accesses, relation, validateField}: validationSchemaProps) => Yup.object({
  firstName: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test(
      'notEmpty',
      t('REQUIRED_FIELD'),
      function (value) {
        return value !== null && value !== undefined && value.trim() !== '';
      },
    ),
  lastName: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test(
      'notEmpty',
      t('REQUIRED_FIELD'),
      function (value) {
        return value !== null && value !== undefined && value.trim() !== '';
      },
    ),
  birthDate: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test(
      'is-birthDate',
      'Change forbidden',
      (value) => validateField(accesses.birthDate, relation?.contact.birthDateS, value),
    ),
  birthSex: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test(
      'is-birthSex',
      'Change forbidden',
      (value) => validateField(accesses.birthSex, t(relation?.contact.birthSex ?? ''), t(value as TKeys<'connections'>)),
    ),
  race: Yup.string()
    .nullable()
    .test(
      'is-race',
      'Change forbidden',
      (value) => validateField(accesses.race, t(relation?.contact.race ?? ''), t(value as TKeys<'connections'>)),
    ),
  gender: Yup.string()
    .nullable()
    .test(
      'is-gender',
      'Change forbidden',
      (value) => validateField(accesses.gender, t(relation?.contact.gender ?? ''), t(value as TKeys<'connections'>)),
    ),
  sexualOrientation: Yup.string()
    .nullable()
    .test(
      'is-sexualOrientation',
      'Change forbidden',
      (value) => validateField(accesses.sexualOrientation, t(relation?.contact.sexualOrientation ?? ''), t(value as TKeys<'connections'>)),
    ),
  ethnicity: Yup.string()
    .nullable()
    .test(
      'is-ethnicity',
      'Change forbidden',
      (value) => validateField(accesses.ethnicity, t(relation?.contact.ethnicity ?? ''), t(value as TKeys<'connections'>)),
    ),
  middleName: Yup.string()
    .nullable()
    .notRequired(),
  ssnTail: ssnTailValidator(t),
});
