import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

export const RequestAccessBtn = () => {
  const {t} = useTranslate('accesses');
  const {tablet: isTablet} = useMQuery();

  const navigate = useNavigate();

  const handleBthClick = () => navigate(ROUTERS_PATH.CONNECTIONS_ACCESSES_REQUESTS_ACCESS);

  return (
    <Button
      sx={{minWidth: {lg: 136}}}
      size={isTablet ? 'xs' : 'lg'}
      variant={isTablet ? 'text' : 'contained'}
      onClick={handleBthClick}
    >
      {t('REQUEST_ACCESS')}
    </Button>
  );
};
