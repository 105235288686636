import {useRef, useEffect} from 'react';

function createRootElement (id: string) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}
function addRootElement (rootElem: Element) {
  document.body.appendChild(rootElem);
}

function usePortal (id: string, containerClassName?: string) {
  const rootElemRef = useRef<Element>();

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent ?? createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElem);
    }

    parentElem.appendChild(rootElemRef.current as Node);

    return () => {
      rootElemRef.current?.remove?.();
      if (!parentElem.childElementCount) {
        parentElem.remove();
      }
    };
  }, [id]);

  function getRootElem () {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
      if (containerClassName) {
        rootElemRef.current.classList.add(containerClassName);
      }
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export default usePortal;
