/** @jsxImportSource @emotion/react */
import {Box, css as _} from '@mui/material';
import {DateTime, Info} from 'luxon';
import {FC} from 'react';

import {DateButton} from './YearArea';

export const MonthsArea: FC<{
  onChange: (d: DateTime) => void
  date: DateTime
  selected?: DateTime | null
}> = ({date, selected, onChange}) => {
  const now = DateTime.now();
  const currentYear = date.hasSame(now, 'year');
  return (
    <Box
      css={_`display: flex; flex-wrap: wrap; row-gap: 24px; margin: 0 -4px; align-self: center`}
    >
      {Info.months('long').map((m) => {
        return (
          <DateButton
            key={m}
            css={_`width: calc(100% / 3)`}
            active={selected?.monthLong === m && date.year === selected?.year}
            current={currentYear && now.monthLong === m}
            onClick={() => {
              onChange(date.set({month: Info.months('long').indexOf(m) + 1}));
            }}
          >
            {m}
          </DateButton>
        );
      })}
    </Box>
  );
};
