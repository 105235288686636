import {styled} from '@mui/material';

import {ReactComponent as X24_SM} from '../24x24/cross-sm.svg';
import {ReactComponent as X24} from '../24x24/cross.svg';
import {ReactComponent as X40} from '../40x40/cross.svg';
import {IconColorProps} from '../types';

const X24Component = styled(X24)<IconColorProps>(({theme, color = 'white'}) => {
  return {
    '& path': {
      fill: theme.palette.icon[color],
    },
  };
});

const X40Component = styled(X40)<IconColorProps>(({theme, color = 'grey'}) => {
  return {
    '& path': {
      fill: theme.palette.icon[color],
    },
  };
});

const X24SMComponent = styled(X24_SM)<IconColorProps>(({theme, color = 'grey'}) => {
  return {
    '& path': {
      fill: theme.palette.icon[color],
    },
  };
});

export const Cross = {
  X40: X40Component,
  X24: X24Component,
  X24_SM: X24SMComponent,
};
