import {useTranslate} from '@src/i18n/useTranslate';
import * as Yup from 'yup';

export const useValidationSchema = (isOptionSelected: boolean) => {
  const {t} = useTranslate('connections');

  return Yup.object({
    address1: Yup.string()
      .nullable()
      .when('address2', {
        is: (address2: string) => !!address2,
        then: Yup.string().required(t('REQUIRED_FIELD')),
        otherwise: Yup.string().nullable(),
      }),
    streetNumber: Yup.string()
      .nullable()
      .test('is-clickToOption', t('ENTER_VALID_ADDRESS'), function () {
        return isOptionSelected || !this.parent.address1;
      })
      .test('is-addressStreetNumber', t('PLEASE_PROVIDE_THE_BUILDING_ADDRESS'), function (value) {
        if (!value && this.parent.address1) {
          return false;
        }
        return true;
      }),

    address2: Yup.string().nullable(),
  });
};
