import {Box, Typography} from '@mui/material';
import {EmptySearch} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {CRITICAL_FLAG} from '@src/pages/Connections/fragments/Relations/fragments/ResultsTab/constants';
import {EBackgroundFlagColor, EFlagColor, EFlagName} from '@src/pages/Results/Results.constant';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {DATE_FORMAT, dateToFormat, getFullNameWithoutMiddleName} from '@src/shared/utils';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {createColumnHelper} from '@tanstack/react-table';
import {Result} from 'api';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useMemo} from 'react';
import {Checkbox} from 'ui-kit';

import {ResultsTableProps} from './types';

export const ResultsTable: FC<ResultsTableProps> = ({result, isLoading}) => {
  const {t} = useTranslate('results');
  const columnHelper = createColumnHelper<Result>();
  const dispatch = useAppDispatch();
  const {selectedResults, initialResults, savedResults} = useAppSelector((state) => state.healthCases);

  const handleCheckboxChange = (data: Result) => {
    if (!initialResults.has(data.id) && !savedResults.has(data.id)) {
      dispatch(healthCasesActions.toggleResultsSelection(data.id));
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('reported', {
        id: 'checkbox',
        header: '',
        maxSize: 50,
        enableSorting: false,
        cell: ({row}) => {
          const isChecked = selectedResults.has(row.original.id) || initialResults.has(row.original.id) || savedResults.has(row.original.id);
          return (
            <Checkbox
              sx={{ mr: 0, height: 'unset'}}
              value={isChecked}
              onClick={() => handleCheckboxChange(row.original)}
              disabled={initialResults.has(row.original.id) || savedResults.has(row.original.id)}
            />
          );
        },
      }),
      columnHelper.accessor('collected', {
        id: 'collected',
        header: t('COLLECTED'),
        maxSize: 130,
        sortingFn: 'datetime',
        cell: ({getValue}) => {
          return <Box>{dateToFormat(DATE_FORMAT, getValue() || '')}</Box>;
        },
      }),
      columnHelper.accessor('physician', {
        id: 'physician',
        header: t('PHYSICIAN'),
        minSize: 200,
        maxSize: 200,
        enableSorting: false,
        cell: ({getValue}) => {
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={getFullNameWithoutMiddleName(getValue()) || ''}
            />
          )
        },
      }),
      columnHelper.accessor('physician', {
        id: 'healthCases',
        header: t('HEALTH_CASES'),
        minSize: 280,
        maxSize: 280,
        enableSorting: false,
        cell: ({row}) => {
          const healthCases = row.original.healthCases?.map((item) => {
            return item.name;
          }) || [];
          return (
            <EllipsisTextTooltip
              variant='14_18_500'
              ellipsisText={healthCases.join(', ')}
            />
          );
        },
      }),
      columnHelper.accessor('flag', {
        id: 'flag',
        header: '',
        minSize: 90,
        enableSorting: false,
        cell: ({getValue}) => {
          const flag = getValue();
          return (
            <Box>
              {flag
                ? (
                  <Typography
                    variant='14_20_500'
                    padding='4px 8px'
                    sx={{backgroundColor: EBackgroundFlagColor[flag], opacity: 0.7}}
                    color={EFlagColor[flag]}>{EFlagName[flag]}</Typography>
                )
                : null}
            </Box>
          );
        },
      }),
    ], [columnHelper, t]);

  const isError = (row: any) => row.flag === CRITICAL_FLAG;

  const isDisabled = (row: any) => {
    return initialResults.has(row.id) || savedResults.has(row.id);
  };

  if (!result?.length) {
    return <EmptySearch isLoading={isLoading} isNew />;
  }

  return (
    <NewTable
      columns={columns}
      data={result}
      isError={isError}
      onRowClick={handleCheckboxChange}
      isDisabled={isDisabled}
    />
  );
};
