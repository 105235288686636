import {Stack} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';

export const Loader = () => {
  return (
    <Stack height={132} justifyContent={'center'} alignItems={'center'}>
      <PulseLoader loading />
    </Stack>
  );
};
