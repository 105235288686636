import {Tooltip as MuiTooltip, tooltipClasses, styled, Box} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {CustomTooltip} from './types';
import {useMQuery} from '@src/shared/hooks';

export const Tooltip = styled(
  ({className, title, disabled, ...props}: CustomTooltip) => {
    const [open, setOpen] = useState(false);
    const tmtCloseRef = useRef(0);
    const tmtOpenRef = useRef(0);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const {desktop} = useMQuery();

    const handleClose = () => {
      if (desktop) {
        clearTimeout(tmtOpenRef.current);
        tmtCloseRef.current = window.setTimeout(() => {
          setOpen(false);
        }, 500);
      }
    };

    const handleOpen = (_: React.MouseEvent, timeout?: boolean) => {
      if (!disabled) {
        clearTimeout(tmtCloseRef.current);

        if (open) {
          setOpen(false);
          return;
        }

        tmtOpenRef.current = window.setTimeout(
          () => {
            setOpen(true);
          },
          timeout ?? true ? 500 : 0,
        );
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const tooltipClicked = tooltipRef.current?.contains(event.target as Node);

      if (!tooltipClicked) {
        setOpen(false);
      }
    };

    const handleScroll = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (open) {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll, true);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('scroll', handleScroll, true);
      };
    }, [open]);

    return (
      <MuiTooltip
        ref={tooltipRef}
        onMouseEnter={handleOpen}
        onClick={(e) => {
          handleOpen(e, false);
        }}
        onMouseLeave={handleClose}
        open={open}
        disableTouchListener
        onClose={handleClose}
        title={disabled ? '' : title}
        TransitionProps={disabled ? {timeout: 0} : undefined}
        {...props}
        classes={{popper: className}}
      />
    );
  },
)(({theme: th, placement, arrowMarginTop}) => ({
  [`.${tooltipClasses.tooltip}`]: {
    backgroundColor: th.palette.grey[800],
    padding: '10px 20px',
    fontSize: '12px',
    lineHeight: '20px',
    marginTop: placement === 'bottom-start' ? '8px !important' : arrowMarginTop || 'initial',
    marginLeft: placement === 'bottom-start' ? '-20px !important' : 'initial',
  },
  [`.${'MuiTooltip-arrow'}`]: {
    color: th.palette.grey[800],
    marginLeft: placement === 'bottom-start' ? '11px !important' : 'initial',
  },
}));

Tooltip.defaultProps = {
  placement: 'left',
  arrow: true,
  enterDelay: 200,
  disableInteractive: true,
};
