import {Box, Typography} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {sx} from '@src/components/Table/styled';
import {useMQuery} from '@src/shared/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';

import {generateClassName, generateRefText} from '../Dynamics.helper';
import {Recharts} from '../Recharts';

import {IGraphicsProps} from './types';

export const Graphics: FC<IGraphicsProps> = ({data, isLoading, isConnections}) => {
  const {t} = useTranslate('details');
  const {mobile} = useMQuery();

  if (isLoading) {
    return (
      <Box sx={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <PulseLoader loading />
      </Box>
    );
  }

  const isGraphDataExist = data.some(({resultLaboratoryAnalyses}) =>
    resultLaboratoryAnalyses.some((a) => a.dot),
  );

  if (!isGraphDataExist || !data?.length) {
    if (isConnections) {
      return (
        <Box sx={sx.emptySearch}>
          <Typography variant={'14_18_500'} color={'grey.500'}>
            {!data?.length ? t('NO_SUITABLE_RESULTS') : t('NO_DATA_FOR_GRAPHS')}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Typography variant={'14_18_500'} color={'grey.500'}>
          {!data?.length ? t('NO_SUITABLE_RESULTS') : t('NO_DATA_FOR_GRAPHS')}
        </Typography>
      );
    }
  }

  return (
    <>
      {data?.map(
        ({resultLaboratoryAnalyses, refMin, refMax, refText, code, testName, domain, unit}) => {
          const key: string = `${code ?? testName}${unit || ''}`;
          return resultLaboratoryAnalyses.some((a: any) => a.dot)
            ? (
              <Box
                key={`${key}graphics`}
                mb={{sm: '24px', lg: '36px'}}
                sx={{padding: mobile ? '0px' : '0px 24px'}}
              >
                <Typography fontSize={18} fontWeight={500} pb={4}>
                  {testName}
                </Typography>
                <Typography
                  color="grey.400"
                  fontSize={14}
                  fontWeight={500}
                  marginBottom="16px">
                  {t('REFERENCE_RANGE')}: {generateRefText({refMax, refMin, refText})}
                </Typography>
                <Recharts
                  data={resultLaboratoryAnalyses}
                  domain={domain}
                  wrapperClassName={generateClassName()}
                />
              </Box>
            )
            : (
              <></>
            );
        },
      ) || null}
    </>
  );
};
