import {RadioGroup, SxProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {ReactNode} from 'react';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: {
      xs: '100%',
      sm: 'initial',
    },
  },
};

interface RadioButtonsTextProps {
  children: ReactNode
}

export const RadioButtonsGroup = ({children}: RadioButtonsTextProps) => (
  <RadioGroup sx={sx.container}>{children}</RadioGroup>
);
