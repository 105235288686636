/* eslint-disable @typescript-eslint/no-misused-promises */
import {Link, PageHeader as PageTitle} from '@components';
import {Stack, Typography} from '@mui/material';
import {MenuTypes, ResultDetail} from '@src/api';
import {FormDateInput} from '@src/components/FormDateInput';
import {FormInputControl} from '@src/components/FormInputControl';
import {FormTextareaControl} from '@src/components/FormTextareaControl';
import Grid from '@src/components/Grid';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowRightIcon} from '@src/shared/assets/icons/arrow-right.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {useMQuery} from '@src/shared/hooks';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions, saveHealthCase} from '@src/store/healthCases/slice';
import {notifyActions} from '@src/store/notifications/slice';
import {useResults} from '@src/store/results/hooks';
import {paletteColors} from '@src/theme';
import {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {Loader} from '../Overview/fragments/Loader';

import {DeleteDialog} from './components/DeleteDialog/DeleteDialog';
import {DocumentListDialog} from './components/DocumentListDialog/DocumentListDialog';
import {FilesCard} from './components/FilesCard/FilesCard';
import {ResultsHealthTable} from './components/ResultsHealthTable/ResultsHealthTable';
import {ResultsHealthTableMobile} from './components/ResultsHealthTableMobile/ResultsHealthTableMobile';
import {ResultsTableDialog} from './components/ResultsTableDialog/ResultsTableDialog';
import {CASES_COUNT_DESKTOP, CASES_COUNT_MOBILE, INITIAL_STATE} from './constants';
import {useCaseData} from './hooks';
import {ButtonContainer, MobileButtonContainer, sx} from './styles';
import {DocumentFile, HealthCaseData} from './types';
import {findRemoveFile} from './utils/findRemoveFile';
import {formatOrderDetails} from './utils/formatOrderDetails';
import {getDefaultValues} from './utils/getDefaultValues';

export const HealthCase = () => {
  const {id} = useParams();
  const {mobile} = useMQuery();
  const {t, ready} = useTranslate('healthCases');
  const {data} = useResults();
  const {files} = useAppSelector((state) => state.storage);
  const {phrase, category, from, to, menu} = files.filters;
  const isRecentOrFavorite = menu === MenuTypes.RECENT || menu === MenuTypes.FAVORITE;
  const isFieldsEmpty = !phrase && !category && !from && !to && !isRecentOrFavorite;
  const [isAddHealthCase, setIsAddHealthCase] = useState(false);
  const [isResultsDialogOpen, setIsResultsDialogOpen] = useState(false);
  const [isDocumentsDialogOpen, setIsDocumentsDialogOpen] = useState(false);
  const [showAllResults, setShowAllResults] = useState(false);
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [results, setResults] = useState<ResultDetail[]>([]);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {savedResults, savedDocuments, initialDocuments, initialResults, removeItems} = useAppSelector((state) => state.healthCases);
  const savedIdsResults = Array.from(savedResults);
  const savedIdsDocuments = Array.from(savedDocuments);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const countResults = data?.length || 0;
  const countDocuments = files.items?.length || 0;
  const isNewFileAdded = savedResults.size > 0 || savedDocuments.size > 0 || removeItems.length > 0;
  const isDisabledDocuments = !countDocuments && isFieldsEmpty;

  const {
    caseFiles,
    caseData,
    resultDetails = [],
    documentsDetails = [],
    isLoading,
  } = useCaseData(id);
  const removeItemsIds = findRemoveFile(removeItems, caseFiles);

  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const methods = useForm<HealthCaseData>({
    defaultValues: getDefaultValues(caseData || INITIAL_STATE),
  });

  const defaultValues = getDefaultValues(caseData || INITIAL_STATE);

  const {control} = methods;
  const watchedValues = useWatch({control});

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const isNameNotEmpty = !!watchedValues.name;
  const isSave = !isNameNotEmpty || (!(isNewFileAdded || !isSaveDisabled));
  const formattedResults = formatOrderDetails(results || []);
  const maxResultsCount = mobile ? CASES_COUNT_MOBILE : CASES_COUNT_DESKTOP;
  const displayedResults = showAllResults ? formattedResults : formattedResults?.slice(0, maxResultsCount);

  const handleShowAllResults = () => {
    setShowAllResults(true);
  };

  const handleCloseResultsDialog = () => {
    setIsResultsDialogOpen(false);
  };

  const handleCloseDocumentsDialog = () => {
    setIsDocumentsDialogOpen(false);
  };

  const saveCase = async (data: HealthCaseData) => {
    return await dispatch(saveHealthCase({id, data, savedIdsResults, savedIdsDocuments, removeItems: removeItemsIds})).unwrap();
  };

  const notifySuccess = (id: string) => {
    dispatch(notifyActions.showNotifications([{id, type: 'success', text: t('THE_HEALTH_CASE_HAS_BEEN_SUCCESSFULLY_SAVED')}]));
  };

  const notifyError = () => {
    dispatch(notifyActions.showNotifications([{id: id || '', type: 'error', text: t('THE_HEALTH_CASE_HAS_NOT_BEEN_SAVED')}]));
  };

  const handleNavigateToList = () => {
    navigate(ROUTERS_PATH.HEALTH_CASES);
  };

  const handleSubmit = async (data: HealthCaseData) => {
    try {
      const healthCaseId = await saveCase(data);
      notifySuccess(healthCaseId);
      handleNavigateToList();
    } catch (e) {
      notifyError();
    }
  };

  useEffect(() => {
    const valuesChanged = JSON.stringify(watchedValues) !== JSON.stringify(defaultValues);
    setIsSaveDisabled(!valuesChanged);
  }, [watchedValues, defaultValues]);

  useEffect(() => {
    methods.reset(getDefaultValues(caseData || INITIAL_STATE));
  }, [caseData]);

  useEffect(() => {
    setIsAddHealthCase(!id);
  }, [id]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const filterDocuments = () => {
    const filteredDetails = documentsDetails.filter(document =>
      savedDocuments.has(document.id) || initialDocuments.has(document.id),
    );
    setDocuments(filteredDetails);
  };

  const filterResults = () => {
    if (!resultDetails) return;
    const filteredResults = resultDetails.filter(result =>
      savedResults.has(result.id) || initialResults.has(result.id),
    );
    setResults(filteredResults);
  };

  useEffect(() => {
    if (!isLoading && resultDetails.length > 0) {
      filterResults();
    }
  }, [savedResults, initialResults, resultDetails, isLoading]);

  useEffect(() => {
    if (!isLoading && documentsDetails.length > 0) {
      filterDocuments();
    };
  }, [savedDocuments, initialDocuments, documentsDetails, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(healthCasesActions.clearSelectedResults());
      dispatch(healthCasesActions.clearSavedResults());
      dispatch(healthCasesActions.clearSavedDocuments());
      dispatch(healthCasesActions.clearSelectedDocuments());
      dispatch(healthCasesActions.clearRemovedItems());
    };
  }, []);

  useEffect(() => {
    if (!isLoading && !hasLoadedOnce) {
      setHasLoadedOnce(true);
    }
  }, [isLoading, hasLoadedOnce]);

  if ((isLoading || !ready) && !hasLoadedOnce) {
    return <Loader />;
  }

  return (
    <>
      <Stack sx={sx.mainContainer}>
        <PageTitle height={'auto'} alignItems="flex-start">
          <Stack sx={sx.breadcrumbs}>
            <Link to={ROUTERS_PATH.HEALTH_CASES}>{t('HEALTH_CASES')}</Link>
            <ArrowRightIcon />
            <Typography
              variant="16_20_500"
              color={'grey.300'}
              sx={sx.breadCrumbsText}>
              {isAddHealthCase ? t('ADD_HEALTH_CASE') : caseData?.name}
            </Typography>
          </Stack>
        </PageTitle>
        <PageTitle sxTypography={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
          {isAddHealthCase ? t('NEW_HEALTH_CASE') : caseData?.name}
        </PageTitle>
        <Stack>
          <Stack sx={sx.formWrapper}>
            <FormProvider {...methods}>
              <Stack
                sx={sx.inputsContainer}
                component={'form'}
                onSubmit={methods.handleSubmit(handleSubmit)}
              >
                <Grid
                  container
                  spacing={mobile ? 24 : 40}
                  columnSpacing={36}
                >
                  <Grid
                    xl={4}
                    md={4}
                    sm={6}
                    xs={12}>
                    <FormInputControl
                      label={t('HEALTH_CASE_NAME')}
                      name={'name'}
                      max={100}
                      inputRef={inputRef}
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    md={4}
                    sm={6}
                    xs={12}>
                    <FormDateInput
                      label={t('DATE')}
                      labelTop
                      optional
                      name={'caseDate'}
                      placeholder={'mm/dd/yyyy'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={mobile ? 36 : 40} columnSpacing={36}>
                  <Grid
                    xl={8}
                    md={8}
                    sm={12}
                    xs={12}>
                    <FormTextareaControl
                      name={'description'}
                      label={t('DESCRIPTION')}
                      optional
                      minRows={1}
                      maxRows={5}
                      max={1000}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </FormProvider>
            <Stack gap={mobile ? 12 : 40}>
              <Stack gap={24} sx={sx.documentsContainer}>
                <Typography variant={mobile ? '22_30_500' : '24_32_700'}>
                  {t('DOCUMENTS')}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setIsDocumentsDialogOpen(true)}
                  startIcon={<AddIcon />}
                  disabled={isDisabledDocuments}
                  sx={sx.button}
                >
                  {t('ADD_DOCUMENTS')}
                </Button>
                {documentsDetails && documentsDetails.length > 0 && (
                  <FilesCard
                    documentsDetails={documents}
                  />
                )}
                {isDisabledDocuments && (
                  <Stack flexDirection={'row'} gap={2}>
                    <Typography variant={'14_18_500'} color={'grey.400'}>
                      {t('YOU_DONT_HAVE_ANY_DOCS')}
                    </Typography>
                    <Typography
                      variant={'14_18_500'}
                      color={paletteColors.blue[400]}
                      onClick={() => navigate(ROUTERS_PATH.STORAGE)}
                      sx={{cursor: 'pointer', textDecoration: 'underline'}}
                    >
                      {t('STORAGE')}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Stack gap={24} sx={sx.documentsContainer}>
                <Typography variant={mobile ? '22_30_500' : '24_32_700'}>
                  {t('RESULTS')}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddIcon />}
                  disabled={!countResults}
                  onClick={() => setIsResultsDialogOpen(true)}
                  sx={sx.button}
                >
                  {t('ADD_RESULTS')}
                </Button>
                {!countResults && (
                  <Typography variant={'14_18_500'} color={'grey.400'}>
                    {t('YOU_DONT_HAVE_ANY_RESULTS')}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          {formattedResults && formattedResults.length > 0 && (
            <>
              <Stack sx={sx.tableWrapper}>
                {mobile && (
                  <ResultsHealthTableMobile
                    results={displayedResults || []}
                  />
                )}
                {!mobile && (
                  <ResultsHealthTable
                    results={displayedResults || []}
                  />
                )}
              </Stack>
              <Stack sx={sx.showButtonWrapper}>
                {!showAllResults && (formattedResults.length > maxResultsCount) && (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={handleShowAllResults}
                  >
                    {t('SHOW_ALL_RESULTS')}
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Stack>
        {!mobile && (
          <ButtonContainer
            justifyContent={isAddHealthCase ? 'end' : 'space-between'}
            isSaveDisabled={isSave}
          >
            {!isAddHealthCase && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<TrashIcon />}
                onClick={handleDeleteDialogOpen}
              >
                {t('DELETE_HEALTH_CASE')}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSave}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              {t('SAVE')}
            </Button>
          </ButtonContainer>
        )}
        {mobile && (
          <>
            <MobileButtonContainer isSaveDisabled={isSave}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSave}
                onClick={methods.handleSubmit(handleSubmit)}
              >
                {t('SAVE')}
              </Button>
            </MobileButtonContainer>
            <Stack
              mb={isSaveDisabled ? 0 : 74}
              sx={sx.deleteButtonContainer}>
              {!isAddHealthCase && (
                <Button
                  variant="text"
                  color="black"
                  startIcon={<TrashIcon />}
                  onClick={handleDeleteDialogOpen}
                >
                  {t('DELETE_HEALTH_CASE')}
                </Button>
              )}
            </Stack>
          </>
        )}
      </Stack>
      <ResultsTableDialog
        isOpen={isResultsDialogOpen}
        onClose={handleCloseResultsDialog}
      />
      <DocumentListDialog
        isOpen={isDocumentsDialogOpen}
        onClose={handleCloseDocumentsDialog}
      />
      <DeleteDialog
        id={id || ''}
        isOpen={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        afterDeleteAction={handleNavigateToList}
      />
    </>
  );
};
