import {RelationContactSearchResult, RequestRelationType} from '@src/api/relations';

export enum EAccountStatusName {
  'NEW' = 'New',
  'ACTIVE' = 'Active',
  'INVITE' = 'Invited',
  'BLOCKED' = 'Blocked',
}

//TODO: nedd create colors ui-kit
export enum EFlagColor {
  'NEW' = '#FAB41C',
  'ACTIVE' = '#05B7AC',
  'INVITE' = '#0E75BF',
  'BLOCKED' = '#858D9C',
}

export interface IAddRelationTableProps {
  relationContacts: RelationContactSearchResult[]
  relationType: RequestRelationType | null
}
