import {Address} from '@api';

export const getAddress = (actualAddress?: Address) => {
  if (!actualAddress) {
    return '';
  }
  const addressItems: Array<string | undefined> = [
    actualAddress.main,
    actualAddress.additional,
    actualAddress.city,
    actualAddress.state,
    actualAddress.postalCode,
  ];

  return addressItems.filter((item) => item).join(', ');
};
