import {AccountDetails, InsurancePlan} from '../account';

export interface UpdateAppointmentErrorType {
  error: string
  field: string
}

export interface CreateAppointmentPayloadData {
  deletedFileIds: string[]
  description: string
  files: AppointmentFile[]
  height: number
  weight: number
}

export interface CreateAppointmentPayload {
  data: CreateAppointmentPayloadData
  files?: File[]
}

export interface AppointmentFull {
  address?: CompanyAddress
  reviewLeaved?: boolean
  anonymousPatient?: AnonymousPatient
  comment?: string
  recommendation?: string
  email?: string
  endDate?: string
  files?: AppointmentFile[]
  height?: number
  id: string
  insurancePlan?: InsurancePlan
  patient?: AccountDetails
  phone?: string
  professional?: Professional
  startDate?: string
  status?: AppointmentStatus
  type?: AppointmentType
  weight?: number
}

export interface AppointmentFile {
  id: string
  name: string
  size?: number
}

export interface CompanyAddress {
  address1: string
  address2: string
  city: string
  endDate: string
  faxNumber: string
  googlePlaceId: string
  id: string
  latitude: number
  longitude: number
  postalCode: string
  state: StateType
  telephoneNumber: string
}

export interface Appointment {
  anonymousPatient: AnonymousPatient
  id: string
  patient: AccountDetails
  professional: Professional
  startDate: string
  status: AppointmentStatus
  type: AppointmentType
}

export interface AnonymousPatient {
  birthDate?: null
  birthSex?: BirthSex
  firstName?: string
  id?: string
  lastName?: string
  middleName?: string
}

export interface Professional {
  account: AccountDetails
  active: boolean
  addresses: CompanyAddress[]
  appointmentTypes: AppointmentType[]
  speciality: Speciality
  totalRating: number
  typePrefix: string
  typeSuffix: string
}

export interface Speciality {
  id: string
  isPrimary: boolean
  name: string
};

export interface ProfessionalReviewRequest {
  explanationRating: number
  serviceRating: number
  text?: string
}

export interface UpdateAppointment {
  deletedFileIds: string [] | null
  description: string | null
  height: number | null
  weight: number | null
}

export interface File {
  id: string
  name: string
}

export interface RescheduleAppointmentRequest {
  addressId: string
  appointmentType: AppointmentType
  endDate: string
  startDate: string
}

enum BirthSex {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN',
}

export enum AppointmentType {
  IN_PERSON = 'IN_PERSON',
  VIDEO = 'VIDEO',
  HOME_VISIT = 'HOME_VISIT',
}

export enum AppointmentStatus {
  CREATED = 'CREATED',
  WAITINGCONFIRMATION = 'WAITINGCONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum StateType {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  ZZ = 'ZZ',
}
