import {Stack, styled, SxProps, Theme} from '@mui/material';
import {initialColors} from '@src/theme';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  mainContainer: {
    gap: 24,
  },
  breadcrumbs: (t) => ({
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    color: t.palette.grey[800],
    width: '100%',
    mt: {
      sm: 3,
    },
    'a, a:visited': {
      color: t.palette.grey[800],
    },
  }),
  breadCrumbsText: {
    overflowX: 'auto',
    whiteSpace: 'nowrap'
  },
  formWrapper: (t) => ({
    background: initialColors.white,
    flex: 1,
    padding: 36,
    gap: '36px',
    [t.breakpoints.down('lg')]: {
      padding: 24,
    },
    [t.breakpoints.down('sm')]: {
      padding: 0,
      background: 'transparent',
    },
  }),
  inputsContainer: (t) => ({
    padding: 0,
    gap: '24px',
    background: 'transparent',
    [t.breakpoints.down('sm')]: {
      background: initialColors.white,
      padding: '18px 18px 32px',
    },
  }),
  documentsContainer: (t) => ({
    padding: 0,
    background: 'transparent',
    [t.breakpoints.down('sm')]: {
      background: initialColors.white,
      padding: 18,
      borderBottom: '1px solid',
      borderColor: t.colors.borderBlueLight,
    },
  }),
  button: (t) => ({
    width: 'fit-content',
    [t.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
  deleteButtonContainer: (t) => ({
    background: initialColors.white,
    flexDirection: 'row',
    padding: '36px',
    [t.breakpoints.down('sm')]: {
      background: 'transparent',
      padding: 0,
      flexDirection: 'unset',
      justifyContent: 'center',
      borderTop: '1px solid',
      borderColor: initialColors.blueLight,
      height: '50px',
    },
  }),
  showButtonWrapper: {
    background: initialColors.white,
    justifyContent: 'center',
    height: '50px',
  },
  image: {
    width: 150,
    height: 200,
  },
  tableWrapper: {
    background: initialColors.white,
    overflow: 'unset',
    height: '100%',
  },
};

export const MobileButtonContainer = styled(Stack)<{ isSaveDisabled: boolean }>(({isSaveDisabled}) => ({
  position: isSaveDisabled ? 'inherit' : 'fixed',
  padding: isSaveDisabled ? 0 : 18,
  boxShadow: isSaveDisabled ? 'unset' : '0px -2px 12px 0px #0000001A',
  bottom: 0,
  left: 0,
  zIndex: 100,
  width: '100%',
  background: initialColors.white,
  gap: 12,
  display: 'grid',
}));

export const ButtonContainer = styled(Stack)<{ isSaveDisabled: boolean }>(({theme, isSaveDisabled}) => ({
  position: isSaveDisabled ? 'inherit' : 'sticky',
  padding: 36,
  boxShadow: isSaveDisabled ? 'unset' : '10px -2px 22px 0px #0000001A',
  background: initialColors.white,
  zIndex: isSaveDisabled ? 0 : 100,
  flexDirection: 'row',
  left: 0,
  right: 0,
  bottom: isSaveDisabled ? 'auto' : 0,
  transform: isSaveDisabled ? 'unset' : 'translateY(35px)',
  [theme.breakpoints.down('lg')]: {
    padding: '36px 24px',
  }
}));
