import {PartialRecord, AddressComponentType, AddressComponentMapType} from '@src/types';

export const addrComponentsToRecord = (
  addressComponents?: google.maps.GeocoderAddressComponent[],
): AddressComponentMapType => {
  const ac =
    addressComponents?.reduce<
    PartialRecord<AddressComponentType, google.maps.GeocoderAddressComponent>
    >((acc, item) => {
      for (const cType of item.types) {
        acc[cType as AddressComponentType] = item;
      }
      return acc;
    }, {}) ?? {};
  return ac;
};
