import {BMIDataType} from '@src/api';
import {WEEK_LENGTH} from '@src/pages/Journal/constants';
import {ChartsDataType} from '@src/pages/Journal/fragments/Card/types';
import {DateTime} from 'luxon';

export const prepareDataForCharts = (
  data: BMIDataType[],
  startDate: DateTime | null,
  endDate: DateTime | null,
  step: number = 1,
  showCurrentPeriod: boolean = false,
): { chartData: ChartsDataType[], isEmptyData: boolean } => {
  const result: ChartsDataType[] = [];
  let isEmptyData = true;

  if (showCurrentPeriod) {
    endDate = DateTime.now();
    startDate = DateTime.now().minus({days: WEEK_LENGTH - 1});
  } else {
    if (!startDate) {
      startDate = DateTime.now().startOf('week');
    }

    if (!endDate) {
      endDate = DateTime.now().endOf('week');
    }
  }

  let currentDate = startDate;

  while (currentDate <= endDate) {
    const currentData = data.find((item) => {
      const itemDate = DateTime.fromISO(item.createdTime).endOf('day');
      return currentDate.hasSame(itemDate, 'day');
    });

    if (currentData) {
      result.push({
        value: currentData.value,
        createdTime: currentDate.toISO({includeOffset: false}),
      });
      isEmptyData = false;
    } else {
      result.push({
        value: 0,
        createdTime: currentDate.toISO({includeOffset: false}),
      });
    }
    currentDate = currentDate.plus({days: step});
  }

  return {
    chartData: result,
    isEmptyData,
  };
};
