import {sortInsurances} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {UUID} from '@src/types';
import {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store';

import {insuranceActions} from './slice';

export const useInsurances = () => {
  const {healthcareInsurancePolicies, fetching} = useAppSelector((state) => state.insurance);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!healthcareInsurancePolicies && !fetching) {
      dispatch(insuranceActions.requestInsurances());
    }
  }, [dispatch, healthcareInsurancePolicies]);

  const sorted = sortInsurances(healthcareInsurancePolicies || []);
  const activeInsurances = sorted?.filter((insurance) => compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null);

  return {
    fetching,
    healthcareInsurancePolicies: sorted,
    activeHealthcareInsurancesPolicies: activeInsurances,
  };
};

export const useInsuranceCarriers = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      getInsuranceCarriers: (filter: string) => {
        if (!filter) {
          console.error('[useInsuranceCarriers] filter is undefined');
        } else {
          dispatch(insuranceActions.requestInsuranceCarriers({filter}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useInsurancePlans = (insuranceCarrierId: UUID | undefined) => {
  const {insurancePlans, isInsurancePlansEmpty} = useAppSelector((state) => state.insurance);
  const dispatch = useDispatch();
  useEffect(() => {
    if (insuranceCarrierId) {
      dispatch(insuranceActions.requestInsurancePlans({insuranceCarrierId}));
    }
  }, [dispatch, insuranceCarrierId]);
  return {
    fetching: null,
    insurancePlans,
    isInsurancePlansEmpty,
  };
};
