import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogError: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitleError: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  dialogTitleSuccess: (t) => ({
    borderBottomColor: t.palette.success.main,
    gap: 12,
  }),
  dialogActionsSuccess: (t) => ({
    backgroundColor: t.palette.success.main,
    padding: '16px 24px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }),
  dialogActionsError: (t) => ({
    backgroundColor: t.palette.error.main,
    padding: '16px 24px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }),
  bth: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
  confirmDeleteContent: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    padding: '36px 24px 48px !important',
  },
  emailContainer: {
    p: {xs: 24, sm: '36px 24px 24px 24px'},
  },
  actionButtons: {
    p: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};
