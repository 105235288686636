import {useEffect, useRef, useState} from 'react';

export const useSidebarClassObserver = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const bodyRef = useRef(document.body);

  useEffect(() => {
    const bodyElement = bodyRef.current;

    const hasSandwichMenuOpenClass = bodyElement.classList.contains('sandwich-menu-open');
    setIsSidebarOpen(hasSandwichMenuOpenClass);

    const observer = new MutationObserver(() => {
      const hasSandwichMenuOpenClass = bodyElement.classList.contains('sandwich-menu-open');

      setIsSidebarOpen(hasSandwichMenuOpenClass);
    });

    observer.observe(bodyElement, {attributes: true, attributeFilter: ['class']});

    return () => {
      observer.disconnect();
    };
  }, []);

  return {isSidebarOpen};
};
