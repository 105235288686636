import {useEffect, useRef, useState} from 'react';

import {DEFAULT_ERROR_TEXT_HEIGHT} from '../constants';

import {useSidebarClassObserver} from './useSidebarClassObserver';

export const useErrorTextHeightObserve = () => {
  const [isErrorTall, setIsErrorTall] = useState(false);
  const errorRef = useRef<HTMLElement | null>(null);

  const {isSidebarOpen} = useSidebarClassObserver();

  useEffect(() => {
    const updateErrorHeight = () => {
      if (errorRef.current) {
        const errorHeight = errorRef.current.clientHeight;
        if (errorHeight > DEFAULT_ERROR_TEXT_HEIGHT) {
          setIsErrorTall(true);
        } else {
          setIsErrorTall(false);
        }
      }
    };

    updateErrorHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateErrorHeight();
    });

    if (errorRef.current) {
      resizeObserver.observe(errorRef.current);
    }

    return () => {
      if (errorRef.current) {
        resizeObserver.unobserve(errorRef.current);
      }
    };
  }, [errorRef, isSidebarOpen, setIsErrorTall]);

  return {errorRef, isErrorTall};
};
