import {Stack, Typography} from '@mui/material';
import {Icons} from '@src/shared/assets/icons';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {IHeaderPageProps} from './types';

export const PageHeader: React.FC<IHeaderPageProps> = ({
  itemAction,
  children,
  withReturn,
  variant,
  height,
  alignItems,
  sxTypography,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {withReturn && (
        <Button onClick={() => navigate(-1)}>
          <Icons.Arrow.Back.X24 /> Back
        </Button>
      )}
      <Stack direction="row" justifyContent="space-between" alignItems={alignItems || 'center'}>
        {typeof children === 'string'
          ? (
            <Typography
              sx={{
                typography: variant || {
                  xs: '24_36_700',
                  sm: '32_36_700',
                  lg: '32_42_700',
                },
                height: (t) => height ?? t.pageTitleHeight,
                ...sxTypography,
              }}
            >
              {children}
            </Typography>
          )
          : (
            children
          )}
        {itemAction}
      </Stack>
    </>
  );
};
