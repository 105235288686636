import {BirthSex, RelationTypeEnum} from '@src/api';
import {Dispatch, SetStateAction} from 'react';

export const openModal = ({
  values,
  birthDate,
  birthSex,
  setIsModalOpened,
  isFirstModalOpen,
}: {
  values: Record<string, any>
  birthDate?: string
  birthSex?: string
  setIsModalOpened: Dispatch<SetStateAction<boolean>>
  isFirstModalOpen: boolean
}) => {
  if (isFirstModalOpen && birthDate === null) {
    setIsModalOpened(true);
    return true;
  }
  if (values.relationType && !birthDate) {
    setIsModalOpened(true);
    return true;
  }
  if ((values.relationType === RelationTypeEnum.Chd && birthSex === BirthSex.Unknown)
  ) {
    setIsModalOpened(true);
    return true;
  }
  return false;
};
