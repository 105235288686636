import {Box, styled} from '@mui/material';

import {getBackgroundColor, getBorderColor, getHoverBackgroundColor, getLabelColor} from './helpers';

export const StyledBox = styled(Box)<{ isActive?: boolean, isDisabled?: boolean }>(
  ({theme, isActive, isDisabled}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: 6,
    border: `1px solid ${getBorderColor(theme, isActive, isDisabled) ?? ''}`,
    borderRight: isActive ? 'none' : 'auto',
    pointerEvents: isDisabled ? 'none' : 'auto',
    backgroundColor: getBackgroundColor(theme, isActive, isDisabled),
    zIndex: !isActive ? 2 : 1,
    [theme.breakpoints.up('md')]: {
      width: 102,
      height: 96,
      flexDirection: 'column',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: 126,
      height: 42,
      flexDirection: 'row',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: 42,
    },
    svg: {
      path: {
        fill: getLabelColor(theme, isActive, isDisabled),
      },
    },
    '&:hover, &:active': {
      backgroundColor: getHoverBackgroundColor(theme, isActive, isDisabled),
    },
    '&:not(first-child)': {
      [theme.breakpoints.between('sm', 'lg')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: -1,
      },
    },
  }),
);
