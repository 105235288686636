import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainBlock: (t: Theme) => ({
    margin: {
      xs: '18px',
      sm: '24px',
    },
    [t.breakpoints.only('xs')]: {
      padding: '24px',
      paddingTop: '15px',
    },
    [t.breakpoints.between('sm', 'md')]: {
      padding: '36px',
      paddingTop: '27px',
    },
    [t.breakpoints.up('md')]: {
      padding: '40px 54px',
      paddingTop: '31px',
      width: 600,
      margin: '90px auto',
    },
  }),
  title: {
    typography: {
      xs: '22_26_700',
      sm: '32_36_700',
    },
    flexGrow: 1,
    alignSelf: 'center',
  },
  topblock: {
    display: 'flex',
    marginBottom: {
      sm: 12,
    },
  },
  block: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  signIn: {
    flexGrow: 1,
    alignSelf: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
    mb: {
      xs: 24,
    },
  },
  viewer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
