// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Box, css, SelectChangeEvent, Stack, useTheme} from '@mui/material';
import {
  Breadcrumbs,
  DatePickerRange,
  FilterWrapper,
  Link,
  PageHeader,
  Text,
} from '@src/components';
import Grid from '@src/components/Grid';
import {MultiSelect} from '@src/components/MultiSelect';
import {SelectLabels} from '@src/components/Select';
import {BoxPaddings, WhiteBox} from '@src/components/WhiteBox';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {ResultDynamicsTable} from '@src/features/ResultDynamicsTable';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as GraphIcon} from '@src/shared/assets/icons/graph.svg';
import {ReactComponent as TableIcon} from '@src/shared/assets/icons/table.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getResultTitle} from '@src/shared/utils/getResultTitle';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {useDynamicsData, useResultDetail} from '@src/store/results/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {dateInInterval} from '../Results/Results.helpers';

import {Graphics, Table} from './Board';
import {dataWithoutEmptyValue, getTimePeriod} from './Dynamic.helpers';
import {initialDates, initialFilters, TIME_PERIOD_OPTIONS} from './Dynamics.constant';
import {sx} from './Dynamics.style';
import {RadioButton} from './Dynamics.styled';
import {FiltersDialog} from './components/FiltersDialog';
import {IFilter, IOptions} from './types';

const initialFilter: (testCodes?: string | null) => IFilter = (testCodes) => {
  return {
    end: null,
    start: null,
    testCodes: testCodes ? JSON.parse(decodeURI(testCodes)) : [],
  };
};

export const Dynamics: FC = () => {
  const {tests, resultId, companyId} = useParams();

  const [timePeriod, setTimePeriod] = useState('');
  const {t, ready} = useTranslate('details');
  const initial = initialFilter(tests);
  const [filter, setFilter] = useState<IFilter>(initial);
  const [isGraphics, setIsGraphics] = useState(true);
  const {mobile, tablet} = useMQuery();
  const theme = useTheme();
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  const {dynamics, isLoading} = useDynamicsData();
  const [filteredDynamics, setFilteredDynamics] = useState<DynamicTestTransformed[]>([]);
  const {data: result} = useResultDetail({resultId, companyId});
  const resultTitle = result?.orderDetails ? getResultTitle(result.orderDetails) : '...';

  const toggleMobileFilersOpen = () => {
    setIsMobileFiltersOpen((prev) => !prev);
  };

  const handleSetTimePeriod = (newTimePeriod: string) => {
    setTimePeriod(newTimePeriod);
  };

  const changeTimePeriod = (event: SelectChangeEvent<string>) => {
    const {value} = event.target;
    handleSetTimePeriod(value);

    const dates = getTimePeriod(value);
    setFilter((prev) => ({...prev, ...dates}));
  };

  const changeDisableVariantDynamics = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(e.target instanceof HTMLElement)) return;
    const {textContent} = e.target;
    setIsGraphics(textContent === 'Graph');
  };

  const testsOptions: IOptions[] = useMemo(() => {
    if (dynamics.testResults) {
      const options = dynamics.testResults?.map((item) => ({
        name: item.testName || '',
        value: item.code,
      }));

      const uniqueNames = new Set<string>();
      return options
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((obj) => {
          if (uniqueNames.has(obj.name)) {
            return false;
          }
          uniqueNames.add(obj.name);
          return true;
        });
    }

    return [];
  }, [dynamics]);

  useEffect(() => {
    const {start, end, testCodes} = filter;

    const filteredDynamicsByTests = dynamics.testResults.filter((item) => doFiltration(item));
    const clearedFilteredDynamics = dataWithoutEmptyValue(filteredDynamicsByTests, testCodes);

    if (!end || !start) {
      setFilteredDynamics(clearedFilteredDynamics);
      return;
    }
    const dataFil =
      clearedFilteredDynamics.map((dataItem) => ({
        ...dataItem,
        resultLaboratoryAnalyses: dataItem?.resultLaboratoryAnalyses?.filter(
          (column) =>
            dateInInterval(start, end, column.biomaterialSamplingDate) &&
            column.value !== null &&
            column.value !== undefined,
        ),
      })) ?? [];

    setFilteredDynamics(dataFil.filter((it) => it.resultLaboratoryAnalyses.length > 0));
  }, [filter, dynamics?.testResults]);

  const updateFilter = (newFilters: IFilter) => {
    setFilter(newFilters);
  };

  const changeTestCodes = (values: string[]) => {
    setFilter((prev) => ({...prev, testCodes: values}));
  };

  const doFiltration = (item: DynamicTestTransformed) => {
    return filter.testCodes.length > 0 ? filter.testCodes.includes(item.code || '') : true;
  };

  const clearDate = () => {
    setFilter((prevState) => ({...prevState, ...initialDates}));
  };

  const onClear = () => {
    clearDate();
    setTimePeriod('');
    setFilter(initialFilters);
  };

  const changeDate = (name: string) => (date: Date | null) => {
    setFilter((prevState) => ({...prevState, [name]: date}));
    setTimePeriod('Custom');
  };

  const renderTable = () => {
    if (!mobile && !tablet) {
      return (
        <ResultDynamicsTable dynamicsWithTests={filteredDynamics} isLoading={isLoading} />
      );
    } else {
      return (
        <Table
          isLoading={isLoading}
          start={filter.start}
          end={filter.end}
          isModifiedScroll
          data={filteredDynamics}
        />
      );
    }
  };

  const calcBoxPaddings = () => {
    if (tablet) return '24px';
    if (mobile) return '24px 16px';
    return '36px';
  };

  const boxPaddings = calcBoxPaddings();

  if (!ready) {
    return null;
  }

  return (
    <Stack gap={mobile ? 24 : 36} flexGrow={1}>
      <Stack sx={sx.container}>
        <Breadcrumbs>
          <Link to={ROUTERS_PATH.RESULTS}>{t('RESULTS')}</Link>
          <Link to={'../'}>{resultTitle}</Link>
          <Text sx={{color: 'grey.300'}}>
            {mobile || tablet ? t('DYNAMICS') : t('DYNAMICS_OF_RESULTS')}
          </Text>
        </Breadcrumbs>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <PageHeader height={'auto'}>{t('DYNAMICS_OF_RESULTS')}</PageHeader>
          <div
            css={css`
              display: flex;
              gap: 40px;
            `}
          >
            {!mobile && (
              <div
                css={css`
                  display: flex;
                `}
              >
                <RadioButton
                  size={'lg'}
                  active={isGraphics}
                  sx={{
                    padding: '3px 24px !important',
                    '& svg path': {
                      fill: isGraphics
                        ? `${theme.palette.common.white}!important`
                        : `${theme.palette.secondary.main}!important`,
                    },
                    '& .MuiButton-endIcon': {
                      width: 24,
                      height: 24,
                    },
                  }}
                  endIcon={<GraphIcon />}
                  onClick={changeDisableVariantDynamics}
                >
                  {t('GRAPH')}
                </RadioButton>
                <RadioButton
                  active={!isGraphics}
                  sx={{
                    padding: '3px 24px !important',
                    '& svg path': {
                      fill: isGraphics
                        ? `${theme.palette.secondary.main}!important`
                        : `${theme.palette.common.white}!important`,
                    },
                    '& .MuiButton-endIcon': {
                      width: 24,
                      height: 24,
                    },
                  }}
                  size={'lg'}
                  endIcon={<TableIcon />}
                  onClick={changeDisableVariantDynamics}
                >
                  {t('TABLE')}
                </RadioButton>
              </div>
            )}
            {(mobile || tablet) && <SettingsButton onClick={toggleMobileFilersOpen} />}
          </div>
        </div>
        {mobile && (
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            <RadioButton
              size={'lg'}
              fullWidth
              active={isGraphics}
              endIcon={
                <GraphIcon
                  fill={isGraphics ? theme.palette.common.white : theme.palette.secondary.main}
                />
              }
              sx={{
                padding: '3px 24px !important',
                '& svg path': {
                  fill: isGraphics
                    ? `${theme.palette.common.white}!important`
                    : `${theme.palette.secondary.main}!important`,
                },
                '& .MuiButton-endIcon': {
                  width: 24,
                  height: 24,
                },
              }}
              onClick={changeDisableVariantDynamics}
            >
              {t('GRAPH')}
            </RadioButton>
            <RadioButton
              active={!isGraphics}
              size={'lg'}
              fullWidth
              endIcon={
                <TableIcon
                  fill={isGraphics ? theme.palette.secondary.main : theme.palette.common.white}
                />
              }
              sx={{
                padding: '3px 24px !important',
                '& svg path': {
                  fill: isGraphics
                    ? `${theme.palette.secondary.main}!important`
                    : `${theme.palette.common.white}!important`,
                },
                '& .MuiButton-endIcon': {
                  width: 24,
                  height: 24,
                },
              }}
              onClick={changeDisableVariantDynamics}
            >
              {t('TABLE')}
            </RadioButton>
          </div>
        )}
      </Stack>
      <WhiteBox noPaddings sx={{flexGrow: 1}}>
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
          {!mobile && !tablet && (
            <BoxPaddings sx={{padding: '24px 36px'}}>
              <FilterWrapper
                onClear={onClear}
                css={css`
                  label {
                    flex: 1 1;
                  }
                  .date_picker {
                    width: 100%;
                  }
                `}
              >
                <Grid container spacing={36} flexGrow={1}>
                  <Grid xs={4} lg={4} xl={4}>
                    <DatePickerRange
                      isIconVisible={false}
                      variant="outlined"
                      label={t('DATE')}
                      startDate={filter.start}
                      endDate={filter.end}
                      startAdornmentName="Date"
                      setStartDate={changeDate('start')}
                      setEndDate={changeDate('end')}
                      clearDate={clearDate}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} xl={4}>
                    <SelectLabels
                      value={timePeriod}
                      onChange={changeTimePeriod}
                      placeholder={t('SELECT')}
                      options={TIME_PERIOD_OPTIONS}
                      contentPaddingLeft="103px"
                      label={t('TIME_PERIOD')}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} xl={4}>
                    <MultiSelect
                      value={filter.testCodes}
                      label={t('TESTS')}
                      placeholder={t('SELECT')}
                      options={testsOptions}
                      onChange={changeTestCodes}
                      inputsSx={sx.testsSelect}
                      compact={false}
                    />
                  </Grid>
                </Grid>
              </FilterWrapper>
            </BoxPaddings>
          )}
          <Box sx={{mt: '-1px', flex: 1, display: 'flex'}}>
            {isGraphics
              ? (
                <BoxPaddings paddings={boxPaddings} sx={{display: 'flex', flex: 1, maxWidth: '100%'}}>
                  <Graphics data={filteredDynamics} isLoading={isLoading} />
                </BoxPaddings>
              )
              : renderTable()
            }
          </Box>
        </Box>
      </WhiteBox>
      <FiltersDialog
        isOpen={isMobileFiltersOpen}
        filters={filter}
        onClose={toggleMobileFilersOpen}
        onSetNewFilters={updateFilter}
        testsOptions={testsOptions}
        timePeriod={timePeriod}
        onSetTimePeriod={handleSetTimePeriod}
      />
    </Stack>
  );
};
