import {SxProps, Stack, Box, Typography, Theme, styled} from '@mui/material';
import {ReactComponent as IconSVGArrowDown} from '@src/shared/assets/icons/24x24/arrow-down.svg';
import {ReactComponent as IconSVGArrowUp} from '@src/shared/assets/icons/24x24/arrow-up.svg';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {alpha} from '@src/theme/utils';
import {Text} from 'components/Text';
import {FC, PropsWithChildren, ReactNode} from 'react';
import {useSearchParams} from 'react-router-dom';

const stylesSx = {
  boxHeaderText: (t: Theme) => ({
    typography: '24_28_500',
    [t.breakpoints.down('md')]: {
      typography: '22_30_500',
    },
    [t.breakpoints.only('md')]: {
      typography: '24_28_500',
    },
    [t.breakpoints.up('md')]: {
      typography: '24_30_500',
    },
  }),
};

interface ButtonContainerProps {
  mobReversed?: boolean
  children: ReactNode
  className?: string
}

const ButtonContainer = ({mobReversed, children, className, ...restProps}: ButtonContainerProps) => {
  return (
    <Box
      sx={(t: Theme) => ({
        display: 'flex',
        flexDirection: {xs: 'row-reverse', md: 'row'},
        gap: 24,
        justifyContent: 'flex-end',
        [t.breakpoints.between('md', 'sm')]: {
          justifyContent: 'space-between',
          flex: {md: '1 1'},
        },
        [t.breakpoints.down('sm')]: {
          flexWrap: {xs: mobReversed ? 'wrap' : 'wrap-reverse'},
          gap: 10,
          width: '100%',
          button: {
            minWidth: '100%',
          },
        },
      })}
      className={className}
      {...restProps}>
      {children}
    </Box>
  );
};

interface BoxFooterProps {
  children: ReactNode
  text?: string
  className?: string
  verticalButtonsFlexWrapReverse?: boolean
}

export const BoxFooter = ({children, text, className, verticalButtonsFlexWrapReverse = true}: BoxFooterProps) => {
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        gap: 24,
        alignItems: 'end',
        justifyContent: 'end',
      }}
    >
      {text && (
        <Box
          sx={{
            flex: '100 1 auto',
            alignItems: 'end',
            display: {xs: 'none', sm: 'flex'},
          }}
        >
          {text && (
            <Text fz={16} lh={24} weight={400}>
              {text}
            </Text>
          )}
        </Box>
      )}
      <ButtonContainer className="bf-buttons" mobReversed={verticalButtonsFlexWrapReverse}>
        {children}
      </ButtonContainer>
    </Box>
  );
};

export const BoxBody = ({children}: { children: ReactNode }) => {
  return (
    <Box sx={{flex: '1 1 auto'}}>
      {children}
    </Box>
  );
};

interface BoxHeaderProps {
  children: ReactNode
  sx?: SxProps<Theme>
  mb?: number | string
}

export const BoxHeader = ({children, sx, mb}: BoxHeaderProps) => (
  <Box mb={mb || 0}>
    <Typography sx={[...spreadSx(stylesSx.boxHeaderText), ...spreadSx(sx)]}>{children}</Typography>
  </Box>
);

interface WProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: ReactNode
  collapsible?: boolean
  id?: string
  noPaddings?: boolean
  headerMargin?: string
  noBackground?: boolean
  noBorder?: boolean
  onExpand?: (isExpanded: boolean) => void
  sx?: SxProps<Theme>
  headerSx?: SxProps
  notApplySx?: boolean
  hasIconBox?: boolean
}
export const WhiteBox: FC<PropsWithChildren<WProps>> = ({
  children,
  collapsible,
  header,
  id,
  headerMargin,
  sx,
  headerSx,
  onExpand,
  notApplySx,
  hasIconBox = false,
  ...restProps
}) => {
  const [params, setParams] = useSearchParams();
  const fullId = `collapsible_${id ?? ''}`;
  const expanded = JSON.parse(params.get(fullId) ?? 'false');

  const toggle = () => {
    setParams((prev) => {
      prev.set(fullId, JSON.stringify(!expanded));
      return prev;
    });

    onExpand?.(!expanded);
  };
  if (!collapsible) {
    return (
      <WBox sx={sx} {...restProps}>
        {header && <BoxHeader sx={{marginBottom: headerMargin || 48}}>{header}</BoxHeader>}
        {children}
      </WBox>
    );
  }

  if (!expanded) {
    return (
      <WBox sx={sx} {...restProps}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={!notApplySx ? headerSx : {}}
        >
          {header && <BoxHeader mb={0}>{header}</BoxHeader>}
          {hasIconBox
            ? (
              <Box sx={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={toggle}>
                <IconSVGArrowDown />
              </Box>
            )
            : <IconSVGArrowDown />}
        </Stack>
      </WBox>
    );
  }

  return (
    <WBox sx={sx} {...restProps}>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{marginBottom: 36, ...headerSx}}
      >
        {header && <BoxHeader mb={0}>{header}</BoxHeader>}
        {hasIconBox
          ? (
            <Box sx={{width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={toggle}>
              <IconSVGArrowUp />
            </Box>
          )
          : <IconSVGArrowUp />}
      </Stack>
      {children}
    </WBox>
  );
};

export const WBox = styled('div')<{ noPaddings?: boolean, noBackground?: boolean, noBorder?: boolean }>(({theme, noPaddings, noBackground, noBorder}) => ({
  backgroundColor: noBackground ? 'unset' : theme.colors.background,
  padding: noPaddings ? 0 : 36,
  width: '100%',
  boxSizing: 'border-box',
  border: noBorder ? 'unset' : `1px solid ${alpha(theme.palette.secondary.main, 14)}`,
  display: 'flex',
  flexDirection: 'column',
  dt: {
    fontWeight: 700,
  },
  [theme.breakpoints.between('lg', 'xs')]: {
    padding: noPaddings ? 0 : 24,
  },
  [theme.breakpoints.down('xs')]: {
    padding: noPaddings ? 0 : '18px 16px',
    height: 'auto',
  },
}));

interface BoxPaddingsProps {
  noTop?: boolean
  noBottom?: boolean
  paddings?: string
  sx?: SxProps<Theme>
  children: ReactNode
}

export const BoxPaddings = ({noTop, noBottom, paddings, sx, children}: BoxPaddingsProps) => {
  return (
    <Box
      sx={{
        paddingTop: noTop ? 0 : paddings || '36px',
        paddingBottom: noBottom ? 0 : paddings || '36px',
        display: 'flex',
        flexDirection: 'column',
        padding: paddings || '36px',
        '&.mobile-layout, &.tablet-layout': {
          paddingTop: noTop ? 0 : '24px',
          paddingBottom: noBottom ? 0 : '24px',
          padding: paddings || '24px',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
