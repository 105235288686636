import React from 'react';
import {TableRowProps} from '../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = TableRowProps<any>;

export const DefaultRowTable: React.FC<Props> = ({className, data, gridTemplateColumns}) => {
  return (
    <div className={className} style={{gridTemplateColumns}}>
      {data.map((_: any, index: number) => (
        <div key={index} />
      ))}
    </div>
  );
};
