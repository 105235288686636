import {UUID} from 'types';

export interface NormalizedState<T, K extends (number | string) = UUID> {
  byID: Partial<Record<K, T>>
  allIDs: K[]
}
export const createNormalizedState = <K extends string, T extends Record<string, any>>(items: T[], key: keyof T = 'id' as keyof T):
NormalizedState<T, K> => {
  const n: NormalizedState<T, K> = {
    byID: {} as Record<K, T>,
    allIDs: [],
  };

  for (const item of items) {
    n.byID[item[key] as K] = item;
    n.allIDs.push(item[key] as K);
  }

  return n;
};

export const updateNormalizedState = <K extends (string | number), T extends Record<string, any>,>(
  n: NormalizedState<T, K>,
  data: T[], key: keyof T = 'id' as keyof T, insertionIndex?: number): void => {
  let i = insertionIndex ?? n.allIDs.length;
  for (const item of data) {
    n.byID[item[key] as K] = item;
    if (!n.allIDs.includes(item[key] as K)) {
      n.allIDs.splice(i, 0, item[key] as K);
    }
    i++;
  }
};

export const deleteByIdFromNormalizedState = <K extends (string | number), T>(
  n: NormalizedState<T, K>, deleteItems: K[]): void => {
  for (const id of deleteItems) {
    delete n.byID[id];
    n.allIDs.splice(n.allIDs.indexOf(id), 1);
  }
};
