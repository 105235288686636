import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogActions: (t) => ({
    justifyContent: 'end !important',
    gap: 36,
    backgroundColor: t.palette.success.main,
    m: 0,
    p: '16px 24px',
  }),
  button: {
    minWidth: '120px !important',
  },
  dialogContent: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    p: '36px 24px !important',
  },
  titleWrapper: {
    flexDirection: 'row',
    gap: 12,
  },
};
