/* eslint-disable react/require-default-props */
import {Stack} from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Icons} from '@src/shared/assets/icons';
import {useSnackbar, SnackbarContent, CustomContentProps} from 'notistack';
import {forwardRef, useCallback, useMemo} from 'react';

type Props = CustomContentProps & {
  customType: 'error' | 'info' | 'success'
  title?: string
  message?: string
};

const CommonMessage = forwardRef<HTMLDivElement, Props>(
  ({id, style, message, customType, title, ...props}, ref) => {
    const {closeSnackbar} = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const Icon = useMemo(
      () =>
        customType === 'error'
          ? Icons.Notification.Error.X24
          : customType === 'info'
            ? Icons.Notification.Info.X24
            : Icons.Notification.Success.X24,
      [customType],
    );

    return (
      <SnackbarContent ref={ref} style={style} {...props}>
        <Card
          sx={{
            width: '100%',
            background:
              customType === 'error' ? '#F4E3E6' : customType === 'info' ? '#F6EFE0' : '#DDEFEE',
            padding: '14px',
          }}
        >
          <Stack direction="row">
            { }
            <Icon style={{flexShrink: 0}} />
            <Stack flexGrow={1} sx={{padding: '0 13px'}}>
              {title && (
                <Typography
                  variant="body2"
                  sx={{fontWeight: 700, fontSize: '14px', lineHeight: '20px'}}
                >
                  {title}
                </Typography>
              )}
              {message && message.length > 0 && (
                <Typography
                  variant="body1"
                  sx={{fontWeight: 400, fontSize: '12px', lineHeight: '20px'}}
                >
                  {message}
                </Typography>
              )}
              {(!message || message.length === 0) && !title && (
                <Typography
                  variant="body1"
                  sx={{fontWeight: 400, fontSize: '12px', lineHeight: '20px'}}
                >
                  empty message
                </Typography>
              )}
            </Stack>
            <IconButton
              onClick={handleDismiss}
              sx={{flexShrink: 0, padding: 0, height: 'fit-content', marginRight: '-2px'}}
            >
              <Icons.Cross.X24_SM color="darkGrey" />
            </IconButton>
          </Stack>
        </Card>
      </SnackbarContent>
    );
  },
);
CommonMessage.displayName = 'CommonMessage';

const ErrorMessage = forwardRef<HTMLDivElement, Omit<Props, 'customType'>>((props, ref) => {
  return <CommonMessage ref={ref} {...props} customType="error" />;
});
ErrorMessage.displayName = 'ErrorMessage';

const InfoMessage = forwardRef<HTMLDivElement, Omit<Props, 'customType'>>((props, ref) => {
  return <CommonMessage ref={ref} {...props} customType="info" />;
});
InfoMessage.displayName = 'InfoMessage';

const SuccessMessage = forwardRef<HTMLDivElement, Omit<Props, 'customType'>>((props, ref) => {
  return <CommonMessage ref={ref} {...props} customType="success" />;
});
SuccessMessage.displayName = 'SuccessMessage';

export {SuccessMessage, ErrorMessage, InfoMessage};
