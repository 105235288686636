import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  whiteContainer: {
    flexGrow: 1,
    mb: 'initial',
  },
  container: (t) => ({
    padding: 36,
    [t.breakpoints.up('lg')]: {
      gap: 36,
    },
    [t.breakpoints.between('sm', 'lg')]: {
      p: 24,
      '& > *:not(:last-child)': {
        mb: 36,
      },
      '& > *:last-child': {
        mb: 0,
      },
      '& > *:nth-child(3)': {
        marginBottom: 27,
      },
    },
    [t.breakpoints.only('xs')]: {
      flexGrow: 1,
      gap: 24,
      p: 18,
    },
  }),
  searchBlockContainer: (t) => ({
    flexDirection: 'row',
    gap: {
      xs: 24,
      sm: 18,
      md: 36,
    },
    maxWidth: 990,
    alignItems: 'end',
    [t.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'inherit',
    },
  }),
  spaceContainer: (t) => ({
    padding: 36,
    flexGrow: 1,
    flexDirection: 'column-reverse',
    marginTop: 'calc(100vh - 590px)',
    [t.breakpoints.between('sm', 'lg')]: {
      p: 24,
    },
  }),
  recaptcha: {
    typography: {
      sm: '14_18_500',
    },
  },
  selectContainer: {
    maxWidth: {
      xs: '100%',
      sm: 410,
      md: 346,
      lg: 393,
    },
    display: 'grid',
    minWidth: '10%',
  },
  flexContainer: {
    flexGrow: 1,
    flexDirection: 'column-reverse',
    gap: 16,
  },
  breadCrumbsFont: {
    typography: {
      xs: '14_18_600',
      sm: '16_20_500',
    },
  },
  breadCrumbsText: (t) => ({
    color: t.palette.grey[400],
  }),
  infiniteScrollContainer: {
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 100px)',
  },
  alertWithAnimatedBorder: (t) => ({
    svg: {
      path: {
        fill: t.colors.all.info,
      },
    },
  }),
  flexContainerBreadCrumbs: (t) => ({
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    svg: {
      path: {
        fill: t.palette.grey[800],
      },
    },
  }),
  searchByNameInput: (t) => ({
    [t.breakpoints.only('sm')]: {
      flex: '2 1 220px',
      minWidth: 220,
    },
    [t.breakpoints.only('md')]: {
      flex: '2 1 346px',
      minWidth: 346,
    },
  }),
  searchByPolicyIdInput: (t) => ({
    [t.breakpoints.only('sm')]: {
      flex: '1 1 128px',
      minWidth: 128,
    },
    [t.breakpoints.only('md')]: {
      flex: '2 1 284px',
      minWidth: 284,
    },
  }),
  searchBth: (t) => ({
    [t.breakpoints.between('sm', 'lg')]: {
      minWidth: 102,
      flex: '0 1 auto',
    },
  }),
};

export const StyledForm = styled(Form)(({theme}: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexGrow: 1,
  gap: 36,
  [theme.breakpoints.only('xs')]: {
    gap: 48,
  },
}));
