import {SxProps, Theme} from '@mui/material';
import {initialColors} from '@src/theme';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  dialog: t => ({
    '.MuiPaper-root': {
      mt: {
        md: 60,
        xs: 24,
      },
      mb: {
        md: 60,
        xs: 24,
      },
      '&.MuiDialog-paperScrollBody': {
        [t.breakpoints.down('md')]: {
          width: '100%',
          maxWidth: 'calc(100% - 48px)',
        },
      },
    },
  }),
  contentWrapper: (t) => ({
    overflow: 'auto',
    height: 'calc(100vh - 400px)',
    background: initialColors.grayBackground,
    [t.breakpoints.down('md')]: {
      height: 'calc(100vh - 202px)',
    },
  }),
  actionsContainer: t => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 24,
    borderTop: '1px solid',
    borderColor: t.colors.borderBlueLight,
    boxShadow: '0px -2px 12px 0px #0000001A',
  }),
  mobileActionsContainer: {
    boxShadow: '0px -2px 12px 0px #0000001A',
    padding: 18,
  },
};
