import {AddressComponentType, FullAddress, PlaceResult} from '@src/types';

import {addrComponentsToRecord} from './addrComponentsToRecord';
import {getFormattedAddress} from './getFormattedAddressString';
import {getTheStreetAddress} from './getTheStreetAddress';

export const getFullAddress = (result: PlaceResult): FullAddress => {
  const addressComponentsRecord = addrComponentsToRecord(result?.address_components);
  const latLng = result?.geometry?.location?.toJSON();
  const latitude = latLng?.lat ? Number(latLng.lat.toFixed(7)) : undefined;
  const longitude = latLng?.lng ? Number(latLng.lng.toFixed(7)) : undefined;
  return {
    fullAddress: getFormattedAddress(result).formattedAddress,
    streetAddress: getTheStreetAddress(addressComponentsRecord),
    city: addressComponentsRecord[AddressComponentType.LOCALITY]?.long_name,
    subLocality: addressComponentsRecord[AddressComponentType.SUBLOCALITY_LEVEL_1]?.long_name,
    state: addressComponentsRecord[AddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1]?.short_name,
    postalCode: addressComponentsRecord[AddressComponentType.POSTAL_CODE]?.long_name,
    latitude,
    longitude,
    googlePlaceId: result?.place_id,
  };
};
