import {IconButton, Stack, Typography} from '@mui/material';
import {ReactComponent as BackIcon} from '@src/shared/assets/icons/24x24/arrow-back.svg';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/24x24/cross.svg';
import {FC, PropsWithChildren} from 'react';

import {sx} from './styles';
import {IMobileInteractionViewProps} from './types';

export const MobileInteractionView: FC<PropsWithChildren<IMobileInteractionViewProps>> = ({isOpen, title, onBack, onClose, children}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Stack sx={sx.container}>
      <Stack sx={sx.header}>
        <IconButton onClick={onBack}>
          <BackIcon />
        </IconButton>
        <Typography variant={'18_24_700'}>{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {children}
    </Stack>
  );
};
