import {IInputProps} from './types';

export const getInputValue = ({type, range, value}: Pick <IInputProps, 'type' | 'range' | 'value'>) => {
  if (type === 'range') {
    if (range?.[0] && range?.[1]) {
      return `${range[0] ?? ''} - ${range[1] ?? ''}`;
    }
    return '';
  }
  return value ?? '';
};

export const getColor = ({error, success}: Pick<IInputProps, 'error' | 'success'>) => {
  if (error) return 'error';
  if (success) return 'success';
  return 'default';
};

export const getPlaceholder = ({placeholder, type}: Pick<IInputProps, 'placeholder' | 'type'>) => {
  if (type === 'range') return `${placeholder ?? ''} - ${placeholder ?? ''}`;
  return placeholder;
};
