import {Stack, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';

export const TooltipContent = () => {
  const {t} = useTranslate('journal');
  return (
    <Stack gap={6}>
      <Typography variant={'14_18_700'}>{t('WHY_DO_I_NEED_BMI')}</Typography>
      <Typography variant={'12_16_500'}>{t('BMI_IS_A_MEASUREMENT_')}</Typography>
      <Typography variant={'12_16_500'}>{t('SPECIFICALLY_THE_VALUE_OBTAINED_')}</Typography>
      <Typography variant={'12_16_500'}>{t('BEING_OVERWEIGHT_OR_UNDERWEIGHT_')}</Typography>
    </Stack>
  );
};
