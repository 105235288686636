import {ErrorsProps} from '@src/types/ErrorsProps';

export const getInsurancePlanError = ({touched, values, errors, t}: ErrorsProps) => {
  if (touched?.insurancePlan && !values.insurancePlan) {
    return errors.insurancePlan;
  }
  if (t && errors.insurancePlan === t('ENTER_UNIQUE_INSURANCE_PLAN_AND_POLICY_ID')) {
    return errors.insurancePlan;
  }
  return '';
};
