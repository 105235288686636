import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {TextareaControl} from 'ui-kit';

import {FormTextareaControlProps} from './types';

export const FormTextareaControl: FC<FormTextareaControlProps> = ({name, ...restProps}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  return (
    <TextareaControl
      {...restProps}
      name={name}
      value={value}
      onChange={onChange}
      error={error?.message}
    />
  );
};
