import {FC} from 'react';

import {ResultsHealthDetails} from '../../types';

import {CardCaseMobile} from './CardCaseMobile/CardCaseMobile';

export interface ResultsHealthTableMobileProps {
  results: ResultsHealthDetails[]
}

export const ResultsHealthTableMobile: FC<ResultsHealthTableMobileProps> = ({results}) => {
  return (
    <>
      {results.map((result) => (
        <CardCaseMobile
          key={result.id}
          {...result}
        />
      ))}
    </>
  );
};
