import {Box, Stack, Typography} from '@mui/material';
import {ReactComponent as GoBackIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as CloseIcon} from '@src/shared/assets/icons/close-icon.svg';
import {ReactComponent as EllipsisFilledIcon} from '@src/shared/assets/icons/ellipsis-filled.svg';
import {ReactComponent as EllipsisOutlinedIcon} from '@src/shared/assets/icons/ellipsis-outlined.svg';
import {GLOB_NOTIFICATIONS_PER_PAGE} from '@src/shared/constants';
import {useMQuery} from '@src/shared/hooks';
import {usePrevious} from '@src/shared/hooks/usePrevious';
import {useScrollPosition} from '@src/shared/hooks/useScrollPosition';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useAppDispatch, useAppSelector} from '@src/store';
import {globalNotificationsActions} from '@src/store/globalNotifications/slice';
import {forwardRef, MutableRefObject, UIEvent, useCallback, useEffect, useState} from 'react';
import {IconButtonNew} from 'ui-kit';

import {ViewGlobalNotification} from './ViewGlobalNotification';
import {TOAST_VISIBILITY_CHECK_INTERVAL} from './constants';
import {validateMainCondition} from './helpers';
import {getGlobalNotificationColor} from './styled';
import {sx} from './styles';
import {GlobalNotification, GlobalNotificationsProps} from './types';

export const GlobalNotifications = forwardRef<HTMLDivElement, GlobalNotificationsProps>(
  ({close, t}: GlobalNotificationsProps, ref) => {
    const {mobile: isMobile} = useMQuery();
    const dispatch = useAppDispatch();

    const [page, setPage] = useState(1);
    const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<GlobalNotification | null>(
      null,
    );

    const {notifications, isNext, isLoading, isError} = useAppSelector(
      (state) => state.globalNotifications,
    );

    const {containerRef, saveScrollPosition, restoreScrollPosition} = useScrollPosition();
    const previousNotification = usePrevious(selectedNotification);

    const emptyNotificationsList = notifications.length === 0;
    const canLoadMore = !isLoading && isNext && !isErrorToastVisible && !isRequestInProgress;

    const handleCloseModal = () => close();

    const handleGoBack = () => {
      setSelectedNotification(null);
    };

    const handleNotificationClick = (notification: GlobalNotification) => {
      saveScrollPosition();
      setSelectedNotification(notification);
    };

    const loadMoreNotifications = useCallback(() => {
      if (canLoadMore && !isRequestInProgress) {
        setIsRequestInProgress(true);

        void dispatch(
          globalNotificationsActions.fetchGlobalNotifications({
            startPage: page,
            perPage: GLOB_NOTIFICATIONS_PER_PAGE,
          }),
        )
          .then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
              setPage((prevPage) => prevPage + 1);
            }
          })
          .finally(() => setIsRequestInProgress(false));
      }
    }, [canLoadMore, isRequestInProgress, page, dispatch, t]);

    const handleScroll = (e: UIEvent) => {
      const container = e.currentTarget as HTMLElement;
      const isNearBottom = validateMainCondition(container);

      if (isNearBottom && canLoadMore) {
        loadMoreNotifications();
      }

      if (isError && !isErrorToastVisible && isNearBottom) {
        setIsErrorToastVisible(true);
      }
    };

    useEffect(() => {
      const checkToastVisibility = () => {
        const isToastVisible = document.querySelector('.Toastify__toast-container') !== null;
        setIsErrorToastVisible(isToastVisible);
      };

      const intervalId = setInterval(checkToastVisibility, TOAST_VISIBILITY_CHECK_INTERVAL);

      return () => clearInterval(intervalId);
    }, [isErrorToastVisible]);

    useEffect(() => {
      if (!isErrorToastVisible && isError) {
        setIsErrorToastVisible(true);
        dispatch(globalNotificationsActions.resetErrorState());
      }
    }, [isErrorToastVisible, isError, dispatch]);

    useEffect(() => {
      const fetchData = async () => {
        if (selectedNotification && !selectedNotification?.read) {
          try {
            const id = selectedNotification.id;

            await dispatch(
              globalNotificationsActions.updateGlobalNotificationStatus({requests: [id]}),
            );

            await dispatch(
              globalNotificationsActions.fetchGlobalNotifications({
                startPage: 0,
                perPage: 1,
                read: false,
                isCheckingUnread: true,
              }),
            );
          } catch (err) {
            console.error(err);
          }
        }
      };

      fetchData().catch((e) => console.error(e));
    }, [selectedNotification, dispatch]);

    useEffect(() => {
      if (previousNotification) {
        restoreScrollPosition();
      }
    }, [previousNotification, restoreScrollPosition]);

    return (
      <Stack sx={sx.wrapper} ref={ref}>
        <Stack sx={sx.header}>
          {selectedNotification && (
            <IconButtonNew
              id="goBackButton"
              color={'quaternary'}
              size={'lg'}
              onClick={handleGoBack}
            >
              <GoBackIcon />
            </IconButtonNew>
          )}
          <Typography typography={'18_24_700'}>{t('NOTIFICATIONS')}</Typography>
          <IconButtonNew
            id="closeButton"
            color={'quaternary'}
            size={'lg'}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButtonNew>
        </Stack>
        {!selectedNotification && (
          <Stack
            sx={sx.flexContainer}
            onScroll={handleScroll}
            ref={containerRef as MutableRefObject<HTMLDivElement>}
          >
            {!emptyNotificationsList &&
              notifications.map((item) => (
                <Stack
                  sx={sx.contentContainer}
                  key={item.id}
                  onClick={() => handleNotificationClick(item)}
                >
                  {item.read ? <EllipsisOutlinedIcon /> : <EllipsisFilledIcon />}
                  <Box sx={sx.content}>
                    <Typography
                      variant={isMobile ? '14_18_600' : '16_20_600'}
                      sx={[
                        ...spreadSx(sx.subject),
                        {color: getGlobalNotificationColor(item.read)},
                      ]}
                    >
                      {item.subject}
                    </Typography>
                    <Typography
                      variant={isMobile ? '12_18_500' : '14_18_500'}
                      sx={[
                        ...spreadSx(sx.description),
                        {color: getGlobalNotificationColor(item.read)},
                      ]}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            {emptyNotificationsList && (
              <Stack sx={sx.emptyList}>
                <Typography variant={'14_18_500'}>{t('NO_NOTIFICATIONS')}</Typography>
              </Stack>
            )}
          </Stack>
        )}
        {selectedNotification && <ViewGlobalNotification notification={selectedNotification} />}
      </Stack>
    );
  },
);

GlobalNotifications.displayName = 'GlobalNotifications';
