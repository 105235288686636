import {DateFormat} from '@src/shared/utils';

import {ERANGES} from './types';

export const RangeValues = {
  [ERANGES.WEEK]: 6,
  [ERANGES.MONTH]: 30,
  [ERANGES.THREE_MONTHS]: 90,
};

export const tickIntervals = {
  [ERANGES.WEEK]: 0,
  [ERANGES.MONTH]: 2,
  [ERANGES.THREE_MONTHS]: 30,
};

export const tickFormat = {
  [ERANGES.WEEK]: DateFormat.DAY_AND_MONTH_SHORT,
  [ERANGES.MONTH]: DateFormat.DAY_OF_MONTH,
  [ERANGES.THREE_MONTHS]: DateFormat.MONTH_FULL,
};

export const LABEL_WIDTH_MOBILE = 174;
export const LABEL_WIDTH = 320;
export const LABEL_OFFSET = 130;

export const BAR_SIZE_DESK_OPEN_XL = 113;
export const BAR_SIZE_DESK_OPEN = 104;
export const BAR_SIZE_DESK_DEFAULT = 65;
export const BAR_SIZE_TABLET = 74;
export const BAR_SIZE_MOBILE = 44;
export const BAR_SIZE_DEFAULT = 94;
