import {RelationProfile} from '@src/api/relations';
import {GuarantorFieldsName} from '@src/pages/Connections/types';

export const setGuarantorFieldsBySelf = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, relation: RelationProfile | null) => {
  setFieldValue(GuarantorFieldsName.FirstName, relation?.contact?.firstName);
  setFieldValue(GuarantorFieldsName.LastName, relation?.contact?.lastName);
  setFieldValue(GuarantorFieldsName.MiddleName, relation?.contact?.middleName);
  setFieldValue(GuarantorFieldsName.BirthDate, relation?.contact?.birthDate);
  setFieldValue(GuarantorFieldsName.BirthSex, relation?.contact?.birthSex);
  setFieldValue(GuarantorFieldsName.SsnTail, relation?.contact?.ssnTail);
  setFieldValue(GuarantorFieldsName.State, relation?.actualAddresses?.state);
  setFieldValue(GuarantorFieldsName.City, relation?.actualAddresses?.city);
  setFieldValue(GuarantorFieldsName.Address1, relation?.actualAddresses?.main);
  setFieldValue(GuarantorFieldsName.Address2, relation?.actualAddresses?.additional);
  setFieldValue(GuarantorFieldsName.PostalCode, relation?.actualAddresses?.postalCode);
  setFieldValue(GuarantorFieldsName.Phone, relation?.phones ? relation?.phones[0]?.phone : '');
};
