import {Box, Stack, Typography} from '@mui/material';
import {PdfViewer} from '@src/components/PdfViewer';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {Icons} from '@src/shared/assets/icons';
import {openBase64NewTab} from '@src/shared/utils/pdfHelpers';
import {FC, useCallback} from 'react';
import {Button} from 'ui-kit';
import {IPdfResultsViewProps} from './types';
import {ROUTERS_PATH} from '@src/routers';

import {sx} from './styled';

export const PdfResultsView:FC<IPdfResultsViewProps> = ({
  resultData
}) => {
  const {t} = useTranslate('results');

  const clickHandler = useCallback(() => {
    openBase64NewTab(resultData.data);
  }, [resultData.data]);

  return (
    <WBox sx={sx.mainBlock}>
      <Stack sx={{flexGrow: 1}} gap={36}>
        <Box sx={sx.topblock}>
          <Typography sx={sx.title}>{t('RESULTS')}</Typography>
          <Button
            variant="text"
            color="secondary"
            startIcon={<Icons.Download.X18 />}
            onClick={clickHandler}
          >{t('DOWNLOAD')}</Button>
        </Box>
        <PdfViewer data={resultData.data} sx={sx.viewer} />
        <Box sx={sx.block}>
          <Typography sx={sx.signIn} variant="14_18_600">{t('SIGNIN_LABLE')}</Typography>
          <Button
            variant="contained"
            color="primary"
            href={ROUTERS_PATH.OVERVIEW}
          >{t('SIGN_IN')}</Button>
        </Box>
      </Stack>
    </WBox>
  );
};
