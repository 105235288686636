import {HealthCaseData} from '@src/api/healthCases';

export const getFilteredItems = (items: HealthCaseData[], healthCases?: HealthCaseData[]) => {
  if (!healthCases || healthCases.length === 0) {
    return items;
  }

  return items.filter(item =>
    !healthCases.some(healthCase => healthCase.id === item.id),
  );
};
