import React, {MutableRefObject} from 'react';

/**
 *
 * @param reference отслеживаемый элемент
 * @param callBack функция выполнения
 * @param isLoading нужно ли грузить данные
 */
export const useObserver = (
  reference: MutableRefObject<HTMLElement | null>,
  callBack?: () => void,
  isLoading?: boolean,
) => {
  React.useEffect(() => {
    if (!callBack) return;
    const {current} = reference;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoading) {
          callBack();
        }
      },
      {
        threshold: 0.9,
        rootMargin: '0px 0px 10px 0px',
      },
    );

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [callBack, isLoading, reference]);
};
