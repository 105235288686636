import {ErrorsProps} from '@src/types/ErrorsProps';

export const getPolicyIdError = ({touched, values, errors, t}: ErrorsProps) => {
  if (touched?.policyId && errors.policyId) {
    return errors.policyId;
  }
  if (touched?.policyId && !values.policyId) {
    return errors.policyId;
  }
  if (t && errors.policyId === t('ENTER_UNIQUE_INSURANCE_PLAN_AND_POLICY_ID')) {
    return errors.policyId;
  }
  return '';
};
