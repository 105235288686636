export interface Slot {
  from?: string
  to?: string
  types?: AppointmentType[]
}

export interface ProfessionalSlots {
  date?: string
  slots?: Slot[]
}

export interface DatePeriod {
  from?: string
  to?: string
}

export interface ProfessionalSlotsRequest {
  addressId: string
  period: DatePeriod
  types: AppointmentType[]
}

enum AppointmentType {
  IN_PERSON = 'IN_PERSON',
  VIDEO = 'VIDEO',
  HOME_VISIT = 'HOME_VISIT',
}
