import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogContent: {
    p: '24px 24px 12px 24px',
  },
  dialogActions: {
    flexDirection: {xs: 'column !important', sm: 'row !important'},
    gap: {xs: '18px !important', sm: '24px !important'},
  },
};
