// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {
  css as _,
  ButtonBase,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import {Result} from '@src/api';
import {Switch} from '@src/components/Switch';
import {WhiteBox} from '@src/components/WhiteBox';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {HealthCaseSelector} from '@src/features/HealthCaseSelector';
import {ReactComponent as ChevronDownIcon} from '@src/shared/assets/icons/chevron-down.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useScrollToTop} from '@src/shared/hooks/useScrollToTop';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {
  EmptySearch,
  Table,
  ITableScrollUp,
  PageHeader,
} from 'components';
import {BoxBody, BoxPaddings} from 'components/WhiteBox/WhiteBox';
import {useTranslate} from 'i18n/useTranslate';
import {isEqual} from 'lodash-es';
import {useEffect, useRef, useState} from 'react';
import {useResults} from 'store/results/hooks';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useHeader, initialFilter, initiialSort, IFilterResults} from './Results.constant';
import {sortByDate} from './Results.helpers';
import {TableRow} from './TableRow';
import {ResultsFilters} from './components/ResultsFilters/ResultsFilters';
import {ResultsTable} from './components/ResultsTable/ResultsTable';
import {sx} from './styles';

export const Results = () => {
  const {t, ready} = useTranslate('results');
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState(initiialSort);
  const {data, filteredResults, isLoading} = useResults();
  const [result, setResult] = useState<Result[]>();
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const {mobile, desktop, tabletPortrait, tablet} = useMQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const refTable = useRef<ITableScrollUp>();
  const [allExpanded, setAllExpanded] = useState(false);
  const [collectedPosition, setCollectedPosition] = useState(false);
  const selectedResults = useAppSelector((state) => state.healthCases.selectedResults);
  const {isSidebarOpen} = useSidebarClassObserver();

  const dispatch = useAppDispatch();

  const isFiltersEmpty = isEqual(filter, initialFilter);

  const handleCloseFilter = () => setFilterOpened(false);
  const handleOpenFilter = () => setFilterOpened(true);

  const handleSetFilter = (newValue: IFilterResults) => {
    setFilter(newValue);
  };

  const handleSetResult = (newValue: Result[]) => {
    setResult(newValue);
    setIsLocalLoading(false);
  };

  useEffect(() => {
    handleSetResult(filteredResults);
  }, [filteredResults]);

  useEffect(() => {
    if (isLoading) {
      setIsLocalLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (result) {
      const arrayForSort = [...result];
      handleSetResult(arrayForSort.sort((a, b) => sortByDate(a, b, sort)));
    }
  }, [sort]);

  const filterResultByDate = (columnName: string) => () => {
    setSort((prev) => ({...prev, sortBy: columnName, sortDesc: !prev.sortDesc}));
    if (columnName === 'collected') setCollectedPosition((prev) => !prev);
  };

  const {HEADER_LIST} = useHeader(filterResultByDate, collectedPosition);

  const handleExpandAll = () => {
    setAllExpanded((prev) => {
      return !prev;
    });
  };

  useScrollToTop();

  const testLoading = isLoading || isLocalLoading;

  const isResultsNotExists = isFiltersEmpty && !result?.length && !isLocalLoading;

  useEffect(() => {
    return () => {
      dispatch(healthCasesActions.clearSelectedResults());
    };
  }, []);

  if (!ready) {
    return null;
  }

  const onPortrait = (mobile || tabletPortrait);
  const paddingSize = desktop ? '36px 24px' : '14px';

  const calcPaddingLeft = () => {
    if (tablet && !tabletPortrait) return '24px';
    if (mobile || tabletPortrait) return '42px';
    return '36px';
  };

  const paddingLeft = calcPaddingLeft();
  const height = desktop ? 'calc(100vh - 348px)' : 'calc(100vh - 315px)';
  const paddingBoxBottom = onPortrait ? '50px' : '0px';

  const renderAddResultsToHealthCase = () => {
    return (
      <Typography variant='14_18_500'>
        {t(selectedResults.size > 1
          ? 'ADD_COUNT_RESULTS'
          : 'ADD_COUNT_RESULT',
        {count: selectedResults.size || ''})}
      </Typography>
    );
  };

  const addResultsToHealthCaseSelector = renderAddResultsToHealthCase();

  if (data?.length === 0 && !isLoading) {
    return (
      <Stack p={mobile ? 0 : 24} sx={{display: 'flex', flexGrow: 1, gap: 24}}>
        <Box>
          <PageHeader height={'auto'}>{t('RESULTS')}</PageHeader>
        </Box>
        <WhiteBox noPaddings sx={{display: 'flex', flexGrow: 1}}>
          <BoxPaddings sx={{padding: paddingSize}}>
            <Typography variant='14_18_500' color={'grey.500'} sx={sx.patientNotFound}>
              {t('NO_RESULTS_WERE_FOUND')}
            </Typography>
          </BoxPaddings>
        </WhiteBox>
      </Stack>
    );
  };

  return (
    <>
      <Box sx={sx.headerContainer}>
        <PageHeader height={'auto'}>{t('RESULTS')}</PageHeader>
        {!onPortrait && (
          <HealthCaseSelector>
            <Stack sx={sx.contentHealthCaseSelector}>
              {addResultsToHealthCaseSelector}
              <ChevronDownIcon />
            </Stack>
          </HealthCaseSelector>
        )}
        {onPortrait && <SettingsButton disabled={isResultsNotExists} onClick={handleOpenFilter} />}
      </Box>
      <WhiteBox noPaddings sx={{display: 'flex', flexGrow: 1, mb: paddingBoxBottom}}>
        <BoxBody>
          {onPortrait && !isResultsNotExists && (
            <ButtonBase
              onClick={handleExpandAll}
              css={_`
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      height: 58px;
                      align-items: center;
                      padding: 0 16px;
                      `}
            >
              <Typography variant='14_18_500'>{t('EXPAND_ALL')}</Typography>
              <Switch css={_`margin-right: -12px;`} readOnly checked={allExpanded} />
            </ButtonBase>
          )}
          <ResultsFilters
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            handleSetFilter={handleSetFilter}
            isOpen={filterOpened}
            filter={filter}
          />
          {!onPortrait && (
            <Box sx={{height, marginTop: '0px'}}>
              <ResultsTable
                result={result}
                isLoading={testLoading}
                isResults
              />
            </Box>
          )}
          {onPortrait && (
            <Box sx={sx.tableWrapper}>
              <Table
                css={_`margin-top: -1px`}
                classNames={{header: 'table-header'}}
                ref={refTable}
                gridTemplateColumns={onPortrait ? '20px 4fr 3fr 1fr' : '1fr 1fr 1fr 0.5fr'}
                data={result || []}
                headerList={HEADER_LIST}
                loading={false}
                rowComponent={{component: TableRow, props: {allExpanded, expandableTemplateColumn: {gridTemplateColumns: '5fr 5fr', padding: '12px 40px 0px', columnGap: '40px'}}}}
                paddingLeft={paddingLeft}
                emptyData={
                  <Box
                    sx={{
                      padding: paddingSize,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <EmptySearch isLoading={testLoading} isNew isResults />
                  </Box>}
              />
            </Box>
          )}
        </BoxBody>
      </WhiteBox>
      {onPortrait && (
        <Box sx={sx.fixedButtonContainer} zIndex={isSidebarOpen ? 0 : 1030}>
          <HealthCaseSelector>
            <Stack sx={sx.caseSelectorWrapper}>
              {addResultsToHealthCaseSelector}
            </Stack>
          </HealthCaseSelector>
        </Box>
      )}
    </>
  );
};
