import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import {spreadSx} from '@src/shared/utils/spreadSx';

import {sx} from './styles';
import {IRadioBthProps} from './types';

export const RadioButtons = ({
  label,
  value,
  handleChange,
  name,
  error,
  firstButtonData,
  secondaryButtonData,
  size = 'small',
  onTouched,
  inColumn,
  gapNumber,
  stylesSx,
  height,
}: IRadioBthProps) => {
  return (
    <FormControl sx={[...spreadSx(sx.container), stylesSx]} onClick={onTouched}>
      {
        label && (
          <FormLabel sx={sx.label}>
            {label}
          </FormLabel>
        )
      }
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={value}
        onChange={handleChange}
        sx={{flexDirection: inColumn ? 'column' : 'row', gap: inColumn && !gapNumber ? 24 : (gapNumber || 36), flexWrap: 'nowrap', height: height || 20}}
      >
        <FormControlLabel
          value={firstButtonData.value}
          control={
            <Radio
              sx={(t) => ({
                paddingLeft: !label || inColumn ? 0 : 9,
                'span svg path': {fill: error ? t.palette.error.main : t.palette.secondary.main},
              })}
              color="secondary"
              size={size}
            />
          }
          label={firstButtonData.label}
        />
        <FormControlLabel
          value={secondaryButtonData.value}
          control={
            <Radio
              sx={(t) => ({
                span: {paddingLeft: 0},
                paddingLeft: inColumn ? 0 : 9,
                'span svg path': {fill: error ? t.palette.error.main : t.palette.secondary.main},
              })}
              color="secondary"
              size={size}
            />
          }
          label={secondaryButtonData.label}
        />
      </RadioGroup>
    </FormControl>
  );
};
