import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    padding: 0,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
    },
    [t.breakpoints.only('xs')]: {
      width: 'auto',
    },
  }),
  upperBlockContainer: (t) => ({
    flexGrow: 1,
    padding: {xs: 0, sm: 0, lg: 24},
    [t.breakpoints.between('sm', 'lg')]: {
      p: 0,
      width: 'auto',
    },
  }),
  mainTitle: {
    mb: {xs: 18, sm: 24, lg: 36},
    typography: {xs: '18_24_500', sm: '20_24_500', lg: '24_34_500'},
  },
  notSpecifiedText: (t) => ({
    color: t.palette.grey[600],
    typography: {xs: '12_16_500', sm: '14_18_500'},
  }),
  phoneAndEmailTitleMain: {
    pb: {xs: 12, sm: 18, lg: 30},
    typography: {xs: '16_20_500', sm: '18_24_500'},
  },
  phoneAndEmailTitleOptional: {
    typography: {xs: '16_20_500', sm: '18_24_500'},
    pb: {xs: 12, sm: 18, lg: 30},
  },
  boxInfoContainer: (t) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: {xs: 4, sm: 10},
    color: t.palette.grey[600],
    mt: {xs: 24, sm: 36},
    svg: {
      flexShrink: 0,
      path: {
        fill: 'currentColor',
      },
    },
  }),
  editInfoText: {
    mt: {xs: 1, sm: 0},
    typography: {xs: '12_16_500', sm: '14_18_500'},
  },
};
