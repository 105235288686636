import Color from 'color';

import {Colors} from './types';

type TPercent = number; // NOTE 1-100

export const alpha = (color: Colors, percent: TPercent) => {
  if (percent >= 100) {
    return color;
  }
  const mixStr = `0.${`0${100 - percent}`.slice(-2)}`;
  const mixNum = parseFloat(mixStr);
  return Color(color).mix(Color('white'), mixNum).hex();
};
