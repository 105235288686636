import {HealthCaseData} from './types';

export const INITIAL_STATE: HealthCaseData = {
  name: '',
  caseDate: '',
  description: '',
};

export const CASES_COUNT_DESKTOP = 10;
export const CASES_COUNT_MOBILE = 5;
