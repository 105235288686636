import {Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx = {
  container: {flex: 1},
  body: (t: Theme) => ({
    flex: '1 1 auto',
    backgroundColor: alpha(t.palette.secondary.main, 7),
  }),
  whiteBox: {
    flex: '1 1 auto',
    backgroundColor: '#fff',
    padding: 12,
    justifyContent: 'center',
  },
  content: {
    alignItems: 'center',
  },
  lineWithButton: {
    gap: 12,
    alignItems: 'center',
  },
  textContent: {
    alignItems: 'center',
    textAlign: 'center',
  },
};
