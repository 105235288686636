import {Box, Typography} from '@mui/material';
import Grid from '@src/components/Grid';
import {useColumnsNumber} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC} from 'react';

import {AccessesCard} from '../AccessesCard';

import {breakpoints, customColumns, defaultColumns} from './constants';
import {accessesCardTitle, cardSpacing} from './helpers';
import {sx} from './styles';
import {IAccessesCardsBlockProps} from './types';

export const AccessesCardsBlock: FC<IAccessesCardsBlockProps> = ({
  accesses,
  onOpenModal,
  blockName,
  showExpired,
}) => {
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();

  const {
    colsNumberXxl,
    colsNumberXl,
    colsNumberLg,
    colsNumberMd,
    colsNumberSm,
    containerRef,
  } = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  if (!accesses?.length) {
    return null;
  }

  return (
    <Box sx={sx.container} ref={containerRef}>
      <Typography sx={sx.title} component="p">
        {blockName}
      </Typography>
      <Grid
        container
        columnGap={isMobile ? 12 : 0}
        spacing={cardSpacing(isDesktop, isMobile)}
        sx={isMobile ? sx.gridWrapperMobile : sx.gridWrapper}>
        {accesses?.map((account) => {
          const currentDate = new Date();
          const isExpired = new Date(account?.endDate || currentDate) < currentDate;

          if (!showExpired && isExpired) {
            return null;
          }

          return (
            <Grid
              xs={12}
              sm={colsNumberSm}
              md={colsNumberMd}
              lg={colsNumberLg}
              xl={colsNumberXl}
              xxl={colsNumberXxl}
              key={account?.id}
              sx={sx.gridContainer}
            >
              <AccessesCard
                title={accessesCardTitle(account)}
                isExpired={isExpired}
                access={account?.access}
                beginDate={account?.beginDate}
                endDate={account?.endDate}
                onOpenModal={onOpenModal}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
