import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  title: {
    mb: {xs: 18, sm: 24},
    typography: {xs: '18_24_500', sm: '20_24_500', lg: '24_34_500'},
  },
  listContainer: {
    mt: {xs: 18, sm: 24, lg: 0},
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
};
