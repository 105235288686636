/** @jsxImportSource @emotion/react */
import {styled} from '@mui/material';

export const BodyContainer = styled('div')`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;
