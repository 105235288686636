import {Stack} from '@mui/material';
import {BasePhone, Email, Phone} from '@src/api';
import {RowInformation} from '@src/components';
import {ListWrapper} from '@src/components/ListWrapper';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';

import {status} from './Status';

interface RenderPhonesProps {
  phones: Phone[] | undefined
  isMainPhone: boolean | undefined
}

interface RenderEmailsProps {
  emails: Email[] | undefined
  isMainEmail: boolean | undefined
}

export const hasMainItems = (items: BasePhone[] | Email[] | undefined) => {
  if (items) {
    return items?.find((item) => item.main === true) && items.length > 1;
  }
};
export const hasOptionalItems = (items: BasePhone[] | Email[] | undefined) => {
  if (items) {
    return items?.find((item) => item.main !== true) && items.length > 1;
  }
};

export const RenderPhones = ({phones, isMainPhone}: RenderPhonesProps) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const nameTypographyVariant = isMobile ? '12_18_700' : '14_18_700';
  const valueTypographyVariant = isMobile ? '12_18_500' : '14_18_500';
  const listWrapperSpaceBetween = isMobile ? '8px' : '12px';
  return (
    <Stack gap={24}>
      {phones?.filter((phone) => (isMainPhone ? phone.main === true : phone.main === false || phone.main === undefined))?.map((phone) => (
        <ListWrapper
          spaceBetween={listWrapperSpaceBetween}
          padding='12px'
          noLast={{xs: 0}}
          key={phone.id}>
          <RowInformation
            variant={nameTypographyVariant}
            valueTypographyVariant={valueTypographyVariant}
            tooltip
            value={formatPhoneNumber(phone.phone)}
            noMargin
            name={t(phone.type)} />
          <RowInformation
            variant={nameTypographyVariant}
            valueTypographyVariant={valueTypographyVariant}
            containerSx={{alignItems: 'center'}}
            tooltip
            value={status(phone.ownershipConfirmed) || t('NO')}
            noMargin
            name={t('STATUS')} />
        </ListWrapper>
      ))}
    </Stack>
  );
};

export const RenderEmails = ({emails, isMainEmail}: RenderEmailsProps) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const nameTypographyVariant = isMobile ? '12_18_700' : '14_18_700';
  const valueTypographyVariant = isMobile ? '12_18_500' : '14_18_500';
  const listWrapperSpaceBetween = isMobile ? '8px' : '12px';
  return (
    <Stack gap={24}>
      {emails?.filter((email) => (isMainEmail ? email.main === true : email.main === false || email.main === undefined))?.map((email) => (
        <ListWrapper
          spaceBetween={listWrapperSpaceBetween}
          padding='12px'
          noLast={{xs: 0}}
          key={email.id}>
          <RowInformation
            variant={nameTypographyVariant}
            valueTypographyVariant={valueTypographyVariant}
            tooltip
            value={email.email}
            noMargin
            name={t('EMAIL')} />
          <RowInformation
            variant={nameTypographyVariant}
            valueTypographyVariant={valueTypographyVariant}
            containerSx={{alignItems: 'center'}}
            tooltip
            value={status(email.ownershipConfirmed) || t('NO')}
            noMargin
            name={t('STATUS')} />
        </ListWrapper>
      ))}
    </Stack>
  );
};
