import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  content: (theme) => ({
    pb: 36,
    pt: '24px !important',
    [theme.breakpoints.down('sm')]: {
      pt: '48px !important',
    },
  }),
  mobileContent: {
    p: 24,
  },
  actionsContainer: (theme) => ({
    borderTop: `1px solid ${theme.colors.borderBlueLight}`,
    justifyContent: 'space-between !important',
    margin: 0,
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
  }),
  cancelButton: {
    typography: '14_18_600',
  },
  buttonsContainer: (theme) => ({
    flexDirection: 'row-reverse',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 18,
      gap: 12,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid',
      borderColor: theme.colors.borderBlueLight,
    },
  }),
};
