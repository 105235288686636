import {Dialog, DialogTitle, EmptySearch} from '@components';
import {Stack} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {useTranslate} from '@src/i18n/useTranslate';
import {DEFAULT_PER_PAGE} from '@src/pages/Storage/constants';
import {useMQuery} from '@src/shared/hooks';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {INITIAL_FILTERS} from '@src/store/storage/constants';
import {fetchStorageFiles, storageActions} from '@src/store/storage/slice';
import {StorageFilters} from '@src/store/storage/types';
import {FC, useEffect, useMemo, useState} from 'react';
import {Button} from 'ui-kit';

import {DocumentCard} from './DocumentCard/DocumentCard';
import {DocumentsFilters} from './DocumentFilters/DocumentFilters';
import {sx} from './styles';
import {DocumentListDialogProps} from './types';

export const DocumentListDialog: FC<DocumentListDialogProps> = ({isOpen, onClose}) => {
  const {t, ready} = useTranslate('healthCases');
  const {mobile, tabletPortrait} = useMQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const dispatch = useAppDispatch();

  const handleCloseFilter = () => setFilterOpened(false);
  const handleOpenFilter = () => setFilterOpened(true);

  const selectedDocuments = useAppSelector((state) => state.healthCases.selectedDocuments);

  const {files, isLoading, selectedHealthCases} = useAppSelector((state) => state.storage);

  const handleFetch = (isNext: boolean) => {
    if (isNext && !files.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = files.filters;
    void dispatch(
      fetchStorageFiles({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? files.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  };

  const onPortrait = (mobile || tabletPortrait);

  const filteredData = useMemo(() => {
    if (selectedHealthCases.length === 0) {
      return files.items;
    }

    return files.items.filter((file) =>
      file.healthCases?.some((healthCase) =>
        selectedHealthCases.includes(healthCase?.id),
      ),
    );
  }, [files.items, selectedHealthCases]);

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: StorageFilters
    updatedValue?: { key: keyof StorageFilters, value: any }
  }) => {
    dispatch(storageActions.setFilters({newState, updatedValue}));
  };

  const handleClearClick = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
    dispatch(healthCasesActions.clearSelectedDocuments());
  };

  const handleSaveClick = () => {
    dispatch(healthCasesActions.saveSelectedDocuments());
    dispatch(healthCasesActions.clearSelectedDocuments());
    onClose();
  };

  useEffect(() => {
    handleFetch(false);
  }, [files.filters]);

  if (!ready) return null;

  const renderContent = () => {
    return (
      <>
        <DocumentsFilters
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          isOpen={filterOpened}
        />
        <Stack sx={sx.contentWrapper}>
          {(!isLoading && !!files.items.length) && (
            <Stack flexDirection={'column'} p={24} gap={8}>
              {filteredData.map((file) => {
                return (
                  <DocumentCard
                    key={file.id}
                    {...file}
                  />
                );
              })}
            </Stack>
          )}
          {!files.items.length && (
            <Stack pt={24} pl={12}>
              <EmptySearch isLoading={isLoading} isNew />
            </Stack>
          )}
        </Stack>
        {!onPortrait && (
          <Stack sx={sx.actionsContainer}>
            <Button variant={'outlined'} color={'secondary'} onClick={onClose}>
              {t('CANCEL')}
            </Button>
            <Stack flexDirection={'row'} gap={12}>
              <Button
                variant={'outlined'}
                color={'secondary'}
                disabled={!selectedDocuments.size}
                onClick={handleClearClick}>
                {t('CLEAR_ALL')}
              </Button>
              <Button color={'secondary'} onClick={handleSaveClick} disabled={!selectedDocuments.size}>
                {t('ADD_DOCUMENTS_COUNT', {count: selectedDocuments.size || ''})}
              </Button>
            </Stack>
          </Stack>
        )}
        {onPortrait && (
          <Stack sx={sx.mobileActionsContainer}>
            <Button color={'secondary'} onClick={handleSaveClick} disabled={!selectedDocuments.size}>
              {t('ADD_DOCUMENTS_COUNT', {count: selectedDocuments.size || ''})}
            </Button>
          </Stack>
        )}
      </>
    );
  };

  if (onPortrait) {
    return (
      <MobileInteractionView
        isOpen={isOpen}
        title={t('SELECT_DOCUMENTS')}
        onBack={onClose}
        onClose={onClose}
      >
        {renderContent()}
      </MobileInteractionView>
    );
  }

  return (
    <Dialog
      open={isOpen}
      size={'xxl'}
      scroll={'body'}
      border={'unset'}
      sx={sx.dialog}
    >
      <DialogTitle onClose={onClose}>
        {t('ADD_DOCUMENTS_TO')}
      </DialogTitle>
      {renderContent()}
    </Dialog>
  );
};
