import {useMQuery} from '@src/shared/hooks';

import {RelationDesktopLayout} from '../RelationDesktopLayout';
import {RelationTabletAndMobileLayout} from '../RelationTabletAndMobileLayout';

export const RelationLayoutView = () => {
  const {desktop: isDesktop} = useMQuery();

  if (!isDesktop) {
    return <RelationTabletAndMobileLayout/>;
  }

  return <RelationDesktopLayout />;
};
