import {Box, SxProps} from '@mui/material';
import {useTrackIntersection} from '@src/shared/utils';
import React from 'react';

import {Oject, RowComponentType} from '../types';
import {useObserver} from '../useObserver';

const sx: Partial<Record<string, SxProps>> = {
  row: {
    display: 'grid',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    color: '#4b5563',
    fontWeight: 400,
    '& > *': {
      alignSelf: 'center',
    },
    borderBottom: '1px solid #dff2f5',
    columnGap: 24,
    padding: '0px 36px',
  },
};

interface BodyType<T extends Oject> {
  data: T[]
  rowComponent: RowComponentType<T>
  gridTemplateColumns: string
  keyIndicator: keyof T
  className?: string
  scrollRest?: () => void
  setIsScrollAbsolute?: (event: any) => void
  loading: boolean
}

export const Body = <T extends Oject>({
  data,
  rowComponent,
  gridTemplateColumns,
  keyIndicator,
  className,
  scrollRest,
  loading,
  setIsScrollAbsolute,
}: BodyType<T>): JSX.Element => {
  const reference = React.useRef<HTMLDivElement | null>(null);
  const {component: Component, props} = rowComponent;
  useTrackIntersection({reference, callBack: setIsScrollAbsolute, threshold: 0});

  useObserver(reference, scrollRest, loading);
  return (
    <>
      <Box>
        {data.map((object: any, index) => (
          <Component
            sx={sx.row}
            data={object}
            /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
            className={`${className ? `${className} ` : ''}`}
            gridTemplateColumns={gridTemplateColumns}
            /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
            key={`${object[keyIndicator as string]}/table-component${index}`}
            {...props}
          />
        ))}
      </Box>
      <Box ref={reference} />
    </>
  );
};
