import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Dialog, Backdrop, DialogProps} from '@mui/material';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
  },
}));

const BootstrapBackdrop = styled(Backdrop)(() => ({
  background: 'rgba(31, 41, 55,0.3)',
}));

export type ModalWrapperProps = DialogProps;

export const ModalWrapper: React.FC<ModalWrapperProps> = ({children, ...rest}) => {
  return (
    <BootstrapDialog components={{Backdrop: BootstrapBackdrop}} {...rest}>
      {children}
    </BootstrapDialog>
  );
};
