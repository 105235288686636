import {ReactComponent as ComingSoonMobile} from '@src/shared/assets/images/modal_coming-soon_mobile.svg';
import {ReactComponent as ComingSoonTablet} from '@src/shared/assets/images/modal_coming-soon_tablet.svg';
import {ReactComponent as ComingSoon} from '@src/shared/assets/images/modal_coming_soon.svg';

export const modalSize = (tablet: boolean, mobile: boolean) => {
  if (tablet) return 'lg';
  if (mobile) return 'xs';
  return 'xl';
};

export const modalType = (tablet: boolean, mobile: boolean) => {
  if (tablet) return <ComingSoonTablet />;
  if (mobile) return <ComingSoonMobile />;
  return <ComingSoon />;
};
