import {MenuTypes} from '@src/api';
import {TKeys} from '@src/i18n/useTranslate';

export const getMenuTypesOptions = (
  translate: (str: TKeys<'healthCases'> | null | undefined) => string,
) => {
  return [
    {label: translate('VIEW_ALL'), value: MenuTypes.ALL},
    {label: translate('RECENT'), value: MenuTypes.RECENT},
    {label: translate('FAVORITES'), value: MenuTypes.FAVORITE},
  ];
};
