import {Stack, styled, SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  breadcrumbs: (t) => ({
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    color: t.palette.grey[800],
    mt: {sm: 3},
    'a, a:visited': {
      color: t.palette.grey[800],
    },
  }),
  mainContainer: {
    gap: 24,
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexDirection: {
      md: 'column-reverse',
      lg: 'row',
    },
  },
  leftContainer: theme => ({
    gap: {xs: 18, sm: 24},
    padding: 24,
    [theme.breakpoints.up('lg')]: {
      flex: 1,
    },
  }),
  mobileDownloadButton: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    background: theme => theme.colors.all.white,
    zIndex: 5,
  },
  rowInfo: {
    '&:first-of-type': {
      mb: 6,
    },
  },
  rowValue: {
    wordBreak: 'break-word',
  },
  fileName: {
    typography: {xs: '22_26_700', lg: '24_32_700'},
    whiteSpace: 'unset',
    wordBreak: 'break-all',
    display: '-webkit-box !important',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
  },
  healthCaseText: {
    b: {
      mr: 12,
    },
  },
  link: {
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    display: 'inline',
  },
  loader: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
};

export const ContentContainer = styled(Stack)<{ isSidebarOpen: boolean }>(({theme, isSidebarOpen}) => ({
  flex: 1,
  [theme.breakpoints.only('lg')]: {
    flexDirection: isSidebarOpen ? 'column' : 'row',
    '& > :first-child': {
      order: isSidebarOpen ? 1 : 0,
      borderRight: !isSidebarOpen && 'unset',
      borderTop: isSidebarOpen && 'unset',
    },
  },
  [theme.breakpoints.up('xl')]: {
    flexDirection: 'row',
    '& > :first-child': {
      borderRight: 'unset',
    },
  },
  [theme.breakpoints.down('lg')]: {
    '& > :first-child': {
      order: 1,
      borderTop: 'unset',
      flex: 1,
    },
  },
  [theme.breakpoints.only('xs')]: {
    marginBottom: 78,
  },
}));
