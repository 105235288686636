import {Box, Typography} from '@mui/material';
import {RowInformation} from '@src/components';
import {ListWrapper} from '@src/components/ListWrapper';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getRowInformationTypography} from '@src/shared/utils/getRowInformationTypography';
import {FC} from 'react';

import {calcRowInfoSpaceBetween} from '../../helpers';
import {listWrapperPadding} from '../styled';

import {sx} from './styles';
import {IRelationAboutWithoutAccess} from './types';

export const RelationAboutWithoutAccess: FC<IRelationAboutWithoutAccess> = ({relation}) => {
  const {t} = useTranslate('connections');
  const {desktop: isDesktop, tablet: isTablet, mobile: isMobile} = useMQuery();

  const {rowInfoNameTypography, rowInfoValueTypography} = getRowInformationTypography(isMobile);

  return (
    <Box sx={sx.listContainer}>
      <Typography component="h4" sx={sx.title}>
        {t('PATIENT_INFORMATION')}
      </Typography>
      <ListWrapper
        spaceBetween={calcRowInfoSpaceBetween(isMobile)}
        padding={listWrapperPadding(isDesktop, isTablet)}
        noLast={{xs: 0}}
      >
        <RowInformation
          tooltip
          variant={rowInfoNameTypography}
          valueTypographyVariant={rowInfoValueTypography}
          value={relation?.contact?.firstName}
          noMargin
          name={t('FIRST_NAME')}
        />
        <RowInformation
          variant={rowInfoNameTypography}
          valueTypographyVariant={rowInfoValueTypography}
          tooltip
          value={relation?.contact?.lastName}
          noMargin
          name={t('LAST_NAME')}
        />
        <RowInformation
          variant={rowInfoNameTypography}
          valueTypographyVariant={rowInfoValueTypography}
          tooltip
          value={relation?.contact?.middleName}
          noMargin
          name={t('MIDDLE_NAME')}
        />
        <RowInformation
          variant={rowInfoNameTypography}
          valueTypographyVariant={rowInfoValueTypography}
          tooltip
          value={relation?.contact?.birthDateS}
          noMargin
          name={t('DATE_OF_BIRTH')}
        />
        <RowInformation
          variant={rowInfoNameTypography}
          valueTypographyVariant={rowInfoValueTypography}
          tooltip
          value={t(relation?.contact?.birthSex)}
          noMargin
          name={t('BIRTH_SEX')}
        />
        <RowInformation
          variant={rowInfoNameTypography}
          valueTypographyVariant={rowInfoValueTypography}
          tooltip
          value={relation?.contact?.ssnTail}
          noMargin
          name={t('SSN_LAST_4')}
        />
      </ListWrapper>
    </Box>
  );
};
