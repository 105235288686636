import {Slider, SxProps, Theme, styled} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  lableBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  slider: {
    padding: '12px 0',
    '&.MuiSlider-rail': {
      height: 6,
      backgroundColor: (t: Theme) => t.palette.grey[300],
    },
    '&.MuiSlider-root': {
      padding: '12px 0',
    },
  },
};

export const StyledSlider = styled(Slider)(({theme}) => ({
  '& .MuiSlider-rail': {
    height: 6,
    backgroundColor: theme.palette.grey[300],
    boxShadow: 'none',
  },
  '& .MuiSlider-thumb': {
    boxShadow: 'none',
    ':hover,:focus-visible': {
      backgroundColor: theme.colors.all.red[300],
      boxShadow: 'none',
    },
    ':focus-visible': {
      backgroundColor: theme.colors.all.red[300],
    },
    ':active': {
      backgroundColor: theme.colors.all.red[500],
    },
    '& .MuiSlider-track': {
      backgroundColor: theme.colors.all.red[400],
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: theme.palette.grey[800],
      padding: '3px 6px',
      ...theme.typography['12_16_500'],
    },
  },
  '@media (pointer: coarse)': {
    padding: '13px 0',
  },
}));
