/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {Stack} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {DateTime} from 'luxon';
import {FC, PropsWithChildren, useMemo} from 'react';

import {Cell, Circle, DatesContainer} from '../../../../styles';
import {TDateRange} from '@src/components/form/DateControl/types';
import {weekdays} from '../utils/weekdays';
import {startOfWeek} from '../utils/startOfWeek';
import {endOfWeek} from '../utils/endOfWeek';
import {isDayDisabled} from '../utils/isDayDisabled';
import {getBorderRadius} from '../utils/getBorderRadius';

export const DatesArea: FC<{
  onChange: (d: DateTime) => void
  month: DateTime
  selected?: DateTime
  range?: TDateRange
  disabledPast?: boolean
  disabledFuture?: boolean
  disableCurrentDay?: boolean
}> = ({month, selected, onChange, range, disabledPast, disabledFuture, disableCurrentDay}) => {
  const weekDays = weekdays();
  const now = DateTime.now();
  const t = useTranslate('common');
  const monthDates: DateTime[] = useMemo(() => {
    const startDay = startOfWeek(month.startOf('month'));
    let endDay = endOfWeek(month.endOf('month'));
    const diff = endDay.diff(startDay, 'days');

    if (Math.round(diff.days / 7) < 6) {
      endDay = endOfWeek(endDay.plus({day: 1}));
    }
    let cursor = startDay;
    const dates = [];
    while (cursor < endDay) {
      dates.push(cursor);
      cursor = cursor.plus({day: 1});
    }
    return dates;

    /* eslint-disable-next-line */
  }, [month, t]);
  const startOfRange = (d: DateTime) => {
    return range?.[0] ? d.hasSame(range[0], 'day') : false;
  };
  const endOfRange = (d: DateTime) => {
    return range?.[1] ? d.hasSame(range[1], 'day') : false;
  };

  const withinRange = (d: DateTime) => {
    if (!range?.[0] || !range?.[1] || range?.[0]?.hasSame(range?.[1], 'day')) {
      return false;
    }
    return (
      range?.[0] &&
      range?.[1] &&
      range[0]?.startOf('day') <= d &&
      d <= range[1]?.endOf('day')
    );
  };
  return (
    <Stack width={'100%'} height={'100%'}>
      <WeekDaysRow>
        {weekDays.map((day, i) => {
          return <Cell key={i}>{day}</Cell>;
        })}
      </WeekDaysRow>
      <DatesContainer>
        {monthDates.map((v, i) => {
          const rangeEnd = endOfRange(v);
          const rangeStart = startOfRange(v);
          return (
            <Cell key={i}>
              {withinRange(v) && (
                <RangeMark end={rangeEnd} start={rangeStart} />
              )}
              <Circle
                onClick={() => onChange(v)}
                disabled={isDayDisabled(v, month, now, disabledPast, disabledFuture, disableCurrentDay)}
                currentDay={v.hasSame(now, 'day')}
                selected={
                  (selected && v.hasSame(selected, 'day')) ||
                  rangeEnd ||
                  rangeStart
                }
              >
                {v.day}
              </Circle>
            </Cell>
          );
        })}
      </DatesContainer>
    </Stack>
  );
};

const WeekDaysRow = styled.div`
  display: flex;
  div {
    width: calc(100% / 7);
    min-width: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
`;

const RangeMark = styled(
  ({
    end: _,
    start: __,
    ...restProps
  }: PropsWithChildren<{ end: boolean, start: boolean }>) => (
    <div {...restProps} />
  ),
)<{ end: boolean, start: boolean }>`
  position: absolute;
  width: 100%;
  height: 34px;
  background-color: ${({theme}) => theme.palette.secondary.dark};
  border-radius: ${({end, start}) => getBorderRadius(end, start)};
`;
