import {Box, Stack, Typography} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {ShareDocumentsMenu} from '@src/components/ShareDocumentsMenu';
import {TKeys} from '@src/i18n/useTranslate';
import {EditDocumentDialogWrapper} from '@src/pages/Storage/components/EditDocumentDialogWrapper';
import {DeleteDialog} from '@src/pages/StorageDocument/components/DeleteDialog';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as EyeIcon} from '@src/shared/assets/icons/18x18/eye.svg';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as FavoriteFilledIcon} from '@src/shared/assets/icons/24x24/favorites-filled.svg';
import {ReactComponent as FavoriteIcon} from '@src/shared/assets/icons/24x24/favorites.svg';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-grey.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {useMQuery} from '@src/shared/hooks';
import {dateFormatted, formatSize} from '@src/shared/utils';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {updateStorageFile} from '@src/store/storage/slice';
import {useAppDispatch, useAppSelector} from '@store';
import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Checkbox, IconButton} from 'ui-kit';

import {useStorageTranslate} from '../../hooks';

import {CardContainer, sx} from './styles';
import {DocumentCardProps} from './types';

export const DocumentCard: FC<DocumentCardProps> = ({file}) => {
  const {name, date, size, favorite, category, type, id, isNew, healthCases} = file;
  const dispatch = useAppDispatch();
  const selectedDocuments = useAppSelector((state) => state.healthCases.selectedDocuments);
  const translate = useStorageTranslate();
  const navigate = useNavigate();
  const {mobile: isMobile} = useMQuery();

  const typeVariant = isMobile ? '12_18_700' : '14_18_700';
  const descriptionVariant = isMobile ? '12_18_500' : '14_18_500';

  const [openModal, setOpenModal] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleToggleEditDialogOpen = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const handleToggleFavorite = () => {
    void dispatch(
      updateStorageFile({
        ...file,
        favorite: !favorite,
        errorToastText: translate(
          favorite ? 'FAILED_DELETE_FROM_FAVORITES' : 'FAILED_ADD_TO_FAVORITES',
        ),
      }),
    );
  };

  const handleCheckboxChange = () => {
    dispatch(healthCasesActions.toggleDocumentsSelection(id));
  };

  const handleDeleteFile = () => {
    setOpenModal(true);
  };

  const closeModal = () => setOpenModal(false);

  const goToDocumentView = () => {
    navigate(`${ROUTERS_PATH.STORAGE}/${id}`);
  };

  const isSelected = selectedDocuments.has(id);

  return (
    <CardContainer isNew={!!isNew} isSelected={isSelected}>
      <Box sx={sx.checkboxContainer}>
        <Checkbox sx={sx.checkbox} onClick={handleCheckboxChange} value={isSelected} />
      </Box>
      <Stack width={1}>
        <Stack sx={sx.mainInfo}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} gap={12}>
            <Box>
              <EllipsisTextTooltip
                sx={sx.name}
                variant={isMobile ? '16_20_700' : '20_24_700'}
                heightCheck
                ellipsisText={name}
              />
            </Box>
            <IconButton variant={'text'} color={'secondary'} onClick={handleToggleFavorite}>
              {favorite ? <FavoriteFilledIcon /> : <FavoriteIcon />}
            </IconButton>
          </Stack>
          <Stack gap={6}>
            <Stack flexDirection={'row'} gap={12}>
              <Typography variant={typeVariant}>{translate('CATEGORY')}</Typography>
              <Typography variant={descriptionVariant}>
                {translate(category as TKeys<'storage'>)}
              </Typography>
            </Stack>
            {!!healthCases.length && (
              <Typography variant={descriptionVariant} sx={sx.healthCaseText}>
                <b>{translate('HEALTH_CASES')}</b>
                {healthCases.map((hCase) => hCase.name).join(', ')}
              </Typography>
            )}
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
              <Stack flexDirection={'row'} gap={24}>
                <Stack flexDirection={'row'} gap={12}>
                  <Typography variant={typeVariant}>{translate('SIZE')}</Typography>
                  <Typography variant={descriptionVariant}>{formatSize(size)}</Typography>
                </Stack>
                <Stack flexDirection={'row'} gap={12}>
                  <Typography variant={typeVariant}>{translate('TYPE')}</Typography>
                  <Typography variant={descriptionVariant}>{type}</Typography>
                </Stack>
              </Stack>
              <Typography
                variant={descriptionVariant}
                color={(theme) => theme.colors.personTypeColor}
              >
                {dateFormatted(date)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={[...spreadSx(sx.buttonsContainer), {borderColor: isSelected && 'secondary.700'}]}
        >
          <Stack
            flexDirection={'row'}
            gap={{
              xs: 6,
              sm: 18,
            }}
          >
            {isMobile && (
              <>
                <IconButton
                  color={'secondary'}
                  variant={'text'}
                  sx={sx.iconButton}
                  onClick={goToDocumentView}
                >
                  <EyeIcon />
                </IconButton>
                <IconButton
                  color={'secondary'}
                  variant={'text'}
                  sx={sx.iconButton}
                  strokeIcon
                  onClick={handleToggleEditDialogOpen}
                >
                  <EditIcon />
                </IconButton>
                <ShareDocumentsMenu documentIds={[id]}>
                  {(onOpen) => (
                    <IconButton
                      color={'secondary'}
                      variant={'text'}
                      sx={sx.iconButton}
                      onClick={onOpen}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}
                </ShareDocumentsMenu>
              </>
            )}
            {!isMobile && (
              <>
                <Button
                  color={'secondary'}
                  startIcon={<EyeIcon />}
                  variant={'text'}
                  size={'sm'}
                  sx={sx.actionButton}
                  onClick={goToDocumentView}
                >
                  {translate('VIEW')}
                </Button>
                <Button
                  color={'secondary'}
                  startIcon={<EditIcon />}
                  strokeIcon
                  variant={'text'}
                  size={'sm'}
                  sx={sx.actionButton}
                  onClick={handleToggleEditDialogOpen}
                >
                  {translate('EDIT')}
                </Button>
                <ShareDocumentsMenu documentIds={[id]}>
                  {(onOpen) => (
                    <Button
                      color={'secondary'}
                      startIcon={<ShareIcon />}
                      variant={'text'}
                      size={'sm'}
                      sx={sx.actionButton}
                      onClick={onOpen}
                    >
                      {translate('SHARE')}
                    </Button>
                  )}
                </ShareDocumentsMenu>
              </>
            )}
          </Stack>
          {isMobile && (
            <IconButton variant={'text'} sx={sx.iconButton} onClick={handleDeleteFile}>
              <TrashIcon />
            </IconButton>
          )}
          {!isMobile && (
            <Button
              startIcon={<TrashIcon />}
              variant={'text'}
              size={'sm'}
              sx={sx.actionButton}
              onClick={handleDeleteFile}
            >
              {translate('DELETE')}
            </Button>
          )}
        </Stack>
      </Stack>
      <DeleteDialog
        isOpen={openModal}
        id={id}
        name={name}
        onClose={closeModal} />
      {isEditDialogOpen && (
        <EditDocumentDialogWrapper onClose={handleToggleEditDialogOpen} id={id} />
      )}
    </CardContainer>
  );
};
