import {BirthSex} from '@src/api';
import {RequestRelationType} from '@src/api/relations';
import {DateTime} from 'luxon';
import * as Yup from 'yup';

import {IValidationSchemaParams} from './types';

export const getValidationSchema = ({
  t,
  relationshipTypes,
  relationType,
  birthDate,
  birthSex,
  ageOfMajority,
}: IValidationSchemaParams) => {
  return Yup.object({
    newBirthDate: Yup.string().when('birthDate', {
      is: () => (relationType && !relationshipTypes.includes(relationType) && !birthDate) || (!relationType && !birthDate),
      then: Yup.string()
        .required(t('REQUIRED_FIELD'))
        .nullable()
        .test(
          'is-newBirthDate',
          t('ENTER_A_VALID_DATE_OF_BIRTH'),
          (value) => (value ? DateTime.fromISO(value) <= DateTime.now().minus({years: ageOfMajority}) : false),
        ),
      otherwise: Yup.string().ensure().nullable(),
    }),
    newBirthSex: Yup.string().when('birthSex', {
      is: () => relationType === RequestRelationType.CHD && birthSex === BirthSex.Unknown,
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().ensure().nullable(),
    }),
  });
};
