import {BreadcrumbsRoute} from 'use-react-router-breadcrumbs';

export const ROUTERS_PATH = {
  QR_RESULTS: '/res',
  RESULTS: '/results',
  MESSAGES: '/messages',
  OVERVIEW: '/overview',
  // NOTE temporary hide APPOINTMENTS: '/appointments',
  // NOTE temporary hide APPOINTMENT_CARD: '/appointments-card/:id',
  TASKS: '/tasks',
  FAVORITES: '/favorites',
  INSURANCE: '/insurance',
  ADD_INSURANCE: '/insurance/add',
  EDIT_INSURANCE: '/insurance/edit/:id',
  CONNECTIONS_ADD_RELATION: '/connections/add-relation',
  CONNECTIONS_CREATE_A_NEW_PATIENT: '/connections/create-new-patient',
  CONNECTIONS_RELATIONS: '/connections',
  CONNECTIONS_RELATION: '/connections/relation/:id',
  CONNECTIONS_RELATION_CHANGE_ACCESS: '/connections/relation/:id/change-access',
  CONNECTIONS_RELATION_ABOUT: '/connections/relation/:id/about',
  CONNECTIONS_RELATION_ABOUT_EDIT: '/connections/relation/:id/edit/about',
  CONNECTIONS_RELATION_CONTACTS: '/connections/relation/:id/contacts',
  CONNECTIONS_RELATION_CONTACTS_EDIT: '/connections/relation/:id/edit/contacts',
  CONNECTIONS_RELATION_HEALTH_POLICIES: '/connections/relation/:id/health-policies',
  CONNECTIONS_RELATION_HEALTH_POLICIES_EDIT: '/connections/relation/:id/edit/health-policies',
  CONNECTIONS_RELATION_HEALTH_POLICIES_ADD: '/connections/relation/:id/add/health-policies',
  CONNECTIONS_RELATION_RELATIONS: '/connections/relation/:id/user-relations',
  CONNECTIONS_RELATION_ORDERS: '/connections/relation/:id/orders',
  CONNECTIONS_RELATION_RESULTS: '/connections/relation/:id/results',
  CONNECTIONS_RELATION_RESULT_DETAILS: '/connections/relation/:id/:resultId/:companyId/results',
  CONNECTIONS_RELATION_RESULT_DYNAMICS: '/connections/relation/:id/dynamics/:resultId/:companyId/:tests/results',
  CONNECTIONS_ACCESSES: '/connections/accesses',
  CONNECTIONS_ACCESSES_REQUESTS_ACCESS: '/connections/accesses/requests-access',
  CONNECTIONS_ACCESSES_REQUESTS: '/connections/accesses/requests',
  CONNECTIONS_ACCESSES_ACCEPTED: '/connections/accesses/accepted',
  SETTINGS: '/settings',
  RESULT_DETAILS: '/results/:resultId/:companyId',
  DYNAMICS: '/results/:resultId/:companyId/dynamics/:tests',
  CONNECTIONS_RELATIONS_RESULTS_FN_DOWNLOAD: '/api/relations/:relationId/results/:resultId/fn/download',
  JOURNAL: '/journal',
  BMI: '/journal/bmi',
  STORAGE: '/storage',
  STORAGE_DOCUMENT: '/storage/:id',
  NOT_FOUND: '*',
  FORBIDDEN: '/403',
  HEALTH_CASES: '/health-cases',
  HEALTH_CASE: '/health-cases/:id',
  HEALTH_CASE_ADD: '/health-cases/add',
} as const;

export const BREADCRUMBS_SETTING: Array<BreadcrumbsRoute<string>> = [
  {
    path: ROUTERS_PATH.RESULTS,
    breadcrumb: 'Details',
    children: [
      {
        path: ':resultId',
        children: [
          {
            path: 'dynamics',
          },
        ],
      },
    ],
  },
];
