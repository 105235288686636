import {FormDateInputProps} from '@src/components/FormDateInput/types';
import {DateControl} from '@src/components/form/DateControl';
import {DateControlOnChange} from '@src/components/form/DateControl/types';
import {dateFormatted} from '@src/shared/utils';
import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';

export const FormDateInput: FC<FormDateInputProps> = ({name, ...restProps}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const handleDateChange: DateControlOnChange = (_, value) => {
    onChange(value);
  };

  return (
    <DateControl
      {...restProps}
      onChange={handleDateChange}
      value={dateFormatted(value) || undefined}
      name={name}
      error={error?.message}
    />
  );
};
