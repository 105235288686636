import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  deleteRelationContent: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    p: '24px 24px 12px 24px',
  },
  deleteRelationActions: (t) => ({
    justifyContent: 'space-between !important',
    gap: 36,
    [t.breakpoints.only('xs')]: {
      flexDirection: 'column !important',
      m: 24,
    },
  }),
  breakRelationContent: {
    p: '24px 24px 24px 14px',
  },
  breakRelationActions: (t) => ({
    justifyContent: 'space-between !important',
    [t.breakpoints.only('xs')]: {
      flexDirection: 'column !important',
      m: 24,
    },
  }),
  confirmDeleteContent: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    padding: '36px 24px !important',
  },
  formControl: {
    gap: 4,
    height: 20,
  },
  dialogError: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitleError: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  dialogTitleSuccess: (t) => ({
    borderBottomColor: t.palette.success.main,
    gap: 12,
  }),
  dialogActionsSuccess: (t) => ({
    backgroundColor: t.palette.success.main,
    margin: 0,
    padding: '16px 24px',
    justifyContent: 'flex-end !important',
  }),
  dialogActionsError: (t) => ({
    backgroundColor: t.palette.error.main,
    margin: 0,
    padding: '16px 24px',
    justifyContent: 'flex-end !important',
  }),
  bth: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
};
