/* eslint-disable no-comments/disallowComments */
import {DynamicsResult, DynamicsTest, Reference} from '@src/api';
import {generateRefText} from '@src/pages/Dynamics/Dynamics.helper';
import {compareAsc, format, subMinutes} from 'date-fns';

const returnPosition = (value: string | null | undefined) => {
  if (value && !Number.isNaN(Number.parseFloat(value))) {
    return Number.parseFloat(value);
  }
  return null;
};
export const getDate = (date: string) => format(new Date(date), 'MM/dd/yyyy');

const getMinMaxValues = (arr: DynamicsResult[]) => {
  const {min, max} = arr.reduce(
    (acc, d) => {
      const {value, reference, criticalReference} = d;
      const v = Number(value);
      const min = Math.min(
        isNaN(v) ? Infinity : v,
        reference?.minValue ?? Infinity,
        criticalReference?.minValue ?? Infinity,
      );
      const max = Math.max(
        isNaN(v) ? -Infinity : v,
        reference?.maxValue ?? -Infinity,
        criticalReference?.maxValue ?? -Infinity,
      );
      acc.min = min < acc.min ? min : acc.min;
      acc.max = max > acc.max ? max : acc.max;
      return acc;
    },
    {max: -Infinity, min: Infinity},
  );
  return {min: min === Infinity ? 0 : min, max: max === -Infinity ? 0 : max};
};
export interface DynamicResultWithAnalysesData
  extends Omit<DynamicsResult, 'dateTime' | 'value' | 'reference' | 'criticalReference'> {
  isRefMark: boolean
  isCriticalRefMark: boolean
  biomaterialSamplingDate: string
  shortDate: string | null // dd.MM
  value?: string | null
  dot: number | null
  refText?: string
  unit?: string
  min?: number
  chart?: {
    zones: {
      upperOverCritical: number
      upperCritical: number
      normal: number
      lowerCritical: number
      lowerOverCritical: number
      offset: number
    }
  }
}

interface GetChartZones {
  reference: Reference
  criticalReference: Reference
  minMaxChartValues: { min: number, max: number }
}
const getChartZones = ({
  reference,
  criticalReference,
  minMaxChartValues: {min, max},
}: GetChartZones) => {
  // case 0 - all values of the chart is either 0 or not set
  if ((!max && !min) || !(max - min)) {
    return {
      upperOverCritical: 0,
      upperCritical: 0,
      normal: 0,
      lowerCritical: 0,
      lowerOverCritical: 0,
      offset: 0,
    };
  }

  const marginForOverCriticalZone = Math.ceil(((max - min) / 100) * 10);

  const allRefValuesAreFalsy =
    !reference?.maxValue &&
    !reference?.minValue &&
    !criticalReference?.minValue &&
    !criticalReference?.maxValue;
  const criticalAreaDoesNotExist =
    criticalReference?.minValue == null ||
    criticalReference?.maxValue == null ||
    criticalReference?.minValue >= criticalReference?.maxValue;
  const normalAreaDoeNotExist =
    reference?.minValue == null ||
    reference?.maxValue == null ||
    reference?.minValue >= reference?.maxValue;
  const areasAreInvalid = criticalAreaDoesNotExist && normalAreaDoeNotExist;
  // case 1 - normal and critical zones do not provided
  if (allRefValuesAreFalsy || areasAreInvalid) {
    return {
      upperOverCritical: 0,
      upperCritical: 0,
      normal: 0,
      lowerCritical: 0,
      lowerOverCritical: 0,
      offset: 0,
    };
  }
  // case 2 - only critical and over critical zones provided
  if (normalAreaDoeNotExist) {
    return {
      upperOverCritical: max + marginForOverCriticalZone - criticalReference?.maxValue,
      upperCritical: criticalReference?.maxValue - criticalReference?.minValue,
      normal: 0,
      lowerCritical: 0,
      lowerOverCritical: criticalReference?.minValue - (min - marginForOverCriticalZone),
      offset: min - marginForOverCriticalZone,
    };
  }
  // case 3 - only normal and over critical zones provided
  if (criticalAreaDoesNotExist) {
    return {
      upperOverCritical: 0,
      upperCritical: max + marginForOverCriticalZone - reference?.maxValue,
      normal: reference?.maxValue - reference?.minValue,
      lowerCritical: reference?.minValue - (min - marginForOverCriticalZone),
      lowerOverCritical: 0,
      offset: min - marginForOverCriticalZone,
    };
  }
  // case 4 - all zones exist
  return {
    upperOverCritical: max + marginForOverCriticalZone - criticalReference?.maxValue,
    upperCritical: criticalReference?.maxValue - reference?.maxValue,
    normal: reference?.maxValue - reference?.minValue,
    lowerCritical: reference?.minValue - criticalReference?.minValue,
    lowerOverCritical: criticalReference?.minValue - (min - marginForOverCriticalZone),
    offset: min - marginForOverCriticalZone,
  };
};
const extendDynamicResultsWithDataOfLabAnalyses = (
  results: DynamicsResult[],
  minMax: { min: number, max: number },
): DynamicResultWithAnalysesData[] => {
  return results.map(({dateTime, value, reference, criticalReference, ...rest}) => {
    const isRefMark = reference?.isOutOfRef;
    const isCriticalRefMark = criticalReference?.isOutOfRef;
    const dot = returnPosition(value);

    return {
      ...rest,
      isRefMark,
      isCriticalRefMark,
      biomaterialSamplingDate: dateTime,
      shortDate: value ? format(new Date(dateTime), 'dd.MM') : null,
      value,
      dot,
      refText: generateRefText({refMin: reference?.minValue, refMax: reference?.maxValue}),
      chart: {
        zones: getChartZones({minMaxChartValues: minMax, criticalReference, reference}),
      },
    };
  });
};

const getRefText = (result: DynamicsResult, nameReference: 'reference' | 'criticalReference') => {
  const referenceValues = result[nameReference];
  if (!result[nameReference]) {
    return '';
  }

  const minValue = referenceValues.minValue;
  const maxValue = referenceValues.maxValue;
  const minValueIsNull = minValue === null;
  const maxValueIsNull = maxValue === null;

  if (!minValueIsNull && !maxValueIsNull) return `${minValue}-${maxValue}`;
  if (minValueIsNull && !maxValueIsNull) return `<=${maxValue}`;
  if (!minValueIsNull && maxValueIsNull) return `>=${minValue}`;
};

const getDatesSlotsCount = (dynamic: DynamicsTest[]) => {
  const datesSlotsCount: Record<string, number> = {};
  dynamic.forEach(({results}) => {
    const datesCount = results.reduce<Record<string, number>>((acc, currentValue) => {
      const formatDate = getDate(currentValue.dateTime);

      acc[formatDate] = acc[formatDate] ? acc[formatDate] + 1 : 1;

      return acc;
    }, {});

    Object.entries(datesCount).forEach(([key, value]) => {
      const a = datesSlotsCount;
      if (a[key]) {
        a[key] = a[key] > value ? a[key] : value;
      } else {
        a[key] = value;
      }
    });
  });

  return datesSlotsCount;
};

// TODO compare with extended DynamicResult
export interface DynamicTestTransformed {
  id?: string
  resultLaboratoryAnalyses: DynamicResultWithAnalysesData[]
  refText?: string
  refMax: number
  refMin: number
  domain: { min: number, max: number }
  criticalRefMax: number
  criticalRefMin: number
  unit?: string
  testName?: string
  code: string
  loinc?: string
}
export const transformData = async (
  dynamic: DynamicsTest[],
): Promise<{ testResults: DynamicTestTransformed[] }> => {
  const changeFormatDate = dynamic.map(({results, name, ...restProps}) => {
    const lastResult: DynamicsResult = results[results.length - 1];

    const minMax = getMinMaxValues(results);
    const minMaxRounded = {min: Math.floor(minMax?.min), max: Math.ceil(minMax?.max)};

    const extendedResults = extendDynamicResultsWithDataOfLabAnalyses(
      results,
      minMaxRounded,
    );

    return {
      ...restProps,
      refText:
        lastResult?.qualityReference?.value ??
        getRefText(lastResult, 'reference') ??
        getRefText(lastResult, 'criticalReference') ??
        '',
      refMax: lastResult.reference?.maxValue,
      refMin: lastResult.reference?.minValue,
      criticalRefMax: lastResult.criticalReference?.maxValue,
      criticalRefMin: lastResult.criticalReference?.minValue,
      testName: name,
      domain: minMaxRounded,
      resultLaboratoryAnalyses: extendedResults,
    };
  });

  const dateSlots = getDatesSlotsCount(dynamic);

  const testResults = changeFormatDate.map(({resultLaboratoryAnalyses, ...rest}) => {
    const putMissingDate = Object.entries(dateSlots).reduce(
      (acc, [dateString, cellCount]) => {
        const existedResultsInDay = acc.filter(
          ({biomaterialSamplingDate}) => getDate(biomaterialSamplingDate) === dateString,
        );
        const missingDatesCount = cellCount - existedResultsInDay.length;

        for (let i = missingDatesCount; i > 0; i--) {
          acc.push({
            biomaterialSamplingDate:
              existedResultsInDay[0]?.biomaterialSamplingDate ||
              subMinutes(new Date(dateString), new Date().getTimezoneOffset()).toISOString(),
            shortDate: format(new Date(dateString), 'dd.MM '),
            value: null,
            comment: '',
            isRefMark: false,
            refText: '',
            unit: '',
            dot: null,
            min: 0,
            isCriticalRefMark: false,
            qualityReference: null,
          });
        }

        return acc;
      },
      [...resultLaboratoryAnalyses],
    );

    const sortedResults = putMissingDate.sort((a, b) =>
      compareAsc(new Date(a.biomaterialSamplingDate), new Date(b.biomaterialSamplingDate)));

    return {
      ...rest,
      resultLaboratoryAnalyses: sortedResults,
    };
  });

  const sortedTestResults = testResults.sort((a, b) => {
    const nameComparison = (a.testName || '').localeCompare(b.testName || '');
    if (nameComparison !== 0) return nameComparison;

    if (!a.unit && b.unit) return 1;
    if (!b.unit && a.unit) return -1;

    return (a.unit || '').localeCompare(b.unit || '');
  });

  return {testResults: sortedTestResults};
};
