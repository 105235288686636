import {Dispatch, RefObject, SetStateAction} from 'react';

export const setupResizeObserver = (
  containerRef: RefObject<HTMLElement>,
  buttonRef: RefObject<HTMLElement>,
  setMenuPosition: Dispatch<SetStateAction<number>>
) => {
  const observer = new ResizeObserver(() => {
    if (containerRef.current && buttonRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const buttonRect = buttonRef.current.getBoundingClientRect();

      const leftSpace = buttonRect.left - containerRect.left;
      const rightSpace = containerRect.right - buttonRect.right;

      if (leftSpace < 50) {
        setMenuPosition(50 - leftSpace);
      } else if (rightSpace < 50) {
        setMenuPosition(-(50 - rightSpace));
      } else {
        setMenuPosition(0);
      }
    }
  });

  if (containerRef.current && buttonRef.current) {
    observer.observe(containerRef.current);
    observer.observe(buttonRef.current);
  }

  return observer;
};
