import {Info, Settings} from 'luxon';

export const weekdays = () => {
  const weekdays = Info.weekdays('short');
  if (/-us/i.test(Settings.defaultLocale)) {
    const last = weekdays.pop() as string;
    weekdays.unshift(last);
  }
  return weekdays;
};
