import {UseTranslationResponse, useTranslation} from 'react-i18next';

import {TranslationKeys, TranslationNS} from './TranslationKeys';

export type TKeys<T extends TranslationNS = 'common'> =
keyof TranslationKeys[T] | keyof TranslationKeys['common'];

const useTranslateProduction = <T extends TranslationNS>(ns: T) => {
  const {t, ready, i18n} = useTranslation(ns);
  return {t, ready, i18n} as {
    t: (str: TKeys<T> | undefined | null, params?: any) => string
    ready: boolean
    i18n: UseTranslationResponse<any>['i18n']
  };
};

export const useTranslate = useTranslateProduction;
