enum AccessType {
  'READ_ONLY' = 'R',
  'READ_UPDATE' = 'RU',
  'CREATE_READ_UPDATE' = 'CRU',
  'CREATE_READ_UPDATE_DELETE' = 'CRUD',
}
export const matrix: Record<string, Record<string, Record<string, string>>> = {};
const entity_contact_creator_contact: Record<string, typeof fields_contact_creator_contact> = {};
const fields_contact_creator_contact: Record<string, string> = {};
const entity_contact_contact_represent: Record<string, typeof fields_contact_contact_represent> = {};
const fields_contact_contact_represent: Record<string, string> = {};
const entity_contact_contact_proxy: Record<string, typeof fields_contact_contact_proxy> = {};
const fields_contact_contact_proxy: Record<string, string> = {};

const entity_guarantor_creator_contact: Record<string, typeof fields_guarantor_creator_contact> = {};
const fields_guarantor_creator_contact: Record<string, string> = {};
const entity_guarantor_contact_represent: Record<string, typeof fields_guarantor_contact_represent> = {};
const fields_guarantor_contact_represent: Record<string, string> = {};
const entity_guarantor_contact_proxy: Record<string, typeof fields_guarantor_contact_proxy> = {};
const fields_guarantor_contact_proxy: Record<string, string> = {};

fields_contact_creator_contact.firstName = AccessType.READ_UPDATE;
fields_contact_creator_contact.lastName = AccessType.READ_UPDATE;
fields_contact_creator_contact.middleName = AccessType.CREATE_READ_UPDATE_DELETE;
fields_contact_creator_contact.birthDate = AccessType.CREATE_READ_UPDATE;
fields_contact_creator_contact.birthSex = AccessType.READ_UPDATE;
fields_contact_creator_contact.ethnicity = AccessType.CREATE_READ_UPDATE;
fields_contact_creator_contact.gender = AccessType.CREATE_READ_UPDATE;
fields_contact_creator_contact.race = AccessType.CREATE_READ_UPDATE;
fields_contact_creator_contact.sexualOrientation = AccessType.CREATE_READ_UPDATE;
fields_contact_creator_contact.ssnTail = AccessType.CREATE_READ_UPDATE_DELETE;

entity_contact_creator_contact.contact = fields_contact_creator_contact;

fields_contact_contact_represent.firstName = AccessType.READ_UPDATE;
fields_contact_contact_represent.lastName = AccessType.READ_UPDATE;
fields_contact_contact_represent.middleName = AccessType.CREATE_READ_UPDATE_DELETE;
fields_contact_contact_represent.birthDate = AccessType.READ_ONLY;
fields_contact_contact_represent.birthSex = AccessType.READ_ONLY;
fields_contact_contact_represent.ethnicity = AccessType.CREATE_READ_UPDATE;
fields_contact_contact_represent.gender = AccessType.CREATE_READ_UPDATE;
fields_contact_contact_represent.race = AccessType.CREATE_READ_UPDATE;
fields_contact_contact_represent.sexualOrientation = AccessType.CREATE_READ_UPDATE;
fields_contact_contact_represent.ssnTail = AccessType.CREATE_READ_UPDATE_DELETE;

entity_contact_contact_represent.contact = fields_contact_contact_represent;

fields_contact_contact_proxy.firstName = AccessType.READ_ONLY;
fields_contact_contact_proxy.lastName = AccessType.READ_ONLY;
fields_contact_contact_proxy.middleName = AccessType.READ_ONLY;
fields_contact_contact_proxy.birthDate = AccessType.READ_ONLY;
fields_contact_contact_proxy.birthSex = AccessType.READ_ONLY;
fields_contact_contact_proxy.ethnicity = AccessType.READ_ONLY;
fields_contact_contact_proxy.gender = AccessType.READ_ONLY;
fields_contact_contact_proxy.race = AccessType.READ_ONLY;
fields_contact_contact_proxy.sexualOrientation = AccessType.READ_ONLY;
fields_contact_contact_proxy.ssnTail = AccessType.READ_ONLY;

entity_contact_contact_proxy.contact = fields_contact_contact_proxy;

matrix.CREATOR_CONTACT = entity_contact_creator_contact;
matrix.CONTACT_REPRESENT = entity_contact_contact_represent;
matrix.CONTACT_PROXY = entity_contact_contact_proxy;

fields_guarantor_creator_contact.relationType = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_creator_contact.firstName = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.lastName = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.middleName = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_creator_contact.birthDate = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.birthSex = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.address1 = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.address2 = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_creator_contact.city = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.phone = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_creator_contact.ssnTail = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_creator_contact.state = AccessType.READ_UPDATE;
fields_guarantor_creator_contact.postalCode = AccessType.READ_UPDATE;

entity_guarantor_creator_contact.guarantor = fields_guarantor_creator_contact;

fields_guarantor_contact_represent.relationType = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_contact_represent.firstName = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.lastName = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.middleName = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_contact_represent.birthDate = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.birthSex = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.address1 = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.address2 = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_contact_represent.city = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.phone = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_contact_represent.ssnTail = AccessType.CREATE_READ_UPDATE_DELETE;
fields_guarantor_contact_represent.state = AccessType.READ_UPDATE;
fields_guarantor_contact_represent.postalCode = AccessType.READ_UPDATE;

entity_guarantor_contact_represent.guarantor = fields_guarantor_contact_represent;

fields_guarantor_contact_proxy.relationType = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.firstName = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.lastName = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.middleName = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.birthDate = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.birthSex = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.address1 = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.address2 = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.city = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.phone = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.ssnTail = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.state = AccessType.READ_ONLY;
fields_guarantor_contact_proxy.postalCode = AccessType.READ_ONLY;

entity_guarantor_contact_proxy.guarantor = fields_guarantor_contact_proxy;

matrix.CREATOR_CONTACT = {...matrix.CREATOR_CONTACT, ...entity_guarantor_creator_contact};
matrix.CONTACT_REPRESENT = {...matrix.CONTACT_REPRESENT, ...entity_guarantor_contact_represent};
matrix.CONTACT_PROXY = {...matrix.CONTACT_PROXY, ...entity_guarantor_contact_proxy};

export const accessCreateEntities = {
  CREATOR_CONTACT: ['contactAddress', 'healthPolicies'],
  CONTACT_REPRESENT: ['contactAddress', 'healthPolicies'],
  CONTACT_PROXY: [],
};
