import {styled, SxProps, Theme} from '@mui/material';
import {useInfiniteScroll} from '@src/shared/hooks/useInfiniteScroll';
import {FC, PropsWithChildren} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface ScrollbarProps {
  width?: number
}

const Scrollbar = styled(PerfectScrollbar)<ScrollbarProps>(({width}) => ({
  '& .ps__thumb-x, & .ps__thumb-y': {
    borderRadius: '0 !important',
    width: width ?? 10,
    visibility: 'hidden',
  },
  '& .ps__thumb-x': {
    bottom: 0,
  },
  '& .ps__thumb-y': {
    right: 0,
  },
  '& .ps__rail-x, & .ps__rail-y': {
    backgroundColor: 'transparent !important',
    width: width ?? 10,
    zIndex: 100,
  },
}));

interface InfiniteScrollContainerProps {
  onLoad?: () => void
  threshold?: number
  className?: string
  sx?: SxProps<Theme>
  onScroll?: (container: HTMLElement) => void
}

export const InfiniteScrollContainer: FC<PropsWithChildren<InfiniteScrollContainerProps>> = ({
  children,
  onLoad,
  threshold,
  className,
  sx,
  onScroll,
}) => {
  const {setContainer} = useInfiniteScroll({
    onLoad,
    threshold: threshold ?? 2000,
  });
  return (
    <Scrollbar
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx]), {minWidth: 300, height: '100%'}]}
      containerRef={setContainer}
      onScrollY={onScroll}
    >
      {children}
    </Scrollbar>
  );
};
