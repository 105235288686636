import {BirthSex} from '@src/api';
import {ReactComponent as DisabledFemale} from '@src/shared/assets/icons/bmi/disabledFemale.svg';
import {ReactComponent as DisabledMale} from '@src/shared/assets/icons/bmi/disabledMale.svg';
import {ReactComponent as NormalWeightFemale} from '@src/shared/assets/icons/bmi/normalWeightFemale.svg';
import {ReactComponent as NormalWeightMale} from '@src/shared/assets/icons/bmi/normalWeightMale.svg';
import {ReactComponent as ObesityWeightFemale} from '@src/shared/assets/icons/bmi/obesityWeightFemale.svg';
import {ReactComponent as ObesityWeightMale} from '@src/shared/assets/icons/bmi/obesityWeightMale.svg';
import {ReactComponent as OverWeightFemale} from '@src/shared/assets/icons/bmi/overWeightFemale.svg';
import {ReactComponent as OverWeightMale} from '@src/shared/assets/icons/bmi/overWeightMale.svg';
import {ReactComponent as UnderWeightFemale} from '@src/shared/assets/icons/bmi/underWeightFemale.svg';
import {ReactComponent as UnderWeightMale} from '@src/shared/assets/icons/bmi/underWeightMale.svg';

export const DEFAULT_HEIGHT = 72;
export const DEFAULT_WEIGHT = 187;
export const DEFAULT_AGE = 38;
export enum WeightTitles {
  UNDER_WEIGHT = 'UNDER_WEIGHT',
  NORMAL_WEIGHT = 'NORMAL_WEIGHT',
  OVERWEIGHT = 'OVERWEIGHT',
  OBESITY = 'OBESITY',
}

export const BMIBreakPoints = {
  [WeightTitles.UNDER_WEIGHT]: 18.5,
  [WeightTitles.NORMAL_WEIGHT]: 25,
  [WeightTitles.OVERWEIGHT]: 30,
  [WeightTitles.OBESITY]: 40,
};

export const weightImages = {
  [BirthSex.Male]: {
    [WeightTitles.UNDER_WEIGHT]: <UnderWeightMale />,
    [WeightTitles.NORMAL_WEIGHT]: <NormalWeightMale />,
    [WeightTitles.OVERWEIGHT]: <OverWeightMale />,
    [WeightTitles.OBESITY]: <ObesityWeightMale />,
    default: <DisabledMale />,
  },
  [BirthSex.Female]: {
    [WeightTitles.UNDER_WEIGHT]: <UnderWeightFemale />,
    [WeightTitles.NORMAL_WEIGHT]: <NormalWeightFemale />,
    [WeightTitles.OVERWEIGHT]: <OverWeightFemale />,
    [WeightTitles.OBESITY]: <ObesityWeightFemale />,
    default: <DisabledFemale />,
  },
  [BirthSex.Unknown]: {
    [WeightTitles.UNDER_WEIGHT]: <UnderWeightMale />,
    [WeightTitles.NORMAL_WEIGHT]: <NormalWeightMale />,
    [WeightTitles.OVERWEIGHT]: <OverWeightMale />,
    [WeightTitles.OBESITY]: <ObesityWeightMale />,
    default: <DisabledMale />,
  },
};
