import {Typography, Box, useTheme, IconButton} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {WEEK_LENGTH} from '@src/pages/Journal/constants';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/arrow-right.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {DateFormat, dateFormatted} from '@src/shared/utils';
import {prepareDataForCharts} from '@src/shared/utils/prepareDateForCharts';
import {DateTime} from 'luxon';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {SwipeableHandlers, useSwipeable} from 'react-swipeable';
import {BarChart, Bar, XAxis, YAxis, Label, ResponsiveContainer} from 'recharts';

import {GroupButton} from './components/GroupButton';
import {
  LABEL_OFFSET,
  LABEL_WIDTH,
  LABEL_WIDTH_MOBILE,
  RangeValues,
  tickFormat,
} from './constants';
import {StackSt, StyledBrush, statisticsSx} from './styles';
import {IStatisticsProps, ERANGES, RangeName, StepHandlers, BrushChangeEvent} from './types';
import {daysInMonth} from './utils/daysInMonth';
import {findFirstAndLastDataDate} from './utils/findFirstAndLastDataDate';
import {getBarSize} from './utils/getBarSize';

export const Statistics: FC<IStatisticsProps> = ({data, isChangeGrid, isLoading}) => {
  const {t} = useTranslate('journal');
  const {mobile, desktop, lg, xl, tablet} = useMQuery();

  const sx = statisticsSx(isChangeGrid);
  const {isSidebarOpen} = useSidebarClassObserver();
  const theme = useTheme();
  const barColor = theme.palette.secondary._14;
  const axiColor = theme.palette.grey[500];

  const [range, setRange] = useState<ERANGES>(ERANGES.WEEK);
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime>(DateTime.now());
  const [indexes, setIndexes] = useState<[number, number]>([0, RangeValues.WEEK]);
  const [latestDataDate, setLatestDataDate] = useState<DateTime | null>(null);
  const [earliestDataDate, setEarliestDataDate] = useState<DateTime | null>(null);

  const fontTitle = mobile ? '18_24_500' : '24_34_500';
  const isEmptyData = data.length === 0;

  useEffect(() => {
    const [firstDataDate, lastDataDate] = findFirstAndLastDataDate(data);
    setLatestDataDate(firstDataDate);
    setEarliestDataDate(lastDataDate);
    const startOfCurrentWeek = DateTime.now().startOf(RangeName.WEEK);
    const start = firstDataDate ? firstDataDate.startOf(RangeName.WEEK) : startOfCurrentWeek;
    const end = start.plus({days: RangeValues.WEEK});
    setStartDate(start);
    setEndDate(end);
    setRange(ERANGES.WEEK);
  }, [data]);

  const options = [
    {
      value: ERANGES.WEEK,
      label: t(ERANGES.WEEK),
    },
    {
      value: ERANGES.MONTH,
      label: t(ERANGES.MONTH),
    },
  ];

  const onChange = useCallback(
    (value: ERANGES) => {
      setRange(value);
      const [firstDataDate] = findFirstAndLastDataDate(data);
      if (firstDataDate) {
        const newStartDate =
          value === ERANGES.WEEK
            ? firstDataDate.startOf(RangeName.WEEK)
            : firstDataDate.startOf(RangeName.MONTH);
        const newEndDate =
          value === ERANGES.WEEK
            ? newStartDate.plus({days: RangeValues.WEEK})
            : newStartDate.plus({months: 1}).minus({days: 1});
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setIndexes([0, value === ERANGES.WEEK ? RangeValues.WEEK : daysInMonth(newStartDate) - 1]);
      } else {
        const newStartDate =
          value === ERANGES.WEEK
            ? DateTime.now().startOf(RangeName.WEEK)
            : DateTime.now().startOf(RangeName.MONTH);
        const newEndDate =
          value === ERANGES.WEEK
            ? newStartDate.plus({days: RangeValues.WEEK})
            : newStartDate.plus({months: 1}).minus({days: 1});
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setIndexes([0, value === ERANGES.WEEK ? RangeValues.WEEK : daysInMonth(newStartDate) - 1]);
      }
    },
    [data],
  );

  const {chartData} = useMemo(() => {
    return prepareDataForCharts(data, startDate, endDate);
  }, [data, startDate, endDate]);

  const getRangeLabel = useCallback((startDate: DateTime | null, endDate: DateTime): string => {
    if (!startDate || !endDate) return '';
    const endYear = endDate.year;
    const startYear = startDate.year !== endYear ? `${startDate.year}` : '';
    return `${
      dateFormatted(startDate.toISO(), DateFormat.DAY_AND_MONTH_SHORT) || ''
    } ${startYear}- ${
      dateFormatted(endDate.toISO(), DateFormat.DAY_AND_MONTH_SHORT) || ''
    } ${endYear}`;
  }, []);

  const isMonthRange = range === ERANGES.MONTH;
  const isIndexNotZero = (index: number) => index !== 0;
  const isEveryThirdIndex = (index: number) => (index + 1) % 3 === 0;

  const shouldFormatDate = (index: number): boolean => {
    return mobile && isMonthRange && isIndexNotZero(index) && isEveryThirdIndex(index);
  };

  const formatTickDate = (e: string, index: number) => {
    if (mobile && range === ERANGES.MONTH) {
      return shouldFormatDate(index) ? dateFormatted(e, tickFormat[range]) || '' : '';
    } else {
      return dateFormatted(e, tickFormat[range]) || '';
    }
  };

  const handleDateChange = useCallback(
    (direction: StepHandlers.PREV | StepHandlers.NEXT) => {
      setStartDate((prevStartDate) => {
        if (!prevStartDate) return null;

        let newStartDate;
        if (direction === StepHandlers.PREV) {
          if (range === ERANGES.WEEK) {
            newStartDate = prevStartDate.minus({days: WEEK_LENGTH});
          } else {
            newStartDate = prevStartDate.minus({months: 1});
          }
        } else {
          if (range === ERANGES.WEEK) {
            newStartDate = prevStartDate.plus({days: WEEK_LENGTH});
          } else {
            newStartDate = prevStartDate.plus({months: 1});
          }
        }

        const newStep = range === ERANGES.WEEK ? 7 : daysInMonth(newStartDate);
        let newEndDate = newStartDate.plus({days: newStep - 1});

        if (
          latestDataDate &&
          newStartDate >
            latestDataDate.startOf(range === ERANGES.WEEK ? RangeName.WEEK : RangeName.MONTH)
        ) {
          newStartDate = latestDataDate.startOf(
            range === ERANGES.WEEK ? RangeName.WEEK : RangeName.MONTH,
          );
          newEndDate = newStartDate.plus({days: newStep - 1});
        }

        if (
          earliestDataDate &&
          newStartDate <
            earliestDataDate.startOf(range === ERANGES.WEEK ? RangeName.WEEK : RangeName.MONTH)
        ) {
          newStartDate = earliestDataDate.startOf(
            range === ERANGES.WEEK ? RangeName.WEEK : RangeName.MONTH,
          );
          newEndDate = newStartDate.plus({days: newStep - 1});
        }

        setEndDate(newEndDate);
        return newStartDate;
      });

      setIndexes((prevIndexes) => {
        const step = range === ERANGES.WEEK ? 7 : daysInMonth(startDate);
        const newStartIndex =
          direction === StepHandlers.PREV
            ? Math.max(0, prevIndexes[0] - step)
            : Math.min(chartData.length - step, prevIndexes[0] + step);
        return [newStartIndex, newStartIndex + step];
      });
    },
    [range, latestDataDate, earliestDataDate, chartData.length, startDate],
  );

  const swipeHandlers: SwipeableHandlers = useSwipeable({
    onSwipedLeft: () => handleDateChange(StepHandlers.NEXT),
    onSwipedRight: () => handleDateChange(StepHandlers.PREV),
    trackMouse: true,
  });

  const handleBrushChange = (e: BrushChangeEvent) => {
    const currentStartIndex = e.startIndex ?? 0;
    const currentEndIndex = e.endIndex ?? 0;
    setIndexes([currentStartIndex, currentEndIndex]);
    setStartDate(DateTime.fromISO(chartData[currentStartIndex].createdTime));
    setEndDate(DateTime.fromISO(chartData[currentEndIndex].createdTime));
  };

  const isPrevDisabled =
    !startDate ||
    (earliestDataDate &&
      startDate <=
        earliestDataDate.startOf(range === ERANGES.WEEK ? RangeName.WEEK : RangeName.MONTH));

  const isNextDisabled =
    endDate >= DateTime.now() ||
    (latestDataDate &&
      endDate >=
        latestDataDate
          .startOf(range === ERANGES.WEEK ? RangeName.WEEK : RangeName.MONTH)
          .plus({days: range === ERANGES.WEEK ? RangeValues.WEEK : endDate.daysInMonth - 1}));

  return (
    <WBox sx={sx.mainBlock} {...swipeHandlers}>
      <StackSt gap={12}>
        <Box sx={sx.header}>
          <Typography variant={fontTitle}>{t('BMI_STATISTICS')}</Typography>
          <GroupButton
            sx={sx.groupButton}
            options={options}
            value={range}
            mobile={mobile}
            onChange={onChange}
            variant="outlined"
            disabled={isEmptyData || isLoading}
            color="secondary"
          />
        </Box>
        {isLoading && (
          <Box sx={sx.fetchingBlock}>
            <PulseLoader loading />
          </Box>
        )}
        {!isLoading && (
          <>
            {!desktop && (
              <Typography sx={sx.dateRange}>{getRangeLabel(startDate, endDate)}</Typography>
            )}
            {desktop && (
              <Box sx={sx.dateWrapper}>
                <IconButton
                  sx={{transform: 'rotate(180deg)', p: 0}}
                  color="secondary"
                  onClick={() => handleDateChange(StepHandlers.PREV)}
                  disabled={isEmptyData || !!isPrevDisabled}
                >
                  <ArrowIcon />
                </IconButton>
                <Box sx={sx.dateRangeWrapper}>
                  <Typography sx={sx.dateRange}>{getRangeLabel(startDate, endDate)}</Typography>
                </Box>
                <IconButton
                  sx={{p: 0}}
                  onClick={() => handleDateChange(StepHandlers.NEXT)}
                  color="secondary"
                  disabled={isEmptyData || !!isNextDisabled}
                >
                  <ArrowIcon />
                </IconButton>
              </Box>
            )}

            <ResponsiveContainer width="100%" height={314}>
              <BarChart
                width={500}
                height={300}
                data={chartData.slice(indexes[0], indexes[1] + 1)}
                margin={{
                  top: 0,
                  right: 0,
                  left: -20,
                  bottom: 1,
                }}
              >
                <XAxis
                  dataKey="createdTime"
                  fill={axiColor}
                  stroke={axiColor}
                  tickLine={false}
                  interval={0}
                  tickSize={20}
                  tickFormatter={(e, index) => formatTickDate(e, index)}
                >
                  {isEmptyData && (
                    <Label
                      fill={axiColor}
                      color={axiColor}
                      width={mobile ? LABEL_WIDTH_MOBILE : LABEL_WIDTH}
                      value={t('TO_SEE_THE_STATISTICS_YOU_')}
                      position="top"
                      fontSize={14}
                      fontWeight={500}
                      offset={LABEL_OFFSET}
                    />
                  )}
                </XAxis>
                <YAxis
                  height={1}
                  tickLine={false}
                  fill={axiColor}
                  stroke={axiColor}
                  tickFormatter={(value) => (isEmptyData ? '' : value)}
                />
                <StyledBrush
                  startIndex={indexes[0]}
                  endIndex={indexes[1]}
                  height={chartData.length > WEEK_LENGTH ? WEEK_LENGTH : 0}
                  travellerWidth={0}
                  y={279}
                  intercept={0}
                  fill="transparent"
                  stroke={'transparent'}
                  onChange={handleBrushChange}
                />
                <Bar
                  activeBar={false}
                  dataKey="value"
                  fill={barColor}
                  barSize={getBarSize(mobile, desktop, lg, xl, tablet, isSidebarOpen)}
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </StackSt>
    </WBox>
  );
};
