import {useEffect, useRef} from 'react';

export const useClickOutside = (cb: () => void) => {
  const elRef = useRef<HTMLElement | null>(null);
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const target: HTMLElement = e.target as HTMLElement;
      if (elRef.current) {
        const el: HTMLElement = elRef.current;
        if (!el.contains(target)) {
          cbRef.current();
        }
      }
    };

    window.document.addEventListener('click', handleDocumentClick, {capture: true});
    return () => window.document.removeEventListener('click', handleDocumentClick, {capture: true});
  }, []);
  return elRef;
};
