export const initialValue = {
  insuranceCarrier: null,
  insurancePlan: null,
  insurancePolicyID: null,
  insuranceEndDate: null,
  relationship: null,
  birthDate: null,
  birthSex: null,
  firstName: null,
  lastName: null,
  middleName: null,
  ssnTail: null,
  state: null,
  city: null,
  postalCode: null,
  address1: null,
  address2: null,
  phone: null,
  endDate: null,
  policyId: null,
};

export const touchedData = {
  address1: true,
  firstName: true,
  lastName: true,
  birthDate: true,
  birthSex: true,
  city: true,
  state: true,
  postalCode: true,
  ssnTail: true,
  endDate: true,
};
