const keyLeftMenu = 'vivica_pro_left_menu';

class LocalStorage<T> {
  constructor (private readonly key: string) {
    this.key = key;
  }

  getItems (): T {
    return JSON.parse(localStorage.getItem(this.key) ?? 'false');
  }

  setItems (value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }
}

export const leftMenuState = new LocalStorage<boolean>(keyLeftMenu);
