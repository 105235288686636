import {styled} from '@mui/material';
import {IconColorProps} from '@src/shared/assets/icons/types';

import {ReactComponent as X24} from '../24x24/trash.svg';

const X24Component = styled(X24)<IconColorProps>(({theme, color = 'white'}) => {
  return {
    '& path': {
      fill: theme.palette.icon[color],
    },
  };
});

export const Trash = {
  X24: X24Component,
};
