/** @jsxImportSource @emotion/react */
import {Box, css, Popover, PopoverProps, Typography} from '@mui/material';
import {ReactComponent as IconSVGTimes} from '@src/shared/assets/images/icons/icon_times_thin.svg';
import {useTranslate} from 'i18n/useTranslate';
import {FC, ReactNode, useCallback, useState} from 'react';

export const PopoverTooltip: FC<{
  anchorEl: PopoverProps['anchorEl']
  open: boolean
  onClose: PopoverProps['onClose']
  children: ReactNode
  width?: number
  header?: boolean | string
}> = ({open, onClose, anchorEl, children, width, header}) => {
  const {t} = useTranslate('common');

  return (
    <Popover
      slotProps={{
        paper: {sx: {filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15))', maxWidth: width ?? 350, minWidth: 300, boxShadow: 'none'}},
      }}
      sx={{'& .MuiBackdrop-root': {backgroundColor: 'transparent'}}}
      TransitionProps={{timeout: 0}}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {header && (
        <Box
          paddingX={{xs: '24px'}}
          css={theme => css`
              display: flex;
              justify-content: space-between;
              height: 48px;
              align-items: center;
              border-bottom: 1px solid ${theme.colors.borderLight}
            `}>
          <Typography fontSize={18} fontWeight={700}>
            {((typeof header === 'string') && header) || t('COMMENT')}
          </Typography>
          <IconSVGTimes css={(theme) => css`path {fill: ${theme.colors.defaultTextColor}}; cursor: pointer;`} onClick={(e) => onClose?.(e, 'backdropClick')} />
        </Box>
      )}
      <Typography sx={{padding: '20px 14px'}} fontSize="16px" fontWeight={400}>
        {children}
      </Typography>
    </Popover>
  );
};

export const usePopoverTooltip = (content?: any) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const onClose = useCallback(() => {
    setElement(null);
  }, []);
  const onOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setElement(e.currentTarget);
  }, []);
  return {
    open: Boolean(element) && Boolean(content),
    anchorEl: element,
    onClose,
    onOpen,
  };
};
