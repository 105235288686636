// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Box, css, LinearProgress, styled} from '@mui/material';
import {ReactComponent as SortIcon} from '@src/shared/assets/icons/sorting_arrows.svg';
import {alpha} from '@src/theme/utils';
import React, {FC, forwardRef, useImperativeHandle, useMemo} from 'react';

import {Body} from './Body';
import {DefaultRowTable} from './Rows';
import {sx} from './styled';
import {Oject, TableActionType, ITableProps, ITableScrollUp} from './types';

interface IActionProps {
  action: TableActionType
}

const Action: FC<IActionProps> = ({action}) => {
  const {component, sorted = false} = action;
  if (component) {
    const {component: Component, props} = component;
    return <Component {...props} />;
  }
  return (
    <SortIcon
      css={(theme) => (css`
      pointer-events: none !important;
      path:first-child {
        fill: ${sorted ? theme.palette.grey[600] : ''};
      }
      path:last-child {
        fill: ${sorted ? '' : theme.palette.grey[600]}
      }
  `)} />
  );
};

const HeaderCellContent = styled('div')<{ hoverable?: boolean }>(({hoverable}) => `
  cursor: ${hoverable ? 'pointer' : 'initial'};
`);

const LocalTable: unknown = <T extends Oject>(
  {
    data,
    scrollRest,
    loading,
    rowComponent,
    headerList,
    gridTemplateColumns,
    viewHeightTable,
    keyIndicator = 'id',
    classNames,
    className,
    emptyData,
    minHeight,
    header = true,
    paddingLeft = '36px',
    onlySemanticBlock = true,
  }: ITableProps<T>,
  reference_: React.MutableRefObject<ITableScrollUp | undefined>,
) => {
  /* NOTE link to the element for infinity scroll
  const reference = React.useRef<HTMLDivElement | null>(null);
  link to the body of the table */
  const referenceBody = React.useRef<HTMLDivElement | null>(null);

  // NOTE We pass the function of returning to the top of the list to the parent
  useImperativeHandle(reference_, () => ({
    scrollUp () {
      if (referenceBody.current) {
        referenceBody.current.scrollTo({top: 0});
      }
    },
  }));

  const styles = useMemo(() => {
    if (viewHeightTable) {
      return {height: `calc(100vh - ${viewHeightTable}px)`};
    }
    if (minHeight) {
      return {minHeight: `calc(100vh - ${minHeight}px)`};
    }
    return {};
  }, [viewHeightTable, minHeight]);

  if (data.length === 0) {
    return emptyData;
  }

  return (
    <Box
      className={`${classNames?.wrapper ?? ''} ${className ?? ''}`}
      sx={[(theme) => ({
        borderTop: `1px solid ${alpha(theme.palette.secondary.main, 14)}`,
        position: 'relative',
      }), ...(Array.isArray(sx.wrapper) ? sx.wrapper : [sx.wrapper])]}
    >
      <Box sx={{overflowX: 'auto'}}>
        {header && (
          <Box
            className={`${classNames?.header || ''}`}
            style={{gridTemplateColumns}}
            sx={[(theme) => ({
              borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 14)}`,
              position: 'unset',
              paddingLeft: `${onlySemanticBlock ? paddingLeft : '24px'} !important`,
              [theme.breakpoints.down('md')]: {
                minWidth: 'auto',
                typography: '12_18_700',
              },
              gap: {xs: 0, sm: 24},
            }), ...(Array.isArray(sx.header) ? sx.header : [sx.header])]}
            paddingX={{xs: '45px', sm: '45px', md: 24, lg: '36px'}}
          >
            {headerList.map(({name, action}, index) => {
              const position = action?.position || 'end';
              return action?.component
                ? (
                  <Action action={action} key={`${name}/table-header/${index}`} />
                )
                : (
                  <HeaderCellContent
                    key={`${name}/table-header/${index}`}
                    onClick={action?.action}
                    hoverable={!!action}
                    sx={{gap: 5}}
                  >
                    {action && position === 'start' && <Action action={action} />}
                    <div>{name}</div>
                    {action && position === 'end' && <Action action={action} />}
                  </HeaderCellContent>
                );
            })}
          </Box>
        )}
        {loading && <Box style={{height: 4}}>{loading && <LinearProgress />}</Box>}
        <Box
          className={`${classNames?.body || ''}`}
          sx={viewHeightTable ? sx.body : null}
          ref={referenceBody}
          style={styles}
        >
          <Box>
            {data.length > 0 && (
              <Body
                className={classNames?.row}
                data={data}
                rowComponent={rowComponent}
                gridTemplateColumns={gridTemplateColumns}
                keyIndicator={keyIndicator}
                loading={loading}
                scrollRest={scrollRest}
              />
            )}

          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface ITableModules {
  DefaultRowTable: typeof DefaultRowTable
  /* NOTE And any other widgets of our component */
}
/* NOTE
 * @param reference_ getting a link to a table (for example to scroll up)
 * @returns Table (take the prop for the string component TableRowComponentType)
 */
export const Table = forwardRef(
  LocalTable as React.ForwardRefRenderFunction<unknown, unknown>,
) as unknown as (<T extends Oject>(
  p: ITableProps<T> & { ref?: React.MutableRefObject<ITableScrollUp | undefined> },
) => JSX.Element) &
ITableModules;

Table.DefaultRowTable = DefaultRowTable;
