import {PageHeader} from '@components';
import {Stack, Grid} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ComingSoon} from '@src/shared/assets/images/coming-soon_journal.svg';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useBmi} from '@src/store/bmi/hooks';
import {DateTime} from 'luxon';
import {useMemo} from 'react';

import {WEEK_LENGTH} from './constants';
import {BMICard, Card} from './fragments/Card';

export const Journal = () => {
  const {t, ready} = useTranslate('journal');
  const {desktop, lg} = useMQuery();
  const {isSidebarOpen} = useSidebarClassObserver();
  const {
    data,
  } = useBmi();

  const lastWeekData = useMemo(() => {
    return data?.filter((el) => Math.abs(DateTime.fromISO(el.createdTime).diffNow().as('day')) < WEEK_LENGTH);
  }, [data]);

  const CARDS = [
    {
      title: t('SLEEP'),
      description: t('TRACKING_SLEEP_QUALITY_IS_'),
      img: <ComingSoon />,
    },
    {
      title: t('WATER'),
      description: t('TRACKING_WATER_INTAKE_IS_'),
      img: <ComingSoon />,
    },
    {
      title: t('STEPS'),
      description: t('MONITORING_STEP_COUNT_IS_'),
      img: <ComingSoon />,
    },
  ];

  if (!ready) {
    return null;
  }

  return (
    <Stack sx={{flexGrow: 1}} gap={{xs: 18, lg: 36}}>
      <PageHeader>{t('JOURNAL')}</PageHeader>
      {(!isSidebarOpen && lg) || (desktop && !lg)
        ? (
          <Grid
            rowSpacing={24}
            columnSpacing={24}
            container>
            <Grid item xs={6} key={'BMI_SECOND'}>
              <BMICard
                title={t('BMI')}
                bmiData={lastWeekData || []}
              />
            </Grid>
            {CARDS.map(card => (
              <Grid item xs={6} key={card.title}>
                <Card
                  title={card.title}
                  description={card.description}
                  img={card.img} />
              </Grid>
            ))}
          </Grid>
        )
        : (
          <Stack gap={12}>
            <Grid item xs={6} key={'BMI_SECOND'}>
              <BMICard
                title={t('BMI')}
                bmiData={lastWeekData || []}
              />
            </Grid>
            {CARDS.map(card => (
              <Grid item xs={6} key={card.title}>
                <Card
                  title={card.title}
                  description={card.description}
                  img={card.img} />
              </Grid>
            ))}
          </Stack>
        )
      }

    </Stack>
  );
};
