import styled from '@emotion/styled';

export const AvatarLayout = styled.div<{ useDefaultIcon?: boolean, src?: string, size?: number, square?: boolean }>`
  --size: ${({size}) => size ?? 34}px;
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  border-radius: ${({square}) => square ? 0 : '50%'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
  background: ${({src, theme, useDefaultIcon}) => src ? `url(${src}) center center / cover no-repeat ${theme.colors.all.secondaryAvatar}` : (!useDefaultIcon && theme.palette.secondary._35)};
`;
