/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React, {FC, HTMLInputTypeAttribute, memo, useEffect, useId, useState} from 'react';
import {Tooltip} from 'components/Tooltip';
import {paletteColors} from 'theme';
import {IconEye, IconWarn} from './icons';
import {
  StyledContainer, StyledBody, StyledIconContainer, StyledInputTip,
  StyledPasswordEyeButton, StyledInput, StyledPhoneInput, Label,
} from './styles';
import {useField} from 'formik';

export const CONTROL_CONTAINER = 'control-container';
export interface InputControlProps extends React.InputHTMLAttributes<HTMLInputElement>{
  label?: string
  error?: string | boolean | undefined | null
  tip?: string | undefined | null
  type?: Extract<HTMLInputTypeAttribute, 'text' | 'password'> | 'phone'
  value?: any
  hideErrorText?: boolean
  activeTooltip?: boolean
}
export const InputControl = memo(({
  onChange, value, type = 'text', label, error, tip,
  placeholder, name, className, disabled, hideErrorText, activeTooltip,
}: InputControlProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const id = useId();
  const [showTooltip, setShowTooltip] = useState(Boolean(activeTooltip && error && typeof error === 'string'));

  useEffect(() => {
    setShowTooltip(Boolean(activeTooltip && error && typeof error === 'string'));
    if (!error) {
      setShowTooltip(false);
    }
  }, [activeTooltip, error]);

  return (
    <StyledContainer className={className}>
      {label && <Label htmlFor={id}>{label}</Label>
      }
      <StyledBody css={(theme) => css`border-bottom-color: ${error ? theme.colors.all.error[200] : theme.colors.all.grey4}`}>
        {type !== 'phone'
          ? (
            <StyledInput
              id={id}
              name={name}
              type={showPassword && type === 'password' ? 'input' : type}
              value={value ?? undefined}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              autoComplete={'new-password'}
            />
          )
          : (
            <StyledPhoneInput
              placeholder={'+1 XXX XXX XXXX'}
              onChange={onChange}
              disabled={disabled}
              name={name}
              id={id}
              value={value as string ?? undefined}
            />
          )}
        {type === 'password' && (
          <StyledIconContainer>
            <StyledPasswordEyeButton
              type={'button'}
              onClick={() => setShowPassword(!showPassword)}>
              <IconEye open={showPassword} />
            </StyledPasswordEyeButton>
          </StyledIconContainer>
        )}
        {type !== 'password' && error && (
          <Tooltip
            open={showTooltip}
            onClose={() => setShowTooltip(false)}
            title={error}
            placement={'top-end'}
          >
            <StyledIconContainer
              css={css`cursor: pointer !important`}
              onClick={() => setShowTooltip((s) => !s)}
              onMouseEnter={() => showTooltip ? null : setShowTooltip(true)}
              onMouseLeave={() => showTooltip ? setShowTooltip(false) : null}
            >
              <IconWarn />
            </StyledIconContainer>
          </Tooltip>
        )}
      </StyledBody>
      <StyledInputTip
        color={error ? paletteColors.error[200] : undefined}
      >
        {(!hideErrorText && error) || tip}
      </StyledInputTip>
    </StyledContainer>
  );
});
InputControl.displayName = 'InputControl';

export const InputControlF: FC<Omit<InputControlProps, 'name'> & {name: string}> = (props) => {
  const {label: _, hideErrorText: _1, activeTooltip: _2, ...restProps} = props;
  const [field, meta] = useField(restProps);
  return (
    <InputControl
      {...field}
      {...props}
      error={meta.touched && meta.error}
    />
  );
};
