import {initialColors} from '@src/theme';

export const sx = {
  caseWrapper: {
    backgroundColor: initialColors.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '16px',
    flexDirection: 'row',
    gap: '12px',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: initialColors.blueLight,
    '&:active': {
      borderColor: initialColors.fileCardBackground,
      backgroundColor: initialColors.blueCardBorder,
    },
  },
  name: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  descriptionWrapper: {
    overflow: 'hidden',
    display: 'block',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
};
