import {DateTime} from 'luxon';

export const checkIsPeroson18OrOlder = (newContactBirthDate?: string | null) => {
  if (!newContactBirthDate) return null;

  const birthDate = DateTime.fromISO(newContactBirthDate).startOf('day');
  const eighteenYearsAgo = DateTime.now().minus({years: 18}).startOf('day');

  return birthDate <= eighteenYearsAgo;
};
