import {Link, PageHeader as PageTitle, RowInformation, WBox} from '@components';
import {Box, Stack} from '@mui/material';
import {HealthCase} from '@src/api/healthCases';
import {EditDocumentDialog} from '@src/components/EditDocumentDialog';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {PulseLoader} from '@src/components/PulseLoader';
import {TKeys} from '@src/i18n/useTranslate';
import {ErrorDialog} from '@src/pages/Storage/components/ErrorDialog';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {BiomarkersTable} from '@src/pages/StorageDocument/components/BiomarkersTable';
import {FileView} from '@src/pages/StorageDocument/components/FileView';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {DateFormat, dateFormatted, formatSize} from '@src/shared/utils';
import {Fragment, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {useFileData} from './hooks';
import {ContentContainer, sx} from './styles';

export const StorageDocument = () => {
  const translate = useStorageTranslate();
  const {mobile: isMobile} = useMQuery();
  const {id} = useParams();
  const {isSidebarOpen} = useSidebarClassObserver();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const navigate = useNavigate();

  const rowInfoVariant = isMobile ? '14_18_700' : '16_20_700';
  const rowInfoValueVariant = isMobile ? '14_18_500' : '16_20_500';

  const {
    handleDownloadFile,
    fileDetails,
    handleToggleFavorite,
    handleUpdateFile,
    handleFetchFileData,
    fileData,
    isFileDetailsLoading,
    isForbidden,
  } = useFileData(id);

  const downloadFileHandler = () => {
    void handleDownloadFile();
  };

  const handleToggleEditDialogOpen = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const generateLinkToHealthCase = (id: HealthCase['id']) =>
    generatePath(ROUTERS_PATH.HEALTH_CASE, {id});

  if (isForbidden && !isFileDetailsLoading) {
    navigate(ROUTERS_PATH.FORBIDDEN);
    return null;
  }

  return (
    <Stack sx={sx.mainContainer}>
      <PageTitle
        height={'auto'}
        alignItems="flex-start"
        itemAction={
          !isMobile
            ? (
              <Button
                color={'secondary'}
                variant={'outlined'}
                startIcon={<DownloadIcon />}
                onClick={downloadFileHandler}
              >
                {translate('DOWNLOAD_DOCUMENT')}
              </Button>
            )
            : undefined
        }
      >
        <Stack sx={sx.breadcrumbs}>
          <ArrowLeftIcon />
          <Link to={ROUTERS_PATH.STORAGE}>{translate('BACK_TO_STORAGE')}</Link>
        </Stack>
      </PageTitle>
      {isFileDetailsLoading && (
        <ContentContainer isSidebarOpen={isSidebarOpen}>
          <WBox sx={sx.leftContainer}>
            <Box sx={sx.loader}>
              <PulseLoader loading />
            </Box>
          </WBox>
        </ContentContainer>
      )}
      {!isFileDetailsLoading && fileDetails && (
        <ContentContainer isSidebarOpen={isSidebarOpen}>
          <WBox sx={sx.leftContainer}>
            <EllipsisTextTooltip sx={sx.fileName} ellipsisText={fileDetails?.name} heightCheck />
            <BiomarkersTable biomarkers={fileDetails?.biomarkers || []} />
            <Stack gap={12}>
              <RowInformation
                variant={rowInfoVariant}
                valueTypographyVariant={rowInfoValueVariant}
                noMargin
                value={translate(fileDetails?.category as TKeys<'storage'>)}
                name={translate('CATEGORY')}
                valueSx={sx.rowValue}
              />
              <RowInformation
                variant={rowInfoVariant}
                valueTypographyVariant={rowInfoValueVariant}
                value={fileDetails?.healthCases.map(({id, name}, index) => (
                  <Fragment key={id}>
                    <Link sx={sx.link} to={generateLinkToHealthCase(id)}>
                      {name}
                      {fileDetails?.healthCases.length - 1 !== index && ', '}
                    </Link>
                  </Fragment>
                ))}
                noWrap
                name={translate('HEALTH_CASES')}
              />
              <RowInformation
                variant={rowInfoVariant}
                valueTypographyVariant={rowInfoValueVariant}
                noMargin
                value={dateFormatted(fileDetails?.date, DateFormat.DATE_SHORT)}
                name={translate('DATE')}
                valueSx={sx.rowValue}
              />
              <RowInformation
                variant={rowInfoVariant}
                valueTypographyVariant={rowInfoValueVariant}
                noMargin
                value={fileDetails?.type}
                name={translate('DOCUMENT_TYPE')}
                valueSx={sx.rowValue}
              />
              <RowInformation
                variant={rowInfoVariant}
                valueTypographyVariant={rowInfoValueVariant}
                noMargin
                value={formatSize(fileDetails?.size || 0)}
                name={translate('DOCUMENT_SIZE')}
                valueSx={sx.rowValue}
              />
              <RowInformation
                variant={rowInfoVariant}
                valueTypographyVariant={rowInfoValueVariant}
                noWrap
                noMargin
                value={fileDetails?.description}
                name={translate('DESCRIPTION')}
                sx={sx.rowInfo}
                valueSx={sx.rowValue}
              />
            </Stack>
          </WBox>
          <FileView
            details={fileDetails}
            isSidebarOpen={isSidebarOpen}
            onToggleFavorite={handleToggleFavorite}
            onOpenEditDialog={handleToggleEditDialogOpen}
            fileData={fileData}
            onFetchFileData={handleFetchFileData}
            isForbidden={isForbidden}
          />
        </ContentContainer>
      )}
      {fileDetails && isEditDialogOpen && (
        <EditDocumentDialog
          fileDetails={fileDetails}
          isOpen
          onClose={handleToggleEditDialogOpen}
          onSave={handleUpdateFile}
        />
      )}
      <ErrorDialog />
      {isMobile && (
        <Box sx={sx.mobileDownloadButton}>
          <Button color={'secondary'} onClick={downloadFileHandler} fullWidth>
            {translate('DOWNLOAD_DOCUMENT')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
