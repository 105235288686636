import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  tableWrapper: {
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: theme => theme.colors.borderBlueLight,
  },
  biomarker: {
    typography: {xs: '12_16_500', md: '14_18_500'},
    whiteSpace: 'wrap',
    display: {xs: '-webkit-box', sm: '-webkit-box', md: '-webkit-box', lg: '-webkit-box'},
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
};
