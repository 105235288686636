import {styled} from '@mui/material';
import {ReactComponent as GlobalNotificationIcon} from '@src/shared/assets/icons/notification-icon.svg';

export const StyledGlobalNotificationIcon = styled(GlobalNotificationIcon)<{ hasUnread: boolean }>(
  ({hasUnread}) => ({
    '.new': {
      display: hasUnread ? 'block' : 'none',
    },
  }),
);
