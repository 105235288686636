import {envs} from '@src/shared/constants/envs';
import {ValidationError, ValidationErrorType} from '@src/types';
import {AxiosError} from 'axios';

const safeError = (e: string | undefined | null) => {
  return Object.keys(ValidationErrorType).includes(e ?? '') ? e as ValidationErrorType : ValidationErrorType.UNKNOWN_ERROR;
};
export const getValidationError = ({error, fieldErrors}: {error: ValidationErrorType, fieldErrors: ValidationError[]}) => {
  return safeError(error ?? fieldErrors?.[0].error);
};

// Error handlers
export const getError = (e: any) => {
  if (!e) {
    return ValidationErrorType.UNKNOWN_ERROR;
  }
  if (e.response?.status === 401) {
    const url = envs.PASSPORT_WEB_URL;
    window.location.href = url;
    return ValidationErrorType.INSUFFICIENT_ACCESS_RIGHTS;
  }
  if (e.response?.status === 403) {
    return ValidationErrorType.INSUFFICIENT_ACCESS_RIGHTS;
  }
  if (e.response?.data) {
    return getValidationError(e.response.data);
  }
  return ValidationErrorType.UNKNOWN_ERROR;
};
type AnyError = AxiosError | any;
export const getErrors = (e: AnyError): Record<'common' | string, ValidationErrorType> => {
  if (!e) {
    return {common: ValidationErrorType.UNKNOWN_ERROR};
  }
  if (e.response?.status === 403) {
    return {common: ValidationErrorType.INSUFFICIENT_ACCESS_RIGHTS};
  }
  if (e.response?.data) {
    const data: {error?: ValidationErrorType, fieldErrors?: ValidationError[]} = e.response.data;
    if (data.error) {
      return {common: safeError(data.error)};
    };
    if (data.fieldErrors?.length) {
      return data.fieldErrors.reduce<Record<string, ValidationErrorType>>((acc, {field, error}) => {
        acc[field] = safeError(error);
        return acc;
      }, {});
    }
  }
  return {common: ValidationErrorType.UNKNOWN_ERROR};
};
