import {Dialog, styled} from '@mui/material';

import {DialogAProps, ModalDialogSizes} from './types';

const sizesMap: Record<Exclude<ModalDialogSizes, 'auto'>, number> = {
  xs: 343,
  sm: 480,
  md: 560,
  lg: 640,
  xl: 720,
  xxl: 800,
};

const getWidth = (size?: ModalDialogSizes, width?: number, type?: 'max-width') => {
  if (width != null) {
    return `${width}px`;
  }

  if (typeof size === 'string' && !Object.keys(sizesMap).includes(size)) {
    return size;
  }

  if (size != null && size !== 'auto') {
    return `${sizesMap[size]}px`;
  } else if (size === 'auto') {
    return type === 'max-width' ? 'initial' : 'auto';
  } else {
    return `${sizesMap.sm}px`;
  }
};

const getTextAlign = (size: string) => {
  if (size === 'sm') return 'center';
  return 'left';
};

const getMinHeight = (fullHeight?: boolean) => {
  if (fullHeight) return '90%';
  return '';
};

export const StyledDialog = styled(Dialog)<DialogAProps>(({size, fullHeight, theme, border}) => ({
  borderRadius: 0,
  '.MuiPaper-root': {
    border: border || `1px solid ${theme.palette.alert.success}`,
    borderRadius: 0,
    color: 'inherit',
    width: getWidth(size),
    maxWidth: getWidth(size, undefined, 'max-width'),
    margin: 0,
    '&.MuiDialog-paperWidthSm': {
      '.mobile-layout &': {
        width: 'calc(100% - 38px)',
        marginRight: 0,
        marginLeft: 0,
      },
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: 'initial !important',
    },
    minHeight: getMinHeight(fullHeight),
  },
  '.MuiDialogContent-root': {
    textAlign: getTextAlign(size || ''),
  },
}));

StyledDialog.defaultProps = {
  keepMounted: false,
  size: 'xs',
};
