import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  toggleButton: {
    color: 'inherit',
    textTransform: 'unset',
    typography: '14_18_600',
    padding: '10px 16px',
    background: 'none',
    '&.Mui-selected': {
      border: '2px solid currentColor',
      background: 'none',
    },
    ':hover': {
      background: 'none',
      borderColor: theme => theme.palette.grey['400'],
      color: theme => theme.colors.all.blue['300'],
      '&.Mui-selected': {
        borderColor: theme => theme.colors.all.blue['300'],
        background: 'none',
      },
    },
  },
  searchToggleContainer: (t) => ({
    gap: 24,
    flex: 1,
    flexDirection: 'row',
    [t.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }),
  searchWrapper: {
    flex: 1,
    display: 'flex',
    gap: 12,
  },
};
