import {DialogActions, DialogProps, styled} from '@mui/material';

export const StyledDialogActions = styled(({spaceBetween: _, ...restProps}: Partial<DialogProps> & {spaceBetween?: boolean}) => {
  return (
    <DialogActions {...restProps}/>
  );
})<{spaceBetween?: boolean}>`
    justify-content: ${({spaceBetween}) => spaceBetween ? 'space-between' : 'center'} !important;
    margin: 24px;
    gap: 21px;
    padding: 0;
    button {
        margin: 0 !important;
    }
    .mobile-layout & {
        gap: 12px;
        flex-direction: column-reverse;
        button {
            flex-grow: 1;
        }
    }
`;
