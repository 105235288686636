import {Stack} from '@mui/material';
import {EmptySearch} from '@src/components';
import {FC} from 'react';

import {CardResultsMobile} from '../CardResultsMobile/CardResultsMobile';

import {sx} from './styles';
import {ResultsTableMobileProps} from './types';

export const ResultsTableMobile: FC<ResultsTableMobileProps> = ({results, isLoading}) => {
  if (!results?.length) {
    return <EmptySearch isLoading={isLoading} isNew />;
  }

  return (
    <Stack gap={12} sx={sx.mainContainer}>
      {results.map((result) => (
        <CardResultsMobile key={result.id} {...result} />
      ))}
    </Stack>
  );
};
