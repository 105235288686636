import {useSelector, useDispatch} from 'react-redux';
import {RootState} from 'store';
import {actions} from './slice';

const useUserState = () => useSelector((state: RootState) => state.user);

export const useUser = () => {
  const {isAboutSystemModalOpen} = useUserState();
  const dispatch = useDispatch();

  return {
    handleAboutSystemModal: () => dispatch(actions.handleAboutSystemModal()),
    isModalOpen: isAboutSystemModalOpen,
  };
};
