export const calcPaddingDownMd = (isConnections?: boolean, isResults?: boolean, isDynamics?: boolean) => {
  if (isConnections) return '12px 14px 14px';
  if (isResults) return '0 14px 14px 2px';
  if (isDynamics) return 24;
  return '0 14px 14px';
};

export const calcPaddingDownLg = (isDynamics?: boolean, isResults?: boolean) => {
  if (isDynamics) return 24;
  if (isResults) return '12px 24px 24px 12px';
};

export const calcPaddingDownSm = (isDynamics?: boolean, isConnections?: boolean) => {
  if (isDynamics) return '24px 16px';
  if (isConnections) return 12;
  return 0;
};

export const calcDefaultPaddings = (isDynamics?: boolean, isResults?: boolean, isConnections?: boolean) => {
  if (isDynamics) return 36;
  if (isResults || isConnections) return 24;
  return '0 24px 24px';
};
