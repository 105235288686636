import {styled, TextareaAutosize} from '@mui/material';

export const TextArea = styled(TextareaAutosize)<{height?: number}>(({theme, height}) => ({
  overflow: 'scroll !important',
  height: height ? `${height}px !important` : 'auto',
  borderColor: theme.colors.all.grey[300],
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  color: theme.colors.all.grey[700],
  padding: '16px',
  resize: 'none',
  width: '100%',
  '&:hover': {
    borderColor: theme.colors.all.grey[300],
  },
  '&::-moz-placeholder': {
    color: theme.colors.all.grey[400],
  },
  '&::-webkit-input-placeholder': {
    color: theme.colors.all.grey[400],
  },
  '&:focus': {
    outline: 0,
    borderColor: theme.colors.all.grey[300],
  },
  '&:focus-visible': {
    outline: 0,
  },
}));
