/* eslint-disable no-comments/disallowComments */
import {AddressComponentType, PlaceResult} from '@src/types';

import {addrComponentsToRecord} from './addrComponentsToRecord';

/**
 * @description
 * **Why?**
 *
 * While we recognize the existence of a **place.formatted_address** field,
 * it doesn't consistently reflect the format we need. To obtain greater control over the format of the address string,
 * it must be constructed on our end, tailored exactly to the developer's specifications.
 */
export const getFormattedAddress = (place: PlaceResult) => {
  const addressComponentsRecord = addrComponentsToRecord(place?.address_components);
  const headerPart = [
    addressComponentsRecord[AddressComponentType.STREET_NUMBER]?.short_name,
    addressComponentsRecord[AddressComponentType.ROUTE]?.short_name,
  ]
    .filter(Boolean)
    .join(' ');
  const generatedFormattedAddressString = [
    headerPart,
    addressComponentsRecord[AddressComponentType.LOCALITY]?.long_name,
    addressComponentsRecord[AddressComponentType.SUBLOCALITY_LEVEL_1]?.short_name,
    addressComponentsRecord[AddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1]?.long_name,
    addressComponentsRecord[AddressComponentType.POSTAL_CODE]?.short_name,
    addressComponentsRecord[AddressComponentType.COUNTRY]?.short_name,
  ]
    .filter(Boolean)
    .join(', ');

  return {
    formattedAddress: generatedFormattedAddressString,
    header: headerPart || generatedFormattedAddressString.split(', ')[0],
  };
};
