import {ReactComponent as DownX16} from '../16x16/arrow-down.svg';
import {ReactComponent as BackX24} from '../24x24/arrow-back.svg';
import {ReactComponent as DownX24} from '../24x24/arrow-down.svg';
import {ReactComponent as GoX24} from '../24x24/arrow-go.svg';

export const Arrow = {
  Back: {
    X24: BackX24,
  },
  Down: {
    X16: DownX16,
    X24: DownX24,
  },
  Go: {
    X24: GoX24,
  },
};
