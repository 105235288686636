import {HealthCase} from '@src/api/healthCases';

import {HealthCasesFilters} from './types';

export const INITIAL_DATES = {
  from: null,
  to: null,
};

export const INITIAL_FILTERS: HealthCasesFilters = {
  phrase: '',
  startPage: '0',
  perPage: '10',
  ...INITIAL_DATES,
};

export const INITIAL_HEALTH_CASE: HealthCase = {
  id: '',
  name: '',
  caseDate: '',
  objectCount: 0,
  description: '',
};
