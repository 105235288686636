/* eslint-disable no-comments/disallowComments */
import {yupResolver} from '@hookform/resolvers/yup';
import {EditFileDialogForm} from '@src/components/EditDocumentDialog/types';
import {useTranslate} from '@src/i18n/useTranslate';
import {MAX_STORAGE_FILE_NAME_LENGTH} from '@src/shared/constants/formFields';
import {array, boolean, object, string} from 'yup';

export const useDocumentValidationSchema = () => {
  const {t} = useTranslate('storage');

  return yupResolver(
    object({
      name: string().required(t('REQUIRED_FIELD')).max(MAX_STORAGE_FILE_NAME_LENGTH),
      category: string().required(t('REQUIRED_FIELD')),
      healthCaseIds: array().of(string()),
      date: string().required(t('REQUIRED_FIELD')),
      description: string().nullable(),
      recognize: boolean(),
      createBiomarkers: array().of(
        object().shape({
          biomarkerId: string()
            .required()
            .test('unique', t('BIOMARKER_UNIQ'), (value, context) => {
              // @ts-expect-error
              const root = context.from[1].value;

              const editBiomarkers = root?.editBiomarkers as EditFileDialogForm['editBiomarkers'];
              const createBiomarkers =
                root?.createBiomarkers as EditFileDialogForm['createBiomarkers'];

              const createBiomarkersIds = createBiomarkers?.map((item) => item.biomarkerId) || [];
              const editBiomarkersIds = editBiomarkers?.map((item) => item.biomarkerId) || [];

              const combinedBiomarkersIds = [...createBiomarkersIds, ...editBiomarkersIds];
              const duplicateCount = combinedBiomarkersIds.filter((id) => id === value).length;

              return duplicateCount === 1;
            }),
          biomarkerUnitId: string().when('hasUnits', {
            is: true,
            then: string().required(),
            otherwise: string().notRequired(),
          }),
          value: string().required(),
        }),
      ),
      editBiomarkers: array().of(
        object().shape({
          biomarkerId: string().required(),
          biomarkerUnitId: string().when('hasUnits', {
            is: true,
            then: string().required(),
            otherwise: string().notRequired(),
          }),
          value: string().required(),
        }),
      ),
    }),
  );
};
