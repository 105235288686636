import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ProfessionalSlots,
  ProfessionalSlotsRequest,
  RescheduleAppointmentRequest,
  AppointmentFull,
  Appointment,
  ProfessionalReviewRequest,
  AppointmentFile,
  UpdateAppointmentErrorType,
} from '@src/api';
import {TableFilterResults} from '@src/pages/Appointments/layouts/consts';
import {PartialRecord, UUID, ValidationErrorType} from 'types';

export type RequestNPIOrgFlowSteps =
  | 'init'
  | 'open'
  | 'loading'
  | 'running'
  | 'success'
  | 'error';
export type UpdateAppointmentFlowSteps =
  | 'init'
  | 'open'
  | 'loading';
export type RescheduleAppointmentFlowSteps =
  | 'init'
  | 'loading'
  | 'reschedule-error-modal-open'
  | 'reschedule-modal-open'
  | 'reschedule-success-modal-open';
export type CancelModalFlowSteps =
  | 'init'
  | 'cancel-modal-open'
  | 'cancel-modal-loading'
  | 'success-cancel-modal-open'
  | 'error-cancel-modal-open';
export type ReviewModalFlowSteps =
  | 'init'
  | 'success-review-modal-open'
  | 'error-review-modal-open';
type Fetching = 'common' | 'account' | 'dashboard' | 'profile' | 'appointments';
export type RescheduleError = 'PROFESSIONAL_SLOT_BUSY';
export interface FavoritesState {
  appointments: Appointment[] | null
  appointment: AppointmentFull | null
  professionalSlots: ProfessionalSlots[] | null
  showArchived: boolean
  filter: TableFilterResults
  step: RequestNPIOrgFlowSteps
  cancelModalStep: CancelModalFlowSteps
  reviewModalStep: ReviewModalFlowSteps
  rescheduleAppointmentSteps: RescheduleAppointmentFlowSteps
  updateAppointmentError: UpdateAppointmentErrorType[] | null
  updateAppointmentStep: UpdateAppointmentFlowSteps
  rescheduleError: RescheduleError | null
  fetching: PartialRecord<Fetching, boolean> | null
  error?: ValidationErrorType | null
}
export const initialFilter: TableFilterResults = {
  doctor: null,
  patient: null,
  status: null,
  startDate: null,
  endDate: null,
  speciality: null,
};
const initialState: FavoritesState = {
  appointments: null,
  appointment: null,
  professionalSlots: null,
  showArchived: false,
  filter: initialFilter,
  step: 'init',
  reviewModalStep: 'init',
  cancelModalStep: 'init',
  rescheduleAppointmentSteps: 'init',
  updateAppointmentStep: 'init',
  updateAppointmentError: null,
  rescheduleError: null,
  fetching: null,
};

export const appointments = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    requestAppointments () {},
    addReviewForAppointment (
      _state,
      _payload: PayloadAction<{ id: string, data: ProfessionalReviewRequest }>,
    ) {},
    requestAppointment (_state, _action: PayloadAction<{ id: UUID }>) {},
    requestsProfessionalSlots (
      _state,
      _action: PayloadAction<{ id: UUID, data: ProfessionalSlotsRequest }>,
    ) {},
    requestsRescheduleAppointment (
      _state,
      _action: PayloadAction<{ id: UUID, data: RescheduleAppointmentRequest }>,
    ) {},
    cancelAppointment (_state, _action: PayloadAction<{ id: UUID }>) {},
    setAppointments (state, {payload}: PayloadAction<{ appointments: Appointment[] }>) {
      state.appointments = payload.appointments;
    },
    setProfessionalSlots (
      state,
      {payload}: PayloadAction<{ professionalSlots: ProfessionalSlots[] }>,
    ) {
      state.professionalSlots = payload.professionalSlots;
    },
    updateAppointment (
      _state,
      _payload: PayloadAction<{
        id: UUID
        deletedFileIds: string[] | null
        description: string | null
        height: number | null
        weight: number | null
        files?: File[]
        oldFiles: AppointmentFile[] | null
      }>,
    ) {},
    setAppointment (state, {payload}: PayloadAction<{ appointment: AppointmentFull | null }>) {
      state.appointment = payload.appointment;
    },
    setFilter (
      state,
      {payload}: PayloadAction<{ filter: TableFilterResults }>,
    ) {
      state.filter = payload.filter;
    },
    setShowArchived (
      state,
      {payload}: PayloadAction<{ showArchived: boolean }>,
    ) {
      state.showArchived = payload.showArchived;
    },
    setRescheduleError (
      state,
      {payload}: PayloadAction<{ rescheduleError: RescheduleError }>,
    ) {
      state.rescheduleError = payload.rescheduleError;
    },
    setStep (
      state,
      {payload}: PayloadAction<{ step: RequestNPIOrgFlowSteps }>,
    ) {
      state.step = payload.step;
    },
    setCancelModalStep (
      state,
      {payload}: PayloadAction<{ step: CancelModalFlowSteps }>,
    ) {
      state.cancelModalStep = payload.step;
    },
    setReviewModalStep (
      state,
      {payload}: PayloadAction<{ step: ReviewModalFlowSteps }>,
    ) {
      state.reviewModalStep = payload.step;
    },
    setRescheduleAppointmentSteps (
      state,
      {payload}: PayloadAction<{ step: RescheduleAppointmentFlowSteps }>,
    ) {
      state.rescheduleAppointmentSteps = payload.step;
    },
    setUpdateAppointmentStep (
      state,
      {payload}: PayloadAction<{ step: UpdateAppointmentFlowSteps }>,
    ) {
      state.updateAppointmentStep = payload.step;
    },
    setFetching (state, {payload}: PayloadAction<FavoritesState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...state.fetching, ...payload};
    },
    setUpdateAppointmentError (state, {payload}: PayloadAction<{updateAppointmentError: UpdateAppointmentErrorType[] | null}>) {
      state.updateAppointmentError = payload.updateAppointmentError;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const appointmentsReducer = appointments.reducer;
export const appointmentsActions = appointments.actions;
export const appointmentsStoreName = appointments.name;
