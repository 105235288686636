import {Test, TestStatus} from '@api';
import {TKeys} from '@src/i18n/useTranslate';

export const getReferenceText = (test: Test, t: (str?: TKeys<'details'> | null) => string) => {
  if (test.status !== null && test.status !== TestStatus.COMPLETED) {
    return '';
  }

  const results = [];
  const {reference, criticalReference, qualityReference} = test;

  if (reference?.minValue != null && reference?.maxValue != null) {
    const referenceText = `${t('OPTIMAL')}: ${reference.minValue} - ${reference.maxValue}`;
    results.push(referenceText);
  }

  if (criticalReference) {
    if (criticalReference.minValue !== null && criticalReference.maxValue !== null) {
      const criticalText = `${t('CRITICAL')}: <${criticalReference.minValue}; >${
        criticalReference.maxValue
      }`;
      results.push(criticalText);
    } else if (criticalReference.minValue !== null) {
      const criticalText = `${t('CRITICAL')}: <${criticalReference.minValue}`;
      results.push(criticalText);
    } else if (criticalReference.maxValue !== null) {
      const criticalText = `${t('CRITICAL')}: >${criticalReference.maxValue}`;
      results.push(criticalText);
    }
  }

  if (
    reference?.minValue !== null &&
    reference?.maxValue !== null &&
    criticalReference &&
    criticalReference?.minValue !== null &&
    criticalReference?.maxValue !== null
  ) {
    const abnormalText = `${t('ABNORMAL')}: ${reference.minValue} - ${
      criticalReference.minValue
    }; ${reference.maxValue} - ${criticalReference.maxValue}`;
    results.push(abnormalText);
  }

  if (qualityReference) {
    results.push(qualityReference.value);
  }

  return results.length > 0 ? results.join('\n') : '';
};

export const getBreakpoints = () => ({
  xxl: 1500,
  xl: 1381,
  lg: 1116,
  sm: 700,
  md: 1080,
});

export const getResultColor = (test: Test) => {
  const {reference, criticalReference, qualityReference} = test;
  const isOutReference = reference?.isOutOfRef;
  const isOutCriticalReference = criticalReference?.isOutOfRef;
  const isOutQualityReference = qualityReference?.isOutOfRef;

  if (isOutCriticalReference || isOutQualityReference) {
    return '#EA3D5C';
  }
  if (isOutReference) {
    return '#FAB41C';
  }

  if (reference !== null || criticalReference !== null || qualityReference !== null) {
    if (
      (reference?.minValue !== null && reference?.maxValue !== null) ||
      (criticalReference?.minValue !== null && criticalReference?.maxValue !== null) ||
      (qualityReference?.value !== undefined && qualityReference?.value !== null)
    ) {
      return '#05B7AC';
    }
  }

  return '';
};
