import {StorageBiomarker} from '@api';
import {Box} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {useMQuery} from '@src/shared/hooks';
import {Table} from '@src/shared/ui/Table';
import {createColumnHelper} from '@tanstack/react-table';
import {FC, useEffect, useMemo, useRef, useState} from 'react';

import {sx} from './styles';
import {BiomarkersTableProps} from './types';

export const BiomarkersTable: FC<BiomarkersTableProps> = ({biomarkers}) => {
  const t = useStorageTranslate();
  const columnHelper = createColumnHelper<StorageBiomarker>();
  const {tabletPortrait: isTablePortrait, mobile: isMobile, tablet: isTablet, desktop: isDesktop} = useMQuery();

  const tableHeaderVariant = isMobile || isTablePortrait ? '12_16_700' : '14_18_700';

  const [heightColumns, setHeightColumns] = useState<string | undefined>(undefined);

  const biomarkerRef = useRef<HTMLParagraphElement>(null);
  const resultRef = useRef<HTMLParagraphElement>(null);

  const checkHeight = () => {
    const biomarkerHeight = biomarkerRef.current?.offsetHeight || 0;
    const resultHeight = resultRef.current?.offsetHeight || 0;

    if (biomarkerHeight > 18 || resultHeight > 18) {
      setHeightColumns('auto');
    } else {
      setHeightColumns(undefined);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkHeight();
    });

    if (biomarkerRef.current) {
      observer.observe(biomarkerRef.current, {childList: true, subtree: true, characterData: true});
    }
    if (resultRef.current) {
      observer.observe(resultRef.current, {childList: true, subtree: true, characterData: true});
    }

    window.addEventListener('resize', checkHeight);

    checkHeight();

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', checkHeight);
    };
  }, [biomarkers, isMobile, isTablet, isDesktop]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('biomarker', {
        id: 'biomarker',
        header: t('BIOMARKERS'),
        enableSorting: false,
        cell: (props) => (
          <EllipsisTextTooltip
            ref={biomarkerRef}
            heightCheck
            ellipsisText={props.getValue()}
            sx={sx.biomarker}/>
        ),
      }),
      columnHelper.accessor('value', {
        id: 'result',
        header: t('RESULT'),
        enableSorting: false,
        maxSize: 200,
        cell: (props) => (
          <EllipsisTextTooltip
            ref={resultRef}
            heightCheck
            ellipsisText={`${props.getValue()} ${props.row.original.unit ?? ''}`}
            sx={sx.biomarker}/>
        ),
      }),
    ];
  }, [columnHelper, isTablePortrait]);

  if (!biomarkers.length) {
    return null;
  }

  return (
    <Box sx={sx.tableWrapper}>
      <Table
        columns={columns}
        data={biomarkers}
        variant={tableHeaderVariant}
        heightColumns={heightColumns}
        minHeightColumns='45px'/>
    </Box>
  );
};
