import {insurancesApi} from '@src/api/insurances';
import {all, takeLeading, call, put} from 'redux-saga/effects';

import {errorHandler} from '../errorHandler';
import {SagaPayload} from '../types';

import {insuranceActions as actions} from './slice';

export function * insuranceSaga () {
  yield all([
    takeLeading(actions.requestInsuranceCarriers, requestInsuranceCarriers),
    takeLeading(actions.requestInsurancePlans, requestInsurancePlans),
    takeLeading(actions.requestInsurances, requestInsurances),
  ]);
}

function * requestInsurances () {
  yield put(actions.setFetching(true));
  try {
    const healthcareInsurancePolicies: Awaited<ReturnType<typeof insurancesApi.getInsurances>> =
      yield call(insurancesApi.getInsurances);
    yield put(actions.setInsurances({healthcareInsurancePolicies}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
  yield put(actions.setFetching(false));
}

function * requestInsuranceCarriers ({
  payload,
}: SagaPayload<typeof actions.requestInsuranceCarriers>) {
  try {
    const insuranceCarriers: Awaited<ReturnType<typeof insurancesApi.getInsuranceCarriersList>> =
      yield call(insurancesApi.getInsuranceCarriersList, {filter: payload.filter});
    yield put(actions.setInsuranceCarriers({insuranceCarriers}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * requestInsurancePlans ({payload}: SagaPayload<typeof actions.requestInsurancePlans>) {
  try {
    const insurancePlans: Awaited<ReturnType<typeof insurancesApi.getInsurancePlansList>> =
      yield call(insurancesApi.getInsurancePlansList, {
        insuranceCarrierId: payload.insuranceCarrierId,
      });
    yield put(actions.setInsurancePlans({insurancePlans}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
