import {Theme, styled, css as _, Interpolation, InputBase, SxProps} from '@mui/material';
import {PropsWithChildren} from 'react';

import {ICircleProps} from './types';

export const styles = {
  iconContainer: _`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    pointer-events: none;
    `,
};

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  iconCalendarContainer: (t) => ({
    color: t.palette.grey[400],
    justifyContent: 'center',
  }),
  container: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  calendarIcon: {
    svg: {path: {fill: (t) => t.palette.grey[400]}},
  },
};

const inputStyles: Interpolation<{ theme?: Theme, error?: boolean, hasValue?: boolean, disabled?: boolean }> = ({
  theme,
  hasValue,
  disabled,
}) => _`
  height: 100%;
  width: 100%;
  input {
    height: 100%;
    width: ${hasValue ? 'calc(100% - 20px)' : '100%'};
    overflow: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: inherit;
    box-sizing: border-box;
    cursor: pointer;
    color: ${disabled ? theme?.palette.grey[500] : 'inherit'};
    &::placeholder {
      color: ${theme?.palette.grey[900] ?? ''}
    }
    outline: none;
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset !important;
    }
  }
`;

export const StyledInput = styled(InputBase)(inputStyles);

StyledInput.defaultProps = {
  type: 'text',
};

export const CalendarContainer = styled('div')<{ twoColumns?: boolean }>(
  ({theme, twoColumns}) => _`
    --width: 292px;
    color: ${theme.palette.common.white};
    background-color: ${theme.colors.all.grey2};
    width: 100%;
    max-width: ${twoColumns ? 'calc(var(--width) * 2)' : 'var(--width)'};
    min-height: 336px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
`,
);

export const Circle = styled(
  ({children, currentDay: _, ...restProps}: PropsWithChildren<ICircleProps>) => {
    return <button {...restProps}>{children}</button>;
  },
)<{ currentDay: boolean, selected: boolean }>(({theme, currentDay, selected, disabled}) => {
  let backgroundColor = 'transparent';
  let color = 'white';

  if (selected) {
    backgroundColor = theme.palette.secondary.main;
  } else if (currentDay) {
    backgroundColor = theme.palette.grey[600];
  }

  if (disabled) {
    color = selected || currentDay ? 'white' : theme.colors.all.grey4;
  }

  return _`
    position: absolute;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    border: none;
    cursor: pointer;
    color: ${color};
    @media (hover: hover) {
      &:hover {
        color: ${!disabled && !selected && !currentDay && theme.palette.grey[400]}
      }
      &:active {
        color: white;
      }
    }
    &:disabled {
      &:hover {
        cursor: inherit
      }
      &:active {
        background-color: transparent;
        transition: none;
      }
    }
    &:active {
      background-color: ${theme.palette.secondary.main};
      transition: none;
    }
  `;
});
Circle.defaultProps = {
  type: 'button',
};
export const DatesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
export const Cell = styled('div')`
  border: none;
  background-color: transparent;
  position: relative;
  width: calc(100% / 7);
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: ' ';
    margin-top: 100%;
  }
`;
