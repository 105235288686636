export const formatBytes = (
  bytes: number,
  decimals = 2,
): { size: string, label: FileSizeLabels } => {
  if (!+bytes) return {size: '0', label: FileSizeLabels.Bt};

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    FileSizeLabels.Bt,
    FileSizeLabels.Kb,
    FileSizeLabels.Mb,
    FileSizeLabels.Gb,
    FileSizeLabels.Tb,
    FileSizeLabels.Pb,
    FileSizeLabels.Eb,
    FileSizeLabels.Zb,
    FileSizeLabels.Yb,
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = String(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))).split('.').join(',');
  return {size, label: sizes[i]};
};

enum FileSizeLabels {
  Bt = 'BT',
  Kb = 'KB',
  Mb = 'MB',
  Gb = 'GB',
  Tb = 'TB',
  Pb = 'PB',
  Eb = 'EB',
  Zb = 'ZB',
  Yb = 'YB',
}
