import {MenuTypes, StorageFileCategory, StorageHealthCase, storageApi} from '@api';
import {DatePickerRange} from '@components';
import {
  Box,
  ListItemText,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {DateRangeButton} from '@src/components/DateRangeButton';
import {MultiSelect} from '@src/components/MultiSelect';
import {TKeys} from '@src/i18n/useTranslate';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {storageActions} from '@src/store/storage/slice';
import {StorageFilters} from '@src/store/storage/types';
import {MenuItemState} from '@src/types';
import {useAppDispatch, useAppSelector} from '@store';
import {ChangeEvent, FC, MouseEvent, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {IconButton, Option, SearchControl, SelectControl} from 'ui-kit';

import {getCategoryOptions, getMenuTypesOptions} from '../../helpers';
import {useStorageTranslate} from '../../hooks';
import {FiltersDialog} from '../FiltersDialog';

import {FiltersContainer, StyledAdaptiveFieldWrapper, sx} from './styles';
import {FiltersProps} from './types';

export const Filters: FC<FiltersProps> = ({isSidebarOpen, isScrolled}) => {
  const translate = useStorageTranslate();
  const {mobile: isMobile, tabletPortrait: isTabletSm} = useMQuery();

  const [healthCases, setHealthCases] = useState<StorageHealthCase[]>([]);
  const [searchValueHealthCase, setSearchValueHealthCase] = useState('');

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValueHealthCase(value);
  };

  const handleClearSearchValue = () => {
    setSearchValueHealthCase('')
  };

  const filters = useAppSelector((state) => state.storage.files.filters);
  const dispatch = useAppDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {state} = useLocation() as { state: MenuItemState };

  const handleToggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleFetchHealthCases = async () => {
    try {
      const response = await storageApi.getStorageHealthCases();
      setHealthCases(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    void handleFetchHealthCases();
  }, []);

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: StorageFilters
    updatedValue?: { key: keyof StorageFilters, value: any }
  }) => {
    dispatch(storageActions.setFilters({newState, updatedValue}));
  };

  const handleChangeHealthCases = (values: string[]) => {
    handleDispatchSetFilters({newState: {...filters, cases: values}});
  };

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    handleDispatchSetFilters({newState: {...filters, category: value}});
  };

  const handleChangeDate = (name: keyof StorageFilters) => (date: Date | null) => {
    handleDispatchSetFilters({
      updatedValue: {
        key: name,
        value: date,
      },
    });
  };
  const handleClearDate = () => {
    handleDispatchSetFilters({newState: {...filters, ...INITIAL_DATES}});
  };
  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleDispatchSetFilters({
      updatedValue: {
        key: 'phrase',
        value,
      },
    });
  };
  const handleChangeMenu = (_: MouseEvent<HTMLElement>, value: MenuTypes) => {
    if (!value) return;
    handleDispatchSetFilters({updatedValue: {key: 'menu', value}});
  };

  const handleUpdateFilters = (newFilters: StorageFilters) => {
    handleDispatchSetFilters({newState: newFilters});
  };

  const handleClearAllFilters = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
    dispatch(healthCasesActions.clearSelectedDocuments());
  };

  useEffect(() => {
    if (state?.needClear) {
      handleClearAllFilters();
    }
  }, [state?.needClear]);

  return (
    <>
      <FiltersContainer isScrolled={isScrolled}>
        <Box sx={sx.inputsContainer}>
          <StyledAdaptiveFieldWrapper downBreakpoint={'xs'} isSidebarOpen={isSidebarOpen}>
            <SearchControl
              placeholder={translate('SEARCH')}
              value={filters.phrase}
              onChange={handleChangeSearch}
              label={!isMobile && !isTabletSm && translate('SEARCH')}
            />
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper
            downBreakpoint={'xs'}
            upBreakpoint={'md'}
            isSidebarOpen={isSidebarOpen}
            sx={{minWidth: 'unset', flex: 'unset'}}
          >
            <IconButton color={'secondary-light'} onClick={handleToggleDialog}>
              <SettingsIcon />
            </IconButton>
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper downBreakpoint={'lg'} isSidebarOpen={isSidebarOpen}>
            <SelectControl
              label={translate('CATEGORY')}
              placeholder={translate('SELECT')}
              value={filters.category || ''}
              onChange={handleChangeCategory}
              renderValue={(val: string) => translate(val as TKeys<'storage'>)}
            >
              {getCategoryOptions(translate).map((option) => (
                <Option key={option.label} value={option.value}>
                  <ListItemText primary={option.label} />
                </Option>
              ))}
            </SelectControl>
          </StyledAdaptiveFieldWrapper>

          <StyledAdaptiveFieldWrapper downBreakpoint={'xl'} isSidebarOpen={isSidebarOpen}>
            <MultiSelect
              options={healthCases.map(({name, id: value}) => ({name, value}))}
              value={filters.cases}
              onChange={handleChangeHealthCases}
              label={translate('HEALTH_CASES')}
              placeholder={translate('SELECT')}
              onChangeSearchValue={handleChangeSearchValue}
              searchValue={searchValueHealthCase}
              onClear={handleClearSearchValue}
              renderValue={(val) =>
                val.length > 1 ? val[0] + ` +${val.length - 1}` : val[0] || ''
              }
            />
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper
            downBreakpoint={'xl'}
            upBreakpoint={'xxl'}
            isSidebarOpen={isSidebarOpen}
            sx={{minWidth: 'unset', flex: 'unset'}}
          >
            <DateRangeButton
              startDate={filters.from}
              endDate={filters.to}
              setStartDate={handleChangeDate('from')}
              setEndDate={handleChangeDate('to')}
              clearDate={handleClearDate}
              startRangeWithCurrentMonth
            />
          </StyledAdaptiveFieldWrapper>
          <StyledAdaptiveFieldWrapper downBreakpoint={'xxl'} isSidebarOpen={isSidebarOpen}>
            <DatePickerRange
              sx={{mb: '0 !important'}}
              isIconVisible={false}
              variant="outlined"
              label={translate('DATE')}
              startDate={filters.from}
              endDate={filters.to}
              setStartDate={handleChangeDate('from')}
              setEndDate={handleChangeDate('to')}
              clearDate={handleClearDate}
              labelTop
              startRangeWithCurrentMonth
            />
          </StyledAdaptiveFieldWrapper>
        </Box>
        <ToggleButtonGroup
          color={'secondary'}
          size={'small'}
          value={filters.menu}
          exclusive
          onChange={handleChangeMenu}
          sx={{height: 42}}
          fullWidth={isMobile || isTabletSm}
        >
          {getMenuTypesOptions(translate).map((option) => (
            <ToggleButton sx={sx.toggleButton} key={option.value} value={option.value}>
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <StyledAdaptiveFieldWrapper
          downBreakpoint={'md'}
          upBreakpoint={'xl'}
          isSidebarOpen={isSidebarOpen}
          sx={{minWidth: 'unset', flex: 'unset'}}
        >
          <IconButton color={'secondary-light'} onClick={handleToggleDialog}>
            <SettingsIcon />
          </IconButton>
        </StyledAdaptiveFieldWrapper>
        <StyledAdaptiveFieldWrapper
          downBreakpoint={'md'}
          isSidebarOpen={isSidebarOpen}
          sx={{minWidth: 'unset', flex: 'unset'}}
        >
          <IconButton color={'secondary-light'} onClick={handleClearAllFilters}>
            <RefreshIcon />
          </IconButton>
        </StyledAdaptiveFieldWrapper>
      </FiltersContainer>
      <FiltersDialog
        filters={filters}
        healthCases={healthCases}
        onUpdateFilters={handleUpdateFilters}
        onClose={handleToggleDialog}
        isOpen={isDialogOpen}
        isSidebarOpen={isSidebarOpen}
      />
    </>
  );
};
