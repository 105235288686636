import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: {
      xs: 18,
      sm: 36,
    },
  },
  link: (t) => ({
    display: 'flex',
    gap: 4,
    svg: {
      p: 0,
      path: {
        fill: t.palette.grey[800],
      },
    },
  }),
  linkText: (t) => ({
    color: t.palette.grey[800],
    typography: '16_20_500',
  }),
};
