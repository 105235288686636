import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  title: {
    typography: {
      xs: '22_26_500',
      sm: '24_34_500',
    },
  },
  text: {
    typography: {
      xs: '12_16_500',
      sm: '14_22_500',
    },
  },
  subtitle: {
    typography: {
      xs: '16_20_700',
      sm: '20_24_700',
    },
  },
  textBlock: (t) => ({
    gap: {
      xs: 18,
      sm: 24,
    },
    width: '100%',
    height: '100%',
    [t.breakpoints.between('sm', 'xl')]: {
      minHeight: 200,
    },
    [t.breakpoints.down('sm')]: {
      minHeight: 150,
    },
  }),
  description: {
    gap: 24,
  },
  subTextBlock: {
    gap: {
      xs: 12,
      sm: 6,
    },
  },
  recomendationsBlock: {
    minHeight: '100%',
    padding: {
      sm: 24,
      xs: 18,
    },
    gap: 5,
  },
  placeholder: {
    typography: {
      sm: '14_22_500',
      xs: '12_16_600',
    },
    color: (t: Theme) => t.palette.grey[600],
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
};
