import {RelationProfile} from '@src/api/relations';
import {ROUTERS_PATH} from '@src/routers';
import {generatePath} from 'react-router-dom';

import {isShowButtonEditProps} from './types';

export const canUserEdit = (relation: RelationProfile | null) => {
  return relation?.accesses?.some((item) => item.access === 'CREATOR_CONTACT' || item.access === 'CONTACT_REPRESENT');
};

export const isShowButtonEdit = ({relation}: isShowButtonEditProps) => {
  return relation && canUserEdit(relation);
};

export const breadCrumbsLink = (id?: string) => {
  return `${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}`;
};

export const getHealthPoliciesRoute = (id?: string) => {
  return generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES, {id: id || ''});
};

export const calcRowInfoSpaceBetween = (isMobile: boolean) => {
  return isMobile ? '8px' : '12px';
};
