import {Switch} from '@mui/material';
import {FormSwitchProps} from '@src/components/FormSwitch/types';
import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';

export const FormSwitch: FC<FormSwitchProps> = ({name, ...restProps}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
  } = useController({
    control: formContext.control,
    name,
  });

  return (
    <Switch
      {...restProps}
      checked={value}
      onChange={onChange}
      name={name}
    />
  );
};
