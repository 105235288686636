import {ReactComponent as StarYellowX24} from '../24x24/star/star-yellow.svg';
import {ReactComponent as StarDefaultX24} from '../24x24/star/star.svg';

export const Star = {
  Default: {
    X24: StarDefaultX24,
  },
  Yellow: {
    X24: StarYellowX24,
  },
};
