export const touchedData = {
  address1: true,
  firstName: true,
  lastName: true,
  birthDate: true,
  birthSex: true,
  city: true,
  state: true,
  postalCode: true,
  ssnTail: true,
};
