import {nanoid} from 'nanoid';

export const generateRefText = ({
  refText,
  refMin,
  refMax,
}: {
  refText?: string | null
  refMin: number | null
  refMax: number | null
}) =>
  refText || ((refMin || refMin === 0) && (refMax || refMax === 0) ? `${refMin} - ${refMax}` : '');

const validClassNamePattern = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

export const generateClassName = (): string => {
  const className = nanoid(5);

  if (validClassNamePattern.test(className)) {
    return className;
  }
  return generateClassName();
};
