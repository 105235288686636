import {ReactComponent as E24} from '../24x24/error.svg';
import {ReactComponent as S24} from '../24x24/successes.svg';
import {ReactComponent as W24} from '../24x24/warning.svg';

export const Alert = {
  Error: {
    E24,
  },
  Warning: {
    W24,
  },
  Successes: {
    S24,
  },
};
