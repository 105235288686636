import {styled, SxProps, Theme} from '@mui/material';
import {initialColors} from '@src/theme';
import {alpha} from '@src/theme/utils';

export const Stripe = styled('div')`
  display: flex;
  justify-content: end;
  align-items: center;
  border-left: none;
  border-right: none;
`;

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    flexGrow: 1,
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    '.tablet-layout &': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 36,
      border: 'unset',
    },
    '.mobile-layout &': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      border: 'unset',
    },
  }),
  topContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 'initial',
    flexGrow: 1,
    padding: 24,
    paddingBottom: 0,
    '.tablet-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: '18px 18px 0px',
      gap: 24,
      flexGrow: 'initial',
    },
    '.mobile-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
      flexGrow: 'initial',
    },
  },
  mobileNavigationPanel: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desktopNavigationPanel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    p: '0px 24px 24px',
  },
  container: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 'initial',
    '.tablet-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
    },
    '.mobile-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
    },
  },
  refreshButton: (t) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '9px',
    backgroundColor: initialColors.grayBackground,
    minWidth: 'unset',
    '&:hover': {backgroundColor: initialColors.grayBackground, 'svg path': {fill: t.palette.secondary.light}},
    '&:active': {'svg path': {fill: 'blue.500'}},
  }),
  radioBtn: {
    p: '9px 36px',
    minWidth: '118px',
    '& .MuiButton-endIcon': {
      width: 24,
      height: 24,
    },
  },
};
