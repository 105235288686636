import {Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    p: 0,
    minHeight: {xs: 'calc(100vh - 102px)', sm: 'calc(100vh - 132px)'},
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: 'none',
  },
  title: {
    mb: 160,
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    mb: 24,
    svg: {
      p: {xs: '0 18px', sm: '0 24px'},
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'end',
    p: 18,
  },
  btn: {
    p: '11px 50px',
  },
};
