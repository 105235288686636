import {Typography} from '@mui/material';
import {ReactComponent as ProfileIcon} from '@src/shared/assets/images/icons/icon_profile.svg';
import {memo} from 'react';

import {AvatarLayout} from './styled';
import {AvatarProps} from './types';

export const Avatar = memo(({src, className, size, square, text, useDefaultIcon = false, children}: AvatarProps) => {
  return (
    <AvatarLayout
      role={'img'}
      useDefaultIcon={useDefaultIcon}
      className={className}
      src={src}
      square={square}
      size={size}>
      {!src && !text && (useDefaultIcon && <ProfileIcon />)}
      {(!src && text) && <Typography typography='12_18_700'>{getFirstWordsLettersFrom(text ?? '')}</Typography>}
      {!src && children}
    </AvatarLayout>
  );
});
Avatar.displayName = 'Avatar';

export const getFirstWordsLettersFrom = (txt: string, amount = 2) => {
  return txt
    .trim()
    .split(' ')
    .filter(Boolean)
    .slice(0, amount)
    .map((w) => w.substring(0, 1))
    .join('')
    .toUpperCase();
};
