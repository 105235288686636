import {Typography, Stack} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Success} from '@src/shared/assets/icons/success.svg';
import {useMQuery} from '@src/shared/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {useModalContentMap} from './hooks';
import {sx} from './styles';
import {ISuccessModal} from './types';

export const SuccessModal = ({successType, email, phone, fullName}: ISuccessModal) => {
  const {t} = useTranslate('connections');

  const {mobile: isMobile, tablet: isTablet} = useMQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState<boolean>(true);

  const closeSuccessModal = () => {
    setIsSuccessModalOpened(false);
    navigate(ROUTERS_PATH.CONNECTIONS_RELATIONS);
    dispatch(relationsActions.setSuccessCreatedContact({successCreatedContact: null} as never));
  };
  const {modalContentMap} = useModalContentMap({email, phone, fullName});

  const modalContent = successType && modalContentMap[successType];

  const modalSize = () => {
    if (isMobile) return 'xs';
    if (isTablet) return '402px';
    return '482px';
  };

  return (
    <Dialog open={isSuccessModalOpened} onClose={closeSuccessModal} size={modalSize()}>
      <DialogTitle width='64px' onClose={closeSuccessModal} noContent={false}>
        <Stack sx={sx.titleWrapper}>
          <Success />
          <Typography variant='18_24_700'>{t('SUCCESS_WITH_EXCLAMATION_MARK')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={sx.dialogContent}>
        <Typography variant="14_18_500">{modalContent}</Typography>
      </DialogContent>
      <DialogActions sx={sx.dialogActions}>
        <Button
          fullWidth={isMobile}
          variant="contained"
          color='white'
          onClick={() => closeSuccessModal()}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
