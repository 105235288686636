export const getBreakpoints = (isSidebarOpen: boolean) => ({
  xxl: 1500,
  xl: isSidebarOpen ? 1105 : 1381,
  lg: isSidebarOpen ? 643 : 1021,
  sm: 700,
  md: 850,
});

export const getDefaultColumns = (isSidebarOpen: boolean) => ({
  xl: isSidebarOpen ? 4 : 3,
  lg: isSidebarOpen ? 6 : 4,
  sm: 6,
  md: 4,
});

export const getCustomColumns = (isSidebarOpen: boolean) => ({
  xl: isSidebarOpen ? 6 : 4,
  lg: isSidebarOpen ? 12 : 6,
  sm: 12,
  md: 6,
});
