import {Box, SxProps, Theme, styled} from '@mui/material';
import {initialColors} from '@src/theme';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  selectedWrapper: {
    alignItems: 'center',
    position: 'relative',
  },
  button: {
    width: '100%',
  },
  createButtonWrapper: (t) => ({
    background: t.colors.all.white,
    borderTop: '1px solid',
    borderColor: t.colors.borderLight,
  }),
  createButton: {
    width: '100%',
    padding: '4px 10px',
    justifyContent: 'flex-start',
    gap: '4px',
  },
  mobileContent: {
    p: 0,
    flex: 1,
    height: '1px',
  },
  buttonContainer: (t) => ({
    padding: 18,
    gap: 12,
    width: '100%',
    borderTop: '1px solid',
    borderColor: t.colors.borderBlueLight,
  }),
  searchInput: (t) => ({
    height: 42,
    svg: {
      color: t.colors.all.grey8,
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'unset !important',
      borderBottom: '1px solid !important',
      borderColor: t.colors.secondary400 + '!important',
    },
    [t.breakpoints.down('sm')]: {
      height: 48,
    },
  }),
  menuList: {
    background: (t) => t.colors.all.white,
    maxHeight: '160px',
    overflowY: 'auto',
    padding: 0,
    flex: 1,
  },
  menuItem: (t) => ({
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
    '&:active': {
      backgroundColor: initialColors.blueLight,
      color: t.colors.secondary,
    },
  }),
  notFound: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    p: 12,
  },
};

export const MenuWrapper = styled(Box)<{ menuWidth: number, menuPosition: number }>(({theme, menuWidth, menuPosition}) => ({
  width: menuWidth,
  backgroundColor: theme.colors.all.white,
  position: 'absolute',
  transform: `translateX(${menuPosition}px)`,
  top: '42px',
  minWidth: 'fit-content',
  zIndex: 1000,
  boxShadow: '-4px 20px 19px 0px rgba(34, 60, 80, 0.14)',
}));
