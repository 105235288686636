export const validateValue = (value?: string | null) => {
  if (!value) return '';

  const numericValue = value.replace(/\D/g, '');


  if (numericValue.length > 5) {
    return `${numericValue.slice(0, 5)}-${numericValue.slice(5, 9)}`;
  }

  return numericValue;
};
