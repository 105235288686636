import {Box, Icon, Stack, Typography} from '@mui/material';
import {HealthCase} from '@src/api/healthCases';
import {getTextValue} from '@src/pages/HealthCases/utils/getTextValue';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as HealthIcon} from '@src/shared/assets/icons/health-cases.svg';
import {DATE_FORMAT, dateToFormat} from '@src/shared/utils';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {sx} from './styles';

export const CardHealthCase: FC<HealthCase> = ({caseDate, description, id, name, objectCount}) => {
  const navigate = useNavigate();

  const handleViewPage = () => {
    navigate(`${ROUTERS_PATH.HEALTH_CASES}/${id}`);
  };

  return (
    <Box key={id} sx={sx.caseWrapper} onClick={handleViewPage}>
      <Icon>
        <HealthIcon color={'grey.700'} />
      </Icon>
      <Stack gap={8} width={'100%'}>
        <Stack flexDirection={'row'}>
          <Typography sx={sx.name} variant="18_24_700">{name}</Typography>
        </Stack>
        <Box sx={sx.descriptionWrapper}>
          <Typography variant="14_18_500" sx={sx.description}>
            {description}
          </Typography>
        </Box>
        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Typography variant='14_18_500' color={'grey.400'}>
            {getTextValue(objectCount, true)}
          </Typography>
          <Typography variant="14_18_500" color={'grey.400'}>
            {dateToFormat(DATE_FORMAT, caseDate || '')}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
