import {DateTime, Settings} from 'luxon';

export const endOfWeek = (dateTime: DateTime) => {
  if (/-us/i.test(Settings.defaultLocale)) {
    if (dateTime.weekday === 7) {
      return dateTime.plus({day: 1}).endOf('week').minus({day: 1});
    }
    return dateTime.endOf('week').minus({day: 1});
  }
  return dateTime.endOf('week');
};
