import {Contact} from '@src/api';
import {GeneralInfoDesktop} from '@src/pages/Overview/fragments/GeneralInfoDesktop';
import {GeneralInfoMobile} from '@src/pages/Overview/fragments/GeneralInfoMobile';
import {Loader} from '@src/pages/Overview/fragments/Loader';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Nullable} from '@src/types/NullableModel';
import {FC} from 'react';

import {GeneralInfoTabletLandscape} from './GeneralInfoTabletLandscape';

export const getInitialContact = (): Nullable<Contact> => ({
  birthDate: '',
  birthSex: null,
  ethnicity: null,
  gender: null,
  race: null,
  ssnTail: '',
  firstName: '',
  lastName: '',
  middleName: '',
  id: '',
  guarantorId: '',
});

export interface GeneralInfoProps {
  contact: Nullable<Contact> | undefined
  avatarUrl: string | undefined
  isSidebarOpen?: boolean
  isLoading?: boolean
}

export const GeneralInfo: FC<GeneralInfoProps> = ({...props}) => {
  const {
    mobile: isMobile,
    tabletPortrait: isTabletPortrait,
    tablet: isTablet,
    lg: isDesktopLg,
    xl: isDesktopXl,
  } = useMQuery();

  if (props.isLoading) {
    return (
      <Loader sx={{height: {xl: 462, sm: 256, xs: 220}}}/>
    );
  }

  if (isMobile || isTabletPortrait) {
    return <GeneralInfoMobile {...props} />;
  }

  if (isTablet || (isDesktopLg && !props.isSidebarOpen) || (isDesktopXl && props.isSidebarOpen)) {
    return <GeneralInfoTabletLandscape {...props} />;
  }

  return <GeneralInfoDesktop {...props} />;
};
