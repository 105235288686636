import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  image: (t) => ({
    maxWidth: '150px',
    maxHeight: '200px',
    backgroundSize: 'contain',
    [t.breakpoints.down('sm')]: {
      maxWidth: '132px',
      maxHeight: '171px',
    },
  }),
  imageContainer: (t) => ({
    display: 'flex',
    width: '150px',
    height: '202px',
    border: '1px solid',
    justifyContent: 'center',
    borderColor: theme => theme.colors.all.grey10,
    position: 'relative',
    cursor: 'pointer',
    ':after': {
      transition: '0.2s',
      content: '""',
      backgroundColor: theme => theme.colors.all.grey6,
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      color: theme => theme.colors.all.grey10,
    },
    ':hover': {
      ':after': {
        opacity: 0.5,
      },
    },
    ':active': {
      ':after': {
        backgroundColor: theme => theme.colors.activeGrayBackground,
        opacity: 0.5,
      },
    },
    [t.breakpoints.down('sm')]: {
      width: '132px',
      height: '173px',
    },
  }),
  name: (t) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    width: '150px',
    [t.breakpoints.down('sm')]: {
      width: '132px',
    },
  }),
  menuItem: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    padding: '0 12px',
  },
  mobileMenuItem: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    padding: '14px 12px',
  },
  svgIcon: {
    width: 18,
    height: 18,
    color: 'grey.700',
  },
  menuOptionBtn: {
    transform: 'rotate(90deg)',
    height: '12px',
  },
  mobileMenuList: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
};
