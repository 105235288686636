import {styled} from '@mui/material';

import {ReactComponent as X24} from '../24x24/settings.svg';
import {IconColorProps} from '../types';

const X24Component = styled(X24)<IconColorProps>(({theme, color = 'white'}) => {
  return {
    '& path': {
      fill: theme.palette.icon[color],
    },
  };
});

export const Settings = {
  X24: X24Component,
};
