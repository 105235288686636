import {Stack, SxProps, Theme} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {FC} from 'react';

const loaderContainer: SxProps<Theme> = {
  alignItems: 'center',
  justifyContent: 'center',
  height: 237,
  padding: {
    xs: 18,
    md: 24,
  },
};

interface LoaderProps {
  sx?: SxProps<Theme>
}

export const Loader: FC<LoaderProps> = ({sx}) => {
  return (
    <Stack sx={{...loaderContainer, ...sx}}>
      <PulseLoader loading />
    </Stack>
  );
};
