/* eslint-disable react-hooks/exhaustive-deps */
import {Rating, Stack, Typography, Box, SxProps, Theme, styled, css as _, IconButton} from '@mui/material';
import {AppointmentFile, AppointmentFull, AppointmentStatus, AppointmentType, UpdateAppointmentErrorType} from '@src/api';
import {Flag, RowInformation} from '@src/components';
import {Avatar} from '@src/components/Avatar';
import {FileUploader} from '@src/components/FileUploader';
import {ListWrapper} from '@src/components/ListWrapper';
import {TextArea} from '@src/components/TextArea';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as AddedFile} from '@src/shared/assets/icons/added-file_sm.svg';
import {ReactComponent as Cancel} from '@src/shared/assets/icons/close-sm.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateFormatted, dateToFormat, getUserInitials, useTrackIntersection} from '@src/shared/utils';
import {alpha} from '@src/theme/utils';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from 'ui-kit';

import {isNotArchivedAppointment} from '../layouts/AppointmentsDesktop';
import {appointmentStatus, appointmentStatusMap} from '../layouts/consts';

import {getName} from '@src/shared/utils/getName';
import {getTime} from '@src/shared/utils/getTime';
import {getPlace} from '@src/shared/utils/getPlace';
import {useUpdateAppointment} from '@src/store/appointments/hooks';
import {spreadSx} from '@src/shared/utils/spreadSx';

import {CancelAppointment} from '../modals/CancelAppointment';
import {LeaveFeedbackModal} from '../modals/LeaveFeedbackModal';
import {RescheduleAppointmentModal} from '../modals/RescheduleAppointmentModal';

import {EditableRowInformation} from './components/EditableRowInformation';

import {CancelModalFlowSteps, RequestNPIOrgFlowSteps, RescheduleAppointmentFlowSteps, UpdateAppointmentFlowSteps, appointmentsActions} from '@src/store/appointments/slice';
import {RootState} from '@src/store';

import {File} from './components/File';

import {useAccount} from '@src/store/account/hooks';

import {FileSize} from './components/FileSize';

import {envs} from '@src/shared/constants/envs';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    width: '30%',
    position: 'sticky',
    top: 0,
    height: 'calc(100vh - 196px)',
  },
  topContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    p: '24px 36px',
  },
  mainContentContainer: {
    overflow: 'scroll',
    p: 36,
  },
  professionalInfoContainer: {
    flexDirection: 'row',
    gap: 12,
  },
  professionalInfoWrapper: {
    justifyContent: 'start',
    gap: 6,
    overflow: 'hidden',
  },
  professionalInfo: {
    flexDirection: 'row',
    gap: 12,
  },
  info: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ratingContainer: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  dateContainer: {
    width: '60%',
    gap: 12,
    overflow: 'hidden',
  },
  descriptionWrapper: {
    justifyContent: 'start',
    gap: 24,
  },
  recommendationWrapper: {
    gap: 24,
    pt: 36,
  },
  filesListContainer: {
    flexDirection: {lg: 'column', xs: 'column', md: 'row', sm: 'row'},
    gap: 8,
    width: {lg: '70%', xs: '100%', md: '100%'},
    flexWrap: 'wrap',
  },
  filesContentWrapper: (t) => ({
    gap: 2,
    color: t.palette.common.white,
    backgroundColor: alpha(t.palette.secondary.main, 63),
    p: '2px 10px',
    width: {lg: '100%', md: '32%', sm: '32%', xs: '100%'},
    minWidth: {lg: 'inherit', md: 'inherit', sm: 'inherit', xs: '100%'},
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    'svg g path': {cursor: 'pointer'},
  }),
  filesContent: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    width: {lg: '65%', md: '55%', sm: '40%', xs: '70%'},
  },
  fileNameWrapper: {
    flexDirection: 'row',
    maxWidth: '90%',
  },
  files: {
    maxWidth: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  fileType: {
    width: '20%',
    ml: '-1px',
  },
  additionalFileContentWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  textAreaDescriptionWrapper: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  formButtonsContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  editFormContainer: {
    gap: {lg: 48, md: 36, sm: 36, xs: 24},
  },
  linkButton: {
    width: 'fit-content',
    padding: 0,
    minWidth: 'initial',
    height: 'initial',
  },
};

export const DesktopAppointmentCard = ({
  appointment,
  setStep,
  setRescheduleAppointmentSteps,
  step,
  rescheduleAppointmentSteps,
  cancelModalStep,
}: {
  appointment: AppointmentFull | null
  setStep: (step: RequestNPIOrgFlowSteps) => void
  setRescheduleAppointmentSteps: (step: RescheduleAppointmentFlowSteps) => void
  step: RequestNPIOrgFlowSteps
  rescheduleAppointmentSteps: RescheduleAppointmentFlowSteps
  cancelModalStep: CancelModalFlowSteps
}) => {
  const {t} = useTranslate('appointments');
  const dispatch = useDispatch();
  const {account} = useAccount();
  const reference = useRef<HTMLDivElement | null>(null);
  const rootElement = useRef<HTMLDivElement | null>(null);
  const {updateAppointment, updateHeight, updateWeight} = useUpdateAppointment();
  const [isShadowVisible, setIsShadowVisible] = useState<boolean>(false);
  const updateAppointmentStep = useSelector((state: RootState) => state.appointments.updateAppointmentStep);
  const isEditLayout = updateAppointmentStep === 'open' || updateAppointmentStep === 'loading';
  const isProfessionalIdEqualAccountId = appointment?.professional?.account.accountId === account?.accountId;
  const isUserCanLeaveFeedback = !appointment?.reviewLeaved && appointment?.status === AppointmentStatus.COMPLETED && !isProfessionalIdEqualAccountId;
  const isDescriptionTitleVisible = !!appointment?.comment?.length || !!appointment?.files?.length;
  const isAddDescriptionButton = !isEditLayout && isNotArchivedAppointment(appointment) && !appointment?.comment?.length;
  const error = useSelector((state: RootState) => state.appointments.updateAppointmentError);

  const setCancelModalStep = (step: CancelModalFlowSteps) => {
    dispatch(appointmentsActions.setCancelModalStep({step}));
  };

  const setIsEditLayout = (step: UpdateAppointmentFlowSteps) => {
    dispatch(appointmentsActions.setUpdateAppointmentStep({step}));
  };

  useEffect(() => {
    dispatch(appointmentsActions.setUpdateAppointmentStep({step: 'init'}));
  }, [appointment]);

  useTrackIntersection({
    reference,
    callBack: setIsShadowVisible,
    rootElement: rootElement.current,
    dependence: appointment,
  });

  if (!appointment) return null;

  return (
    <Stack sx={sx.mainContainer}>
      <Stack
        sx={[
          {
            boxShadow: !isShadowVisible ? '0px 4px 8px -2px rgba(0, 0, 0, 0.10)' : 'none',
          },
          ...spreadSx(sx.topContainer),
        ]}
      >
        <Stack sx={sx.dateContainer}>
          {appointment?.status && (
            <Flag status={appointmentStatusMap[appointment?.status]}>{t(appointmentStatus[appointment.status])}</Flag>
          )}
          <Stack gap={6}>
            <Typography variant="32_36_700" sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {dateToFormat('P', appointment?.startDate)}
            </Typography>
            <Typography variant="22_26_500">{getTime(appointment?.startDate)}</Typography>
          </Stack>
        </Stack>
        {isNotArchivedAppointment(appointment)
          ? (
            <Stack gap={12}>
              {appointment.professional?.active && (
                <Button variant="contained" color="primary" onClick={() => setRescheduleAppointmentSteps('reschedule-modal-open')}>
                  {t('RESCHEDULE')}
                </Button>
              )}
              <Button variant="outlined" color="primary" onClick={() => setCancelModalStep('cancel-modal-open')}>
                {t('CANCEL')}
              </Button>
            </Stack>
          )
          : (isUserCanLeaveFeedback && (
            <Button variant="outlined" onClick={() => setStep('open')}>
              {t('LEAVE_FEEDBACK')}
            </Button>
          )
          )}
      </Stack>
      <Stack sx={sx.mainContentContainer} ref={rootElement}>
        <Stack gap={36}>
          <Stack gap={24}>
            <Stack flexDirection="row">
              <Typography variant="24_34_500" ref={reference}>
                {t('PROFESSIONAL')}
              </Typography>
            </Stack>
            <Stack sx={sx.professionalInfoContainer}>
              <Stack>
                <Avatar size={72} src={appointment?.professional?.account.avatarUrl}>
                  <Typography variant="22_26_400">
                    {getUserInitials(appointment?.professional?.account)}
                  </Typography>
                </Avatar>
              </Stack>
              <Stack sx={sx.professionalInfoWrapper}>
                <Stack sx={sx.ratingContainer}>
                  <Rating
                    value={appointment?.professional?.totalRating}
                    readOnly
                    precision={0.1}
                    size="small"
                  />
                  <Typography
                    component="legend"
                    variant="14_18_500"
                    sx={(t) => ({color: t.colors.all.grey[500]})}
                  >
                    {appointment?.professional?.totalRating}
                  </Typography>
                </Stack>
                <Stack sx={sx.professionalInfo}>
                  <Typography variant="14_18_700" sx={sx.info}>
                    {`${getName(appointment?.professional?.account, {
                      prefix: appointment?.professional?.typePrefix,
                    })},`}
                  </Typography>
                  <Typography variant="14_18_500" sx={sx.info}>
                    {appointment?.professional?.speciality.name}
                  </Typography>
                </Stack>
                <Button
                  component='a'
                  variant="text"
                  color="secondary"
                  sx={sx.linkButton}
                  href={`${envs.BOOKING_WEB_URL}/professionals/${appointment?.professional?.account?.accountId || ''
                  }?tab=reviews`}
                  target='_blank'
                >
                  {t('REVIEWS')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={24}>
            <Typography variant="24_34_500">{t('APPOINTMENT_DETAILS')}</Typography>
            <ListWrapper noLast={{xl: 0, lg: 0, xs: 0}} padding="16px">
              <RowInformation
                noMargin
                tooltip
                name={t('TYPE')}
                value={t(appointment?.type)} />
              <RowInformation
                noMargin
                name={t('DATE_AND_TIME')}
                value={`${dateToFormat('P', appointment?.startDate)}, ${getTime(
                  appointment?.startDate,
                )}`}
                tooltip
              />
              {appointment.type === AppointmentType.IN_PERSON && (
                <RowInformation
                  noMargin
                  name={t('PLACE')}
                  value={getPlace(appointment?.address)}
                  tooltip
                />
              )}
            </ListWrapper>
          </Stack>
          <Stack gap={24}>
            <Typography variant="24_34_500">{t('PATIENT_INFO')}</Typography>
            <ListWrapper noLast={{xl: 0, lg: 0, xs: 0}} padding="16px">
              <RowInformation noMargin name={t('NAME')} value={getName(appointment?.patient) || getName(appointment?.anonymousPatient)} />
              <RowInformation
                noMargin
                name={t('DATE_OF_BIRTH')}
                value={dateFormatted(appointment?.patient?.birthDateS || appointment.anonymousPatient?.birthDate)}
              />
              <RowInformation
                noMargin
                name={t('BIRTH_SEX')}
                value={t(appointment?.patient?.birthSex || appointment.anonymousPatient?.birthSex)}
              />
              {isNotArchivedAppointment(appointment)
                ? (
                  <>
                    <EditableRowInformation
                      initialValue={appointment?.weight ?? 0}
                      name={t('WEIGHT')}
                      onChange={updateWeight}
                    />
                    <EditableRowInformation
                      initialValue={appointment?.height ?? 0}
                      name={t('HEIGHT')}
                      onChange={updateHeight}
                    />
                  </>
                )
                : (
                  <>
                    <RowInformation
                      noMargin
                      name={t('WEIGHT')}
                      value={`${appointment?.weight ?? ''} ${t('LB')}`}
                    />
                    <RowInformation
                      noMargin
                      name={t('HEIGHT')}
                      value={`${appointment.height ?? ''} ${t('FT')}`}
                    />
                  </>
                )}
            </ListWrapper>
          </Stack>
        </Stack>
        <Stack sx={[{pt: isAddDescriptionButton ? 0 : 36}, ...spreadSx(sx.descriptionWrapper)]}>
          {isDescriptionTitleVisible && <Typography variant="24_34_500">{t('DESCRIPTION_OF_THE_PROBLEM')}</Typography>}
          {isEditLayout && (
            <EditAppointmentForm
              errors={error}
              updateAppointmentStep={updateAppointmentStep}
              appointment={appointment}
              setIsEditLayout={setIsEditLayout}
              updateAppointment={updateAppointment}
            />
          )}
          {!isEditLayout && (
            <Stack gap={24}>
              <Typography variant="14_18_500">{appointment?.comment}</Typography>
              <Stack sx={sx.filesListContainer}>
                {appointment?.files?.map((file) => (
                  <Box key={file?.id} sx={sx.filesContentWrapper}>
                    <Stack sx={[{width: '90%'}, ...spreadSx(sx.filesContent)]}>
                      <Stack alignItems='center'>
                        <AddedFile />
                      </Stack>
                      <Stack sx={sx.fileNameWrapper}>
                        <Typography variant="12_16_500" sx={sx.files}>
                          {file?.name.slice(0, file?.name.length - 4)}
                        </Typography>
                        <Typography variant="12_16_500" sx={sx.fileType}>
                          {file?.name.slice(file?.name.length - 4)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <FileSize size={file?.size} />
                  </Box>
                ))}
              </Stack>
            </Stack>
          )}
          {!isEditLayout && isNotArchivedAppointment(appointment) && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  dispatch(appointmentsActions.setUpdateAppointmentStep({step: 'open'}));
                }}
              >
                {appointment?.comment?.length || appointment.files?.length ? t('EDIT') : t('ADD_DESCRIPTION')}
              </Button>
            </>
          )}
        </Stack>
        {!isNotArchivedAppointment(appointment) && appointment?.recommendation?.length && (
          <Stack sx={sx.recommendationWrapper}>
            <Typography variant="24_34_500">{t('RECOMMENDATIONS')}</Typography>
            <Typography variant="14_18_500">{appointment?.recommendation}</Typography>
          </Stack>
        )}
      </Stack>
      <RescheduleAppointmentModal step={rescheduleAppointmentSteps} setStep={setRescheduleAppointmentSteps} appointment={appointment} />
      <CancelAppointment step={cancelModalStep} setStep={setCancelModalStep} appointment={appointment} />
      <LeaveFeedbackModal step={step} setStep={setStep} appointment={appointment} />
    </Stack>
  );
};

export const EditAppointmentForm = ({
  errors,
  updateAppointmentStep,
  appointment,
  setIsEditLayout,
  updateAppointment,
}: {
  updateAppointmentStep: UpdateAppointmentFlowSteps
  errors: UpdateAppointmentErrorType[] | null
  appointment: AppointmentFull
  setIsEditLayout: (step: UpdateAppointmentFlowSteps) => void
  updateAppointment: ({
    id,
    deletedFileIds,
    description,
    height,
    weight,
    files,
    oldFiles,
  }: {
    id: string | undefined
    deletedFileIds: string[] | null
    description: string | null
    height: number | null
    weight: number | null
    files: File[]
    oldFiles: AppointmentFile[] | null
  }) => void
}) => {
  const {t} = useTranslate('appointments');
  const {mobile} = useMQuery();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const {weight, height, files: appointmentFiles, comment: description, id} = appointment;
  const [files, setFiles] = useState<AppointmentFile[] | null>(appointmentFiles || null);

  const initialValues = {
    id,
    description: description || null,
    oldFiles: appointmentFiles || [],
    files: [] as File[],
    deletedFileIds: [],
    weight: weight || null,
    height: height || null,
  };

  const deleteFile = (canceledFile: File | AppointmentFile) => {
    if (files) {
      const filteredFiles = files.filter((file) => file.name !== canceledFile.name);
      setFiles(filteredFiles);
      dispatch(appointmentsActions.setUpdateAppointmentError({updateAppointmentError: null}));
    }
  };

  const errorNameMap = {
    MAX_UPLOAD_SIZE_EXCEEDED: 'ALLOWED_ATTACHMENT_SIZE_EXCEEDED',
    FILE_TYPE_UNSUPPORTED: 'INVALID_FILE_TYPE',
    MAX_UPLOAD_SIZE_EXCEEDED_FILE_TYPE_UNSUPPORTED: 'ALLOWED_ATTACHMENT_SIZE_EXCEEDED_INVALID_FILE_TYPE',
    FILE_TYPE_UNSUPPORTED_MAX_UPLOAD_SIZE_EXCEEDED: 'ALLOWED_ATTACHMENT_SIZE_EXCEEDED_INVALID_FILE_TYPE',
  };

  const getErrorAppointment = (errors: UpdateAppointmentErrorType[] | null) => {
    if (!errors) return '';
    const errorName: string[] = [];
    for (const item of errors) {
      if (!errorName.includes(item.error)) {
        errorName.push(item.error);
      }
    };
    return errorName.length > 1 ? errorNameMap.MAX_UPLOAD_SIZE_EXCEEDED_FILE_TYPE_UNSUPPORTED : errorNameMap[errorName[0] as keyof typeof errorNameMap];
  };

  useEffect(() => {
    setError(t(getErrorAppointment(errors) as TKeys<'appointments'>));
  }, [errors]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        updateAppointment(values);
      }}
    >
      {({values, handleChange, setFieldValue}) => (
        <Form>
          <Stack sx={sx.editFormContainer}>
            <Stack gap={24}>
              <Stack gap={2}>
                <TextArea
                  height={182}
                  name="description"
                  value={values.description || ''}
                  maxLength={500}
                  placeholder={t('PLEASE_DESCRIBE_YOUR_PROBLEM')}
                  onChange={handleChange}
                />
                <Stack sx={sx.textAreaDescriptionWrapper}>
                  <Typography variant="12_16_500">
                    {t('MAXIMUM_SIZE_PER_ATTACHMENT_SIZE_MB_')}
                  </Typography>
                  <Typography variant="12_16_500">{`${values?.description?.length || 0
                  }/500`}</Typography>
                </Stack>
              </Stack>
              <Stack gap={4}>
                <Stack sx={sx.filesListContainer}>
                  {values.files.length
                    ? (
                      values.files.map((file: File, index) => (
                        <File
                          key={file.name}
                          errors={errors}
                          file={file}
                          index={index}
                          onCancel={() => {
                            handleChange({
                              target: {
                                name: 'files',
                                value: values.files.filter((item: File) => item.name !== file.name),
                              },
                            });
                            deleteFile(file);
                          }} />
                      ))
                    )
                    : null}
                  {values.oldFiles.length
                    ? (
                      values.oldFiles.map((file: AppointmentFile) => (
                        <Box key={file?.id} sx={sx.filesContentWrapper}>
                          <Stack sx={sx.filesContent}>
                            <Stack alignItems='center'>
                              <AddedFile />
                            </Stack>
                            <Stack sx={sx.fileNameWrapper}>
                              <Typography variant="12_16_500" sx={sx.files}>
                                {file?.name.slice(0, file?.name.length - 4)}
                              </Typography>
                              <Typography variant="12_16_500" sx={sx.fileType}>
                                {file?.name.slice(file?.name.length - 4)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack sx={sx.additionalFileContentWrapper}>
                            <FileSize size={file?.size} />
                            <IconButton
                              sx={{margin: '-8px'}}
                              onClick={() => {
                                handleChange({
                                  target: {
                                    name: 'oldFiles',
                                    value: values.oldFiles.filter((item: AppointmentFile) => item.name !== file.name),
                                  },
                                });
                                if (file.id) {
                                  setFieldValue('deletedFileIds', [...values.deletedFileIds, file.id]);
                                }
                                deleteFile(file);
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Stack>
                        </Box>
                      ))
                    )
                    : null}
                </Stack>
                <Stack>
                  <Typography variant='12_16_500' sx={(t) => ({color: t.palette.primary.light})}>{error}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={sx.formButtonsContainer}>
              <Button
                variant={mobile ? 'text' : 'outlined'}
                color={mobile ? 'primary' : 'secondary'}
                sx={{paddingLeft: {xs: 0, md: 12, sm: 12}}}
                onClick={() => {
                  setIsEditLayout('init');
                  dispatch(appointmentsActions.setUpdateAppointmentError({updateAppointmentError: null}));
                }}
                disabled={updateAppointmentStep === 'loading'}
              >
                {t('CANCEL')}
              </Button>
              <Stack sx={{flexDirection: 'row', gap: 12}}>
                <FileUploader<typeof values> values={values} handleChange={handleChange} />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={updateAppointmentStep === 'loading'}>
                  {t('SAVE')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export const CancelIcon = styled(Cancel)(
  ({theme}) => _`
  path {
    fill: ${theme.colors.all.white};
  }
`,
);
