import {Box, Stack, Theme, Typography} from '@mui/material';
import {BirthSex} from '@src/api';
import {RequestRelationType} from '@src/api/relations';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {RadioButtons} from '@src/components/RadioButtons';
import {DateControl} from '@src/components/form/DateControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Information} from '@src/shared/assets/icons/information.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat} from '@src/shared/utils';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {RootState} from '@src/store';
import {useAccountForm} from '@src/store/account/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Formik, Form} from 'formik';
import lodash from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from 'ui-kit';

import {AGE_OF_MAJORITY} from './constants';
import {sx} from './styles';
import {ISelectParentRolDialog} from './types';
import {getValidationSchema} from './validationSchema';

export const SelectParentRolDialog = ({
  isModalOpened,
  closeModal,
  relationType,
  setFieldValue,
  birthDate,
  birthSex,
}: ISelectParentRolDialog) => {
  const {t} = useTranslate('connections');

  const {mobile: isMobile, tablet: isTablet, desktop: isDesktop} = useMQuery();

  const {update} = useAccountForm();

  const dispatch = useDispatch();
  const step = useSelector((state: RootState) => state.relations.step);

  const relationshipTypes = [RequestRelationType.SPO, RequestRelationType.HUSB, RequestRelationType.WIFE];

  const initialValues = {
    newBirthDate: null,
    newBirthSex: null,
  };

  const shouldShowDateControl = !birthDate;
  const showRadioBth = relationType === RequestRelationType.CHD && birthSex === BirthSex.Unknown;

  const saveData = ({newBirthDate, newBirthSex}: {newBirthDate: string | null, newBirthSex: 'FEMALE' | 'MALE' | null}) => {
    const data = {
      birthDate: newBirthDate
        ? {
          value: newBirthDate,
        }
        : null,
      birthSex: newBirthSex
        ? {
          value: newBirthSex,
        }
        : null,
    };
    update({data});
  };

  const handleCloseModal = () => {
    setFieldValue('relationShipType', null);
    setFieldValue('relationType', null);
    closeModal();
  };

  const handleApplyBthClick = (handleSubmit: () => void) => {
    handleSubmit();
  };

  const dialogModalSize = () => {
    if (isMobile) return 'xs';
    if (isDesktop) return 'md';
    if (isTablet) return 'sm';
  };

  return (
    <Formik<typeof initialValues>
      enableReinitialize
      onSubmit={(values) => {
        dispatch(relationsActions.setStep({step: 'loading'}));
        if (!lodash.isEqual(initialValues, values)) saveData(values);
        closeModal();
      }}
      initialValues={initialValues}
      validationSchema={getValidationSchema({
        t,
        relationshipTypes,
        relationType,
        birthDate,
        birthSex,
        ageOfMajority: AGE_OF_MAJORITY,
      })}
    >
      {({handleChange, values, errors, touched, handleSubmit, handleBlur}) => {
        const hasErrors = !!errors.newBirthDate;
        const isErrorEnterAValidDateOfBirth = errors.newBirthDate === t('ENTER_A_VALID_DATE_OF_BIRTH');

        return (
          <Form>
            <Dialog
              border={(t: Theme) => t.palette.secondary._14}
              open={isModalOpened}
              onClose={handleCloseModal}
              size={dialogModalSize()}
            >
              <DialogTitle
                width='66px'
                sx={sx.dialogTitleContainer}
                onClose={handleCloseModal}
                noContent={false}
              >
                <Typography variant="18_24_700">
                  {t('EDIT_INFORMATION')}
                </Typography>
              </DialogTitle>
              <DialogContent
                sx={sx.dialogContentWrapper}
              >
                <Stack
                  sx={[
                    ...spreadSx(sx.dialogContentInfo),
                    {
                      mb: shouldShowDateControl
                        ? {xs: 24, sm: 32}
                        : {xs: 32, sm: 24},
                    }]}>
                  <Box sx={sx.informationIconWrapper}>
                    <Information />
                  </Box>
                  <Typography variant="12_16_700">
                    {t('FILL_IN_INFORMATION')}
                  </Typography>
                </Stack>
                <Stack gap={isErrorEnterAValidDateOfBirth ? {xs: 44, lg: 36} : 36}>
                  {shouldShowDateControl && (
                    <DateControl
                      errorTextPositionRelative={shouldShowDateControl && !showRadioBth}
                      errorTextStyles={sx.errorText}
                      minWidth={288}
                      labelTop={isMobile}
                      labelLeft={!isMobile}
                      placeholder='mm/dd/yyyy'
                      disabledFuture
                      name="newBirthDate"
                      value={dateToFormat('P', values.newBirthDate) || ''}
                      onChange={handleChange}
                      label={t('DATE_OF_BIRTH')}
                      hideTip={false}
                      error={errors.newBirthDate}
                      sx={sx.dateControl}
                    />
                  )}
                  {showRadioBth && (
                    <RadioButtons
                      gapNumber={isMobile ? 12 : 24}
                      stylesSx={sx.radioBths}
                      label={t('PARENTAL_ROLE')}
                      name="newBirthSex"
                      error={touched.newBirthSex && errors.newBirthSex}
                      value={values.newBirthSex}
                      handleChange={handleChange}
                      onTouched={handleBlur}
                      firstButtonData={{value: BirthSex.Male, label: t('FATHER')}}
                      secondaryButtonData={{value: BirthSex.Female, label: t('MOTHER')}}
                    />
                  )}
                </Stack>
              </DialogContent>
              <DialogActions
                sx={
                  [...spreadSx(sx.dialogActionsWrapper),
                    {
                      m: shouldShowDateControl && !showRadioBth
                        ? {xs: '12px 18px 18px 18px', sm: '18px 24px 24px 24px', lg: 24}
                        : {xs: '28px 18px 18px 18px', sm: '32px 24px 24px 24px'},
                    },
                  ]}>
                <Button
                  fullWidth={isMobile}
                  variant="outlined"
                  color="secondary"
                  disabled={step === 'loading'}
                  onClick={handleCloseModal}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  color="secondary"
                  disabled={step === 'loading' || hasErrors}
                  onClick={() => handleApplyBthClick(handleSubmit)}
                >
                  {t('APPLY')}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};
