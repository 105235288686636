import {sortInsurances} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {useAccountProfile} from '@src/store/account/hooks';
import {useResults} from '@src/store/results/hooks';
import {useMemo} from 'react';

import {OverviewDesktop} from './OverviewDesktop';
import {OverviewDesktopLg} from './OverviewDesktopLg';
import {OverviewDesktopXl} from './OverviewDesktopXl';
import {OverviewMobile} from './OverviewMobile';
import {OverviewTabletLandscape} from './OverviewTabletLandscape';

export const Overview = () => {
  const {mobile: isMobile, tabletPortrait: isTabletPortrait, tablet: isTablet, lg: isDesktopLg, xl: isDesktopXl} = useMQuery();
  const {accountProfile, fetching: isAccountLoading} = useAccountProfile();
  const {data, isLoading: isResultsLoading} = useResults();
  const {isSidebarOpen} = useSidebarClassObserver();

  const activeInsurances = useMemo(() => {
    const sorted = sortInsurances(accountProfile?.healthcareInsurancePolicies || []);
    return sorted?.filter((insurance) => compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null) || [];
  }, [accountProfile?.healthcareInsurancePolicies]);

  if (isMobile || isTabletPortrait) {
    return (
      <OverviewMobile
        accountProfile={accountProfile}
        isAccountLoading={isAccountLoading}
        data={data}
        isResultsLoading={isResultsLoading}
        insurances={activeInsurances} />
    );
  }

  if (isTablet) {
    return (
      <OverviewTabletLandscape
        accountProfile={accountProfile}
        isAccountLoading={isAccountLoading}
        data={data}
        isResultsLoading={isResultsLoading}
        insurances={activeInsurances} />
    );
  }

  if (isDesktopLg) {
    return (
      <OverviewDesktopLg
        accountProfile={accountProfile}
        isAccountLoading={isAccountLoading}
        data={data}
        isResultsLoading={isResultsLoading}
        isSidebarOpen={isSidebarOpen}
        insurances={activeInsurances}
      />
    );
  }

  if (isDesktopXl) {
    return (
      <OverviewDesktopXl
        accountProfile={accountProfile}
        isAccountLoading={isAccountLoading}
        data={data}
        isResultsLoading={isResultsLoading}
        isSidebarOpen={isSidebarOpen}
        insurances={activeInsurances}
      />
    );
  }

  return (
    <OverviewDesktop
      accountProfile={accountProfile}
      isAccountLoading={isAccountLoading}
      data={data}
      isResultsLoading={isResultsLoading}
      insurances={activeInsurances}
    />
  );
};
