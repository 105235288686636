import {Stack, Typography, Box} from '@mui/material';
import {Loader} from '@src/pages/Overview/fragments/Loader';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {OverviewEmptyFragmentProps} from './types';

export const OverviewEmptyFragment: FC<OverviewEmptyFragmentProps> = ({
  title,
  button,
  description,
  onClick,
  isDisabled = true,
  variant = '12_16_500',
  isLoading,
}) => {
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();

  if (isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <Stack sx={sx.container}>
      <Box sx={sx.topContainer}>
        <Typography component="h3" variant={isMobile ? '22_26_500' : '24_34_500'}>
          {title}
        </Typography>
        {button
          ? (
            <Button
              sx={sx.btn}
              variant={isDesktop ? 'outlined' : 'text'}
              color="secondary"
              size={isDesktop ? 'sm' : 'xs'}
              disabled={isDisabled}
              onClick={onClick}
              startIcon={<AddIcon/>}
            >
              {button}
            </Button>
          )
          : null}
      </Box>
      <Typography
        sx={sx.description}
        variant={variant}
      >
        {description}
      </Typography>
    </Stack>
  );
};
