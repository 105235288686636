export interface GapConditions {
  birthDate: string | null
  isMobile: boolean
}

export const calculateGap = ({birthDate, isMobile}: GapConditions) => {
  if (isMobile && birthDate) {
    return 10;
  }
  if (birthDate) {
    return 12;
  }
  return 30;
};
