import {apiClient} from 'client/ApiClient';

import {BMIDataType, BMIRecommendation, BMIRecommendationPayload} from './models';

export const getBmiData = async () => {
  const response = await apiClient.get<BMIDataType[]>('/api/bmi');
  return response;
};

export const createBmi = async ({height, weight}: {height: number, weight: number}) => {
  const response = await apiClient.post('/api/bmi/create', {height, weight});
  return response;
};

export const getRecomendation = async ({birthDate, birthSex, bmi}: BMIRecommendationPayload) => {
  const response = await apiClient.get<BMIRecommendation>('/api/bmi/recommendation', {params: {birthDate, birthSex, bmi}});
  return response;
};

export * as bmiApi from './';
export * from './models';
