import {styled} from '@mui/material';
import {ReactComponent as FileIcon} from '@src/shared/assets/icons/file.svg';
import {ChangeEvent} from 'react';
import {Button} from 'ui-kit';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const FileUploader = <T extends { files?: File[] }>({
  values,
  handleChange,
}: {
  values: T
  handleChange: {
    (e: ChangeEvent<any>): void
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? any
      : (e: string | ChangeEvent<any>) => void
  }
}) => {
  return (
    <Button
      component="label"
      variant="outlined"
      color="secondary"
      sx={{minWidth: 42, p: 9}}
      onClick={(e) => e.stopPropagation()}
    >
      <FileIcon />
      <VisuallyHiddenInput
        type="file"
        name="files"
        multiple
        onChange={(e) => {
          if (e.target.files?.length && values.files) {
            const fileName = e.target.files[0].name;
            if (!values?.files.find((item: File) => item.name === fileName)) {
              handleChange({
                target: {
                  name: 'files',
                  value: [...values.files, ...Array.from(e.target.files ?? [])],
                },
              });
            }
          }
        }}
      />
    </Button>
  );
};
