import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  tooltipContainer: {
    background: (theme) => theme.colors.defaultTextColor,
    padding: '6px 12px 6px 12px',
    gap: 6,
    boxShadow: '2px 2px 6px 1px #0000001A, -2px -2px 6px 1px #0000001A',
  },
  closeButton: {
    svg: {
      path: {
        stroke: (theme) => theme.colors.all.grey10,
      },
    },
  },
  text: {
    color: (theme) => theme.colors.all.white,
  },
  graphWrapper: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    scrollbarWidth: 'initial',
    '-ms-overflow-style': 'initial',
    '::-webkit-scrollbar': {
      display: 'block',
    },
  },
};
