import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css';
import './css/styles.scss';
import {ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useMQuery} from '@src/shared/hooks';
import {store} from '@store';
import {ToastContainer} from 'components/Toast/ToastContainer';
import {createBrowserHistory} from 'history';
import {Settings} from 'luxon';
import {useLayoutEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import {UiKitThemeProvider} from 'ui-kit';

import {App} from './App';
import {GoogleLoaderProvider} from './components/Google/GoogleLoaderProvider';
import {CookieDialog} from './features/cookies/CookieDialog';
import {useTranslate} from './i18n/useTranslate';
import {muiTheme} from './theme/theme';
import './scripts';

export const browserHistory = createBrowserHistory();

const AppContainer: React.FC = () => {
  const {mobile, tablet} = useMQuery();
  const {i18n} = useTranslate('common');

  Settings.defaultLocale = i18n.language;
  useLayoutEffect(() => {
    if (mobile) {
      window.document.body.classList.add('mobile-layout');
      window.document.body.classList.remove('tablet-layout');
    } else if (tablet) {
      window.document.body.classList.add('tablet-layout');
      window.document.body.classList.remove('mobile-layout');
    } else {
      window.document.body.classList.remove('mobile-layout');
      window.document.body.classList.remove('tablet-layout');
    }
  }, [mobile, tablet]);

  return (
    <ReduxProvider store={store}>
      <HistoryRouter history={browserHistory as any}>
        <ThemeProvider theme={muiTheme}>
          <UiKitThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <GoogleLoaderProvider>
                <App />
              </GoogleLoaderProvider>
              <CookieDialog />
            </LocalizationProvider>
            <ToastContainer />
          </UiKitThemeProvider>
        </ThemeProvider>
      </HistoryRouter>
    </ReduxProvider>
  );
};

createRoot(document.querySelector('#root') as Element).render(<AppContainer />);
