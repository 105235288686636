import {Theme, SxProps} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  tableContainer: (t) => ({
    borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
    thead: {
      tr: {
        height: 52,
        th: {height: 52},
      },
    },
    tr: {
      gap: 24,
      borderBottom: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
      th: {
        borderBottom: 'none',
        padding: '13px 0',
        display: 'flex',
        alignItems: 'center',
      },
      td: {
        borderBottom: 'none',
        padding: '12px 0',
        display: 'flex',
        alignItems: 'center',
        height: 64,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        minWidth: 100,
      },
    },
  }),
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 220px',
    padding: '0 24px 0 24px',
  },
  tablePairsSwitch: {
    pl: 9,
    pr: 9,
    width: 42,
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0 24px 24px 24px',
    flexGrow: 1,
  },
  paginationContent: {
    '.MuiInputBase-root': {display: 'none'},
    '.MuiTablePagination-displayedRows': {display: 'none'},
    border: 'none',
    display: 'flex',
    alignItems: 'end',
  },
  requestAvatar: (t) => ({
    width: 40,
    height: 40,
    backgroundColor: t.palette.secondary._21,
    color: t.palette.common.white,
  }),
  personType: {
    minHeight: 20,
    maxWidth: 232,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  personEmail: {
    display: 'inline-block',
    minWidth: 30,
    maxWidth: 190,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  flag: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    flexShrink: 0,
    ml: 9,
  },
  patientNotFound: (t) => ({
    mt: 12,
    color: t.palette.grey[500],
    pl: 24,
  }),
  patientColContainer: (t) => ({
    svg: {
      path: {
        fill: t.palette.grey[400],
      },
    },
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    overflow: 'hidden',
  }),
};
