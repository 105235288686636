import {Stack, Typography, Box} from '@mui/material';
import {ReactComponent as AttentionIcon} from '@src/shared/assets/icons/attention-icon.svg';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {EmptyFragmentProps} from './types';

export const EmptyFragment = ({
  title,
  button,
  description,
  isButtonIcon,
  noPaddings,
  descriptionStyles,
  icon,
  hasDescriptionIcon,
  titleStyles,
  boxContainerStyles,
}: EmptyFragmentProps) => {
  return (
    <Stack sx={{padding: noPaddings ? 0 : '26px 24px 0 24px'}}>
      <Box sx={[...spreadSx(sx.topContainer), ...spreadSx(boxContainerStyles)]}>
        <Typography component='h3' sx={[...spreadSx(sx.title), ...spreadSx(titleStyles)]}>{title}</Typography>
        {button
          ? (
            <Button
              variant='outlined'
              color='secondary'
              size='sm'
              disabled
              startIcon={isButtonIcon ? <Add /> : null}>{button}</Button>
          )
          : null}
      </Box>
      {hasDescriptionIcon
        ? (
          <Box sx={sx.boxContainer}>
            {icon ? <AttentionIcon/> : icon}
            <Typography sx={descriptionStyles} variant='12_18_500'>{description}</Typography>
          </Box>
        )
        : <Typography sx={descriptionStyles} variant='12_18_500'>{description}</Typography>
      }
    </Stack>
  );
};
