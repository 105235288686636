import {BMIBreakPoints, WeightTitles} from '../constants/BMIconstants';

// NOTE uses for calculate BMI value
const BMIConst = 703;

export const getBmi = (weight: number, height: number) => weight / (height * height) * BMIConst;

export const getWeightTitleFromBmi = (bmi: number) => {
  if (bmi < BMIBreakPoints[WeightTitles.UNDER_WEIGHT]) {
    return WeightTitles.UNDER_WEIGHT;
  }
  if (bmi >= BMIBreakPoints[WeightTitles.UNDER_WEIGHT] && bmi < BMIBreakPoints[WeightTitles.NORMAL_WEIGHT]) {
    return WeightTitles.NORMAL_WEIGHT;
  }
  if (bmi >= BMIBreakPoints[WeightTitles.NORMAL_WEIGHT] && bmi < BMIBreakPoints[WeightTitles.OVERWEIGHT]) {
    return WeightTitles.OVERWEIGHT;
  }
  if (bmi >= BMIBreakPoints[WeightTitles.OVERWEIGHT] && bmi < BMIBreakPoints[WeightTitles.OBESITY]) {
    return WeightTitles.OBESITY;
  }
  if (bmi >= BMIBreakPoints[WeightTitles.OBESITY]) {
    return WeightTitles.OBESITY;
  }
  return WeightTitles.NORMAL_WEIGHT;
};

export const getWeightTitle = (weight: number, height: number) => {
  const currentBMI = getBmi(weight, height);
  return getWeightTitleFromBmi(currentBMI);
};
