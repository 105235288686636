import {asyncThunkCreator, buildCreateSlice} from '@reduxjs/toolkit';

import {GlobalNotificationsState} from './types';

export const createSlice = buildCreateSlice({
  creators: {asyncThunk: asyncThunkCreator},
});

export const initialStateData: GlobalNotificationsState = {
  hasUnreadNotification: false,
  notifications: [],
  isError: false,
  isNext: true,
  isLoading: false,
};
