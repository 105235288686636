import {SxProps, Theme} from '@mui/material';
import {ReactComponent as IconSVGTimes} from '@src/shared/assets/images/icons/icon_times.svg';
import {memo, PropsWithChildren} from 'react';

import {StyledDialog} from './StyledDialog';
import {StyledDialogActions} from './StyledDialogActions';
import {StyledDialogTitle, StyledButton, StyledDialogContent} from './styles';
// TODO make it not to affect on scrollbar of the app

export const Dialog = StyledDialog;

interface IDialogTitleProps {
  onClose?: () => void
  className?: string
  noContent?: boolean
  sx?: SxProps<Theme>
  width?: string
}
export const DialogTitle = memo(({onClose, children, className, noContent, sx, width}: PropsWithChildren<IDialogTitleProps>) => {
  return (
    <StyledDialogTitle className={className} sx={sx}>
      {children}
      {!noContent && (
        <StyledButton width={width} type="button" onClick={onClose}>
          <IconSVGTimes />
        </StyledButton>
      )}
    </StyledDialogTitle>
  );
});
DialogTitle.displayName = 'DialogTitle';
export const DialogContent = StyledDialogContent;
export const DialogActions = StyledDialogActions;
