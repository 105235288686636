import {Stack, Typography} from '@mui/material';

import {sx} from './/styles';
import {ViewGlobalNotificationProps} from './types';

export const ViewGlobalNotification = ({notification}: ViewGlobalNotificationProps) => {
  return (
    <Stack sx={sx.container}>
      <Typography sx={sx.subject}>{notification?.subject}</Typography>
      <Typography sx={sx.description}>{notification?.description}</Typography>
    </Stack>
  );
};
