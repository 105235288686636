import {ISODate, RelationshipTypes} from '@src/types';
import {Nullable} from '@src/types/NullableModel';

import {EthnicityType, SexualOrientationType, GenderType, RaceType} from '../accesses';

export interface Account {
  phone: string
  email: string
  contact: Contact
}

export interface Dashboard {
  dashboardInterval: string
  role: 'Clinical'
  criticalResults: number
  unreadResults: number
  readResults: number
  draftOrders: number
  inWorkOrders: number
  completedOrders: number
}

export interface Contact {
  birthDate: ISODate
  birthSex?: BirthSex
  ethnicity?: Ethnicity
  firstName: string
  gender?: Gender
  guarantorId: string
  id: string
  lastName: string
  middleName?: string
  race?: Race
  sexualOrientation?: SexualOrientation
  ssnTail: string
}

export interface RelationContact {
  birthDate: string
  firstName: string
  id: string
  lastName: string
  middleName?: string
}

export interface AccountDetails {
  accountId?: string
  avatarUrl: string
  birthDate: string
  birthDateS: string
  birthSex: BirthSex
  ethnicity: EthnicityType
  firstName: string
  gender: GenderType
  guarantorId: string
  id: string
  lastName: string
  middleName: string
  race: RaceType
  sexualOrientation: SexualOrientationType
  ssnTail: string
  email: string
  phone: string
}

export enum BirthSex {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN',
}

export enum Ethnicity {
  HispanicOrLatino = 'HISPANIC_OR_LATINO',
  NotHispanicOrLatino = 'NOT_HISPANIC_OR_LATINO',
  Oth = 'OTH',
  Unk = 'UNK',
  Asku = 'ASKU'
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  FemaleToMale = 'FEMALE_TO_MALE',
  MaleToFemale = 'MALE_TO_FEMALE',
  Genderqueer = 'GENDERQUEER',
  Oth = 'OTH',
  Asku = 'ASKU'
}

export enum SexualOrientationMap {
  LESBIAN_GAY_HOMOSEXUAL = 'LESBIAN_GAY_HOMOSEXUAL',
  STRAIGHT_HETEROSEXUAL = 'STRAIGHT_HETEROSEXUAL',
  BISEXUAL = 'BISEXUAL',
  SOMETHING_ELSE_PLEASE_DESCRIBE = 'OTH',
  DONT_KNOW = 'UNK',
  CHOOSE_NOT_TO_DISCLOSE = 'ASKU'
}

export enum Race {
  AmericanIndianOrAlaskaNative = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  Asian = 'ASIAN',
  BlackOrAfricanAmerican = 'BLACK_OR_AFRICAN_AMERICAN',
  NativeHawaiianOrOtherPacificIslander = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  White = 'WHITE',
  Oth = 'OTHER',
  Asku = 'ASKU',
  Unknown = 'UNKNOWN'
}

export enum SexualOrientation {
  Asku = 'ASKU',
  Bisexual = 'BISEXUAL',
  LesbianGayHomosexual = 'LESBIAN_GAY_HOMOSEXUAL',
  Oth = 'OTH',
  StraightHeterosexual = 'STRAIGHT_HETEROSEXUAL',
  Unk = 'UNK',
}

export interface ProfileUpdatedData {
  birthDate: {
    value: string
  } | null
  birthSex: {
    value: 'FEMALE' | 'MALE'
  } | null
}

export interface Profile {
  actualAddresses?: Address
  avatarUrl?: string
  contact?: Contact
  emails?: Email[]
  healthcareInsurancePolicies?: HealthcareInsurancePolicy[]
  phones?: Phone[]
  relations?: RelationElement[]
}

export interface Relation {
  avatarUrl?: string
  contact?: RelationContact
  id?: string
  type?: RelationType
}

export type BasePhone = BaseBasePhone &
(
  | BaseBasePhoneTypeMapping<'HOME_PHONE', HomePhone>
  | BaseBasePhoneTypeMapping<'MOBILE_PHONE', MobilePhone>
  | BaseBasePhoneTypeMapping<'WORK_PHONE', WorkPhone>
);

type BaseBasePhoneTypeMapping<Key, Type> = {
  type: Key
} & Type;

export type HomePhone = BaseBasePhone;

export type WorkPhone = BaseBasePhone;

export type MobilePhone = BaseBasePhone & {
  allowResultsDelivery?: boolean
  main?: boolean
  ownershipConfirmed?: boolean
};

interface BaseBasePhone {
  id?: string
  phone?: string
  type?: PhoneType
}
export interface HealthcareInsurancePolicy {
  endDate?: string
  guarantor?: Guarantor
  id?: string
  insurancePlan?: InsurancePlan
  number?: string
  primary?: boolean
}

export interface InsuranceCarrier {
  id?: string
  name?: string
}

export interface InsurancePlan {
  carrier?: InsuranceCarrier
  id?: string
  name?: string
}

export interface Guarantor {
  additional?: string
  birthDate?: string
  birthSex?: BirthSex
  city?: string
  firstName?: string
  lastName?: string
  main?: string
  middleName?: string
  phone?: string
  postalCode?: string
  relationType?: RelationshipTypes
  ssnTail?: string
  state?: State
}

export interface RelationElement {
  avatarUrl?: string
  contact: Nullable<RelationContact>
  endDate?: ISODate
  id: string
  type?: RelationTypeEnum
}
export interface RelationElementGroupedByContactId {
  avatarUrl?: string
  contact: Nullable<RelationContact>
  endDate?: ISODate
  id: string
  types?: RelationTypeEnum[]
}

export interface Phone {
  id?: string
  phone?: string
  type?: PhoneTypeEnum
  allowResultsDelivery?: boolean
  main?: boolean
  ownershipConfirmed?: boolean
}

export interface Insurance {
  id: string
  name?: string
  number?: string
  primary?: boolean
  endDate?: string
  owner?: boolean
}

export interface Email {
  allowResultsDelivery?: boolean
  doubleOptIn?: DoubleOptIn
  email?: string
  id?: string
  main?: boolean
  ownershipConfirmed?: boolean
}

export interface Location {
  googlePlaceId?: string
  latitude?: number
  longitude?: number
}

export interface Address {
  additional?: string
  city?: string
  id?: string
  main?: string
  postalCode?: string
  state?: State
  location?: Location
}

export enum PhoneType {
  HOME_PHONE = 'HOME_PHONE',
  WORK_PHONE = 'WORK_PHONE',
  MOBILE_PHONE = 'MOBILE_PHONE',
}

export enum RelationType {
  SELF = 'SELF',
  CHD = 'CHD',
  DEP = 'DEP',
  EMC = 'EMC',
  FCH = 'FCH',
  FTH = 'FTH',
  GCH = 'GCH',
  GRP = 'GRP',
  MTH = 'MTH',
  OTH = 'OTH',
  SPO = 'SPO',
  HUSB = 'HUSB',
  WIFE = 'WIFE',
}

export enum PhoneTypeEnum {
  HomePhone = 'HOME_PHONE',
  MobilePhone = 'MOBILE_PHONE',
  WorkPhone = 'WORK_PHONE',
}

export enum DoubleOptIn {
  Confirmed = 'CONFIRMED',
  NotSent = 'NOT_SENT',
  Sent = 'SENT',
}

export enum RelationTypeEnum {
  Chd = 'CHD',
  Dep = 'DEP',
  Emc = 'EMC',
  Fch = 'FCH',
  Fth = 'FTH',
  Gch = 'GCH',
  Grp = 'GRP',
  Husb = 'HUSB',
  Mth = 'MTH',
  Oth = 'OTH',
  Spo = 'SPO',
  Wife = 'WIFE',
  Wrd = 'WRD',
}

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  CA = 'CA',
  CT = 'CT',
  Co = 'CO',
  Dc = 'DC',
  De = 'DE',
  FL = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  ID = 'ID',
  IL = 'IL',
  Ia = 'IA',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  MS = 'MS',
  MT = 'MT',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  PR = 'PR',
  Pa = 'PA',
  Pw = 'PW',
  Ri = 'RI',
  SD = 'SD',
  Sc = 'SC',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  VT = 'VT',
  Va = 'VA',
  Vi = 'VI',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Zz = 'ZZ',
}
