import {apiClient} from 'client/ApiClient';
import {FavoriteProfessional} from './models';

export const getFavoritesDoctors = async () => {
  const response = await apiClient.get<FavoriteProfessional[]>('/api/professionals/fn/get-favorites');
  return response.data;
};

export const deleteFavoriteDoctor = async ({id}: {id: string}) => {
  const response = await apiClient.post(`/api/professionals/${id}/fn/remove-from-favorites `);
  return response.data;
};

export * as favoritesApi from './';
export * from './models';
