export const fieldAccesses: Record<string, string> = {
  birthDate: '',
  birthSex: '',
  ethnicity: '',
  firstName: '',
  gender: '',
  lastName: '',
  middleName: '',
  race: '',
  sexualOrientation: '',
  ssnTail: '',
};
