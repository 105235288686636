import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    height: {
      xs: 'calc(100vh - 100px)',
      sm: 'calc(100vh - 112px)',
      lg: 'calc(100vh - 132px)',
    },
    backgroundColor: t.palette.common.white,
    overflow: 'auto',
  }),
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: {xs: 120, lg: 100},
  },
  textContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    mt: {xs: 24, lg: 48},
    mb: 50,
  },
  flexContainer: {
    flexDirection: 'row',
    gap: 6,
    mt: 6,
  },
  forbiddenText: {
    typography: {xs: '24_36_700', sm: '36_38_700'},
    mb: {xs: 12, sm: 24, lg: 18},
  },
  text: {
    typography: {xs: '14_18_500', sm: '18_30_500'},
  },
  link: (t) => ({
    cursor: 'pointer',
    typography: {xs: '14_18_500', sm: '18_30_500'},
    color: t.palette.primary.main,
  }),
};
