import {ReactComponent as ErrorX24} from '../24x24/notification-error.svg';
import {ReactComponent as InfoX24} from '../24x24/notification-info.svg';
import {ReactComponent as SuccessX24} from '../24x24/notification-success.svg';

export const Notification = {
  Info: {
    X24: InfoX24,
  },
  Error: {
    X24: ErrorX24,
  },
  Success: {
    X24: SuccessX24,
  },
};
