import {MultiSelect} from '@src/components/MultiSelect';
import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';

import {FormMultiSelectProps} from './type';

export const FormMultiSelect: FC<FormMultiSelectProps> = ({
  name,
  ...restProps
}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
  } = useController({
    control: formContext.control,
    name,
  });

  return (
    <MultiSelect
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};
