import {Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    p: {xs: '12px 18px', sm: '18px 24px'},
    gap: {xs: 6, sm: 12},
    height: {
      xs: 'calc(100vh - 52px)',
      sm: 'calc(100vh - 59px)',
      md: 732,
    },
    overflow: 'auto',
  },
  subject: {
    typography: {
      xs: '14_18_600',
      sm: '16_20_600',
    },
  },
  description: {
    typography: {
      xs: '12_22_500',
      sm: '14_24_500',
    },
  },
};
