import {fork} from 'redux-saga/effects';
import {accountSaga} from './account/saga';
import {notificationsSaga} from './notifications/sagas';
import {resultsSaga} from './results/sagas';
import {messengerSaga} from './messenger/saga';
import {relationsSaga} from './relations/saga';
import {accessesSaga} from '@src/store/accesses/saga';
import {favoritesSaga} from './favorites/saga';
import {appointmentsSaga} from './appointments/saga';
import {insuranceSaga} from './insurance/saga';
import {bmiSaga} from './bmi/saga';

export function * rootSaga () {
  yield fork(accountSaga);
  yield fork(notificationsSaga);
  yield fork(resultsSaga);
  yield fork(messengerSaga);
  yield fork(relationsSaga);
  yield fork(accessesSaga);
  yield fork(favoritesSaga);
  yield fork(appointmentsSaga);
  yield fork(insuranceSaga);
  yield fork(bmiSaga);
}
