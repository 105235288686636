import {all, takeLeading, call, SagaReturnType, put} from 'redux-saga/effects';
import {appointmentsActions as actions, appointmentsActions} from './slice';
import {appointmentsApi} from '@src/api/appointments';
import {SagaPayload, SagaReturn} from '../types';
import {professionalsApi} from '@src/api';
import {AxiosError} from 'axios';
import {errorHandler} from '../errorHandler';

export function * appointmentsSaga () {
  yield all([
    takeLeading(actions.updateAppointment, updateAppointment),
    takeLeading(actions.cancelAppointment, cancelAppointment),
    takeLeading(actions.requestAppointment, requestAppointment),
    takeLeading(actions.requestAppointments, requestAppointments),
    takeLeading(actions.addReviewForAppointment, addReviewForAppointment),

    takeLeading(actions.requestsProfessionalSlots, requestsProfessionalSlots),
    takeLeading(actions.requestsRescheduleAppointment, requestsRescheduleAppointment),
  ]);
}

export function * requestAppointments (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({appointments: true}));
    const appointments: Awaited<ReturnType<typeof appointmentsApi.getAppointments>> = yield call(
      appointmentsApi.getAppointments,
    );
    yield put(actions.setAppointments({appointments}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({appointments: false}));
  }
}

function * addReviewForAppointment ({
  payload,
}: SagaPayload<typeof actions.addReviewForAppointment>): SagaReturn {
  try {
    yield call(appointmentsApi.addReviewForAppointment, {id: payload.id, data: payload.data});
    const appointment: Awaited<ReturnType<typeof appointmentsApi.getAppointmentById>> = yield call(
      appointmentsApi.getAppointmentById,
      {id: payload.id},
    );
    yield put(actions.setAppointment({appointment}));
    yield put(appointmentsActions.setStep({step: 'init'}));
    yield put(appointmentsActions.setReviewModalStep({step: 'success-review-modal-open'}));
  } catch (e) {
    yield put(appointmentsActions.setStep({step: 'init'}));
    yield put(appointmentsActions.setReviewModalStep({step: 'error-review-modal-open'}));
    errorHandler(e, actions.setCommonError);
  }
}

export function * requestAppointment ({payload}: SagaPayload<typeof actions.requestAppointment>) {
  try {
    yield put(actions.setAppointment({appointment: null}));
    const appointment: Awaited<ReturnType<typeof appointmentsApi.getAppointmentById>> = yield call(
      appointmentsApi.getAppointmentById,
      {id: payload.id},
    );
    yield put(actions.setAppointment({appointment}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({appointments: false}));
  }
}

export function * cancelAppointment ({payload}: SagaPayload<typeof actions.requestAppointment>) {
  try {
    yield call(appointmentsApi.cancelAppointment, {id: payload.id});
    const appointment: Awaited<ReturnType<typeof appointmentsApi.getAppointmentById>> = yield call(
      appointmentsApi.getAppointmentById,
      {id: payload.id},
    );
    yield put(actions.setAppointment({appointment}));
    const appointments: Awaited<ReturnType<typeof appointmentsApi.getAppointments>> = yield call(
      appointmentsApi.getAppointments,
    );
    yield put(actions.setAppointments({appointments}));
    yield put(appointmentsActions.setCancelModalStep({step: 'success-cancel-modal-open'}));
  } catch (e) {
    yield put(appointmentsActions.setCancelModalStep({step: 'error-cancel-modal-open'}));
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({appointments: false}));
  }
}

export function * requestsProfessionalSlots ({payload}: SagaPayload<typeof actions.requestsProfessionalSlots>) {
  try {
    const professionalSlots: Awaited<ReturnType<typeof professionalsApi.getProfessionalSlots>> = yield call(
      professionalsApi.getProfessionalSlots,
      {id: payload.id, data: payload.data},
    );
    yield put(actions.setProfessionalSlots({professionalSlots}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({appointments: false}));
  }
}

function * requestsRescheduleAppointment ({
  payload,
}: SagaPayload<typeof actions.requestsRescheduleAppointment>): SagaReturn {
  try {
    yield put(appointmentsActions.setStep({step: 'loading'}));
    yield call(appointmentsApi.rescheduleAppointment, {id: payload.id, data: payload.data});
    yield put(appointmentsActions.setRescheduleAppointmentSteps({step: 'reschedule-success-modal-open'}));
    const appointment: Awaited<ReturnType<typeof appointmentsApi.getAppointmentById>> = yield call(
      appointmentsApi.getAppointmentById,
      {id: payload.id},
    );
    yield put(actions.setAppointment({appointment}));
  } catch (e: any) {
    if (e) {
      yield put(appointmentsActions.setRescheduleAppointmentSteps({step: 'reschedule-error-modal-open'}));
      yield put(appointmentsActions.setRescheduleError({rescheduleError: e.data.error}));
    }
  }
}

function * updateAppointment ({
  payload,
}: SagaPayload<typeof actions.updateAppointment>): SagaReturn {
  try {
    yield put(actions.setUpdateAppointmentStep({step: 'loading'}));
    yield call(appointmentsApi.updateAppointment, {
      id: payload.id,
      deletedFileIds: payload.deletedFileIds,
      description: payload.description,
      height: payload.height,
      weight: payload.weight,
      files: payload.files,
      oldFiles: payload.oldFiles,
    });
    const appointment: Awaited<ReturnType<typeof appointmentsApi.getAppointmentById>> = yield call(
      appointmentsApi.getAppointmentById,
      {id: payload.id},
    );
    yield put(actions.setAppointment({appointment}));
    yield put(actions.setUpdateAppointmentStep({step: 'init'}));
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e?.response?.status === 400) {
        const updateAppointmentError = e?.response?.data;
        yield put(actions.setUpdateAppointmentError({updateAppointmentError}));
        yield put(actions.setUpdateAppointmentStep({step: 'open'}));
      } else {
        yield put(actions.setUpdateAppointmentStep({step: 'init'}));
      }
    }
    errorHandler(e, actions.setCommonError);
  }
}
