import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DynamicsTest, Result, ResultDetail} from '@src/api';
import {RootState} from '@store';
import {NormalizedState} from 'store/common/normalized';
import {PartialRecord, UUID, ValidationErrorType} from 'types';

import {globalStateResetAction} from '../common/actions';
import {createNormalizedState, updateNormalizedState} from '../common/normalized';

import {DynamicTestTransformed} from './helpers';
export enum EFetching {
  Common = 'common',
  Results = 'results',
  Details = 'details',
  Dynamics = 'dynamics',
}
export interface IResultsState {
  results: NormalizedState<Result> | null
  filteredResults: Result[]
  resultDetails: NormalizedState<ResultDetail> | null
  commonError: ValidationErrorType | null
  dynamics: DynamicsTest[] | null
  fetching: PartialRecord<EFetching, boolean> | null
  testResults: DynamicTestTransformed[]
  step: TRequestNPIOrgFlowSteps
  errorStatus?: number | null
}

export type TRequestNPIOrgFlowSteps =
  | 'init'
  | 'open'
  | 'loading'
  | 'running'
  | 'success'
  | 'error';

const initialState: IResultsState = {
  results: null,
  filteredResults: [],
  resultDetails: null,
  commonError: null,
  dynamics: null,
  fetching: null,
  testResults: [],
  step: 'init',
  errorStatus: null,
};

export const slice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    requestResults () {},
    requestResultDetails (state, _action: PayloadAction<{companyId: UUID, resultId: UUID}>) {
      state.errorStatus = null;
    },
    requestDynamics () {},
    setResults (state, {payload}: PayloadAction<Result[]>) {
      if (!state.results) {
        state.results = createNormalizedState(payload);
      } else {
        updateNormalizedState(state.results, payload);
      }
    },
    setFilterResults: (state, {payload}: PayloadAction<Result[]>) => {
      state.filteredResults = payload;
    },
    setDetails (state, {payload}: PayloadAction<ResultDetail>) {
      if (!state.resultDetails) {
        state.resultDetails = createNormalizedState([payload]);
      } else {
        updateNormalizedState(state.resultDetails, [payload]);
      }
    },
    setDynamics (state, {payload}: PayloadAction<DynamicsTest[]>) {
      state.dynamics = payload;
    },
    setTranformedData (state, {payload}: PayloadAction<DynamicTestTransformed[]>) {
      state.testResults = payload;
    },
    sendResultsByEmail (_state, _payload: PayloadAction<{resultId: UUID, email: string}>) {},
    setFetching (state, {payload}: PayloadAction<IResultsState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...state.fetching ?? {}, ...payload};
    },
    setStep (
      state,
      {payload}: PayloadAction<{ step: TRequestNPIOrgFlowSteps }>,
    ) {
      state.step = payload.step;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.commonError = payload;
    },
    setErrorStatus (state, {payload}: PayloadAction<number | null>) {
      state.errorStatus = payload;
    },
  },
  extraReducers (builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const resultsStateName = slice.name;
export const resultsActions = slice.actions;
export const resultsReducer = slice.reducer;

export const selectDynamicsData = createSelector(
  (state: RootState) => state.results.testResults,
  (testResults) => ({testResults}),
);
