/* eslint-disable no-comments/disallowComments */
import {
  HealthcareInsurancePolicy,
  Profile,
  RelationElement,
  RelationElementGroupedByContactId,
  RelationTypeEnum,
} from '@src/api';
import {RelationProfile, deleteRelationByType} from '@src/api/relations';
import {DateTimeFormat} from '@src/components/DateFormat';
import {dateToFormat} from '@src/shared/utils';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {AxiosError} from 'axios';
import {DateTime, Interval} from 'luxon';

export const getDeletionWithErrorResult = (
  brokeRelationsStatus: Record<string, number | null> | null,
) => {
  if (!brokeRelationsStatus) return null;
  const statuses = Object.values(brokeRelationsStatus);
  const failureRelationStatus = statuses.find((item) => item === 200);
  if (failureRelationStatus) {
    const failureRelation = Object.entries(brokeRelationsStatus).find((item) => item[1] !== 200);
    const failureRelationName = failureRelation?.at(0)?.toString();
    return failureRelationName;
  }
  return null;
};

export const getRelationTypes = (
  groupedRelations: Profile | null | any,
  relation: RelationProfile | null,
) => {
  if (!groupedRelations?.length || !relation?.contact?.id) return null;
  const relations = groupedRelations;
  const contactId = relation?.contact?.id;
  const relationTypes = [];
  for (const relation of relations) {
    if (relation.contact.id === contactId && relation.type !== undefined) { relationTypes.push(...relation.types); }
  }
  return relationTypes || null;
};

export const deleteRelation = async ({
  relations,
  types,
  relationId,
}: {
  relations: RelationElement[]
  types: RelationTypeEnum[]
  relationId: string
}) => {
  if (!relations || !types) return {};
  const result: Record<string, number | null> = {};
  for (const type of types) {
    const relation = relations?.find((relation) => relation.contact.id === relationId && relation.type === type);
    if (relation) {
      try {
        const response = await deleteRelationByType({relationId: relation.id});
        result[type] = response;
      } catch (error) {
        if (error instanceof AxiosError && error.response) {
          result[type] = error.response.status;
        }
      }
    }
  }
  return result;
};

export const groupRelationsByType = (relations: RelationElement[] | undefined | null): RelationElementGroupedByContactId[] => {
  if (!relations) return [];
  const groupedRelations: Record<string, RelationElementGroupedByContactId> = {};
  for (const relation of relations) {
    const relationId = relation.contact.id as string;
    if (groupedRelations[relationId] && relation.type !== undefined) {
      groupedRelations[relationId].types?.push(relation.type);
    } else if (relation.type !== undefined) {
      groupedRelations[relationId] = {...relation, types: [relation?.type]};
    }
  }
  return Object.values(groupedRelations);
};

export const makeMapOfAccess = ({
  fieldAccesses,
  relation,
  matrix,
  entity,
}: {
  fieldAccesses: Record<string, string>
  relation: RelationProfile | null
  matrix: Record<string, Record<string, Record<string, string>>>
  entity: string
}) => {
  const newAccesses = {...fieldAccesses};
  if (relation?.accesses) {
    const accessesForFields = relation.accesses.map(({access}) => access && matrix[access][entity]);
    accessesForFields.forEach((fieldsList) => {
      if (fieldsList) {
        for (const field in fieldsList) {
          newAccesses[field] = (newAccesses[field] ?? '') + fieldsList[field];
        }
      }
    });
  }
  return newAccesses;
};

export const makeMapOfAddressAccess = ({
  relation,
  accessCreateEntities,
}: {
  relation: RelationProfile | null
  accessCreateEntities: Record<string, string[]>
}) => {
  let accessCreate;
  if (relation?.accesses) {
    accessCreate = relation.accesses.map(({access}) => access && accessCreateEntities[access]);
  }

  return accessCreate?.flat();
};

export const getUpdatedInsurances = ({
  insurances,
  deletedInsuranceIds,
  primaryInsurancePlan,
  primaryInsurancePolicyID,
  primaryInsuranceEndDate,
  primaryInsuranceIsOwner,
  secondaryInsurancePlan,
  secondaryInsurancePolicyID,
  secondaryInsuranceEndDate,
  secondaryInsuranceIsOwner,
}: {
  insurances: HealthcareInsurancePolicy[]
  deletedInsuranceIds: any[] | string[]
  primaryInsurancePlan: string | null
  primaryInsurancePolicyID: string | null
  primaryInsuranceEndDate: string | null
  primaryInsuranceIsOwner: boolean | null
  secondaryInsurancePlan: string | null
  secondaryInsurancePolicyID: string | null
  secondaryInsuranceEndDate: string | null
  secondaryInsuranceIsOwner: boolean | null
}) => {
  const isPrimaryCreated = !!primaryInsurancePlan || !!primaryInsurancePolicyID;
  const isSecondaryCreated = !!secondaryInsurancePlan || !!secondaryInsurancePolicyID;
  const restOldInsurance = insurances.filter((insurance) => !deletedInsuranceIds.find((deletedInsuranceId) => insurance.id === deletedInsuranceId));

  const secondaryData = {
    id: Math.round(Math.random() * 10),
    name: secondaryInsurancePlan,
    number: secondaryInsurancePolicyID,
    endDate: dateToFormat(DateTimeFormat.ISODate, secondaryInsuranceEndDate),
    owner: secondaryInsuranceIsOwner,
    primary: false,
  };
  const primaryData = {
    id: Math.round(Math.random() * 1000),
    name: primaryInsurancePlan,
    number: primaryInsurancePolicyID,
    endDate: dateToFormat(DateTimeFormat.ISODate, primaryInsuranceEndDate || ''),
    owner: primaryInsuranceIsOwner,
    primary: true,
  };

  if (isPrimaryCreated && isSecondaryCreated) {
    return [primaryData, secondaryData];
  }
  if (!isPrimaryCreated && isSecondaryCreated) {
    return restOldInsurance ? [...restOldInsurance, secondaryData] : [secondaryData];
  }
  if (isPrimaryCreated && !isSecondaryCreated) {
    return restOldInsurance ? [...restOldInsurance, primaryData] : [primaryData];
  }
  if (deletedInsuranceIds?.length === 2) {
    return [];
  }
  if (!insurances?.length && !isPrimaryCreated && !isSecondaryCreated) {
    return [];
  }
  if (!isPrimaryCreated && !isSecondaryCreated) {
    return [...Array.isArray(restOldInsurance) ? restOldInsurance : [restOldInsurance]];
  }
};

export const getOnlyActiveInsurances = (insurances: HealthcareInsurancePolicy[] | undefined) => {
  if (!insurances) return null;
  return insurances.filter((insurance) => {
    return !insurance.endDate || DateTime.fromISO(insurance.endDate).startOf('day') >= DateTime.now().startOf('day');
  });
};

export const isDateBetween = (startDate: string | null, endDate: string | null, date: string | null | undefined) => {
  if (!date || !startDate || !endDate) return null;
  const date1 = dateToFormat(DateTimeFormat.ISODate, startDate);
  const date2 = dateToFormat(DateTimeFormat.ISODate, endDate);
  const date3 = dateToFormat(DateTimeFormat.ISODate, date);
  const interval = Interval.fromDateTimes(DateTime.fromISO(date1).minus({day: 1}), DateTime.fromISO(date2).plus({day: 1}));
  const dateTimeToCheck = DateTime.fromISO(date3);

  return interval.contains(dateTimeToCheck);
};

export const sortInsurances = (healthcareInsurancePolicies: HealthcareInsurancePolicy[] | undefined) => {
  let filteredInsurances: HealthcareInsurancePolicy[] | null = [];

  const canceledInsurances = healthcareInsurancePolicies?.filter((insurance) => compareDatesWithToday(insurance.endDate, true, false) || insurance.endDate !== null);
  const activeInsurance = healthcareInsurancePolicies?.filter((insurance) => compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null);
  const endDates = canceledInsurances?.filter((insurance) => insurance.primary).map(insurance => DateTime.fromJSDate(new Date(insurance.endDate || '')));
  const latestEndDate = endDates ? DateTime.max.apply(null, endDates) : null;
  const closePrimaryInsurance = canceledInsurances?.find((insurance) => DateTime.fromISO(insurance.endDate || '').toISODate() === latestEndDate?.toISODate() && insurance.primary);
  if (activeInsurance?.length && !canceledInsurances?.find((insurance) => insurance.primary)) {
    filteredInsurances = [...activeInsurance];
  } else if (activeInsurance?.length && activeInsurance?.find((insurance) => insurance.primary)) {
    filteredInsurances = [...activeInsurance];
  } else {
    if (activeInsurance?.find((insurance) => !insurance.primary) && activeInsurance && closePrimaryInsurance) {
      filteredInsurances = [closePrimaryInsurance, ...activeInsurance].length > 1 ? [closePrimaryInsurance, ...activeInsurance] : null;
    }
  }
  return filteredInsurances?.length ? [...filteredInsurances].sort((a, b) => Number(b.primary) - Number(a.primary)) : null;
};
