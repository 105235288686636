import {IFilter} from '@src/pages/Dynamics/types';

export const TIME_PERIOD_OPTIONS = [
  {
    value: 'One week',
    name: 'One week',
  },
  {
    value: 'One month',
    name: 'One month',
  },
  {
    value: 'Six month',
    name: 'Six month',
  },
  {
    value: 'One year',
    name: 'One year',
  },
  {
    value: 'Custom',
    name: 'Custom',
  },
];

export const initialDates: Pick<IFilter, 'start' | 'end'> = {
  end: null,
  start: null,
};

export const initialFilters = {
  end: null,
  start: null,
  testCodes: [],
};
