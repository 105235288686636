import {styled} from '@mui/material';
import {Dialog} from '@src/components/Dialog';

export const StyledModal = styled(Dialog)(
  ({theme}) => ({
    '.MuiPaper-root': {
      marginLeft: 24,
      marginRight: 24,
      [theme.breakpoints.only('xs')]: {
        minHeight: 379,
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        minHeight: 730,
        justifyContent: 'space-between',
      },
    },
  }),
);
