import {BirthSex, Ethnicity, HealthcareInsurancePolicy, SexualOrientation} from '@api';
import {
  AddressLocation,
  GenderType,
  GuarantorCreateRequest,
  RaceType,
  RelationshipType,
  RequestRelationType,
  StateType,
} from '@src/api/relations';

export enum EWhichPrimaryInsuranceUse {
  My = 'my',
  Patient = 'patient',
}

export interface IRelationContactBatchCreateRequestSaveData {
  relationShipType: RequestRelationType
  firstName: string
  lastName: string
  middleName: string
  birthDate: string
  birthSex: BirthSex
  gender: GenderType
  race: RaceType
  ethnicity: Ethnicity
  sexualOrientation: SexualOrientation
  phone: string
  email: string
  primary?: boolean
  insurancePlan?: string
  policyId?: string
  endDate?: string
  insuranceId?: string
  guarantor: GuarantorCreateRequest
  insurancePlanId: string
  guarantorRelationshipType: RelationshipType
  guarantorFirstName: string
  guarantorLastName: string
  guarantorMiddleName: string
  guarantorBirthDate: string
  guarantorBirthSex: BirthSex
  guarantorPhone: string
  guarantorState: StateType
  guarantorCity: string
  guarantorAddress1: string
  guarantorAddress2: string
  guarantorSsnTail: string
  guarantorPostalCode: string
  whichPrimaryInsuranceUse: EWhichPrimaryInsuranceUse
  myInsurance: HealthcareInsurancePolicy | undefined
  address1: string
  address2: string
  state: StateType | string
  city: string
  postalCode: string
  telephoneNumber: string
  faxNumber: string
  invoicesReceiving: boolean
  insuranceBillType: boolean
  patientBillType: boolean
  clientBillType: boolean
  location: AddressLocation | null
}

export interface IInitialValueProps {
  insurance?: HealthcareInsurancePolicy | null
  accountProfileValidInsurance?: HealthcareInsurancePolicy
  initRelationshipType?: RequestRelationType | null
}
