import {Theme} from '@mui/material';

export const getBackgroundColor = (t: Theme, isActive?: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return t.palette.grey[200];
  }
  if (isActive) {
    return t.palette.common.white;
  }
  return t.palette.secondary[300];
};

export const getHoverBackgroundColor = (t: Theme, isActive?: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return t.palette.grey[200];
  }
  if (isActive) {
    return t.palette.common.white;
  }
  return t.palette.secondary[400];
};

export const getLabelColor = (t: Theme, isActive?: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return t.palette.grey[400];
  }
  if (isActive) {
    return t.palette.grey[800];
  }
  return t.palette.secondary.main;
};

export const getBorderColor = (t: Theme, isActive?: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return t.palette.grey[300];
  }
  if (isActive) {
    return t.palette.secondary[300];
  }
  return t.palette.secondary[500];
};
