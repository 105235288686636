import {Theme, SxProps, styled} from '@mui/material';
import {Dialog} from '@src/components/Dialog';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: 24,
  },
  modalButton: {
    padding: '11px 50px',
  },
};

export const StyledModal = styled(Dialog)(
  ({theme}) => ({
    '.MuiPaper-root': {
      marginLeft: 24,
      marginRight: 24,
      overflow: 'hidden',
      border: 'none',
      [theme.breakpoints.only('xs')]: {
        minHeight: 379,
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        minHeight: 730,
        justifyContent: 'space-between',
      },
    },
  }),
);
