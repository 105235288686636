import {BirthSex, StateType} from '@src/api';
import {
  RelationHealthcareInsurancePolicyChangeRequest,
  RelationHealthcareInsurancePolicyEditRequest,
} from '@src/api/relations';
import {RelationshipTypes} from '@src/types';
import {Nullable} from '@src/types/NullableModel';

export interface EditDataHealthPolicies {
  relationship: RelationshipTypes
  birthDate: string
  birthSex: BirthSex
  firstName: string
  lastName: string
  middleName: string
  ssnTail: string
  state: StateType
  city: string
  postalCode: string
  address1: string
  address2: string
  phone: string
  endDate: string
  id: string
  edit: ({relationId, data}: {
    relationId: string | undefined
    data: RelationHealthcareInsurancePolicyChangeRequest
  }) => void
  relationId: string | undefined
}

export const saveData = ({
  relationship,
  birthDate,
  birthSex,
  firstName,
  lastName,
  middleName,
  ssnTail,
  state,
  city,
  postalCode,
  address1,
  address2,
  phone,
  endDate,
  id,
  edit,
  relationId,
}: Nullable<EditDataHealthPolicies>) => {
  const getValueData = <T>(data: T) => ({
    value: data || null,
  });

  const data: RelationHealthcareInsurancePolicyEditRequest[] = [
    {
      endDate: endDate
        ? {
          value: endDate || '',
        }
        : null,
      guarantor: {
        value: {
          address1: getValueData(address1),
          address2: getValueData(address2),
          birthDate: getValueData(birthDate),
          birthSex: getValueData(birthSex),
          city: getValueData(city),
          firstName: getValueData(firstName),
          lastName: getValueData(lastName),
          middleName: getValueData(middleName),
          phone: getValueData(phone),
          postalCode: getValueData(postalCode),
          relationType: getValueData(relationship === RelationshipTypes.SELF ? null : relationship),
          ssnTail: getValueData(ssnTail),
          state: getValueData(state),
        },
      },
      id: id || '',
    },
  ];
  if (edit && relationId) {
    edit({
      relationId,
      data: {
        deletedHealthcareInsurancePolicyIds: [],
        healthcareInsurancePoliciesCreate: [],
        healthcareInsurancePoliciesEdit: data || undefined,
      },
    });
  }
};
