import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    flexDirection: 'row',
    alignItems: 'center',
    gap: 36,
    height: 20,
    [t.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
      gap: 16,
    },
  }),
  label: (t) => ({
    color: `${t.colors.all.grey[700]} !important`,
    typography: '14_18_700',
    display: 'flex',
    whiteSpace: 'nowrap',
  }),
};
