import {Box, Stack} from '@mui/material';
import {WBox} from '@src/components';
import {DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {modalType} from '@src/pages/Connections/utils/functionHelpers';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sx} from './styles';

export const ChangeAccess = () => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile, tablet: isTablet, desktop: isDesktop} = useMQuery();

  const navigate = useNavigate();
  const location = useLocation();

  const prevPage = location.state?.from || ROUTERS_PATH.CONNECTIONS_RELATIONS;

  const handleReturnToPrevPage = () => navigate(prevPage);

  useEffect(() => {
    if (isDesktop) {
      handleReturnToPrevPage();
    }
  }, [isDesktop]);

  return (
    <WBox sx={sx.container}>
      <DialogTitle
        sx={sx.title}
        width='60px'
        onClose={handleReturnToPrevPage}
        noContent={false}>{t('CHANGE_ACCESS')}
      </DialogTitle>
      <Stack
        sx={sx.content}>
        {modalType(isTablet, isMobile)}
      </Stack>
      <Box sx={sx.btnContainer}>
        <Button
          variant="contained"
          color="secondary"
          sx={sx.btn}
          onClick={handleReturnToPrevPage}
        >
          {t('BACK')}
        </Button>
      </Box>
    </WBox>
  );
};
