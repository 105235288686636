import {TableHeaderItem} from '@src/components/Table/types';
import {useTranslate} from 'i18n/useTranslate';

import {ISortResult} from './types';

export const CRITICAL_FLAG = 'CRITICAL';

export const useHeader = (filterResultByDate: (key: string) => () => void, collectedPosition?: boolean) => {
  const {t} = useTranslate('results');
  const HEADER_LIST: TableHeaderItem[] = [
    {
      name: t('COLLECTED'),
      action: {action: filterResultByDate('collected'), position: 'end', sorted: collectedPosition},
    },
    {
      name: t('PHYSICIAN'),
    },
  ];
  return {HEADER_LIST};
};

export const initiialSort: ISortResult = {
  sortBy: '',
  sortDesc: true,
};
