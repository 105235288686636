import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: {
      xs: 'inherit',
      sm: 'center',
    },
    flexDirection: {
      xs: 'column',
      sm: 'unset',
    },
    gap: 24,
    height: theme => theme.pageTitleHeight,
  },
  tabs: {
    typography: '16_20_500',
    width: {
      sm: 160,
      md: 205,
      lg: 250,
    },
  },
};
