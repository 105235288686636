import styled from '@emotion/styled';
import {ButtonBase} from '@mui/material';

export const ProfileMenuButtonStyled = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(4.5),
  position: 'relative',
  justifyContent: 'end',
  '&:hover, &:active': {
    '.sandwich-menu-open &': {
      backgroundColor: 'transparent',
    },
  },
}));
