import {Box, Typography} from '@mui/material';
import {useMQuery} from '@src/shared/hooks';

import {getLabelColor} from './helpers';
import {StyledBox} from './styled';
import {TabTetraryProps} from './types';

export const SidebarTabTetrary = ({
  icon,
  label,
  isActive,
  onClick,
  isDisabled,
  id,
}: TabTetraryProps) => {
  const {mobile: isMobile} = useMQuery();

  return (
    <StyledBox
      id={id}
      onClick={onClick}
      isActive={isActive}
      isDisabled={isDisabled}>
      {!isMobile && <Box display="flex">{icon}</Box>}
      <Typography
        variant="14_18_600"
        sx={(theme) => ({
          color: getLabelColor(theme, isActive, isDisabled),
        })}
      >
        {label}
      </Typography>
    </StyledBox>
  );
};
