
import {Stack, Typography, styled, css as _, SxProps, Theme, IconButton, OutlinedInput, InputAdornment} from '@mui/material';
import {RowInformation} from '@src/components/RowInformation';
import {Tooltip} from '@src/components/Tooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as EditGrey} from '@src/shared/assets/icons/20x20/edit_grey.svg';
import {ReactComponent as Cancel} from '@src/shared/assets/icons/close-sm.svg';
import {appointmentsActions} from '@src/store/appointments/slice';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  contentWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 19,
  },
  tooltip: (t) => ({
    width: '42%',
    [t.breakpoints.between('sm', 'lg')]: {
      width: '25%',
    },
  }),
  textArea: (t) => ({
    ml: 6,
    width: '30%',
    height: 30,
    '&.Mui-focused': {
      ' .MuiOutlinedInput-notchedOutline': {
        borderColor: `${t.palette.grey[500]} !important`,
        legend: {
          [t.breakpoints.between('sm', 'lg')]: {
            lineHeight: 0,
          },
        },
      },
    },
    ' .MuiOutlinedInput-notchedOutline': {
      borderColor: `${t.palette.grey[300]} !important`,
      '&:hover': {
        borderColor: 'grey.300',
      },
    },
    ' .MuiOutlinedInput-root': {
      '.MuiInputAdornment-root p': {
        color: 'grey.700',
      },
      bottom: 5,
      ' .MuiOutlinedInput-notchedOutline': {
        borderColor: `${t.palette.grey[300]} !important`,
        top: 0,
      },
      fieldset: {
        borderColor: `${t.palette.grey[300]} !important`,
        top: 0,
        legend: {
          lineHeight: 0,
        },
      },
    },
    ' .MuiOutlinedInput-input': {
      padding: '6px 0 6px 12px',
    },
    '.MuiInput-input': {
      pt: 0,
      pl: 12,
    },
    ' .MuiInput-root:after': {
      borderBottom: 'inherit',
    },
  }),
  contentContainer: {
    flexDirection: 'row',
    gap: 8,
    svg: {cursor: 'pointer'},
  },
  iconButtons: {
    minWidth: 0,
    padding: 0,
    height: 24,
    width: 24,
    svg: {
      padding: 0,
    },
  },
};

export const EditableRowInformation = ({
  initialValue,
  name,
  onChange,
  spaceBetween,
}: {
  initialValue: string | number | null
  name: string
  onChange: (value: number) => void
  spaceBetween?: number
}) => {
  const {t} = useTranslate('common');
  const [value, setValue] = useState(initialValue);
  const [focused, setFocused] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const MAX_HEIGHT = 8.2;
  const MAX_WEIGHT = 1323;
  const MIN_HEIGHT = 1;
  const MIN_WEIGHT = 1;
  const dispatch = useDispatch();
  const onFocus = () => setFocused(true);
  const onBlur = useCallback(() => {
    setIsEdit(false);
    setFocused(false);
    if (value !== initialValue) {
      onChange(Number(value));
      dispatch(appointmentsActions.setUpdateAppointmentStep({step: 'init'}));
    }
  }, [dispatch, initialValue, onChange, value]);
  const tooltipText = name === t('WEIGHT') ? t('WEIGHT_SHOULD_BE_BETWEEN_1_AND_1323_LB') : t('HEIGHT_SHOULD_BE_BETWEEN_1_AND_8.2_FT');
  const handleChangeValue = useCallback((e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if ((name === t('WEIGHT') && Number(e.currentTarget.value) >= MIN_WEIGHT && Number(e.currentTarget.value) <= MAX_WEIGHT && e.currentTarget.value.length < 6) || !e.currentTarget.value) {
      setValue(e.currentTarget.value);
    }
    if ((name === t('HEIGHT') && Number(e.currentTarget.value) >= MIN_HEIGHT && Number(e.currentTarget.value) <= MAX_HEIGHT && e.currentTarget.value.length < 5) || !e.currentTarget.value) {
      setValue(e.currentTarget.value);
    }
  }, [name, t]);

  return (
    <Stack>
      {isEdit
        ? (
          <Stack sx={sx.contentWrapper}>
            <Typography variant="14_18_700" sx={{whiteSpace: 'nowrap'}}>{name}</Typography>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              sx={sx.tooltip}
              open={focused}
              disableHoverListener
              title={tooltipText}
              placement='right'
              arrow
            >
              <OutlinedInput
                value={value || ''}
                sx={sx.textArea}
                autoFocus={true}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleChangeValue}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={sx.iconButtons}
                      onClick={() => {
                        setValue(initialValue);
                        setIsEdit(false);
                      }}
                      edge="end"
                    >
                      <CancelIcon
                        width={20}
                        height={20}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Tooltip>
          </Stack>
        )
        : (
          <Stack sx={sx.contentContainer}>
            <RowInformation
              name={name}
              value={value || ''}
              noMargin
              spaceBetween={spaceBetween} />
            <IconButton
              sx={{m: -8}}
              onClick={() => {
                setIsEdit(true);
              }}>
              <EditGreyIcon />
            </IconButton>
          </Stack>
        )}
    </Stack>
  );
};

const CancelIcon = styled(Cancel)(({theme}) => _`
  padding: 0 9px;
  box-sizing: initial;
  g path {
    fill: ${theme.colors.all.grey[300]};
  }
`);

const EditGreyIcon = styled(EditGrey)(({theme}) => _`
  width: 18px;
  height: 18px;
  path {
    fill: ${theme.palette.secondary.main};
  }
`);
