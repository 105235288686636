import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    padding: {xs: 0, sm: 24},
    flexGrow: 1,
    justifyContent: 'space-between',
    minWidth: '10%',
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
      padding: 0,
    },
    [t.breakpoints.only('xs')]: {
      width: 'auto',
    },
  }),
  editBthContainer: {
    display: 'flex',
    justifyContent: {xs: 'start', sm: 'end'},
  },
  buttonEdit: {
    mt: {xs: 48, sm: 60, lg: 48},
  },
};
