import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    p: 0,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      mt: 24,
      width: 'auto',
      border: 'none',
    },
    [t.breakpoints.only('xs')]: {
      mt: 18,
      width: 'auto',
      backgroundColor: t.palette.common.white,
    },
  }),
  buttonsContainer: {
    mt: {xs: 36, lg: 12},
    display: 'flex',
    justifyContent: {xs: 'start', sm: 'end'},
    pb: {xs: 0, lg: 24},
    pr: {xs: 0, lg: 24},
  },
};
