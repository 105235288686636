import {Stack, styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12
  },
};

export const FiltersContainer = styled(Stack)(({theme}) => ({
  flexDirection: 'row',
  gap: 24,
  background: theme.colors.background,
  padding: '24px 36px',
  borderBottom: '1px solid',
  borderTop: '1px solid',
  borderColor: theme.colors.borderBlueLight,
  alignItems: 'end',
  justifyContent: 'space-between',
  zIndex: 1,
  [theme.breakpoints.between('lg', 'xxl')]: {
    gap: 36,
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: 24,
  },
  [theme.breakpoints.down('sm')]: {
    padding: 18,
    flexDirection: 'column',
    alignItems: 'initial',
  },
}));
