import {Stack, styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  checkboxContainer: {
    minWidth: 34,
    pt: 17,
    label: {
      justifyContent: 'flex-end',
    },
  },
  checkbox: {
    mr: 0,
    height: 'unset',
  },
  mainInfo: {
    padding: {
      xs: '6px 9px 9px 18px',
      sm: '6px 12px 12px 18px',
    },
    gap: 6,
    flex: 1,
    justifyContent: 'space-between',
  },
  name: {
    pt: 6,
    whiteSpace: 'wrap',
    wordBreak: 'break-all',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
  buttonsContainer: (theme) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid',
    transition: '0.2s',
    borderColor: theme.colors.blueCardBorder,
    padding: {
      xs: '3px 6px 3px 9px',
      sm: '12px 18px',
    },
  }),
  actionButton: {
    padding: 0,
    typography: '16_20_600',
    display: 'inline-flex',
    minWidth: 'unset',
    height: 20,
    '.MuiButton-startIcon': {
      width: 18,
      height: 18,
    },
  },
  iconButton: {
    svg: {
      width: 24,
      height: 24,
    },
  },
  healthCaseBlock: {
    height: 36,
  },
  healthCaseText: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    b: {
      mr: 12,
    },
  },
};

export const CardContainer = styled(Stack)<{ isNew: boolean, isSelected: boolean }>(
  ({theme, isNew, isSelected}) => ({
    background: isSelected ? theme.palette.secondary['400'] : theme.colors.background,
    border: '1px solid',
    borderColor: isSelected ? theme.colors.fileCardBackground : theme.colors.blueCardBorder,
    transition: '0.2s',
    flexDirection: 'row',
    animation: isNew ? 'colorChange 2s infinite' : '',
    '@keyframes colorChange': {
      '0%': {
        backgroundColor: theme.colors.grayBackground,
      },
      '50%': {
        backgroundColor: theme.colors.blueCardBorder,
      },
      '100%': {
        backgroundColor: theme.colors.grayBackground,
      },
    },
  }),
);
