import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  container: {
    background: theme => theme.colors.fileCardBackground,
    padding: '2px 6px 2px 10px',
    flexDirection: 'row',
    color: theme => theme.colors.all.white,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  icon: {
    mr: 4,
  },
  text: {
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    mr: 20,
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardEnd: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'end',
  },
};
