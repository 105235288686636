import {Backdrop, ButtonBase, css, styled, Theme} from '@mui/material';
import {ReactComponent as AppLogo} from '@src/shared/assets/images/logo.svg';
import {initialColors} from '@src/theme';

export const MenuLayout = styled('div')`
  justify-content: center;
  display: flex;
  gap: 12px;
`;

export const FloatMenu = styled('div')`
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MenuBackdrop = styled(Backdrop)`
  bottom: 0;
  top: auto;
  height: calc(100% - ${({theme}) => theme.header.height.lg - 1}px);
  z-index: 2;

  .mobile-layout & {
    height: calc(100% - ${({theme}) => theme.header.height.sm - 1}px);
  }

  .tablet-layout & {
    height: calc(100% - ${({theme}) => theme.header.height.md - 1}px);
  }
`;

export const IconButton = styled(ButtonBase)(() => `
    min-width: 42px;
    height: 42px;
    background: none;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`);

export const StyledAppLogo = styled(AppLogo)`
  path {
    fill: ${({theme}) => theme.colors.primary};
  }
  width: 100px;
  height: 27px;
`;

export const PageBody = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled('header')`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
  height: ${({theme}) => theme.header.height.lg}px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.background};
  border-bottom: 1px solid ${initialColors.blueLight};
  display: flex;
  padding: 0 20px 0 20px;

  .mobile-layout & {
    height: ${({theme}) => theme.header.height.sm}px;
    padding: 0 9px 6px 12px;
    align-items: flex-end;
  }

  .tablet-layout & {
    height: ${({theme}) => theme.header.height.md}px;
    padding: 0 12px 6px 12px;
    align-items: flex-end;
  }

  transition: all 0.3s;
`;

export const Footer = styled('footer')`
  flex: 0 1 auto;
  min-height: 100px;
  background-color: ${({theme}) => theme.colors.background};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({theme}) => theme.colors.borderLight};
  align-items: center;
  justify-content: right;
  display: flex;
  padding: 0 36px;
  color: ${({theme}) => theme.colors.all.grey4};

  .mobile-layout & {
    padding: 0 16px;
  }
  .tablet-layout & {
    padding: 0 24px;
  }
`;

export const Main = styled('div')`
  margin-top: ${({theme}) => theme.header.height.lg}px;
  flex: 1 1 auto;
  display: flex;
  height: calc(100vh - ${({theme}) => theme.header.height.lg}px);

  .mobile-layout & {
    height: calc(100vh - ${({theme}) => theme.header.height.sm}px);
    margin-top: ${({theme}) => theme.header.height.sm}px;
  }

  .tablet-layout & {
    height: calc(100vh - ${({theme}) => theme.header.height.md}px);
    margin-top: ${({theme}) => theme.header.height.md}px;
  }
`;

export const GridContainer = styled('div')(({theme}) => ({
  position: 'relative',
  padding: '36px',
  overflow: 'auto',
  flexGrow: 1,
  minHeight: 'calc(100vh - 120px)',
  [theme.breakpoints.down('lg')]: {
    minHeight: 'auto',
    padding: '24px',
    width: '100vw',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '18px',
    width: '100vw',
    height: '100%',
  },
}));

export const GridNavPanel = styled('div')<{ open?: boolean }>(
  ({theme}) => `
  max-width: 375px;
  min-width: 375px;
  flex: 1 1 auto;
  background-color: ${theme.colors.background};
  border-right: 1px solid ${theme.colors.borderBlueLight};
  position: relative;
  z-index: 100;
  ${theme.breakpoints.down('lg')} {
    position: fixed;
    transition: all 0.3s;
    height: calc(100% - ${theme.header.height.md - 1}px);
    bottom: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    ${theme.breakpoints.down('sm')} {
      height: calc(100% - ${theme.header.height.sm - 1}px);
      max-width: 100%;
      min-width: 100%;
    }
  }
  .sandwich-menu-open & {
    transform: translate3d(0%, 0, 0);
  }
`,
);

export const Content = styled('div')`
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  box-sizing: border-box;

  .tablet-layout & {
    gap: 24px;
  }
  .mobile-layout & {
    gap: 24px;
  }
`;

export const BurgerLogoContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;

  .mobile-layout & {
    gap: 6px;
  }
`;

export const globalStyles = (theme: Theme) =>
  css({
    body: {
      backgroundColor: theme.colors.defaultBackground,
      color: theme.colors.defaultTextColor,
      paddingRight: '0px !important',
      '&.sandwich-menu-open': {
        overflowY: 'hidden',
      },
    },
    html: {
      marginRight: 'calc(100% - 100vw)',
      overflowX: 'hidden',
    },
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0px 1000px ${theme.colors.all.white} inset !important`,
      },
      '&:-webkit-autofill:hover': {
        WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.grey[100]} inset !important`,
      },
      '&:-webkit-autofill:active': {
        WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.grey[200]} inset !important`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      html: {
        marginRight: 0,
      },
      body: {
        width: '100vw',
        overflow: 'hidden',
        overflowY: 'auto',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '*': {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
      },
    },
  });
