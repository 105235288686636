import {styled} from '@mui/material';
import {ReactComponent as SortIcon} from '@src/shared/assets/icons/sorting_arrows_24x24.svg';
import {initialColors, paletteColors} from '@src/theme';

import {IStyledHeaderTRProps, IStyledTDProps, IStyledTHProps, IStyledTRProps} from './types';

export const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  border-collapse: separate;
`;

export const TableWrapper = styled('div')`
  height: 100%;
  overflow: auto;
`;

export const StyledTR = styled('tr')<IStyledTRProps>(({isPointer, isError, height, minHeight, isDisabled}) => {
  return {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    display: 'flex',
    width: '100%',
    color: isDisabled ? paletteColors.grey[300] : paletteColors.grey[700],
    cursor: !isDisabled && isPointer ? 'pointer' : 'default',
    backgroundColor: isError ? paletteColors.red[100] : 'initial',
    minHeight,
    height,

    ':hover': {
      backgroundColor: !isDisabled && isPointer && !isError ? initialColors.grayBackground : '',
    },

    ':active': {
      backgroundColor: !isDisabled && isPointer && !isError ? initialColors.blueLight : '',
    },

    'td:first-child': {
      paddingLeft: '24px',
    },

    'td:last-child': {
      paddingRight: '24px',
    },

    td: {
      padding: '6px 12px',
    },
  };
});

export const StyledTH = styled('th')<IStyledTHProps>(({isFirstColumn, isHorizontalScrolled}) => ({
  width: '100%',
  display: 'flex',
  position: 'sticky',
  top: '0',
  background: initialColors.white,
  zIndex: isFirstColumn ? '2' : '1',
  left: isFirstColumn ? '0' : undefined,
  boxShadow: isHorizontalScrolled && isFirstColumn
    ? '8px 0px 9px 0px rgba(34, 60, 80, 0.04)'
    : 'none',
}));

export const StyledTD = styled('td')<IStyledTDProps>(({
  isFirstColumn,
  isLastColumn,
  isHorizontalScrolled,
  height,
  isError,
  isRowHovered,
  isHiddenColumn,
  isLastRow,
  isNew,
}) => {
  const backgroundError = isError ? paletteColors.red[100] : initialColors.white;
  const hoverHiddenVisibility = isRowHovered && isHiddenColumn ? '1' : '0';
  const displayedLastCell = isLastColumn ? '1' : hoverHiddenVisibility;

  const borderBottomColor = () => {
    if (isLastRow) return 'transparent';
    if (isError) return paletteColors.red[200];
    return initialColors.blueLight;
  };

  return {
    width: '100%',
    display: 'flex',
    alignItems: height === 'auto' ? 'start' : 'center',
    top: '0',
    zIndex: isFirstColumn ? '1' : '0',
    position: isFirstColumn ? 'sticky' : 'initial',
    left: isFirstColumn ? '0' : '',
    backgroundColor: isFirstColumn && isHorizontalScrolled ? backgroundError : isNew ? initialColors.blueLight : 'transparent',
    overflow: isLastColumn ? 'hidden' : 'unset',
    textOverflow: isLastColumn ? 'ellipsis' : 'unset',
    whiteSpace: isLastColumn ? 'nowrap' : 'unset',
    borderBottom: `1px solid ${borderBottomColor()}`,
    transition: 'background-color 0.3s ease-in-out',
    boxShadow: isHorizontalScrolled && isFirstColumn
      ? '8px 10px 9px 0px rgba(34, 60, 80, 0.04)'
      : 'none',
    '& > div:last-child': {
      opacity: isHiddenColumn ? displayedLastCell : '1',
    },
  };
});

export const StyledTableHead = styled('thead')`
  position: sticky;
  top: 0;
  z-index: 2;
  display: grid;

  th:first-child {
    padding-left: 24px;
  }

  th {
    padding: 6px 12px;
    border-top: 1px solid ${initialColors.blueLight};
    border-bottom: 1px solid ${initialColors.blueLight};
    height: 54px;
  }
`;

export const StyledHeaderTR = styled('tr')<IStyledHeaderTRProps>(({isVerticallyScrolled}) => ({
  display: 'flex',
  width: '100%',
  boxShadow: isVerticallyScrolled ? '8px 0px 9px 0px rgba(34, 60, 80, 0.14)' : 'none',
}));

export const HeaderItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  textOverflow: ellipsis;
  whiteSpace: nowrap;
`;

export const StyledSortIcon = styled(SortIcon)<{ sorting: 'asc' | 'desc' | null }>`
  .up {
    color: ${({sorting}) => (sorting === 'asc' ? paletteColors.grey[500] : paletteColors.grey[300])};
  }

  .down {
    color: ${({sorting}) => (sorting === 'desc' ? paletteColors.grey[500] : paletteColors.grey[300])};
  }
`;
