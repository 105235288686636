import {Stack, SxProps, Typography, Theme} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {FC, ReactNode} from 'react';

export const RowInformation: FC<{
  value: null | ReactNode
  name: string
  sx?: SxProps<Theme>
  ellipsisTextSx?: SxProps<Theme>
  noMargin?: boolean
  noWrap?: boolean
  isMobile?: boolean
  ellipsisText?: boolean
}> = ({value, name, sx, ellipsisTextSx, noMargin, noWrap, isMobile, ellipsisText = false}) => (
  <Stack
    direction="row"
    marginBottom={noMargin ? 0 : '12px'}
    whiteSpace={!noWrap ? 'nowrap' : 'unset'}
    flexWrap={noWrap ? 'wrap' : 'unset'}>
    <Typography
      variant={isMobile ? '12_18_700' : '14_20_700'}
      color={(t: Theme) => t.colors.defaultTextColor}
      marginRight="12px"
      whiteSpace="nowrap"
      marginBottom={noMargin ? 0 : '6px'}
      sx={sx}
    >
      {name}
    </Typography>
    {ellipsisText
      ? <EllipsisTextTooltip sx={ellipsisTextSx} ellipsisText={value}/>
      : (
        <Typography
          variant={isMobile ? '12_18_500' : '14_20_400'}
          color={(t: Theme) => t.colors.defaultTextColor}
          sx={sx}>
          {value || '\u2015'}
        </Typography>
      )
    }
  </Stack>
);
