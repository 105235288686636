import {
  Table,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Avatar,
  Box,
  TableRow,
} from '@mui/material';
import {AccountStatus} from '@src/api/relations';
import {CircleStatus, ListWrapper, RowInformation, Tooltip} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ArrowDownIcon} from '@src/shared/assets/icons/arrow-down.svg';
import {getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useState} from 'react';

import {COLORS} from '../constant';
import {EAccountStatusName} from '../types';

import {ActionsColumn} from './components/ActionsColumn';
import {sx} from './styles';
import {IAddRelationTableTabletLayoutProps} from './types';

export const AddRelationTableTabletLayout = ({
  relationContacts,
  relationType,
}: IAddRelationTableTabletLayoutProps) => {
  const {t} = useTranslate('connections');

  const [expandedRows, setExpandedRows] = useState <Record<string, boolean>>({});

  const handleClickViewPatientDetails = (id: string) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      {relationContacts.length < 1 &&
      <Typography variant='14_18_500' sx={sx.patientNotFound}>{t('NO_SUITABLE_RESULTS')}</Typography>
      }
      <Table sx={sx.tableContainer}>
        <TableBody>
          {relationContacts.map((row) => (
            <TableRow
              key={row.id}
              sx={[
                ...(Array.isArray(sx.tableRow) ? sx.tableRow : [sx.tableRow]),
              ]}
            >
              <TableCell
                align="left"
                component="th"
                scope="row"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Stack flexDirection='column' width='100%' gap={18}>
                  <Stack sx={sx.patientColContainer}>
                    <ArrowDownIcon
                      onClick={() => handleClickViewPatientDetails(row.id)}
                      style={{
                        transform: expandedRows[row.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                        flexShrink: 0,
                      }}
                    />
                    <Tooltip title={`Account status - ${EAccountStatusName[row.accountStatus]}`}>
                      <Box sx={sx.flag}>
                        <CircleStatus color={COLORS[AccountStatus[row.accountStatus]].color} />
                      </Box>
                    </Tooltip>
                    <Avatar sx={sx.requestAvatar} src={row.accountAvatarUrl}>
                      <Typography variant="14_20_700">
                        {getUserInitials({
                          firstName: row.firstName,
                          lastName: row.lastName,
                          middleName: row.middleName,
                        })}
                      </Typography>
                    </Avatar>
                    <Stack sx={{minWidth: '10%'}}>
                      <EllipsisTextTooltip
                        variant="14_18_700"
                        ellipsisText={getName({
                          firstName: row.firstName,
                          lastName: row.lastName,
                        })}/>
                      <Typography variant="12_16_500">{row.birthDateS}</Typography>
                    </Stack>
                  </Stack>
                  {expandedRows[row.id] && (
                    <ListWrapper padding='0' stylesSx={{display: 'flex', flexDirection: 'column', pl: 83}}>
                      <RowInformation
                        tooltip
                        name={t('PHONE')}
                        value={row.phone?.slice(1)}
                      />
                      <RowInformation
                        tooltip
                        name={t('EMAIL')}
                        value={row.email}
                      />
                      <RowInformation
                        tooltip
                        name={t('SSN_LAST_4')}
                        value={row.ssnTail}
                      />
                      <RowInformation
                        tooltip
                        name={t('POLICY_ID')}
                        value={row.policyNumber}
                      />
                    </ListWrapper>
                  )}
                </Stack>
              </TableCell>
              {<ActionsColumn row={row} relationType={relationType}/>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
