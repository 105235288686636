export const DATE_FORMAT = 'MM/dd/yyyy';
export const ISO_DATE_FORMAT = 'LL/dd/yyyy';
export const NO_VALUE = '\u2013';
export const NON_BREAKING_SPACE = '\u00A0';
export const MEDIUM_SIZED_DASH = '\u2013';

export enum DateFormat {
  DATE_SHORT = 'LL/dd/yyyy',
  TIME_SIMPLE = 'hh:mm a',
  MONTH_FULL = 'MMMM',
  DAY_AND_MONTH_SHORT = 'd MMM',
  DAY_OF_MONTH = 'd',
  DATE_TIME = 'LL/dd/yyyy, hh:mm'
}
