import {useTheme, Typography, SxProps} from '@mui/material';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {alpha} from '@src/theme/utils';

const sx: Partial<Record<string, SxProps>> = {
  flag: {
    padding: '4px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'fit-content',
  },
};

export enum FlagStatus {
  SUCCESS = 'SUCCESS',
  ATTENTION = 'ATTENTION',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WAITING = 'WAITING',
  ASKED = 'ASKED',
  UNKNOWN = 'UNKNOWN',
  BLOCKED = 'BLOCKED',
  NONE = 'NONE',
}

export const Flag = ({status, children}: { status: FlagStatus, children: React.ReactNode }) => {
  const theme = useTheme();
  const BackgroundFlagColor = {
    SUCCESS: alpha(theme.colors.all.valid, 10),
    ATTENTION: alpha(theme.colors.all.warning[200], 10),
    ERROR: alpha(theme.colors.all.error[200], 10),
    INFO: alpha(theme.colors.all.info, 10),
    WAITING: alpha(theme.colors.all.waiting, 10),
    ASKED: alpha(theme.colors.all.asked, 10),
    UNKNOWN: alpha(theme.colors.all.unknown, 10),
    BLOCKED: alpha(theme.colors.all.blocked, 10),
    NONE: alpha(theme.colors.all.none, 10),
  };

  const FlagColor = {
    SUCCESS: theme.colors.all.valid,
    ATTENTION: theme.colors.all.warning[200],
    ERROR: theme.colors.all.error[200],
    INFO: theme.colors.all.info,
    WAITING: theme.colors.all.waiting,
    ASKED: theme.colors.all.asked,
    UNKNOWN: theme.colors.all.unknown,
    BLOCKED: theme.colors.all.blocked,
    NONE: theme.colors.all.none,
  };

  if (!status) return null;

  return (
    <Typography
      variant="14_18_500"
      sx={[
        {
          backgroundColor: BackgroundFlagColor[status],
          color: FlagColor[status],
          whiteSpace: 'nowrap',
        },
        ...spreadSx(sx.flag),
      ]}
    >
      {children}
    </Typography>
  );
};
