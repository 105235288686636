/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {Box, ButtonBase, css as _, Stack} from '@mui/material';
import {DateTime} from 'luxon';
import {
  FC,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface YAProps {
  onChange: (d: DateTime) => void
  date: DateTime
  selected?: DateTime | null
  range: [number, number]
  reverseYears?: boolean
}
export const YearArea: FC<YAProps> = ({
  date,
  selected,
  onChange,
  range: [start, end],
  reverseYears,
}) => {
  const [toucheMove, setTouchMove] = useState(false);
  const years = useMemo(() => {
    return [...Array(end - start + 1)];
  }, [start, end]);
  const selectedRef = useRef<null | HTMLButtonElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const parent: HTMLDivElement | null = selectedRef.current?.parentNode
      ?.parentNode as HTMLDivElement;
    if (parent) {
      parent.scrollTop = selectedRef.current?.offsetTop ?? 0;
    }
  }, []);

  const currentYear = DateTime.now().year;
  return (
    <Stack
      marginX={-4}
      flexGrow={1}
      alignItems={'center'}
      alignSelf={'center'}
      justifyContent={'center'}
      sx={(t) => ({
        height: 270,
        [t.breakpoints.down('sm')]: {
          height: 230,
        },
      })}
    >
      <PerfectScrollbar
        css={_`top: 0; left: 0; width: 100%; height: 80%;`}
        options={{swipeEasing: true}}
        containerRef={(e) => {
          containerRef.current = e as HTMLDivElement;
        }}
      >
        <Box
          css={_`display: flex; flex-direction: column; align-items: center; gap: 0px;`}
        >
          {years.map((__, i) => {
            const year = reverseYears ? start + i : end - i;
            const s = selected?.year === year;
            return (
              <DateButton
                css={_`width: 100%`}
                key={i}
                active={s}
                current={year === currentYear}
                onClick={() => {
                  onChange(date.set({year}));
                }}
                onTouchEnd={() => {
                  setTouchMove(false);
                  if (!toucheMove) {
                    onChange(date.set({year}));
                  }
                }}
                onTouchMove={() => {
                  setTouchMove(true);
                }}
                ref={s ? selectedRef : undefined}
              >
                {year}
              </DateButton>
            );
          })}
        </Box>
      </PerfectScrollbar>
    </Stack>
  );
};

interface MbProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean
}

export const BtComponent = forwardRef(
  ({active: _, ...restProps}: PropsWithChildren<MbProps>, ref) => (
    <ButtonBase {...restProps} ref={ref as any} />
  ),
);
BtComponent.displayName = 'DateButton';

export const DateButton = styled(BtComponent)<{
  active?: boolean
  current?: boolean
}>(
  ({theme, active, current}) => _`
  height: 32px;
  min-width: 76px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  background-color: ${
  active
    ? theme.palette.secondary.main
    : current
      ? theme.palette.grey[600]
      : 'transparent'
};
  color: white;
  &:hover {
    color: ${!active && !current && theme.palette.grey[400]}
  }
  &:focus-visible {
    border: 1px solid ${theme.colors.all.focus};
  }
`,
);
