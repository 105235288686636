import {HealthcareInsurancePolicy} from '@src/api';
import {useTranslate} from '@src/i18n/useTranslate';
import {phoneNotRequiredUpdateValidator} from '@src/shared/utils/phoneNotRequiredUpdateValidator';
import {postalCodeValidator, ssnTailValidator} from '@src/shared/utils/validators';
import {differenceInYears} from 'date-fns';
import * as Yup from 'yup';

export const useValidationSchema = (insurance: HealthcareInsurancePolicy | undefined) => {
  const {t} = useTranslate('insurance');
  return Yup.object().shape({
    firstName: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    lastName: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    birthDate: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD'))
      .test('is-birthDate', t('ENTER_A_VALID_DATE_OF_BIRTH'), function(value) {
        if (!value) return true;
        const birthDate = new Date(value);
        return differenceInYears(new Date(), birthDate) >= 18;
      }),
    birthSex: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    state: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    city: Yup.string().required(t('REQUIRED_FIELD')),
    address1: Yup.string().required(t('REQUIRED_FIELD')),
    phone: phoneNotRequiredUpdateValidator(t),
    postalCode: postalCodeValidator(t),
    endDate: Yup.string()
      .nullable()
      .test('is-endDate', t('PLEASE_ENTER_AN_END_DATE'), (value) => {
        return !(typeof insurance?.endDate === 'string' && !value);
      }),
    ssnTail: ssnTailValidator(t),
  });
};
