export const touchedData = {
  address1: true,
  firstName: true,
  lastName: true,
  birthDate: true,
  birthSex: true,
  city: true,
  state: true,
  postalCode: true,
  endDate: true,
  ssnTail: true,
  insurancePlan: true,
  policyId: true,
};

export const POSTAL_CODE_LENGTH = 10;
