import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  btn: (t) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 9,
    backgroundColor: t.palette.secondary[200],
    minWidth: 42,
    '&:hover': {
      backgroundColor: t.palette.secondary[200],
      'svg path': {
        fill: t.palette.secondary[800],
      },
    },
    '&:active': {
      'svg path': {
        fill: t.palette.secondary.dark,
      },
    },
  }),
};
