import {DateFormat, dateFormatted} from '@src/shared/utils';

import {IRelationError, ITooltipsListParams} from './types';

export const getRequestDateAndTime = (relationError: IRelationError) => {
  const createdDate = relationError?.createdDate || '';
  const formattedDate = dateFormatted(createdDate, DateFormat.DATE_SHORT) ?? '';
  const formattedTime = dateFormatted(createdDate, DateFormat.TIME_SIMPLE) ?? '';
  return `${formattedDate} ${formattedTime}`;
};

export const getTooltipsList = ({t, newContactBirthDate, checkIfPearsonYounger18}: ITooltipsListParams) => {
  const isYoungerThan18 = checkIfPearsonYounger18(newContactBirthDate);

  return {
    PARENT_AGE_ERROR_MTH: t(
      isYoungerThan18 ? 'PARENT_SHOULD_ALWAYS_BE_OLDER' : 'PARENT_SHOULD_ALWAYS_BE_OVER_18',
    ),
    PARENT_AGE_ERROR_CHD: t(
      isYoungerThan18 ? 'PARENT_SHOULD_ALWAYS_BE_OLDER' : 'PARENT_SHOULD_ALWAYS_BE_OVER_18',
    ),
    PARENT_AGE_ERROR_FTH: t(
      isYoungerThan18 ? 'PARENT_SHOULD_ALWAYS_BE_OLDER' : 'PARENT_SHOULD_ALWAYS_BE_OVER_18',
    ),
    PARENT_AGE_ERROR_GRP: t(
      isYoungerThan18 ? 'GRANDPARENT_SHOULD_ALWAYS_BE_OLDER' : 'GRANDPARENT_SHOULD_ALWAYS_BE_OVER_18',
    ),
    PARENT_AGE_ERROR_GCH: t('GRANDPARENT_SHOULD_ALWAYS_BE_OLDER'),
    'PARENT_AGE_ERROR_Foster child': t('FOSTER_PARENT_SHOULD_ALWAYS_BE_OLDER'),
    LESS_EIGHTEEN_ERROR: t('FOSTER_CHILD_SHOULD_ALWAYS_BE_UNDER_18'),
    GREATER_EIGHTEEN_ERROR_FTH: t('PARENT_SHOULD_ALWAYS_BE_OVER_18'),
    GREATER_EIGHTEEN_ERROR_MTH: t('PARENT_SHOULD_ALWAYS_BE_OVER_18'),
    GREATER_EIGHTEEN_ERROR_GRP: t('GRANDPARENT_SHOULD_ALWAYS_BE_OVER_18'),
    GREATER_EIGHTEEN_ERROR_DEP: t('A_DISABLED_DEPENDENT_SHOULD_ALWAYS_BE_OVER_18'),
    GREATER_EIGHTEEN_ERROR_SPO: t('A_SPOUSE_SHOULD_ALWAYS_BE_OVER_18_YEARS_OLD'),
    GREATER_EIGHTEEN_ERROR_HUSB: t('A_HUSBAND_SHOULD_ALWAYS_BE_OVER_18_YEARS_OLD'),
    GREATER_EIGHTEEN_ERROR_WIFE: t('A_WIFE_SHOULD_ALWAYS_BE_OVER_18_YEARS_OLD'),
    ACCOUNT_STATUS_ERROR: t('PATIENT_IS_BLOCKED_IN_THE_VIVICA_ECOSYSTEM'),
    BIRTH_DATE_ERROR: t('THE_CONTACT_MUST_HAVE_A_DATE_OF_BIRTH_SPECIFIED'),
    RELATION_CONTRADICT_ERROR: t('SELECTED_RELATIONSHIP_TYPE_IS_NOT_VALID'),
  };
};
