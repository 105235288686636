import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  whiteBox: {
    padding: {
      lg: 36,
      sm: 24,
      xs: 18,
    },
    flexGrow: 1,
    mb: {xs: 14, sm: 0},
  },
  content: {
    gap: {xs: 24, sm: 36},
  },
  insuranceWrapper: {
    gap: 18,
  },
  guarantorWrapper: {
    gap: {xs: 12, sm: 18},
  },
  guarantorContentWrapper: {
    flexDirection: {xs: 'column', sm: 'row'},
    gap: {xs: 24, lg: 36},
  },
  noInsuranceDescription: (t) => ({
    color: t.palette.grey[600],
  }),
  contentWrapper: {
    flexGrow: 1,
    gap: {xs: 10, sm: 24},
  },
  addInsuranceButtonOnMobile: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '16px !important',
  },
  wrapperEditButton: {
    flexDirection: 'row',
    gap: {xs: 0, sm: 18},
  },
  listHeaderWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: (t) => ({
    p: 0,
    width: 42,
    height: 42,
    border: {
      sm: `1px solid ${t.palette.primary.main}`,
      xs: 'none',
    },
    backgroundColor: 'transparent',
    borderRadius: 0,
    color: t.palette.primary.main,
    svg: {
      width: {xs: 24, sm: 18},
      height: {xs: 24, sm: 18},
      path: {
        fill: 'currentColor',
      },
    },
    ':hover': {
      borderColor: t.palette.primary.light,
      svg: {
        path: {
          fill: t.palette.primary.light,
        },
      },
    },
    ':active': {
      borderColor: t.palette.primary.dark,
      svg: {
        path: {
          fill: t.palette.primary.dark,
        },
      },
    },
  }),
  listWrapperContainer: {
    flexGrow: 1,
    maxWidth: {sm: 'calc(50% - 12px)', lg: 'calc(50% - 18px)'},
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: {xs: '50%', sm: 'calc(50% + 50px)'},
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
