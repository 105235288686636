import {Box, Typography} from '@mui/material';
import {BirthSex} from '@src/api/account/models';
import {Tooltip} from '@src/components/Tooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as GenderFemale} from '@src/shared/assets/icons/bmi/genderFemale.svg';
import {ReactComponent as GenderMale} from '@src/shared/assets/icons/bmi/genderMale.svg';
import {FC, useCallback, useEffect, useState} from 'react';

import {SexButton} from './styles';
import {SexPickerI} from './types';

export const SexPicker: FC<SexPickerI> = ({defaultSex = BirthSex.Unknown, sex = BirthSex.Male, onChange, sx}) => {
  const {t} = useTranslate('journal');
  const [insideSex, setInsideSex] = useState<BirthSex>(sex === BirthSex.Unknown ? BirthSex.Male : sex);

  useEffect(() => {
    setInsideSex(sex === BirthSex.Unknown ? BirthSex.Male : sex);
  }, [sex]);

  const setFemale = useCallback(() => {
    setInsideSex(BirthSex.Female);
    onChange?.(BirthSex.Female);
  }, [onChange]);

  const setMale = useCallback(() => {
    setInsideSex(BirthSex.Male);
    onChange?.(BirthSex.Male);
  }, [onChange]);

  return (
    <Tooltip
      sx={{width: 214}}
      title={<Typography variant='12_16_500'>{t('YOU_CAN_CHANGE_YOUR_SEX_')}</Typography>}
      placement={'right'}
      disabled={defaultSex === BirthSex.Unknown}>
      <Box sx={sx}>
        {(defaultSex === BirthSex.Male || defaultSex === BirthSex.Unknown) && (
          <SexButton
            aria-label={BirthSex.Male}
            onClick={setMale}
            variant='outlined'
            endIcon={<GenderMale />}
            disabled={defaultSex === BirthSex.Male}
            checked={insideSex === BirthSex.Male}>{t('MALE')}</SexButton>
        )}
        {(defaultSex === BirthSex.Female || defaultSex === BirthSex.Unknown) && (
          <SexButton
            aria-label={BirthSex.Female}
            onClick={setFemale}
            disabled={defaultSex === BirthSex.Female}
            variant='outlined'
            endIcon={<GenderFemale />}
            checked={insideSex === BirthSex.Female}>{t('FEMALE')}</SexButton>
        )}
      </Box>
    </Tooltip>
  );
};
