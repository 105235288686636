import {OverviewDesktopXlOpenSidebar} from './OverviewDesktopXlOpenSidebar';
import {OverviewDesktop} from './OverviewDesktop';
import {OverviewProps} from '@src/types';

export const OverviewDesktopXl = ({isSidebarOpen, ...props}: OverviewProps) => {
  return (
    isSidebarOpen
      ? (
        <OverviewDesktopXlOpenSidebar
          {...props}
          isSidebarOpen={isSidebarOpen}/>
      )
      : (
        <OverviewDesktop
          {...props}
        />
      )
  );
};
