import {TableHeaderItem} from '@src/components/Table/types';
import {Checkbox} from '@src/components/form/CheckboxControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';

const sx = {
  checkbox: {
    mr: 12,
    ml: 7,
    p: 0,
  },
};

export const useHeader = (changeAllTests: (_: any, checked: boolean) => void, checked: boolean) => {
  const {t} = useTranslate('details');
  const {mobile} = useMQuery();
  const HEADER_LIST: TableHeaderItem[] = [
    {
      name: 'check-box',
      action: {
        component: {
          component: (props) => <div><Checkbox sx={sx.checkbox} {...props} /> {t('TEST_PANEL')}</div>,
          props: {color: 'secondary', onChange: changeAllTests, checked},
        },
      },
    },
    {name: t('RESULTS')},
    {name: t('UNITS')},
    {name: t('COMMENTS')},
    {name: t('REFERENCE_INTERVAL')},
  ];
  return {HEADER_LIST: mobile ? [] : HEADER_LIST};
};
