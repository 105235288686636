import {Dialog, DialogActions, DialogTitle} from '@components';
import {Box, Stack, Typography} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {ChangeEvent, useState} from 'react';
import {Button, InputView} from 'ui-kit';

import {sx} from './styles';
import {CreateHealthCaseDialogProps} from './types';

export const CreateHealthCaseDialog = ({
  isOpen,
  onClose,
  healthCase,
  handleChangeName,
  handleCreateLink,
}: CreateHealthCaseDialogProps) => {
  const {mobile} = useMQuery();
  const {t} = useTranslate('healthCases');
  const [isEmpty, setIsEmpty] = useState(false);

  const handleCreateHealthCase = () => {
    if (!healthCase.name.trim()) {
      setIsEmpty(true);
      return;
    }
    handleCreateLink(healthCase);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEmpty(false);
    const value = event.target.value;
    handleChangeName(value);
  };

  if (mobile) {
    return (
      <MobileInteractionView
        isOpen={isOpen}
        onBack={onClose}
        title={t('NEW_HEALTH_CASE')}
        onClose={onClose}
      >
        <Box sx={sx.content}>
          <Typography variant="14_18_500">{t('HEALTH_CASE_NAME')}</Typography>
          <InputView value={healthCase.name} onChange={handleChange} error={isEmpty} />
          {isEmpty && (
            <Typography variant={'12_18_500'} position={'absolute'} color={'error.200'}>
              {t('REQUIRED_FIELD')}
            </Typography>
          )}
        </Box>
        <Stack sx={sx.buttonsContainer}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleCreateHealthCase}>
            {t('ADD')}
          </Button>
        </Stack>
      </MobileInteractionView>
    );
  }

  return (
    <Dialog open={isOpen} size={'sm'} border={'0px solid'}>
      <DialogTitle onClose={onClose}>{t('NEW_HEALTH_CASE')}</DialogTitle>
      <Box sx={sx.content}>
        <Typography variant="14_18_500">{t('HEALTH_CASE_NAME')}</Typography>
        <InputView value={healthCase.name} onChange={handleChange} error={isEmpty} />
        {isEmpty && (
          <Typography variant={'12_18_500'} position={'absolute'} color={'error.200'}>
            {t('REQUIRED_FIELD')}
          </Typography>
        )}
      </Box>
      <DialogActions sx={sx.actionsContainer}>
        <Button
          variant="outlined"
          onClick={onClose}
          color={'secondary'}
          sx={sx.cancelButton}>
          {t('CANCEL')}
        </Button>
        <Button onClick={handleCreateHealthCase} color={'secondary'} sx={sx.cancelButton}>
          {t('ADD')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
