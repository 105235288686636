import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    maxHeight: 210,
    overflowY: 'auto',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
    p: 0,
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    backgroundColor: t.palette.common.white,
  }),
  emptyText: (t) => ({
    p: 12,
    color: t.palette.grey[500],
    fontStyle: 'italic',
    background: t.palette.common.white,
  }),
  optionText: {
    textOverflow: 'ellipsis', overflow: 'hidden',
  },
  searchInput: (t) => ({
    height: 42,
    pr: 16,
    '& .MuiInputBase-input': {
      typography: '14_18_500',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: t.palette.grey[400],
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: t.palette.grey[500],
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: t.palette.grey[600],
      borderWidth: 2,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: t.palette.alert.error,
      borderWidth: 2,
    },
  }),
  errorText: (t) => ({
    alignText: 'left',
    typography: '12_16_500',
    color: t.palette.alert.error,
    position: 'absolute',
    left: 0,
    bottom: -20,
  }),
  label: {
    mb: 3,
    typography: '14_18_700',
  },
  clearIcon: (t) => ({
    color: t.palette.grey[400],
    '& svg': {
      width: 18,
      height: 18,
      '&:hover': {
        color: t.palette.grey[600],
      },
      '&:active': {
        color: t.palette.grey[800],
      },
    },
  }),
};
