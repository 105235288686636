import {DateTime} from 'luxon';

export const isDayDisabled = (
  day: DateTime,
  month: DateTime,
  now: DateTime,
  disabledPast?: boolean,
  disabledFuture?: boolean,
  disableCurrentDay?: boolean,
): boolean => {
  if (!day.hasSame(month, 'month')) {
    return true;
  }

  if (disabledPast && day.startOf('day') < now.minus({day: 1})) {
    return true;
  }

  if (disabledFuture && day.startOf('day') > now) {
    return true;
  }

  if (disableCurrentDay && day.hasSame(now, 'day')) {
    return true;
  }

  return false;
};
