export const breakpoints = {
  xxl: 1400,
  xl: 1057,
  lg: 712,
  sm: 700,
  md: 1032,
};

export const defaultColumns = {
  xxl: 4,
  xl: 4,
  lg: 6,
  sm: 6,
  md: 4,
};

export const customColumns = {
  xxl: 3,
  xl: 6,
  lg: 12,
  sm: 12,
  md: 6,
};
