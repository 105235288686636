import {Stack, Avatar, Typography, Link, SxProps, Theme} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {GeneralInfoProps, getInitialContact} from '@src/pages/Overview/fragments/GeneralInfo';
import {GeneralSubInfo} from '@src/pages/Overview/fragments/GeneralSubInfo';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-icon.svg';
import {envs} from '@src/shared/constants/envs';
import {NO_VALUE, dateFormatted, getFullName, getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {alpha} from '@src/theme/utils';
import {FC} from 'react';
import {IconButtonNew} from 'ui-kit';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  avatar: (t) => ({
    width: '100px',
    height: '100px',
    marginBottom: '24px',
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  container: {
    padding: {
      xs: 0,
      lg: 24,
    },
  },
  personName: {
    pb: 8,
  },
};

export const GeneralInfoDesktop: FC<GeneralInfoProps> = ({contact = getInitialContact(), avatarUrl}) => {
  const {birthDate} = contact;

  return (
    <Stack sx={sx.container}>
      <Stack textAlign="start">
        <Stack justifyContent="space-between" flexDirection="row">
          <Avatar sx={sx.avatar} alt={getFullName(contact)} src={avatarUrl}>
            <Typography fontSize='32px' lineHeight='36px'>
              {getUserInitials(contact)}
            </Typography>
          </Avatar>
          <Link
            height={42}
            target='_blank'
            href={`${envs.PASSPORT_WEB_URL}/profile`}>
            <IconButtonNew color={'tertiary'} size={'lg'}>
              <EditIcon />
            </IconButtonNew>
          </Link>

        </Stack>
        <EllipsisTextTooltip ellipsisText={getName(contact, {initialsOnlyMiddleName: true})} sx={sx.personName} variant='24_32_700' />
        {birthDate && (
          <Typography variant='16_20_500'>
            {dateFormatted(birthDate ?? NO_VALUE)}
          </Typography>
        )}
      </Stack>
      <GeneralSubInfo contact={contact} />
    </Stack>
  );
};
