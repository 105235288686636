import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  recaptcha: (t) => ({
    typography: '12_16_500',
    color: t.palette.grey[500],
    a: {
      color: t.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        color: t.palette.secondary.light,
      },
      '&:active': {
        color: t.palette.secondary.dark,
      },
    },
  }),
};
