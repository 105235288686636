import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {InputControl} from 'ui-kit';

import {FormInputControlProps} from './types';

export const FormInputControl: FC<FormInputControlProps> = ({name, showErrorText = true, ...restProps}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const errorTextHandler = () => {
    if (showErrorText) {
      return error?.message;
    }
    return error?.message ? ' ' : error?.message;
  };

  return (
    <InputControl
      name={name}
      value={value}
      onChange={onChange}
      error={errorTextHandler()}
      {...restProps}
    />
  );
};
