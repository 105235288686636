import {Stack, Typography, Box, Avatar} from '@mui/material';
import {AccessRequest} from '@src/api/accesses';
import {RowInformation} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {DateRange} from '@src/pages/Connections/fragments/Accesses/common/DateRange';
import {useMQuery, useSortOfDate} from '@src/shared/hooks';
import {getUserInitials, dateFormatted} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useApproveAccessesRequest, useDeclineAccessesRequest} from '@src/store/accesses/hooks';
import {alpha} from '@src/theme/utils';
import {Button} from 'ui-kit';

import {sx} from './styles';

export const IncomingRequests = ({data}: { data: AccessRequest[] | null }) => {
  const {t} = useTranslate('accesses');
  const {approve} = useApproveAccessesRequest();
  const {decline} = useDeclineAccessesRequest();
  const sortedData = useSortOfDate(data, 'createdDate');
  const {mobile: isMobile} = useMQuery();

  if (!data?.length) {
    return (
      <Typography
        sx={sx.emptyRequests}>
        {t('NO_INCOMING_REQUESTS')}
      </Typography>
    );
  };
  return (
    <Stack sx={sx.wrapper}>
      {sortedData?.map((request, index) => (
        <Stack
          sx={(t) => ({
            padding: {xs: '12px 18px', lg: '18px 24px'},
            borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
            borderBottom:
              index === sortedData.length - 1
                ? `1px solid ${alpha(t.palette.secondary.main, 14)}`
                : 'none',
            height: {lg: 218},
          })}
          key={request.id}
        >
          <Typography variant="12_16_600" sx={sx.date}>
            {dateFormatted(request.createdDate)}
          </Typography>
          <Box sx={sx.boxContainer}>
            <Avatar sx={sx.requestAvatar} src={request.avatarUrl} alt={''}>
              <Typography variant={isMobile ? '12_16_700' : '14_20_700'}>{getUserInitials(request?.contact)}</Typography>
            </Avatar>
            <Stack sx={sx.widthLimitContainer}>
              <EllipsisTextTooltip ellipsisText={(getName(request?.contact, {short: true}))} sx={sx.personName} />
              <Typography variant="14_18_500" sx={sx.personType}>
                {t(request?.access)}
              </Typography>
            </Stack>
          </Box>
          <Stack mb={isMobile ? 18 : 24}>
            <RowInformation
              spaceBetween={isMobile ? 8 : 0}
              variant={isMobile ? '12_16_700' : '14_18_700'}
              valueTypographyVariant={isMobile ? '12_16_500' : '14_18_500'}
              tooltip
              value={t(`${request?.access}_RIGHTS` as TKeys<'accesses'>)}
              noMargin
              sx={sx.rowInformation}
              name={t('RIGHTS')} />
            <DateRange beginDate={request?.createdDate} endDate={request?.endDate} />
          </Stack>
          <Stack sx={sx.bthContainer}>
            <Button
              sx={sx.button}
              variant='text'
              size='xs'
              color="primary"
              onClick={() => decline({requestId: request?.id})}
            >{t('DECLINE')}</Button>
            <Button
              sx={sx.button}
              variant='text'
              size='xs'
              color="secondary"
              onClick={() => approve({requestId: request?.id})}
            >{t('ACCEPT')}</Button>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
