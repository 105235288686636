import {WBox} from '@components';
import {styled, SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';
import Image from 'shared/assets/icons/zoom-in.svg';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  actionButton: {
    typography: '16_20_600',
    display: 'inline-flex',
    padding: 0,
    minWidth: 'unset',
  },
  imageContainer: {
    display: 'flex',
    maxWidth: {
      md: 636,
      lg: 620,
      sm: 552,
    },
    border: '1px solid',
    borderColor: theme => theme.colors.all.grey10,
    position: 'relative',
    cursor: 'pointer',
    ':after': {
      transition: '0.2s',
      content: '""',
      backgroundColor: theme => theme.colors.all.grey6,
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      color: theme => theme.colors.all.grey10,
      // eslint-disable-next-line @typescript-eslint/no-base-to-string,@typescript-eslint/restrict-template-expressions
      backgroundImage: `url(${Image})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    ':hover': {
      ':after': {
        opacity: 0.5,
      },
    },
    ':active': {
      ':after': {
        backgroundColor: theme => theme.colors.activeGrayBackground,
        opacity: 0.5,
      },
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
  loader: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  mobileShareButton: {
    svg: {
      width: 24,
      height: 24,
    },
  },
};

export const RightContainer = styled(WBox)<{ isSidebarOpen: boolean }>(({theme, isSidebarOpen}) => ({
  padding: '6px 24px 24px 24px',
  alignItems: 'center',

  [theme.breakpoints.only('lg')]: {
    maxWidth: isSidebarOpen ? 'unset' : 626,
    borderBottom: isSidebarOpen && 'unset',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 626,
  },
  [theme.breakpoints.down('lg')]: {
    borderBottom: 'unset',
  },
}));
