import {IFilterResults} from '@src/pages/Results/Results.constant';

export const initialFilter: IFilterResults = {
  collectionDateFrom: null,
  collectionDateTo: null,
  physician: null,
  healthCase: null,
  reportDateFrom: null,
  reportDateTo: null,
};
