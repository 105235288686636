import {useTranslate} from '@src/i18n/useTranslate';

export const useModalContentMap = () => {
  const {t} = useTranslate('connections');

  const sentenceWithUnconfirmedPhone = t('PHONE_NUMBER_ALREADY_IN_USE_BY_ANOTHER_PATIENT');
  const sentenceWithConfirmedPhone = t('PHONE_NUMBER_ALREADY_CONFIRMED_BY_ANOTHER_PATIENT');
  const sentenceWithSimilarStrategy = t('PATIENT_WITH_THIS_DATA_ALREADY_EXISTS');

  const modalContentMap = {
    CONTACT_SIMILAR_STRATEGY: sentenceWithSimilarStrategy,
    UNIQUE_UNCONFIRMED_PHONE: sentenceWithUnconfirmedPhone,
    UNIQUE_CONFIRMED_PHONE: sentenceWithConfirmedPhone,
  };

  return {
    modalContentMap,
  };
};
