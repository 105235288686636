import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialog: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitile: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  bthOk: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
  dialogActions: (t) => ({
    backgroundColor: t.palette.error.main,
    margin: 0,
    p: '16px 24px',
    justifyContent: 'flex-end !important',
    alignItems: 'end',
  }),
  dialogContent: {
    p: '36px 24px !important',
  },
};
