export enum GuarantorFieldsName {
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  BirthDate = 'birthDate',
  BirthSex = 'birthSex',
  SsnTail = 'ssnTail',
  State = 'state',
  City = 'city',
  Address1 = 'address1',
  Address2 = 'address2',
  PostalCode = 'postalCode',
  Phone = 'phone',
}
