import {Box, Divider, Stack, Typography} from '@mui/material';
import {RelationProfile} from '@src/api/relations';
import {RowInformation} from '@src/components';
import Grid from '@src/components/Grid';
import {ListWrapper} from '@src/components/ListWrapper';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as InfoIcon} from '@src/shared/assets/icons/info-without-background.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getName} from '@src/shared/utils/getName';

import {RenderEmails, RenderPhones, hasMainItems, hasOptionalItems} from '../../../helpers/RenderPhonesAndEmails';
import {calcListWrapperPaddings, calcListWrapperSpaceBetween, calcMarginTop, nameTypographyVariant, valueTypographyVariant} from '../styled';

import {sx} from './styles';

export const RelationContactsWithAccess = ({relation}: { relation: RelationProfile | null }) => {
  const {t} = useTranslate('connections');

  const {mobile: isMobile, desktop: isDesktop} = useMQuery();

  const isPhone = relation?.phones?.length;
  const isEmail = relation?.emails?.length;

  const spaceBetween = calcListWrapperSpaceBetween(isMobile);
  const nameTypography = nameTypographyVariant(isMobile);
  const valueTypography = valueTypographyVariant(isMobile);

  return (
    <>
      <Stack sx={sx.upperBlockContainer}>
        <Box sx={sx.listContainer}>
          <Grid container columnSpacing={24} gap={isMobile ? 24 : 0}>
            <Grid
              gap={24}
              xs={12}
              lg={6}
              sm={6}
              md={6}
              xl={6}>
              <Stack>
                <Typography component='h4' sx={sx.mainTitle}>{t('PHONE_LIST')}</Typography>
                {
                  isPhone
                    ? (
                      <>
                        <Stack>
                          {hasMainItems(relation?.phones) && (
                            <Typography sx={sx.phoneAndEmailTitleMain}>{t('MAIN')}</Typography>
                          )}
                          <RenderPhones phones={relation?.phones} isMainPhone />
                        </Stack>
                        <Stack>
                          {
                            hasOptionalItems(relation?.phones) && (
                              <Typography sx={sx.phoneAndEmailTitleOptional} mt={calcMarginTop(relation?.phones)}>{t('OPTIONAL')}</Typography>
                            )}
                          <RenderPhones phones={relation?.phones} isMainPhone={false} />
                        </Stack>
                      </>
                    )

                    : (
                      <Typography sx={sx.notSpecifiedText} pb={12}>
                        {t('NOT_SPECIFIED')}
                      </Typography>
                    )
                }
              </Stack>
            </Grid>
            <Grid
              xs={12}
              lg={6}
              sm={6}
              md={6}
              xl={6}>
              <Stack>
                <Typography component='h4' sx={sx.mainTitle} mb={36}>
                  {t('EMAIL_LIST')}
                </Typography>
                {
                  isEmail
                    ? (
                      <>
                        <Stack>
                          {hasMainItems(relation?.emails) && (
                            <Typography sx={sx.phoneAndEmailTitleMain}>{t('MAIN')}</Typography>
                          )}
                          <RenderEmails emails={relation?.emails} isMainEmail />
                        </Stack>
                        <Stack>
                          {
                            hasOptionalItems(relation?.emails) && (
                              <Typography
                                sx={sx.phoneAndEmailTitleOptional}
                                mt={calcMarginTop(relation?.phones)}>
                                {t('OPTIONAL')}
                              </Typography>
                            )}
                          <RenderEmails emails={relation?.emails} isMainEmail={false} />
                        </Stack>
                      </>
                    )
                    : (
                      <Typography sx={sx.notSpecifiedText}>
                        {t('NOT_SPECIFIED')}
                      </Typography>
                    )
                }

              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={sx.boxInfoContainer}>
          <InfoIcon />
          <Typography sx={sx.editInfoText}>
            {t('YOU_CANT_EDIT_THIS_INFORMATION', {name: getName(relation?.contact, {short: true})})}
          </Typography>
        </Box>
      </Stack>
      <Stack sx={sx.loverBlockContainer}>
        {!isDesktop && <Divider sx={sx.divider} />}
        <Stack sx={sx.addressContainer}>
          <Typography
            component='h4'
            sx={sx.mainTitle}>
            {t('ADDRESS')}
          </Typography>
        </Stack>
        {
          relation?.actualAddresses?.main
            ? (
              <ListWrapper
                stylesSx={{mt: {lg: 8}}}
                spaceBetween={spaceBetween}
                padding={calcListWrapperPaddings(isDesktop, isMobile)}
                noLast={{xs: 0}}>
                <RowInformation
                  noWrap={!isDesktop}
                  variant={nameTypography}
                  valueTypographyVariant={valueTypography}
                  tooltip={isDesktop}
                  value={relation?.actualAddresses.main}
                  noMargin
                  name={t('STREET_ADDRESS')} />
                <RowInformation
                  noWrap={!isDesktop}
                  variant={nameTypography}
                  valueTypographyVariant={valueTypography}
                  tooltip={isDesktop}
                  value={relation?.actualAddresses.additional}
                  noMargin
                  name={t('APARTMENT_SUIT_OR_UNIT')} />
                <RowInformation
                  noWrap={!isDesktop}
                  variant={nameTypography}
                  valueTypographyVariant={valueTypography}
                  tooltip={isDesktop}
                  value={relation?.actualAddresses.city}
                  noMargin
                  name={t('CITY')} />
                <RowInformation
                  noWrap={!isDesktop}
                  variant={nameTypography}
                  valueTypographyVariant={valueTypography}
                  tooltip={isDesktop}
                  value={t(relation?.actualAddresses?.state as TKeys<'common'>)}
                  noMargin
                  name={t('STATE')} />
                <RowInformation
                  noWrap={!isDesktop}
                  variant={nameTypography}
                  valueTypographyVariant={valueTypography}
                  tooltip={isDesktop}
                  value={relation?.actualAddresses.postalCode}
                  noMargin
                  name={t('ZIP_CODE')} />
              </ListWrapper>
            )
            : (
              <Typography sx={sx.notSpecifiedText}>
                {t('NOT_SPECIFIED')}
              </Typography>
            )}
      </Stack>
    </>
  );
};
