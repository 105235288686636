import {Stack, styled} from '@mui/material';
import {initialColors, paletteColors} from '@src/theme';

export const sx = {
  cardWrapper: {
    border: '1px solid',
    borderColor: initialColors.blueLight,
    padding: '18px',
    flexDirection: 'row',
    background: initialColors.white,
    transition: 'background-color 0.1s ease',
    '&:active': {
      backgroundColor: initialColors.secondary400,
      borderColor: initialColors.blueCardBorder,
    },
  },
  checkbox: {
    padding: 5,
    transform: 'translate(-10px, -4px)',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
};

export const MobileCardContainer = styled(Stack)<{ isSelected: boolean, isDisabled: boolean }>(({isSelected, isDisabled}) => {
  const isDisabledBorder = isDisabled ? paletteColors.grey[300] : initialColors.blueCardBorder;
  const isDisabledBackground = isDisabled ? paletteColors.grey[200] : initialColors.secondary400;
  return {
    border: '1px solid',
    borderColor: isSelected ? isDisabledBorder : initialColors.blueLight,
    padding: '18px',
    color: isDisabled ? paletteColors.grey[300] : paletteColors.grey[700],
    flexDirection: 'row',
    background: initialColors.white,
    transition: 'background-color 0.1s ease',
    backgroundColor: isSelected ? isDisabledBackground : initialColors.white,
    '&:active': {
      backgroundColor: initialColors.secondary400,
      borderColor: initialColors.blueCardBorder,
    },
  };
});
