
type StringOpt = string | null | undefined;

const SERVER_EMPTY = '--';

export interface PersonName {
  firstName?: StringOpt
  lastName?: StringOpt
  middleName?: StringOpt
}

export const getName = (
  data: PersonName | null | undefined,
  optionsOrShort?:
  | boolean
  | {
    prefix?: StringOpt
    suffix?: StringOpt
    short?: boolean
    initialsOnlyMiddleName?: boolean
    initialsOnlyMiddleNameWithDot?: boolean
    withoutMiddleName?: boolean;
  },
) => {
  let suffix: StringOpt, prefix: StringOpt, short: boolean, initialsOnlyMiddleName: boolean, initialsOnlyMiddleNameWithDot: boolean, withoutMiddleName: boolean;

  if (typeof optionsOrShort === 'object' && optionsOrShort) {
    ({suffix, prefix, short = false, initialsOnlyMiddleName = false, initialsOnlyMiddleNameWithDot = false, withoutMiddleName = false} = optionsOrShort);
    if (suffix === SERVER_EMPTY) suffix = undefined;
    if (prefix === SERVER_EMPTY) prefix = undefined;
  } else {
    short = !!optionsOrShort;
    initialsOnlyMiddleName = false;
    initialsOnlyMiddleNameWithDot = false;
    withoutMiddleName = false;
  }

  const getInitials = (name: string) => name.charAt(0);

  const getMiddleName = () => {
    if (withoutMiddleName || !data?.middleName) return;
    if (initialsOnlyMiddleName) return getInitials(data.middleName);
    if (initialsOnlyMiddleNameWithDot) return `${getInitials(data.middleName)}.`;
    return !short ? data.middleName : null;
  };

  return (
    data
      ? [
        prefix,
        data.firstName,
        getMiddleName(),
        data.lastName,
        suffix,
      ]
      : []
  )
    .filter(Boolean)
    .join(' ');
};
