import {Switch as SwitchMui, SwitchProps} from '@mui/material';

export const Switch = (props: SwitchProps) => {
  return (
    <>
      <SwitchMui
        {...props}
        sx={{
          '.MuiSwitch-track': {
            height: 8,
            mt: 5,
            minWidth: 40,
            backgroundColor: t => t.palette.grey[500],
            opacity: 1,
          },
          '.MuiSwitch-thumb': {
            width: 22,
            height: 22,
          },
          '.MuiSwitch-switchBase': {
            '&.Mui-checked': {
              transform: 'translateX(24px)',
              '& .MuiSwitch-thumb': {
                backgroundColor: t => t.palette.common.white,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: t => t.palette.secondary[900],
                opacity: 1,
              },
            },
          },
        }}/>
    </>
  );
};
