import {Stack} from '@mui/material';
import {getBase64DisplayLink} from '@src/pages/StorageDocument/helpers';
import {FC, useState} from 'react';

import {DocumentFile} from '../../types';

import {FileCard} from './FileCard/FileCard';
import {sx} from './styles';
import {FilesCardProps} from './types';

export const FilesCard: FC<FilesCardProps> = ({documentsDetails}) => {
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);

  const getImgSrc = (documentsDetailsArray: DocumentFile[]) => {
    if (documentsDetailsArray && documentsDetailsArray.length > 0) {
      return documentsDetailsArray.map((doc, index) => {
        return {
          ...documentsDetailsArray[index],
          src: getBase64DisplayLink(doc?.data.contentType, doc?.data.data),
        };
      });
    }
    return [];
  };

  const documents = getImgSrc(documentsDetails);

  const handleOpenMenuId = (id: string | null) => {
    setOpenMenuId(id);
  };

  return (
    <Stack sx={sx.mainContainer}>
      {documents.map((data) => (
        <FileCard
          key={data.id}
          data={data}
          openMenuId={openMenuId}
          getOpenMenuId={handleOpenMenuId}
        />
      ))}
    </Stack>
  );
};
