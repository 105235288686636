import {useCallback, useEffect, useRef, useState} from 'react';

export const useContainerAlignment = () => {
  const [alignItems, setAlignItems] = useState<string>('center');
  const containerWidth = useRef<HTMLDivElement | null>(null);

  const updateAlignItems = useCallback(() => {
    const minContainerWidth = 530;
    const isContainerNarrow = containerWidth.current && containerWidth.current.offsetWidth < minContainerWidth;
    setAlignItems(isContainerNarrow ? 'flex-start' : 'center');
  }, []);

  useEffect(() => {
    updateAlignItems();
    window.addEventListener('resize', updateAlignItems);
    return () => {
      window.removeEventListener('resize', updateAlignItems);
    };
  }, [updateAlignItems]);

  return {containerWidth, alignItems};
};
