/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {ButtonBase} from '@mui/material';
import {ReactComponent as Question} from '@src/shared/assets/icons/question.svg';

export const SupportMenuButtonStyled = styled(ButtonBase)({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'end',
  '&:hover, &:active': {
    '.sandwich-menu-open &': {
      backgroundColor: 'transparent',
    },
  },
});

export const QuestionStyled = styled(Question)`
  path {
    fill: ${({theme: t}) => t.palette.grey[800]};
  }
`;
