export const sx = {
  dialogWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
    pointerEvents: 'all',
  },
  dialog: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    bgcolor: 'common.white',
    boxShadow: 3,
    zIndex: 1341,
  },
};
