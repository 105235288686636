import {Box, Stack, Typography} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';

import {sx} from './styles';
import {Props} from './types';

export const Recomendations = ({
  recommendation,
  description,
  isFetching,
}: Props) => {
  const {t} = useTranslate('journal');

  if (isFetching) {
    return (
      <WBox sx={sx.recomendationsBlock}>
        <Box sx={sx.fetchingBlock}>
          <PulseLoader loading />
        </Box>
      </WBox>
    );
  }

  return (
    <WBox sx={sx.recomendationsBlock}>
      <Stack sx={sx.textBlock} >
        {!description && !recommendation && (
          <>
            <Typography sx={sx.title} >{t('RECOMMENDATIONS')}</Typography>
            <Typography sx={sx.placeholder}>{t('TO_SEE_THE_RECOMMENDATIONS_')}</Typography>
          </>
        )}
        {description && <Typography sx={sx.title} >{t('DETAILS')}</Typography>}
        <Stack sx={sx.description}>
          {description && (
            <>
              <Stack sx={sx.subTextBlock}>
                <Typography sx={sx.subtitle} >{t('DESCRIPTION')}</Typography>
                <Typography sx={sx.text} dangerouslySetInnerHTML={{__html: description}} />
              </Stack>
            </>
          )}
          {recommendation && (
            <Stack sx={sx.subTextBlock}>
              <Typography sx={sx.subtitle}>{t('RECOMMENDATIONS')}</Typography>
              <Typography sx={sx.text} dangerouslySetInnerHTML={{__html: recommendation}} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </WBox>

  );
};
