import {Stack, Typography, Box} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Image404} from '@src/shared/assets/icons/404.svg';
import {useMQuery} from '@src/shared/hooks';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sx} from './styles';

export const NotFound: FC = () => {
  const {mobile, tablet, desktop} = useMQuery();
  const {t, ready} = useTranslate('common');
  const textTablet = tablet ? '18_24_500' : '14_18_500';
  const textVariant = desktop ? '22_26_500' : textTablet;

  return (
    <Stack sx={sx.container}>
      <Stack sx={sx.body}>
        <Stack sx={sx.whiteBox}>
          <Stack sx={sx.content} gap={48}>
            <Stack height={{xs: 113, sm: 164}}>
              <Box component={Image404} height={'100%'} />
            </Stack>
            {ready && (
              <Stack gap={12} sx={sx.textContent}>
                <Typography variant={mobile ? '24_34_700' : '36_38_700'}>
                  {t('PAGE_NOT_FOUND')}
                </Typography>
                <Typography variant={textVariant}>
                  {t('SORRY_THE_PAGE')}
                </Typography>
                <Stack
                  direction={{xs: 'column', md: 'row'}}
                  sx={sx.lineWithButton}
                >
                  <Typography variant={textVariant}>
                    {t('TRY_TO_RETURN_TO')}
                  </Typography>
                  <Button
                    size={'sm'}
                    component={Link}
                    to={{
                      pathname: ROUTERS_PATH.OVERVIEW,
                      search: window.location.search,
                    }}
                  >
                    {t('VIVICA_PATIENT')}
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
