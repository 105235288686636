import {styled} from '@mui/material';
import {ButtonSize, Button} from 'ui-kit';

interface IButtonProps {
  active: boolean
  size?: ButtonSize
  fullWidth?: boolean
}

export const RadioButton = styled(Button)<IButtonProps>(({active, theme, size = 'small', fullWidth}) => ({
  border: `2px solid ${theme.palette.secondary.main}`,
  backgroundColor: active ? theme.palette.secondary.main : 'transparent',
  color: active ? '#FFFFFF' : theme.palette.secondary.main,
  minWidth: size === 'lg' ? '136px' : '90px',
  flex: fullWidth ? '1 1 auto' : 'initial',
  height: size === 'lg' ? '42px' : '32px',
  '&.MuiButtonBase-root:hover': {
    backgroundColor: active ? theme.palette.secondary.main : 'transparent',
  },
}));

export const Stripe = styled('div')`
  display: flex;
  height: 56px;
  justify-content: end;
  align-items: center;
  padding: 0 36px;
  border: 1px solid ${({theme}) => theme.colors.borderLight};
  border-left: none;
  border-right: none;
`;
