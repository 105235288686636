import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  selectItem: (t) => ({
    height: 48,
    '&.Mui-selected': {
      backgroundColor: `${t.palette.secondary._14 || ''} !important`,
      color: `${t.palette.secondary.main || ''} !important`,
    },
  }),
  label: {
    color: 'grey.700',
    transform: 'translate(14px, 55%)',
    width: 'auto !important',
    typography: '14_20_700',
    '&.Mui-focused': {
      color: 'grey.700',
    },
  },
  optionsContainer: {
    flexGrow: 1,
    overflowY: 'scroll',
    mb: 78,
  },
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    padding: 18,
    width: '100%',
  },
};
