import {initialColors} from '@src/theme';

export const sx = {
  caseWrapper: {
    borderBottom: '1px solid',
    borderColor: initialColors.blueLight,
    padding: '18px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  menuList: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  menuItem: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    padding: '14px 12px',
  },
};
