import {SxProps, TooltipProps, Typography, TypographyProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {ReactNode, useEffect, useRef, useState} from 'react';

import {Tooltip} from '../Tooltip';

interface Props extends Omit<TypographyProps, 'variant'> {
  variant?: TypographyProps['variant']
  ellipsisText: ReactNode
  sx?: SxProps<Theme>
  placement?: TooltipProps['placement']
  sxForTooltip?: SxProps<Theme>
  arrowMarginTop?: string
  heightCheck?: boolean
}

export const EllipsisTextTooltip = ({variant = '18_24_700', ellipsisText, sx, placement = 'bottom', sxForTooltip, arrowMarginTop, heightCheck}: Props) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (textRef.current) {
        if (heightCheck) {
          setIsTooltipVisible(textRef.current.offsetHeight < textRef.current.scrollHeight);
        } else {
          setIsTooltipVisible(textRef.current.offsetWidth < textRef.current.scrollWidth);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [ellipsisText]);

  return (
    <Tooltip
      title={isTooltipVisible ? ellipsisText : ''}
      placement={placement}
      arrow
      sx={sxForTooltip}
      arrowMarginTop={arrowMarginTop}
    >
      <Typography
        variant={variant}
        ref={textRef}
        sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer', ...sx}}
      >
        {ellipsisText}
      </Typography>
    </Tooltip>
  );
};
