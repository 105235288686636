import {Theme} from '@mui/material';
import {DynamicResultWithAnalysesData} from '@src/store/results/helpers';

import {ETextResults, TextResults} from '../constants/results';

export const getCriticalColor = (data: DynamicResultWithAnalysesData, t: Theme) => {
  const {isCriticalRefMark, isRefMark, qualityReference, value} = data;
  if (isCriticalRefMark) return `${t.colors.all.primaryLight}`;
  if (!!qualityReference?.isOutOfRef && (value && TextResults.includes(value as ETextResults))) return `${t.colors.all.primaryLight}`;
  if (isRefMark || !!qualityReference?.isOutOfRef) return `${t.colors.all.attention}`;
  return `${t.colors.all.valid}`;
};
