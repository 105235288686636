import {ROUTERS_PATH} from '@src/routers';
import {NavigateFunction} from 'react-router-dom';

import {MainTabs, SubTabs} from './types';

export const createNavigateHandler = (navigate: NavigateFunction, id?: string) => (tab: MainTabs | SubTabs) => {
  switch (tab) {
    case MainTabs.INFO:
      return () => navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/about`);
    case MainTabs.RELATIONS:
      return () => navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/user-relations`);
    case MainTabs.RESULTS:
      return () => navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/results`);
    case SubTabs.PERSONAL:
      return () => navigate('about');
    case SubTabs.CONTACTS:
      return () => navigate('contacts');
    case SubTabs.HEALTH_POLICIES:
      return () => navigate('health-policies');
    default:
      return () => {};
  }
};
