import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  contentContainer: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary[500],
    padding: {xs: '14px', sm: '12px 18px 10px 18px'},
    backgroundColor: t.palette.secondary[200],
    boxSizing: 'border-box',
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'start',
  }),
  container: (t) => ({
    flexGrow: 1,
    p: 24,
    height: 'auto',
    minWidth: 0,
    [t.breakpoints.between('sm', 'md')]: {
      padding: 0,
      border: 'none',
    },
    [t.breakpoints.down('sm')]: {
      width: 'auto',
      backgroundColor: t.palette.common.white,
      minHeight: 'calc(100vh - 190px)',
    },
    [t.breakpoints.between('sm', 'lg')]: {
      padding: 24,
    },
    [t.breakpoints.only('xs')]: {
      padding: 0,
    },
  }),
  content: {
    display: 'flex',
    width: '100%',
  },
  avatar: (t) => ({
    width: {xs: 40, sm: 72},
    height: {xs: 40, sm: 72},
    marginRight: {xs: 12, sm: 16},
    backgroundColor: t.palette.secondary[600],
    color: t.palette.common.white,
  }),
  personBirthDay: {
    minHeight: {xs: 38, sm: 50, lg: 40},
    paddingTop: 6,
    paddingBottom: {xs: 12},
    typography: {xs: '12_16_500', sm: '14_18_500'},
  },
  personType: {
    color: 'grey.500',
    typography: {xs: '12_16_500', sm: '14_24_500'},
  },
  personName: {
    typography: {xs: '14_18_700', sm: '18_22_700'},
  },
  title: {
    typography: {xs: '22_30_500', sm: '24_34_500'},
    mb: {xs: 18, sm: 24},
  },
  mainContainer: (t) => ({
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      minHeight: 'calc(100vh - 276px)',
    },
    [t.breakpoints.only('xs')]: {
      flexGrow: 'unset',
      gap: 34,
      flexDirection: 'column',
      minHeight: 'calc(100vh - 308px)',
    },
  }),
  avatarTypography: {
    typography: {xs: '14_18_700', sm: '24_28_600', lg: '24_32_700'},
  },
  widthLimitContainer: {
    minWidth: '10%',
  },
  mobileBthContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  emptyFragment: (t) => ({
    typography: {
      xs: '12_18_500',
      sm: '14_18_500',
    },
    color: t.palette.grey[500],
  }),
  emptyFragmentTitle: {
    typography: {
      xs: '24_30_500',
      lg: '24_34_500',
    },
  },
};
