import {ISODate, UUID} from '@src/types';

export enum CompanyAccessType {
  CreatorContact = 'CREATOR_CONTACT',
  DocumentCreator = 'DOCUMENT_CREATOR',
  ContactMedcare = 'CONTACT_MEDCARE',
  AccountEmployer = 'ACCOUNT_EMPLOYER',
}

export enum AccountAccessType {
  CreatorContact = 'CREATOR_CONTACT',
  CreatorAccount = 'CREATOR_ACCOUNT',
  ContactRepresent = 'CONTACT_REPRESENT',
  ContactProxy = 'CONTACT_PROXY',
}

export enum RaceType {
  AmericanIndianOrAlaskaNative = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  Asian = 'ASIAN',
  BlackOrAfricanAmerican = 'BLACK_OR_AFRICAN_AMERICAN',
  NativeHawaiianOrOtherPacificIslander = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  White = 'WHITE',
  Other = 'OTHER',
  Asku = 'ASKU',
  Unknown = 'UNKNOWN'
}

export enum SexualOrientationType {
  LesbianGayHomosexual = 'LESBIAN_GAY_HOMOSEXUAL',
  StraightHeterosexual = 'STRAIGHT_HETEROSEXUAL',
  Bisexual = 'BISEXUAL',
  Oth = 'OTH',
  Unk = 'UNK',
  Asku = 'ASKU'
}

export enum GenderType {
  Male = 'MALE',
  Female = 'FEMALE',
  FemaleToMale = 'FEMALE_TO_MALE',
  MaleToFemale = 'MALE_TO_FEMALE',
  Genderqueer = 'GENDERQUEER',
  Oth = 'OTH',
  Asku = 'ASKU'
}

export enum EthnicityType {
  HispanicOrLatino = 'HISPANIC_OR_LATINO',
  NotHispanicOrLatino = 'NOT_HISPANIC_OR_LATINO',
  Oth = 'OTH',
  Unk = 'UNK',
  Asku = 'ASKU'
}

export enum BirthSex {
  Male = 'MALE',
  Female = 'FEMALE',
  Unknown = 'UNKNOWN'
}

export interface CompanyAccessContact {
  birthDate: ISODate
  birthDateS: ISODate
  birthSex: BirthSex
  ethnicity: EthnicityType
  firstName: string
  gender: GenderType
  guarantorId: UUID
  id: UUID
  lastName: string
  middleName: string
  race: RaceType
  sexualOrientation: SexualOrientationType
  ssnTail: string
}

export interface CompanyAccess {
  access: CompanyAccessType
  beginDate: ISODate
  company: {
    description: string
    id: UUID
    logoUrl: string
    name: string
  }
  endDate: ISODate
  id: UUID
}

export interface AccountAccess {
  access: AccountAccessType
  avatarUrl: string
  beginDate: ISODate
  contact: CompanyAccessContact
  endDate: ISODate
  id: UUID
}

export function isAccountAccesses (accesses: AccountAccess | CompanyAccess): accesses is AccountAccess {
  return 'contact' in accesses;
}

export interface Access {
  companyAccesses: CompanyAccess[]
  incomingAccountAccesses: AccountAccess[]
  outgoingAccountAccesses: AccountAccess[]
}

export interface AccessRequest {
  access: AccountAccessType
  avatarUrl: string
  beginDate: string
  contact: AccessRequestContact
  createdDate: string
  endDate: string
  id: string
}

export interface AccessRequestContact {
  firstName?: string
  lastName?: string
  middleName?: string
}

export interface AccessRequests {
  incoming: AccessRequest[]
  outgoing: AccessRequest[]
}
