import {EditDocumentDialog} from '@src/components/EditDocumentDialog';
import {useFileData} from '@src/pages/StorageDocument/hooks';
import {FC} from 'react';

import {EditDocumentDialogWrapperProps} from './types';

export const EditDocumentDialogWrapper: FC<EditDocumentDialogWrapperProps> = ({onClose, id}) => {
  const {fileDetails, handleUpdateFile} =
    useFileData(id);

  if (!fileDetails) {
    return null;
  }

  return (
    <EditDocumentDialog
      isOpen
      onClose={onClose}
      onSave={handleUpdateFile}
      fileDetails={fileDetails}
    />
  );
};
