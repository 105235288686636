import {useTranslate} from '@src/i18n/useTranslate';
import {parsePhoneNumber} from 'react-phone-number-input';
import * as yup from 'yup';

export const phoneNotRequiredUpdateValidator = (
  t: ReturnType<typeof useTranslate<'common'>>['t'],
) =>
  yup
    .string()
    .nullable()
    .test('validate-phone', t('PLEASE_ENTER_A_VALID_PHONE'), (value) => {
      if (!value) {
        return true;
      }
      const phoneNumber = parsePhoneNumber(value ?? '', 'US');
      return phoneNumber?.isValid() ?? false;
    });
