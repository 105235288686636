import {DatePickerRange} from '@components';
import {Grid} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/arrow-down.svg';
import {ReactComponent as CalendarIcon} from '@src/shared/assets/icons/calendar_secondary.svg';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {useMQuery} from '@src/shared/hooks';
import {INITIAL_FILTERS, INITIAL_DATES} from '@src/store/healthCases/constants';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {HealthCasesFilters} from '@src/store/healthCases/types';
import {useAppDispatch, useAppSelector} from '@store';
import {debounce} from 'lodash-es';
import {ChangeEvent, FC, useEffect, useState} from 'react';
import {IconButton, SearchControl} from 'ui-kit';

import {DELAY_TIME} from '../../constants';

import {FiltersContainer, sx} from './styles';
import {FiltersProps} from './types';

export const Filters: FC<FiltersProps> = ({isSidebarOpen}) => {
  const {t, ready} = useTranslate('healthCases');
  const {mobile} = useMQuery();
  const [isOpenDateRange, setIsOpenDateRange] = useState(false);

  const filters = useAppSelector((state) => state.healthCases.cases.filters);
  const dispatch = useAppDispatch();

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: HealthCasesFilters
    updatedValue?: { key: keyof HealthCasesFilters, value: any }
  }) => {
    dispatch(healthCasesActions.setFilters({newState, updatedValue}));
  };

  const handleChangeDate = (name: keyof HealthCasesFilters) => (date: Date | null) => {
    handleDispatchSetFilters({
      updatedValue: {
        key: name,
        value: date,
      },
    });
  };

  const handleClearDate = () => {
    handleDispatchSetFilters({newState: {...filters, ...INITIAL_DATES}});
  };

  const handleDebouncedChangeSearch = debounce((value: string) => {
    handleDispatchSetFilters({
      updatedValue: {
        key: 'phrase',
        value,
      },
    });
  }, DELAY_TIME);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleDebouncedChangeSearch(value);
  };

  useEffect(() => {
    return () => {
      handleDebouncedChangeSearch.cancel();
      handleClearAllFilters();
    };
  }, []);

  const handleClearAllFilters = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      <FiltersContainer>
        <Grid
          direction={"row"}
          container
          flexGrow={1}
          gap={24}>
          <Grid
            xs={12}
            sm={5.5}
            md={5.5}
            lg={5.5}
            xl={3.5}
            sx={sx.searchWrapper}
          >
              <SearchControl
                label={mobile ? '' : t('SEARCH')}
                placeholder={t('NAME_OR_DESCRIPTION')}
                value={filters.phrase}
                onChange={handleChangeSearch}
              />
              {mobile && (
                <IconButton
                  strokeIcon
                  color={'secondary'}
                  variant={'outlined'}
                  size='lg'
                  onClick={() => setIsOpenDateRange(!isOpenDateRange)}
                  sx={{transform: isOpenDateRange ? 'rotate(180deg)' : undefined}}
                >
                  {isOpenDateRange ? <ArrowIcon /> : <CalendarIcon />}
                </IconButton>
              )}
            </Grid>
            {(!mobile || isOpenDateRange) && (
            <Grid
              xs={12}
              sm={5.5}
              md={5.5}
              lg={5.5}
              xl={3.5}>
              <DatePickerRange
                sx={{mb: '0 !important'}}
                isIconVisible={false}
                variant="outlined"
                label={t('DATE')}
                startDate={filters.from}
                endDate={filters.to}
                setStartDate={handleChangeDate('from')}
                setEndDate={handleChangeDate('to')}
                clearDate={handleClearDate}
                labelTop
                startRangeWithCurrentMonth
                />
            </Grid>
          )}
          </Grid>
        {!mobile && (
          <IconButton color={'secondary-light'} onClick={handleClearAllFilters}>
            <RefreshIcon />
          </IconButton>
        )}
      </FiltersContainer>
    </>
  );
};
