import {Stack, Typography, debounce, Box, ListItemText} from '@mui/material';
import {
  InsurancePlansList,
  HealthcareInsurancePolicyChangeRequest,
} from '@src/api/insurances';
import {PageHeader as PageTitle} from '@src/components';
import {ComboBoxInput} from '@src/components/ComboBoxInput';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH} from '@src/pages/Connections/constants';
import {ROUTERS_PATH} from '@src/routers';
import {envs} from '@src/shared/constants/envs';
import {
  MAX_ADDRESS_LENGTH,
  MAX_CITY_LENGTH,
  MAX_INPUT_LENGTH,
  MAX_POLICY_ID_LENGTH,
  MAX_SSN_LENGTH,
} from '@src/shared/constants/formFields';
import {useErrorTextHeightObserve} from '@src/shared/hooks/useErrorTextHeightObserve';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useScrollToTop} from '@src/shared/hooks/useScrollToTop';
import {dateToFormat} from '@src/shared/utils';
import {getInsurancePlanError} from '@src/shared/utils/getInsurancePlanError';
import {getPolicyIdError} from '@src/shared/utils/getPolicyIdError';
import {validateValue} from '@src/shared/utils/validateValue';
import {RootState, useAppDispatch} from '@src/store';
import {useAccountProfile} from '@src/store/account/hooks';
import {
  useInsuranceCarriers,
  useInsurancePlans,
  useInsurances,
} from '@src/store/insurance/hooks';
import {createEditDeleteInsuranceThunk} from '@src/store/insurance/slice';
import {notifyActions} from '@src/store/notifications/slice';
import {RelationshipTypes} from '@src/types';
import {Nullable} from '@src/types/NullableModel';
import {Formik, FormikProps, FormikTouched} from 'formik';
import {isEqual} from 'lodash-es';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PhoneInputControl, Button, InputControl, SelectControl, Option} from 'ui-kit';

import {
  birthSexSelectOptions,
  relationTypesSelectOptions,
  statesSelectOptions,
} from '../constants';
import {getColSpacing} from '../utils/getColSpacing';

import {initialValue, touchedData} from './constants';
import {StyledForm, sx} from './styles';
import {SaveDataHealthPolicies} from './types';
import {useValidationSchema} from './utils/useValidationSchema';

export const AddInsurance = () => {
  const {t, ready} = useTranslate('insurance');
  const dispatch = useAppDispatch();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {accountProfile, fetching: isAccountFetching} = useAccountProfile();
  const {healthcareInsurancePolicies, activeHealthcareInsurancesPolicies: activeInsurance, fetching: isInsurancesLoading} = useInsurances();
  const isLoading = isAccountFetching || isInsurancesLoading;
  const validationSchema = useValidationSchema(healthcareInsurancePolicies);
  const navigate = useNavigate();
  const {getInsuranceCarriers} = useInsuranceCarriers();
  const [searchPrimaryText, setSearchPrimaryText] = useState<string>('');
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlansList[] | null>([]);
  const [insuranceCarrierId, setInsuranceCarrierId] = useState('');
  const [insuranceCarrierError, setInsuranceCarrierError] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState<string>('');
  const [isSubmit, setSubmit] = useState(false);
  const insuranceCarriers = useSelector((state: RootState) => state.insurance.insuranceCarriers);
  const isPrimaryInsurance =
    activeInsurance?.length && !!activeInsurance.find((insurance) => insurance.primary);
  const handlePrimaryTextChange = (value: string) => {
    setSearchPrimaryText(value);
  };

  const debouncedPrimaryChangeHandler = useCallback(debounce(handlePrimaryTextChange, 300), []);
  const {insurancePlans: plans, isInsurancePlansEmpty} = useInsurancePlans(insuranceCarrierId);

  const colSpacing = getColSpacing(isDesktop);

  const createInsurance = useCallback(
    (data: HealthcareInsurancePolicyChangeRequest) => {
      dispatch(createEditDeleteInsuranceThunk(data))
        .unwrap()
        .then(() => {
          navigate(ROUTERS_PATH.INSURANCE);
        }).catch(() => {});
    },
    [dispatch],
  );

  const setCarrier = (
    value: string,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    setSelectedCarrier(value);
    handlePrimaryTextChange(value);
    setInsuranceCarrierError('');
    if (setFieldValue && value !== selectedCarrier) setFieldValue('insurancePlan', null);
  };

  useEffect(() => {
    if (!selectedCarrier || !plans) {
      setInsurancePlans(null);
      return;
    }

    if (plans.length > 0) {
      setInsurancePlans(plans);
      return;
    }

    if (plans.length === 0 && isInsurancePlansEmpty) {
      dispatch(
        notifyActions.showNotifications([
          {id: '', type: 'error', text: t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL})},
        ]),
      );
      setInsurancePlans(null);
    }
  }, [plans, selectedCarrier, dispatch, isInsurancePlansEmpty]);

  useEffect(() => {
    const selectedInsuranceCarrier = insuranceCarriers?.find(
      (carrier) => carrier.name.toLocaleLowerCase() === selectedCarrier.toLocaleLowerCase(),
    );
    if (selectedInsuranceCarrier) setInsuranceCarrierId(selectedInsuranceCarrier?.id);
  }, [selectedCarrier, insuranceCarriers]);

  useEffect(() => {
    if (searchPrimaryText) {
      getInsuranceCarriers(searchPrimaryText);
      setInsurancePlans(null);
      setInsuranceCarrierId('');
    }
  }, [getInsuranceCarriers, searchPrimaryText]);

  const saveData = (values: Nullable<SaveDataHealthPolicies>) => {
    if (isEqual(initialValue, values)) {
      return;
    }

    const {
      relationship,
      birthDate,
      birthSex,
      firstName,
      lastName,
      middleName,
      ssnTail,
      state,
      city,
      postalCode,
      address1,
      address2,
      phone,
      endDate,
      policyId,
    } = values;

    const insurancePlanId = insurancePlans?.find((plan) => plan.name === values.insurancePlan)?.id;

    const data = [
      {
        endDate: {
          value: endDate || '',
        },
        guarantor: {
          address1: address1 || '',
          address2: {
            value: address2 || '',
          },
          birthDate: birthDate || '',
          birthSex: birthSex || null,
          city: city || '',
          firstName: firstName || '',
          lastName: lastName || '',
          middleName: {
            value: middleName || '',
          },
          phone: {
            value: phone || '',
          },
          postalCode: postalCode || '',
          relationType: relationship === RelationshipTypes.SELF ? null : relationship,
          ssnTail: {
            value: ssnTail || '',
          },
          state: state || null,
        },
        insurancePlanId: insurancePlanId || '',
        number: policyId || '',
        primary: !isPrimaryInsurance,
      },
    ];

    createInsurance({
      deletedHealthcareInsurancePolicyIds: [],
      healthcareInsurancePoliciesCreate: data || undefined,
      healthcareInsurancePoliciesEdit: [],
    });
  };

  const setGuarantorFieldsBySelf = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    setFieldValue('firstName', accountProfile?.contact?.firstName);
    setFieldValue('lastName', accountProfile?.contact?.lastName);
    setFieldValue('middleName', accountProfile?.contact?.middleName);
    setFieldValue('birthDate', accountProfile?.contact?.birthDate);
    setFieldValue('birthSex', accountProfile?.contact?.birthSex);
    setFieldValue('ssnTail', accountProfile?.contact?.ssnTail);
    setFieldValue('state', accountProfile?.actualAddresses?.state);
    setFieldValue('city', accountProfile?.actualAddresses?.city);
    setFieldValue('address1', accountProfile?.actualAddresses?.main);
    setFieldValue('address2', accountProfile?.actualAddresses?.additional);
    setFieldValue('postalCode', accountProfile?.actualAddresses?.postalCode);
    setFieldValue('phone', accountProfile?.phones ? accountProfile?.phones[0].phone : '');
  };

  const handleCancelClick = (resetForm: FormikProps<typeof initialValue>['resetForm']) => {
    navigate(ROUTERS_PATH.INSURANCE);
    resetForm();
  };

  const handleFormSubmit = (
    handleSubmit: FormikProps<typeof initialValue>['handleSubmit'],
    setTouched: (touched: FormikTouched<typeof initialValue>, shouldValidate?: boolean) => void,
    selectedCarrier: string,
    setInsuranceCarrierError: (error: string) => void,
  ) => {
    if (!selectedCarrier) setInsuranceCarrierError(t('REQUIRED_FIELD'));
    setTouched(touchedData);
    handleSubmit();
    setSubmit(true);
  };

  useScrollToTop();

  if (isLoading || !ready) {
    return (
      <>
        <Stack sx={sx.mainTitleWrapper}>
          <PageTitle>{ready ? t('INSURANCE') : ''}</PageTitle>
        </Stack>
        <WBox sx={{...sx.whiteBox, justifyContent: 'center', alignItems: 'center', flex: 1}}>
          <PulseLoader loading />
        </WBox>
      </>
    );
  }

  return (
    <Formik<typeof initialValue>
      enableReinitialize
      onSubmit={saveData}
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        resetForm,
        setTouched,
        handleSubmit,
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const {errorRef, isErrorTall} = useErrorTextHeightObserve();
        return (
          <StyledForm>
            <Stack sx={sx.mainTitleWrapper}>
              <PageTitle>{t('INSURANCE')}</PageTitle>
            </Stack>
            <WBox sx={sx.whiteBox}>
              <Stack sx={sx.mainContainer}>
                <Stack gap={16}>
                  <Typography variant={isMobile ? '22_26_500' : '24_30_500'}>
                    {isPrimaryInsurance ? t('SECONDARY_INSURANCE') : t('PRIMARY_INSURANCE')}
                  </Typography>
                  <Grid container spacing={24} columnSpacing={colSpacing}>
                    <Grid
                      xl={4}
                      sm={6}
                      xs={12}>
                      <ComboBoxInput
                        value={values.insuranceCarrier}
                        label={t('CARRIER')}
                        options={insuranceCarriers || []}
                        debouncedChangeHandler={debouncedPrimaryChangeHandler}
                        setSelectedCarrier={(value: string) =>
                          setCarrier(value, setFieldValue)
                        }
                        error={insuranceCarrierError}
                        clearInputValue={isInsurancePlansEmpty}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        disabled={!insurancePlans?.length}
                        name="insurancePlan"
                        value={t(values.insurancePlan) || ''}
                        onChange={handleChange}
                        error={getInsurancePlanError({touched, values, errors, t})}
                        placeholder={t('SELECT')}
                        label={t('PLAN')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                        sx={sx.plan}
                      >
                        {insurancePlans?.map((option) => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={sx.ListItemText}>
                              {t(option.name as TKeys<'common'>)}
                            </ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      sm={6}
                      xs={12}>
                      <InputControl
                        name="policyId"
                        label={t('POLICY_ID')}
                        value={values.policyId || ''}
                        error={getPolicyIdError({touched, values, errors, t})}
                        onChange={handleChange}
                        maxlength={MAX_POLICY_ID_LENGTH}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      sm={6}
                      xs={12}>
                      <DateControl
                        labelTop
                        reverseYears
                        disabledPast
                        onlyFutureYears
                        disableCurrentDay
                        name="endDate"
                        helperText={t('OPTIONAL')}
                        value={dateToFormat('P', values.endDate) || ''}
                        onTouched={setFieldTouched}
                        placeholder={t('MM_DD_YYYY')}
                        onChange={handleChange}
                        label={t('END_DATE')}
                        error={touched.endDate && !values?.endDate ? errors.endDate : ''}
                        optional
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack gap={24}>
                  <Stack flexGrow={1} gap={16}>
                    <Box sx={{display: 'flex', gap: 24}}>
                      <Typography component="h4" variant={isMobile ? '18_24_700' : '20_24_700'} sx={sx.title}>
                        {t('GUARANTOR')}
                      </Typography>
                    </Box>
                    <Grid container spacing={24} columnSpacing={colSpacing}>
                      <Grid
                        xl={4}
                        sm={6}
                        xs={12}>
                        <SelectControl
                          name="relationship"
                          placeholder={t('SELECT')}
                          optional
                          value={t(values.relationship) || ''}
                          onChange={(e: any) => {
                            handleChange(e);
                            if (e.target.value === RelationshipTypes.SELF) {
                              setGuarantorFieldsBySelf(setFieldValue);
                            }
                          }}
                          error={
                            touched?.relationship && !values.relationship ? errors.relationship : ''
                          }
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                          label={t('RELATIONSHIP')}
                        >
                          {relationTypesSelectOptions.map((option) => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>
                                {t(option.name as TKeys<'common'>)}
                              </ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack gap={24}>
                    <Stack flexGrow={1}>
                      <Grid container spacing={24} columnSpacing={colSpacing}>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="firstName"
                            label={t('FIRST_NAME')}
                            value={values.firstName || ''}
                            error={touched?.firstName && !values.firstName ? errors.firstName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="lastName"
                            label={t('LAST_NAME')}
                            value={values.lastName || ''}
                            error={touched?.lastName && !values.lastName ? errors.lastName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="middleName"
                            label={t('MIDDLE_NAME')}
                            value={values.middleName || ''}
                            optional
                            error={touched?.middleName ? errors.middleName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}
                          sx={{mb: isErrorTall ? 12 : 0}}>
                          <DateControl
                            ref={errorRef}
                            labelTop
                            name="birthDate"
                            disabledFuture
                            value={dateToFormat('P', values.birthDate) || ''}
                            onChange={handleChange}
                            onTouched={setFieldTouched}
                            placeholder={t('MM_DD_YYYY')}
                            label={t('DATE_OF_BIRTH')}
                            hideTip={false}
                            error={isSubmit && (!values.birthDate && errors.birthDate) ? errors.birthDate : ''}
                            errorTextStyles={{
                              whiteSpace: 'break-spaces',
                            }}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <SelectControl
                            name="birthSex"
                            placeholder={t('SELECT')}
                            value={t(values.birthSex || ('' as TKeys<'insurance'>)) || ''}
                            onChange={handleChange}
                            error={touched?.birthSex && !values.birthSex ? errors.birthSex : ''}
                            label={t('BIRTH_SEX')}
                            renderValue={(value: any) => t(value as TKeys<'common'>)}
                          >
                            {birthSexSelectOptions.map((option) => (
                              <Option key={option.name} value={option.name}>
                                <ListItemText sx={sx.ListItemText}>
                                  {t(option.name as TKeys<'common'>)}
                                </ListItemText>
                              </Option>
                            ))}
                          </SelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="ssnTail"
                            label={t('SSN_LAST_4')}
                            optional
                            error={touched?.ssnTail ? errors.ssnTail : ''}
                            value={values.ssnTail || ''}
                            onChange={handleChange}
                            maxlength={MAX_SSN_LENGTH}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack flexGrow={1}>
                      <Grid container spacing={24} columnSpacing={colSpacing}>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <SelectControl
                            name="state"
                            value={t(values.state) || ''}
                            placeholder={t('SELECT')}
                            onChange={handleChange}
                            error={touched?.state && !values.state ? errors.state : ''}
                            label={t('STATE')}
                            renderValue={(value: any) => t(value as TKeys<'common'>)}
                          >
                            {statesSelectOptions.map((option) => (
                              <Option key={option.name} value={option.name}>
                                <ListItemText sx={sx.ListItemText}>
                                  {t(option.name as TKeys<'common'>)}
                                </ListItemText>
                              </Option>
                            ))}
                          </SelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="city"
                            label={t('CITY')}
                            value={values.city || ''}
                            error={touched?.city && !values.city ? errors.city : ''}
                            onChange={handleChange}
                            maxlength={MAX_CITY_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            simpleTextInput
                            name="postalCode"
                            label={t('ZIP_CODE')}
                            value={validateValue(values.postalCode || '')}
                            error={touched?.postalCode && !values.postalCode ? errors.postalCode : ''}
                            onChange={handleChange}
                            maxlength={POSTAL_CODE_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="address1"
                            label={t('ADDRESS_1')}
                            error={touched?.address1 && !values.address1 ? errors.address1 : ''}
                            value={values.address1 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="address2"
                            label={t('ADDRESS_2')}
                            error={touched?.address2 ? errors.address2 : ''}
                            optional
                            value={values.address2 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          sm={6}
                          xs={12}>
                          <PhoneInputControl
                            optional
                            name="phone"
                            label={t('PHONE')}
                            error={touched?.phone ? errors.phone : ''}
                            placeholder={t('ENTER_A_PHONE')}
                            value={values.phone || ''}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={sx.buttonsWrapper}>
                <Button
                  sx={sx.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCancelClick(resetForm)}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={isEqual(initialValue, values)}
                  sx={sx.button}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleFormSubmit(
                      handleSubmit,
                      setTouched,
                      selectedCarrier,
                      setInsuranceCarrierError,
                    )
                  }
                >
                  {t('SAVE')}
                </Button>
              </Stack>
            </WBox>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
