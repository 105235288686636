import {DateTime} from 'luxon';

export const checkIfPersonOlderThanUser = (personBirthDate?: string | null, userBirthDate?: string | null) => {
  if (!personBirthDate || !userBirthDate) return null;

  const personDate = DateTime.fromISO(personBirthDate).startOf('day');
  const userDate = DateTime.fromISO(userBirthDate).startOf('day');

  return personDate < userDate;
};
