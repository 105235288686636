const escapeRegExp = (text: string) => {
  // eslint-disable-next-line
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
};

export const getBoldText = (input: string, text: string) => {
  const regExString = escapeRegExp(text);
  const regex = new RegExp(regExString, 'ig');

  return input.replace(regex, ($1) => {
    return `<b>${$1}</b>`;
  });
};
