import {DateTime} from 'luxon';

export const compareDatesWithToday = (
  startDate: string | undefined | null,
  lessThanToday: boolean,
  moreThanToday: boolean,
) => {
  if (!startDate) return null;
  if (lessThanToday) {
    return (
      DateTime.fromISO(startDate).startOf('day') < DateTime.now().startOf('day')
    );
  }
  if (moreThanToday) {
    return (
      DateTime.fromISO(startDate).startOf('day') > DateTime.now().startOf('day')
    );
  }
};
