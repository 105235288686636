import {isEqual, sortBy} from 'lodash-es';

export const arraysIsEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = sortBy(arr1);
  const sortedArr2 = sortBy(arr2);

  return isEqual(sortedArr1, sortedArr2);
};
