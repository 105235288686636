import {Box, Breakpoint, Stack, styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  toggleButton: {
    color: 'inherit',
    textTransform: 'unset',
    typography: '14_18_600',
    padding: '10px 16px',
    background: 'none',
    maxWidth: 160,
    '&.Mui-selected': {
      border: '2px solid currentColor',
      background: 'none',
    },
    ':hover': {
      background: 'none',
      borderColor: (theme) => theme.palette.grey['400'],
      color: (theme) => theme.colors.all.blue['300'],
      '&.Mui-selected': {
        borderColor: (theme) => theme.colors.all.blue['300'],
        background: 'none',
      },
    },
  },
  inputsContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    gap: 24,
  },
};

export const FiltersContainer = styled(Stack)<{ isScrolled: boolean }>(({theme, isScrolled}) => ({
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 24,
  background: theme.colors.background,
  padding: '24px 36px',
  border: '1px solid',
  borderColor: theme.colors.borderBlueLight,
  zIndex: 1,
  boxShadow: isScrolled ? '0px 4px 8px 0px #0000001A' : 'unset',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: 24,
  },
  [theme.breakpoints.down('md')]: isScrolled
    ? {
      position: 'fixed',
      top: theme.header.height.sm,
      left: 0,
      width: '100%',
      padding: 18,
      height: 78,
      overflow: 'hidden',
      flexDirection: 'column',
      alignItems: 'stretch',
      borderTop: 'unset',
    }
    : {
      padding: 18,
      flexDirection: 'column',
      alignItems: 'stretch',
    },
}));

const getBreakpointBySidebar = (breakpoint: Breakpoint, isSidebarOpen: boolean) => {
  const sortedBreakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
  const breakpointIndex = sortedBreakpoints.findIndex((point) => point === breakpoint);
  const previousBreakpoint = sortedBreakpoints[breakpointIndex + 1] || breakpoint;

  return isSidebarOpen ? previousBreakpoint : breakpoint;
};

export const StyledAdaptiveFieldWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'downBreakpoint' && propName !== 'upBreakpoint',
})<{ downBreakpoint: Breakpoint, upBreakpoint?: Breakpoint, isSidebarOpen: boolean }>(
  ({theme, downBreakpoint, upBreakpoint, isSidebarOpen}) => ({
    minWidth: 200,
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      [theme.breakpoints.down(getBreakpointBySidebar(downBreakpoint, isSidebarOpen))]: {
        display: 'none',
      },
      [theme.breakpoints.up(
        upBreakpoint ? getBreakpointBySidebar(upBreakpoint, isSidebarOpen) : 0,
      )]: upBreakpoint && {
        display: 'none',
      },
    },
    [theme.breakpoints.down('lg')]: {
      [theme.breakpoints.down(downBreakpoint)]: {
        display: 'none',
      },
      [theme.breakpoints.up(upBreakpoint || 0)]: upBreakpoint && {
        display: 'none',
      },
    },
  }),
);
