import {FC} from 'react';

export const CircleStatus: FC<{ color?: string | null }> = ({color}) => {
  if (!color) return null;
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 8 8"
      fill="none">
      <circle
        cx="4"
        cy="4"
        r="4"
        fill={color} />
    </svg>
  );
};
