import {SxProps, Theme, styled, css as _} from '@mui/material';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    gap: {xs: 24, sm: 36},
    flexGrow: 1,
  },
  whiteBox: {
    p: {xs: 0, lg: 24},
    mt: {xs: 18, sm: 24, lg: 0},
    flexGrow: 1,
    border: 'none',
    position: 'relative',
  },
  container: {
    p: {lg: 24},
    mt: {xs: 18, sm: 24, lg: 0},
  },
  content: {
    gap: {xs: 24, sm: 36},
  },
  insuranceWrapper: {
    gap: {xs: 12, sm: 24},
  },
  guarantorWrapper: {
    gap: {xs: 12, sm: 18},
  },
  guarantorContentWrapper: {
    flexDirection: {xs: 'column', sm: 'row'},
    gap: {xs: 12, sm: 36},
  },
  noInsuranceDescription: (t) => ({
    typography: {xs: '12_18_500', sm: '14_18_500'},
    color: t.palette.grey[600],
  }),
  insuranceTitle: {
    typography: {xs: '18_24_500', sm: '20_24_500', lg: '24_34_500'},
  },
  contentWrapper: {
    flexGrow: 1,
    gap: {xs: 18, lg: 36},
  },
  addInsuranceButtonOnMobile: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '16px !important',
  },
  emptyFragment: (t) => ({
    mt: {xs: 3, sm: 1},
    typography: {xs: '12_18_500', lg: '14_18_500'},
    color: t.palette.grey[500],
  }),
  noInsuranceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: (t) => ({
    p: 0,
    width: {xs: 24, sm: 42},
    height: {xs: 24, sm: 42},
    border: {
      sm: `1px solid ${t.palette.primary.main}`,
      xs: 'none',
    },
    backgroundColor: 'transparent',
    borderRadius: 0,
    color: t.palette.primary.main,
    svg: {
      width: 18,
      height: 18,
      path: {
        fill: 'currentColor',
      },
    },
    ':hover': {
      borderColor: t.palette.primary.light,
      svg: {
        path: {
          fill: t.palette.primary.light,
        },
      },
    },
    ':active': {
      borderColor: t.palette.primary.dark,
      svg: {
        path: {
          fill: t.palette.primary.dark,
        },
      },
    },
  }),
  dialogContent: {pb: 0},
  dialogActions: {flexDirection: 'row !important'},
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noInsurancePlansContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: {xs: 18, sm: 24},
  },
  addInsuranceBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'end',
    mt: {xs: 12, sm: 0},
  },
  listWrapperContainer: {
    flexGrow: 1,
    maxWidth: {sm: 'calc(50% - 18px)'},
  },
  iconBtnsWrapper: {
    flexDirection: 'row',
    gap: {xs: 6, sm: 18},
  },
  addInsuranceButtonOnisMobile: {
    mt: 42,
    border: 'none',
    p: 0,
  },
  titleEmptyFragment: {
    typography: {xs: '22_26_500', sm: '20_24_500', lg: '24_34_500'},
  },
  boxContainerEmptyFragment: {
    alignItems: {xs: 'center', sm: 'flex-start', lg: 'center'},
  },
  insuranceMainTitle: {
    typography: {xs: '18_24_500', sm: '20_24_500', lg: '24_34_500'},
  },
  guarantorTitle: {
    typography: {xs: '16_24_500', sm: '18_24_500'},
  },
  divider: (t) => ({
    position: 'absolute',
    left: {xs: -18, sm: -24},
    right: {xs: -18, sm: -24},
    bottom: {xs: -18, sm: -24},
    borderColor: t.palette.secondary._14,
  }),
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    minHeight: 200,
  },
};

export const AddIconWhite = styled(Add)(
  ({theme}) => _`
  path {
    fill: ${theme.colors.all.white};
  }
`,
);

export const AddIconPrimary = styled(Add)(
  ({theme}) => _`
  path {
    fill: ${theme.palette.primary.main};
  }
  &:hover {
    path {
      fill: ${theme.palette.primary.light};
    }
  }
  &:active {
    path {
      fill: ${theme.palette.primary.dark};
    }
  }
`,
);
