import {SxProps, Theme} from '@mui/material';

export const iconBtnSx: SxProps<Theme> = {
  width: 42,
  height: 42,
  ml: {xs: -5, sm: -9},
  mr: {xs: 2, md: 7},
  padding: 9,
  color: (theme) => theme.colors.breadcrumbsText,
};
