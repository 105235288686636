import {apiClient} from 'client/ApiClient';
import {Appointment, ProfessionalReviewRequest, AppointmentFull, RescheduleAppointmentRequest, AppointmentFile} from './models';
import {UUID} from '@src/types';

export const getAppointments = async () => {
  const response = await apiClient.get<Appointment[]>('/api/appointments');
  return response.data;
};

export const addReviewForAppointment = async ({id, data}: {id: UUID, data: ProfessionalReviewRequest}) => {
  const response = await apiClient.post(`/api/appointments/${id}/fn/add-review`, data);
  return response.data;
};

export const getAppointmentById = async ({id}: { id: UUID }) => {
  const response = await apiClient.get<AppointmentFull>(`/api/appointments/${id}`);
  return response.data;
};

export const cancelAppointment = async ({id}: {id: UUID}) => {
  const response = await apiClient.post(`/api/appointments/${id}/cancel`);
  return response.data;
};

export const updateAppointment = async ({
  id,
  deletedFileIds,
  description,
  height,
  weight,
  files,
  oldFiles,
}: {
  id: string
  deletedFileIds: string[] | null
  description: string | null
  height: number | null
  weight: number | null
  files?: File[]
  oldFiles: AppointmentFile [] | null
}) => {
  const datas = {
    deletedFileIds,
    description: description?.trim(),
    height,
    weight,
    files: oldFiles,
  };
  const formData = new FormData();
  formData.append('data', JSON.stringify(datas));
  if (files) {
    for (const file of files) {
      formData.append('files', file);
    }
  }
  if (datas.files) {
    for (const file of datas.files) {
      formData.append('data.files', JSON.stringify(file));
    }
  }
  const response = await apiClient.post(`/api/appointments/${id}/fn/update`, formData);
  return response.status;
};

export const rescheduleAppointment = async ({id, data}: { id: UUID, data: RescheduleAppointmentRequest }) => {
  const response = await apiClient.post(`/api/appointments/${id}/fn/reschedule`, data);
  return response.data;
};

export * as appointmentsApi from './';
export * from './models';
