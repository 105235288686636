import {envs} from '@src/shared/constants/envs';

export const loadGoogleTagManager = () => {
  if (!envs.GOOGLE_TAG_MANAGER_ID) {
    return;
  }
  const scriptURL = `https://www.googletagmanager.com/gtag/js?id=${envs.GOOGLE_TAG_MANAGER_ID}`;
  const scriptContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${envs.GOOGLE_TAG_MANAGER_ID}');
  `;

  const script = document.createElement('script');
  script.innerHTML = scriptContent;
  const gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = scriptURL;
  document.head.append(document.createComment('Google tag (gtag.js)'));
  document.head.append(script);
  document.head.append(gtagScript);
};

loadGoogleTagManager();
