import {Stack, Typography, Box, Avatar} from '@mui/material';
import {RelationRequest} from '@src/api/relations';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {useSortOfDate} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getUserInitials, dateFormatted} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useApproveRelationRequest, useDeclineRelationRequest} from '@src/store/relations/hooks';
import {alpha} from '@src/theme/utils';
import {Button} from 'ui-kit';

import {sx} from './styles';

export const IncomingRequests = ({data}: { data: RelationRequest[] | null }) => {
  const {t} = useTranslate('connections');
  const {desktop: isDesktop} = useMQuery();
  const {approve} = useApproveRelationRequest();
  const {decline} = useDeclineRelationRequest();
  const sortedData = useSortOfDate(data, 'createdDate');

  if (!data?.length) {
    return (
      <Typography
        sx={sx.emptyRequests}
      >
        {t('NO_INCOMING_REQUESTS')}
      </Typography>
    );
  }
  return (
    <Stack sx={sx.container}>
      {sortedData?.map((request, index) => (
        <Stack
          sx={(t) => ({
            padding: isDesktop ? '18px 24px' : '12px 18px',
            borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
            borderBottom:
              index === sortedData.length - 1
                ? `1px solid ${alpha(t.palette.secondary.main, 14)}`
                : 'none',
          })}
          key={request.id}
        >
          <Typography variant="12_16_600" sx={sx.date}>
            {dateFormatted(request?.createdDate)}
          </Typography>
          <Box display="flex" mb={isDesktop ? 10 : 16}>
            <Avatar sx={sx.requestAvatar} src={request?.avatarUrl} alt={''}>
              <Typography variant="14_20_700">{getUserInitials(request?.contact)}</Typography>
            </Avatar>
            <Stack sx={sx.widthLimitContainer}>
              <EllipsisTextTooltip
                ellipsisText={getName(request?.contact, {short: true})}
                sx={sx.personName}
              />
              <Typography sx={sx.personType}>{t(request?.type)}</Typography>
            </Stack>
          </Box>
          <Stack sx={sx.bthContainer}>
            <Button
              sx={sx.button}
              variant='text'
              size='xs'
              color="primary"
              onClick={() => decline({requestId: request?.id})}
            >{t('DECLINE')}</Button>
            <Button
              sx={sx.button}
              variant='text'
              size='xs'
              color="secondary"
              onClick={() => approve({requestId: request?.id})}
            >{t('ACCEPT')}</Button>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
