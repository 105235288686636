import {Dialog, DialogTitle} from '@components';
import {Box, Stack} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {useMQuery} from '@src/shared/hooks';
import {useAppDispatch, useAppSelector} from '@src/store';
import {healthCasesActions} from '@src/store/healthCases/slice';
import {FC, useState} from 'react';
import {Button} from 'ui-kit';

import {ResultsFilter} from '../ResultsFilters/ResultsFilters';

import {ResultsTable} from './ResultsTable/ResultsTable';
import {ResultsTableMobile} from './ResultsTableMobile/ResultsTableMobile';
import {sx} from './styles';
import {ResultsTableDialogProps} from './types';

export const ResultsTableDialog: FC<ResultsTableDialogProps> = ({isOpen, onClose}) => {
  const {t, ready} = useTranslate('healthCases');
  const {selectedResults, result} = useAppSelector((state) => state.healthCases);

  const [filterOpened, setFilterOpened] = useState(false);
  const dispatch = useAppDispatch();

  const {mobile, tabletPortrait} = useMQuery();
  const onPortrait = (mobile || tabletPortrait);

  const handleCloseFilter = () => setFilterOpened(false);
  const handleOpenFilter = () => setFilterOpened(true);

  const handleSaveClick = () => {
    dispatch(healthCasesActions.saveSelectedResults());
    dispatch(healthCasesActions.clearSelectedResults());
    onClose();
  };

  const handleClearClick = () => {
    dispatch(healthCasesActions.clearSelectedResults());
  };

  const handleClose = () => {
    dispatch(healthCasesActions.clearSelectedResults());
    onClose();
  };

  const renderTable = () => {
    return (
      <Stack>
        <ResultsFilter
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          isOpen={filterOpened}
        />
        {onPortrait && (
          <Stack p={18}>
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={handleOpenFilter}
              startIcon={<SettingsIcon />}
            >
              {t('FILTERS')}
            </Button>
          </Stack>
        )}
        {!onPortrait && (
          <Box sx={sx.tableWrapper}>
            <ResultsTable
              result={result}
            />
          </Box>
        )}
        {onPortrait && (
          <Box sx={sx.mobileTableWrapper}>
            <ResultsTableMobile
              results={result}
            />
          </Box>
        )}
        {!onPortrait && (
          <Stack sx={sx.actionsContainer}>
            <Button variant={'outlined'} color={'secondary'} onClick={handleClose}>
              {t('CANCEL')}
            </Button>
            <Stack flexDirection={'row'} gap={12}>
              <Button
                variant={'outlined'}
                color={'secondary'}
                onClick={handleClearClick}
                disabled={!selectedResults.size}
              >
                {t('CLEAR_ALL')}
              </Button>
              <Button color={'secondary'} onClick={handleSaveClick} disabled={!selectedResults.size}>
                {t('ADD_RESULTS_COUNT', {count: selectedResults.size || ''})}
              </Button>
            </Stack>
          </Stack>
        )}
        {onPortrait && (
          <Stack sx={sx.mobileActionsContainer}>
            <Button color={'secondary'} onClick={handleSaveClick} disabled={!selectedResults.size}>
              {t('ADD_RESULTS_COUNT', {count: selectedResults.size || ''})}
            </Button>
          </Stack>
        )}
      </Stack>
    );
  };

  if (onPortrait) {
    return (
      <MobileInteractionView
        isOpen={isOpen}
        title={t('SELECT_RESULTS')}
        onBack={onClose}
        onClose={handleClose}
      >
        {renderTable()}
      </MobileInteractionView>
    );
  }

  if (!ready) return null;

  return (
    <Dialog
      open={isOpen}
      size={'xxl'}
      scroll={'body'}
      border={'unset'}
      sx={sx.dialog}
    >
      <DialogTitle onClose={handleClose}>
        {t('ADD_RESULTS_TO')}
      </DialogTitle>
      {renderTable()}
    </Dialog>
  );
};
