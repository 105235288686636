import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  confirmDeleteContent: {
    padding: '36px 24px',
  },
  dialogError: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitleError: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  dialogActionsError: (t) => ({
    backgroundColor: t.palette.error.main,
    margin: 0,
    padding: '16px 24px',
    justifyContent: 'flex-end !important',
  }),
  bth: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
};
