import {FCC} from '@src/types/react';
import {SnackbarProvider, VariantType, useSnackbar} from 'notistack';
import {FC, PropsWithChildren, useCallback, useLayoutEffect} from 'react';
import {ErrorMessage, InfoMessage, SuccessMessage} from './RootNotificationWrapper.components';

interface ToastProps { variant: VariantType, message?: string, title?: string }

// eslint-disable-next-line import/no-mutable-exports
export let toast: (props: ToastProps) => void = () => {};

const NotificationContext: FC<PropsWithChildren> = ({children}) => {
  const {enqueueSnackbar} = useSnackbar();

  const makeMessage = useCallback(
    ({message, title, variant}: ToastProps) => enqueueSnackbar(message || '', {variant, title}),
    [enqueueSnackbar],
  );

  useLayoutEffect(() => {
    toast = makeMessage;
  }, [makeMessage]);

  return <>{children}</>;
};
// TODO REMOVE? I did replaced this component
// TODO: стилизации сообщений
// https://notistack.com/features/customization

declare module 'notistack' {
  interface VariantOverrides {
    warning: false
    default: false
    error: {
      title?: string
      message?: string
    }
    info: {
      title?: string
      message?: string
    }
    success: {
      title?: string
      message?: string
    }
  }
}

// eslint-disable-next-line react/prop-types
export const RootNotificationWrapper: FCC = ({children}) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={5000}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      preventDuplicate
      Components={{
        error: ErrorMessage,
        info: InfoMessage,
        success: SuccessMessage,
      }}
    >
      <NotificationContext>{children}</NotificationContext>
    </SnackbarProvider>
  );
};
