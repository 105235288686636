import {styled} from '@mui/material';
import {Button} from 'ui-kit';

interface ButtonProps {
  active: boolean
}

export const RadioButton = styled(Button)<ButtonProps>(({active, theme}) => ({
  border: `2px solid ${theme.palette.secondary.main}`,
  backgroundColor: active ? theme.palette.secondary.main : 'transparent',
  color: active ? '#FFFFFF' : theme.palette.secondary.main,
  height: 24,
  minWidth: 'fit-content',
  padding: '3px 12px',
  '&.MuiButtonBase-root:hover': {
    backgroundColor: active ? theme.palette.secondary.main : 'transparent',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: active ? theme.palette.secondary.main : 'transparent',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '50%',
  },
}));
