import {Stack, SxProps, Theme, styled} from '@mui/material';
import {Brush} from 'recharts';

export const statisticsSx = (isChangeGrid: boolean): Partial<Record<string, SxProps<Theme>>> => ({
  mainBlock: {
    order: isChangeGrid ? 2 : 4,
    padding: {
      sm: 24,
      xs: 18,
    },
  },
  header: (t) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [t.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    gap: {
      xs: 12,
      sm: 0,
    },
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  }),
  dateRange: {
    alignSelf: 'flex-end',
    typography: {
      xs: '12_16_600',
    },
    color: (theme) => theme.palette.grey[400],
  },
  groupButton: {
    display: {
      xs: 'flex',
      sm: 'block',
    },
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  dateRangeWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '140px',
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
});

export const StackSt = styled(Stack)(({theme}) => ({
  '.recharts-brush-slide': {
    fill: theme.palette.secondary._14,
    stroke: theme.palette.secondary._14,
    opacity: 1,
    fillOpacity: 1,
  },
  [theme.breakpoints.only('xs')]: {
    '.yAxis': {
      fontSize: 10,
    },
    '.xAxis': {
      fontSize: 10,
    },
  },
  [theme.breakpoints.up('sm')]: {
    '.yAxis': {
      fontSize: 12,
    },
    '.xAxis': {
      fontSize: 12,
    },
  },
}));

export const StyledBrush = styled(Brush)`
  & .recharts-brush {
    background-color: #f0f0f0;
    border: 0px solid #ccc;
  }

  & .recharts-brush-traveller {
    fill: #888;
  }

  & .recharts-brush-traveller:hover {
    fill: #555;
  }
`;
