import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  requestAvatar: {
    width: 40,
    height: 40,
    marginRight: 10,
    backgroundColor: t => t.palette.secondary._35,
    color: t => t.palette.common.white,
  },
  personType: (t) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [t.breakpoints.only('xs')]: {
      typography: '14_18_500',
    },
  }),
  personName: {
    cursor: 'pointer',
    typography: {xs: '18_24_700'},
  },
  wrapper: {
    pb: {
      xs: 12,
      sm: 40,
      lg: 38,
    },
    overflow: 'auto',
  },
  boxContainer: {
    display: 'flex',
    mb: {xs: 12, sm: 18},
  },
  widthLimitContainer: {
    minWidth: '10%',
  },
  resetBthSize: {
    padding: 0,
    height: '24px',
  },
  bthContainer: {
    flexDirection: 'row',
    justifyContent: 'end',
    gap: 18,
  },
  rowInformation: {
    mb: {xs: 8, sm: 12},
  },
  emptyRequests: {
    color: t => t.palette.grey[500],
    p: {
      xs: 18,
      sm: '2px 24px',
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
  date: {
    mb: 12,
    color: t => t.palette.grey[500],
  },
  button: {
    p: '0px !important',
    fontWeight: 600,
    minWidth: 'inherit',
  },
};
