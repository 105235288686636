import {Stack, Avatar, Typography, SxProps, Theme, Link} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {GeneralInfoProps, getInitialContact} from '@src/pages/Overview/fragments/GeneralInfo';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-icon.svg';
import {envs} from '@src/shared/constants/envs';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {NO_VALUE, dateFormatted, getFullName, getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {alpha} from '@src/theme/utils';
import {FC} from 'react';
import {IconButtonNew} from 'ui-kit';

import {GeneralSubInfo} from './GeneralSubInfo';
import {GeneralSubInfoDesktop} from './GeneralSubInfoDesktop';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  avatar: (t) => ({
    width: 100,
    height: 100,
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  container: {
    padding: '24px 24px 18px 24px',
  },
  contentContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  link: {
    alignSelf: 'flex-start',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '10%',
    gap: 24,
  },
  minWidthContainer: {
    minWidth: '10%',
    gap: 8,
  },
};

export const GeneralInfoTabletLandscape: FC<GeneralInfoProps> = ({contact = getInitialContact(), avatarUrl}) => {
  const {birthDate} = contact;
  const {lg: isDesktopLg, xl: isDesktopXl} = useMQuery();

  return (
    <Stack sx={sx.container}>
      <Stack textAlign="start">
        <Stack sx={sx.contentContainer}>
          <Stack sx={sx.content}>
            <Avatar sx={sx.avatar} alt={getFullName(contact)} src={avatarUrl}>
              <Typography fontSize='24px' lineHeight='28px' fontWeight='600'>
                {getUserInitials(contact)}
              </Typography>
            </Avatar>
            <Stack sx={sx.minWidthContainer}>
              <EllipsisTextTooltip variant="24_32_700" ellipsisText={getName(contact, {initialsOnlyMiddleName: true})} />
              {birthDate && (
                <Typography variant="16_20_500">
                  {dateFormatted(birthDate ?? NO_VALUE)}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Link sx={sx.link} target='_blank' href={`${envs.PASSPORT_WEB_URL}/profile`}>
            <IconButtonNew color={'tertiary'} size={'lg'}>
              <EditIcon />
            </IconButtonNew>
          </Link>
        </Stack>
      </Stack>
      {isDesktopXl || isDesktopLg
        ? <GeneralSubInfoDesktop contact={contact} />
        : <GeneralSubInfo contact={contact} />
      }
    </Stack>
  );
};
