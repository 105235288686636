import {styled} from '@mui/material';
import {NavLink} from 'react-router-dom';
export const liClassname = 'li-item';
export const selectedClassName = 'active';

export const MenuListItem = styled(NavLink)<{header?: boolean, open: boolean}>(
  ({theme, header, open}) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 64px;
  & > div {
    position: relative;
  }
  p {
    font-size: 12px;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: inherit;
    line-height: 20px;
  }
  border-top-width: 0;
  text-decoration: none;

  & > div {
    border-left:${open ? '6px solid transparent' : 'none'};

  }
  & > div {
    svg circle,
    svg path{
      fill:${open ? '#4B5563' : 'white'}
    }
  }

  .mobile-layout &, .tablet-layout & {
    & > div {
      border-left: none;
    }
  }

  &.${selectedClassName} {
    & > div {
      background-color: ${open ? theme.palette.background.default : theme.palette.secondary.dark};
      border-left-color: ${theme.palette.secondary.main};
      color: ${theme.palette.secondary.main};
      svg circle,
      svg path{
        fill:${open ? theme.palette.secondary.main : 'white'}
      }
      .mobile-layout &, .tablet-layout & {
        background-color: ${theme.palette.background.default};
      }
    }
  };

  ${!header ? 'border-top-width: 0 !important;' : ''}
`);

export const classes = {
  button: (open: boolean) => ({
    width: open ? '100%' : '64px',
    height: '64px',
    p: 0,
    transition: 'width 0.3s',
    color: 'grey.700',
    '&:hover': {
      background: open ? '#F3F4F6' : '#60D2E9',
    },
    '.mobile-layout &, .tablet-layout & ': {
      height: '56px',
      width: '100%',
    },
  }),
};

export const IconView = styled('div')`
position: relative;
margin: 0 16px 0 20px;
height: 100%;
width: 24px;
display: flex;
justify-content: center;
align-items: center;
flex-shrink: 0;
'.mobile-layout &, .tablet-layout & ': {
  margin: 0 16px 0 24px;
};
`;
