import {Stack, Box, Typography, Link} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Image403} from '@src/shared/assets/icons/403.svg';
import {ReactComponent as Image403Mobile} from '@src/shared/assets/icons/403_mobile.svg';
import {ReactComponent as Image403Tablet} from '@src/shared/assets/icons/403_tablet.svg';
import {envs} from '@src/shared/constants/envs';
import {useMQuery} from '@src/shared/hooks';

import {sx} from './styles';

export const Forbidden = () => {
  const {mobile: isMobile, tablet: isTablet} = useMQuery();
  const {t, ready} = useTranslate('common');

  const renderImage403 = () => {
    if (isMobile) return Image403Mobile;
    if (isTablet) return Image403Tablet;
    return Image403;
  };

  if (!ready) return null;
  return (
    <Stack sx={sx.container}>
      <Stack sx={sx.imageContainer}>
        <Box component={renderImage403()} height={'100%'} />
      </Stack>
      <Stack sx={sx.textContainer}>
        <Typography sx={sx.forbiddenText}>{t('FORBIDDEN')}</Typography>
        <Typography sx={sx.text}>{t('NOT_ENOUGH_ACCESS_PREMISSIONS')}</Typography>
        <Stack sx={sx.flexContainer}>
          <Typography sx={sx.text}>{t('TRY_AGAIN_LATER')}</Typography>
          <Link href={envs.PATIENT_CONTACT_SUPPORT_URL} underline='none' sx={sx.link}>
            {t('CONTACT_SUPPORT_WITH_DOT')}
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
