import React, {FC, PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {Tab as MuiTab, Tabs as MuiTabs} from '@mui/material';

export const TabPanel: FC<PropsWithChildren<{value: number | string, index: number | string}>> = ({value, index, children}) => {
  if (value !== index) {
    return null;
  }
  return (
    <>
      {children}
    </>
  );
};

export const useTabs = (tab: number | string, cb?: (tab: number | string) => void) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const [selectedTabValue, setSelectedTabValue] = useState(tab);
  const onTabChange = useCallback((e: React.SyntheticEvent, v: number | string) => {
    cbRef.current?.(v);
    setSelectedTabValue(v);
  }, []);
  useEffect(() => {
    setSelectedTabValue(tab);
  }, [tab]);
  return {index: selectedTabValue, handleChange: onTabChange};
};

export const Tabs = styled(MuiTabs)({
  '--tabs-height': '42px',
  transition: 'none',
  '&.MuiTabs-root': {
    transition: 'none',
    minHeight: 'var(--tabs-height)',
    '& button': {
      transition: 'none',
      minHeight: 'var(--tabs-height)',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  },
});

export const Tab = styled(MuiTab, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({theme, isActive = false}) => ({
  textTransform: 'none',
  fontSize: '16px',
  transition: 'none',
  fontWeight: 500,
  borderBottom: !isActive ? `2px solid ${theme.palette.grey[700]}` : '0',
  color: isActive ? Tabs.defaultProps?.textColor : theme.palette.grey[700],
}));

Tabs.defaultProps = {
  indicatorColor: 'secondary',
  textColor: 'secondary',
};
