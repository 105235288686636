import {StorageFileCategory} from '@src/api';
import {TKeys} from '@src/i18n/useTranslate';

export const getCategoryOptions = (
  translate: (str: TKeys<'healthCases'> | null | undefined) => string,
) => {
  return [
    {
      label: translate(StorageFileCategory.LABORATORY_REPORT),
      value: StorageFileCategory.LABORATORY_REPORT,
    },
    {
      label: translate(StorageFileCategory.MEDICAL_RECORD),
      value: StorageFileCategory.MEDICAL_RECORD,
    },
    {
      label: translate(StorageFileCategory.IMAGING_REPORT),
      value: StorageFileCategory.IMAGING_REPORT,
    },
    {
      label: translate(StorageFileCategory.MEDICAL_IMAGE),
      value: StorageFileCategory.MEDICAL_IMAGE,
    },
    {
      label: translate(StorageFileCategory.PRESCRIPTION),
      value: StorageFileCategory.PRESCRIPTION,
    },
    {
      label: translate(StorageFileCategory.MISCELLANEOUS),
      value: StorageFileCategory.MISCELLANEOUS,
    },
  ];
};
