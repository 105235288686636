import {AppointmentStatus} from '@src/api';
import {FlagStatus} from '@src/components/Flag';

export interface TableFilterResults {
  doctor: string | null
  patient: string | null
  status: string | null
  startDate: Date | null
  endDate: Date | null
  speciality: string | null
}

export const initialFilter: TableFilterResults = {
  doctor: null,
  patient: null,
  status: null,
  startDate: null,
  endDate: null,
  speciality: null,
};

export const appointmentStatusMap = {
  CREATED: FlagStatus.INFO,
  WAITINGCONFIRMATION: FlagStatus.INFO,
  CONFIRMED: FlagStatus.INFO,
  CANCELED: FlagStatus.ERROR,
  COMPLETED: FlagStatus.SUCCESS,
};

export const appointmentStatus = {
  CREATED: AppointmentStatus.CREATED,
  WAITINGCONFIRMATION: AppointmentStatus.CREATED,
  CONFIRMED: AppointmentStatus.CREATED,
  CANCELED: AppointmentStatus.CANCELED,
  COMPLETED: AppointmentStatus.COMPLETED,
};
