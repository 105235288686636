import {Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  contentContainer: (t) => ({
    minHeight: 'calc(100vh - 200px)',
    [t.breakpoints.between('sm', 'lg')]: {
      backgroundColor: 'unset',
      p: 0,
      minHeight: 'calc(100vh - 210px)',
      border: 'none',
    },
    [t.breakpoints.only('xs')]: {
      minHeight: 'calc(100vh - 168px)',
      backgroundColor: 'unset',
      p: 0,
      border: 'none',
    },
  }),
  tabs: {
    typography: '16_24_500',
    p: '6px 90px 12px 90px',
  },
  requestAccessBtn: {
    background: '#A81D42',
    minWidth: 170,
  },
  container: {
    gap: {
      xs: 24,
      lg: 36,
    },
  },
};
