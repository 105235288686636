/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {ListItemButton} from '@mui/material';
import cn from 'classnames';
import {memo} from 'react';
import {To} from 'react-router-dom';

import {ListItemText, Messages, TooltipWrapper} from '../SideManuContainer.components';

import {MenuListItem, selectedClassName, liClassname, classes, IconView} from './styles';

const Dot = styled.span(({theme}) => `
    width: 8px;
    height: 8px;
    display: inline-block;
    background: ${theme.palette.text.primary};
    border-radius: 50%;
    line-height: 20px;
    a:hover & {
        background: ${theme.colors.secondary};
    }
    a.active & {
        background: ${theme.colors.secondary};
    }
`);

interface MenuItemProps {
  title: string
  header?: boolean
  subtitle?: string
  selected?: boolean
  icon?: JSX.Element
  onClick?: () => void
  to?: To
  end?: boolean
  open: boolean
  count?: number
  state?: {
    needClear: boolean | null
  }
}
export const MenuItem = memo(({title, selected, header, icon, onClick, to, end, open, count, state}: MenuItemProps) => {
  return (
    <MenuListItem
      to={to ?? ''}
      header={header}
      end={end}
      tabIndex={0}
      role={'button'}
      onClick={onClick}
      className={cn({[selectedClassName]: selected}, liClassname)}
      open={open}
      state={state}
    >
      <TooltipWrapper open={open} name={title}>
        <ListItemButton sx={classes.button(open)} disableRipple>
          {count
            ? (
              <Messages
                open={open}
                icon={icon}
                title={title}
                count={count || 0}/>
            )
            : (
              <>
                {icon && (
                  <IconView>
                    {icon}
                  </IconView>
                )}
                {!header && !icon && <Dot/>}
                <ListItemText name={title} open={open} />
              </>
            )}
        </ListItemButton>
      </TooltipWrapper>
    </MenuListItem>
  );
});
MenuItem.displayName = 'MenuItem';
