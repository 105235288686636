import {Stack} from '@mui/material';
import {FC} from 'react';

import {CardHealthCase} from './CardHealthCase/CardHealthCase';
import {HealthCasesMobileTableProps} from './CardHealthCase/types';

export const HealthCasesMobileTable: FC<HealthCasesMobileTableProps> = ({cases}) => {
  return (
    <Stack gap={8}>
      {cases.map((caseItem) => (
        <CardHealthCase key={caseItem.id} {...caseItem} />
      ))}
    </Stack>
  );
};
