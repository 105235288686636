
import {Email} from '@src/api';
import {BasePhone} from '@src/api/relations';

import {isEmptyMainContainer} from '../../helpers/checkingForEmptyContainer';

export const calcListWrapperPaddings = (isDesktop: boolean, isMobile: boolean) => {
  if (isDesktop) return '16px';
  if (isMobile) return '8px';
  return '12px';
};

export const calcListWrapperSpaceBetween = (isMobile: boolean) => isMobile ? '8px' : '12px';

export const nameTypographyVariant = (isMobile: boolean) => isMobile ? '12_18_700' : '14_18_700';

export const valueTypographyVariant = (isMobile: boolean) => isMobile ? '12_18_500' : '14_18_500';

export const calcMarginTop = (phoneList?: BasePhone[] | Email[]) => isEmptyMainContainer({items: phoneList}) ? {xs: 18, sm: 24, lg: 36} : 0;
