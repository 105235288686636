import {PageHeader as PageTitle} from '@components';
import {Stack, Typography} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useAppDispatch, useAppSelector} from '@src/store';
import {fetchHealthCases} from '@src/store/healthCases/slice';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {IconButtonNew, Button} from 'ui-kit';

import {Filters} from './components/Filters/Filters';
import {HealthCasesMobileTable} from './components/HealthCasesMobileTable/HealthCasesMobileTable';
import {HealthCasesTable} from './components/HealthCasesTable/HealthCasesTable';
import {DEFAULT_PER_PAGE} from './constants';
import {MobileWrapper, sx} from './styles';

export const HealthCases = () => {
  const dispatch = useAppDispatch();
  const {mobile, tabletPortrait} = useMQuery();
  const {t, ready} = useTranslate('healthCases');
  const {cases: {items, ...cases}, isLoading} = useAppSelector((state) => state.healthCases);
  const {isSidebarOpen} = useSidebarClassObserver();
  const navigate = useNavigate();

  const handleFetch = (isNext: boolean) => {
    if (isNext && !cases.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = cases.filters;
    void dispatch(
      fetchHealthCases({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? cases.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  };

  const {phrase, from, to} = cases.filters;

  const isFieldsEmpty = !phrase && !from && !to;

  const displayedMessage = isFieldsEmpty
    ? t('YOU_DONT_HAVE')
    : t('NO_SUITABLE_RESULTS');

  useEffect(() => {
    handleFetch(false);
  }, [cases.filters]);

  const onPortrait = (mobile || tabletPortrait);

  const handleAddHealthCase = () => {
    return navigate(`${ROUTERS_PATH.HEALTH_CASE_ADD}`);
  };

  if (!ready) {
    return (
      <Stack sx={sx.loadingContainer}>
        <PulseLoader loading/>
      </Stack>
    );
  }

  return (
    <>
      <PageTitle
        height={'auto'}
        itemAction={
          mobile
            ? (
              <IconButtonNew
                color={'secondary'}
                sx={sx.iconButton}
                onClick={handleAddHealthCase}
              >
                <AddIcon />
              </IconButtonNew>
            )
            : (
              <Stack gap={12} flexDirection={'row'}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleAddHealthCase}
                >
                  {t('ADD_HEALTH_CASE')}
                </Button>
              </Stack>
            )
        }
      >
        {t('HEALTH_CASES')}
      </PageTitle>
      <Stack sx={sx.generalContainer}>
        <Filters isSidebarOpen={isSidebarOpen} />
        {!items.length && (
          <Stack sx={sx.emptyWrapper}>
            <Typography variant={'14_18_500'} color={(theme) => theme.colors.alertColor}>
              {displayedMessage}
            </Typography>
          </Stack>
        )}
        {!!items.length && (
          <Stack sx={sx.contentWrapper}>
            {!onPortrait && (
              <HealthCasesTable
                cases={items}
                onFetch={handleFetch}
                isLoading={isLoading}
              />
            )}
            {onPortrait && (
              <MobileWrapper
                onLoad={() => handleFetch(true)}
                threshold={500}
              >
                <HealthCasesMobileTable
                  cases={items}
                />
              </MobileWrapper>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};
