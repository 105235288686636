import {resultsApi} from '@src/api';
import {all, call, put, select, takeLeading} from 'redux-saga/effects';

import {errorHandler} from '../errorHandler';
import {SagaPayload, SagaReturn} from '../types';

import {transformData} from './helpers';
import {resultsActions} from './slice';

export function * resultsSaga () {
  yield all([
    takeLeading(resultsActions.requestResults, requestResults),
    takeLeading(resultsActions.requestDynamics, requestDynamics),
    takeLeading(resultsActions.requestResultDetails, requestResultDetails),
    takeLeading(resultsActions.sendResultsByEmail, sendResultsByEmail),
    takeLeading(resultsActions.setDynamics, transformDynamicData),
  ]);
}

function * transformDynamicData (): SagaReturn {
  try {
    yield put(resultsActions.setFetching({results: true}));
    const dynamics = yield select(s => s.results.dynamics);
    console.info('warn, transformData executed');
    const {testResults}: Awaited<ReturnType<typeof transformData>> = dynamics ? yield call(transformData, dynamics) : {testResults: []};
    yield put(resultsActions.setTranformedData(testResults));
  } catch (e) {
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({results: false}));
  }
};

function * requestResults (): SagaReturn {
  try {
    yield put(resultsActions.setFetching({results: true}));
    const results: Awaited<ReturnType<typeof resultsApi.getResults>> = yield call(resultsApi.getResults);
    yield put(resultsActions.setResults(results));
  } catch (e) {
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({results: false}));
  }
}

function * requestDynamics () {
  try {
    yield put(resultsActions.setFetching({dynamics: true}));
    const dynamics: Awaited<ReturnType<typeof resultsApi.getDynamics>> = yield call(resultsApi.getDynamics);
    yield put(resultsActions.setDynamics(dynamics));
  } catch (e) {
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({dynamics: false}));
  }
}

function * requestResultDetails ({payload}: SagaPayload<typeof resultsActions.requestResultDetails>): SagaReturn {
  try {
    yield put(resultsActions.setFetching({details: true}));
    const details: Awaited<ReturnType<typeof resultsApi.getResultByid>> = yield call(resultsApi.getResultByid, payload);
    yield put(resultsActions.setDetails(details));
  } catch (e: any) {
    yield put(resultsActions.setErrorStatus(e.status));
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({dynamics: false}));
  }
}

function * sendResultsByEmail ({payload}: SagaPayload<typeof resultsActions.sendResultsByEmail>): SagaReturn {
  try {
    yield call(resultsApi.sendResultsByEmail, {resultId: payload.resultId, data: {email: payload.email}});
    yield put(resultsActions.setStep({step: 'success'}));
  } catch (e) {
    yield put(resultsActions.setStep({step: 'error'}));
    errorHandler(e, resultsActions.setCommonError);
  }
}
