import {Stack, Theme, Box} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {BoxTypeMap} from '@mui/system';
import {DEFAULT_DATE_FORMAT} from '@src/components/DateFormat';
import {GrayishIconButton} from '@src/components/DotsButton';
import {Nowrap} from '@src/components/Text';
import {IconContainer, InputBody, Label} from '@src/components/form/InputControl/styles';
import {ReactComponent as IconSVGCalendar} from '@src/shared/assets/icons/icon-calendar.svg';
import {ReactComponent as IconSVGTimes} from '@src/shared/assets/images/icons/icon_times_2.svg';
import {useOnClickOutside} from '@src/shared/hooks/useOnClickOutside';
import usePortal from '@src/shared/hooks/usePortal';
import {DateTime} from 'luxon';
import React, {FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {usePopper} from 'react-popper';

import {TDateRange} from '../types';

import {CalendarPicker} from './CalendarPicker';
import {PARSING_FORMAT} from './constants';
import {StyledInput, sx} from './styles';
import {IDateInputProps, TDateInputSynteticHandler, TPickerProps} from './types';

export const DateInput: FC<IDateInputProps> = (props) => {
  const {
    label,
    name,
    placeholder,
    error,
    success,
    id,
    onChange,
    value,
    range,
    type = 'calendar',
    disabled,
    isIconVisible = true,
    reverseYears,
    minWidth,
    maxWidth,
  } = props;
  const resetField: React.MouseEventHandler = (e) => {
    handleChange({target: {name, value: ''}}, '');
    e.stopPropagation();
  };

  const [internalValue, setInternalValue] = useState<
  undefined | string | [string | null, string | null]
  >();
  const intRange = type !== 'range' ? range : (internalValue as [string, string]);
  const intValue = type !== 'range' ? (internalValue as string) : value;

  const handleChange: TDateInputSynteticHandler = useCallback(
    (...args) => {
      onChange?.(...args);
      setInternalValue(args[1]);
    },
    [onChange],
  );

  useEffect(() => {
    if (type === 'range') {
      setInternalValue((old) => {
        const [rangeStart, rangeEnd] = (old as [string, string]) || [];
        const [newRangeStart, newRangeEnd] = range || [];
        if (rangeStart !== newRangeStart || rangeEnd !== newRangeEnd) {
          return range;
        }
        return old;
      });
    } else {
      setInternalValue(value);
    }
  }, [value, range, type]);

  return (
    <HeadlessDatePicker
      {...props}
      onChange={handleChange}
      value={intValue}
      range={intRange}
      disabled={disabled}
      reverseYears={reverseYears}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      {({value, active}) => {
        return (
          <InputBody
            error={error}
            variant={'contained'}
            className={active ? 'focused' : ''}
            border={(t: Theme) => {
              if (error) {
                return t.palette.error.main;
              }
              if (success) {
                return t.palette.success.main;
              }
            }}
          >
            <Stack direction={'row'} gap={10} height={'100%'}>
              {isIconVisible && (
                <Stack justifyContent={'center'} sx={sx.calendarIcon}>
                  <IconSVGCalendar />
                </Stack>
              )}
              {label && (
                <Label htmlFor={id}>
                  <Nowrap>{label}</Nowrap>
                </Label>
              )}
              <StyledInput
                disabled={disabled}
                error={error}
                hasValue={Boolean(value)}
                id={id}
                value={value}
                name={`prevent-suggestions ${id ?? ''}`}
                placeholder={placeholder}
                readOnly
              />
            </Stack>

            <IconContainer direction={'row'}>
              {value && (
                <GrayishIconButton sx={{width: 42, height: '100%'}} onClick={resetField}>
                  <IconSVGTimes />
                </GrayishIconButton>
              )}
            </IconContainer>
          </InputBody>
        );
      }}
    </HeadlessDatePicker>
  );
};

export const HeadlessDatePicker = forwardRef<any, TPickerProps>((props, ref) => {
  const {
    disabledFuture,
    disabledPast,
    onlyFutureYears,
    disabled,
    children,
    name,
    value,
    onChange,
    format = PARSING_FORMAT,
    onOpen,
    type,
    range,
    reverseYears,
    startRangeWithCurrentMonth,
    minWidth,
    maxWidth,
    disableCurrentDay,
    ...restProps
  } = props;
  const [inputEl, setInputEl] = useState<HTMLDivElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>();
  const [show, setShowHidePicker] = useState(false);
  const [internalValue, setInternalValue] = useState<string | [string, string] | undefined>(value);
  const containerRef = useRef<HTMLDivElement | null | OverridableComponent<BoxTypeMap<{}, 'div'>>>(
    null,
  );
  const popperRef = useRef<any>();
  popperRef.current = popperEl;
  useOnClickOutside([containerRef, popperRef], () => {
    setShowHidePicker(false);
  });

  const handleChange = useCallback(
    (date: DateTime | TDateRange) => {
      const currentValue = type === 'range' ? value : internalValue;

      if (type === 'range') {
        if (date instanceof Array) {
          const [start, end] = date as [DateTime, DateTime];
          const newValue = [start.toFormat(format), end.toFormat(format)] as [string, string];
          if (JSON.stringify(newValue) !== JSON.stringify(currentValue)) {
            onChange?.({target: {name, value: newValue}}, newValue);
            setInternalValue(newValue);
          }
          setShowHidePicker(false);
        }
      } else {
        if (date instanceof DateTime) {
          const newValue = date.toFormat(format);

          const formattedDateValue = DateTime.fromFormat(value ?? '', 'MM/dd/yyyy');
          const formattedDate = formattedDateValue.isValid ? formattedDateValue.toFormat(format) : '';

          if (newValue === formattedDate) {
            setShowHidePicker(false);
            return;
          }

          if (newValue !== currentValue || value === '') {
            onChange?.({target: {name, value: newValue}}, newValue);
            setInternalValue(newValue);
          }
          setShowHidePicker(false);
        }
      }
    },
    [format, name, onChange, value, internalValue, type],
  );

  const {styles: pStyles, attributes} = usePopper(inputEl, popperEl, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top-start', 'bottom-start'],
        },
      },
    ],
  });
  const target = usePortal('modal-root', 'calendar-picker-root');

  const handleKeyUp: React.KeyboardEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (e.key) {
      case 'Enter':
        setShowHidePicker(true);
        break;
      case 'Escape':
        setShowHidePicker(false);
        break;
    }
  }, []);

  const onOpenRef = useRef(onOpen);
  onOpenRef.current = onOpen;
  useEffect(() => {
    onOpenRef.current?.(show);
  }, [onOpen, show]);

  useEffect(() => {
    popperEl?.getElementsByTagName('button')[0].focus();
  }, [popperEl]);

  const dateTimeRange = useMemo(() => {
    if (type !== 'range') {
      return;
    }
    return [
      range?.[0] ? DateTime.fromFormat(range[0], DEFAULT_DATE_FORMAT) : null,
      range?.[1] ? DateTime.fromFormat(range[1], DEFAULT_DATE_FORMAT) : null,
    ] as TDateRange;
  }, [range, type]);

  const parsed = DateTime.fromFormat(value ?? '', DEFAULT_DATE_FORMAT);
  const [rangeStart, rangeEnd] = dateTimeRange || [];

  const getValueToDisplay = () => {
    const isRange = type === 'range';

    if (!isRange) {
      return parsed.isValid ? parsed.toFormat(DEFAULT_DATE_FORMAT) : '';
    }

    /* eslint-disable */
    const hasValidRange = rangeStart && rangeStart?.isValid && rangeEnd && rangeEnd?.isValid;
    /* eslint-disable */

    return hasValidRange
      ? `${rangeStart.toFormat(DEFAULT_DATE_FORMAT)} - ${rangeEnd.toFormat(DEFAULT_DATE_FORMAT)}`
      : '';
  };

  const valueToDisplay = getValueToDisplay();

  return (
    <Box
      sx={(t) => ({
        minWidth: minWidth ?? 'auto',
        width: '100%',
        maxWidth: maxWidth ?? 'auto',
        backgroundColor: disabled ? t.palette.grey[200] : 'unset',
        '&:hover': t.palette.grey[100]
      })}
      ref={(node) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
        containerRef.current = node as HTMLDivElement;
      }}
      onKeyUp={handleKeyUp}
      {...restProps}
    >
      <Box
        sx={sx.container}
        ref={setInputEl}
        onClick={() => setShowHidePicker(true)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children({
          value: valueToDisplay,
          active: show,
        })}
      </Box>
      {show &&
        createPortal(
          <CalendarPicker
            startRangeWithCurrentMonth={startRangeWithCurrentMonth}
            reverseYears={reverseYears}
            disabledPast={disabledPast}
            onlyFutureYears={onlyFutureYears}
            disabledFuture={disabledFuture}
            disableCurrentDay={disableCurrentDay}
            mode={type}
            date={parsed.isValid ? parsed : undefined}
            range={dateTimeRange}
            ref={setPopperEl}
            style={{ ...pStyles.popper, zIndex: 1301 }}
            onChange={handleChange}
            onClose={() => {
              setShowHidePicker(false);
            }}
            attrs={attributes.popper}
          />,
          target,
        )}
    </Box>
  );
});

HeadlessDatePicker.displayName = 'HeadlessDatePicker';
