import {Email, HealthcareInsurancePolicy, Phone, Profile} from '@src/api';
import {RelationRequestContact} from '@src/api/relations';
import {ROUTERS_PATH} from '@src/routers';
import {Nullable} from '@src/types';
import {format} from 'date-fns';
import {cloneDeep} from 'lodash';
import {DateTime} from 'luxon';
import React, {MutableRefObject} from 'react';
import {generatePath} from 'react-router-dom';

import {DateFormat} from './constants';

interface PersonType {
  lastName: Nullable<string>
  firstName: Nullable<string>
  middleName?: Nullable<string>
}

export const getNameAndMiddleName = (person?: PersonType | RelationRequestContact | null) => {
  if (!person) return '';

  return `${person.firstName ?? ''} ${person.middleName ?? ''}`;
};

export const getFullName = (person?: PersonType | RelationRequestContact | null) => {
  if (!person) return '';

  return `${person.firstName ?? ''} ${person.middleName ?? ''} ${person.lastName ?? ''}`;
};

export const getFullNameWithoutMiddleName = (person?: PersonType | null) => {
  if (!person) return '';

  return `${person.firstName ?? ''} ${person.lastName ?? ''}`;
};

export const getFullNameWithShortMiddleName = (person?: PersonType | RelationRequestContact | null) => {
  if (!person) return '';

  return `${person.firstName ?? ''} ${person.middleName?.slice(0, 1) ?? ''} ${person.lastName ?? ''}`;
};

export const getMainEmail = (email?: Email[] | null) => {
  if (!email) return '';

  return email.find(m => !!m.main)?.email || null;
};

export const getPrimaryInsurance = (insurances?: HealthcareInsurancePolicy[] | null) => {
  if (!insurances) return null;

  return insurances.find(m => m.primary) || null;
};

export const getSecondaryInsurance = (insurances?: HealthcareInsurancePolicy[] | null) => {
  if (!insurances) return null;

  return insurances.find(m => !m.primary) || null;
};

export const getAge = (date?: Date | string | null): number => {
  if (!date) return 0;
  const diff = Number(
    ((Date.now() - new Date(date).getTime()) / (24 * 3600 * 365.25 * 1000)).toFixed(1),
  );
  return Math.trunc(diff);
};

// NOTE @deprecated - please use dateFormatted instead
export const dateToFormat = (dateFormat: string, date?: string | null) =>
  date ? format(new Date(date), dateFormat) : '';

export const getUserInitials = (person?: PersonType | RelationRequestContact | null) => {
  if (!person) return '';
  const name = person.firstName ?? '';
  const lastName = person.lastName ?? '';

  return `${name.charAt(0).toLocaleUpperCase() ?? ''}${lastName.charAt(0).toLocaleUpperCase() ?? ''}`;
};

export const dateFormatted = (date?: string | null, format?: DateFormat) => {
  if (!date) return null;

  const parsedDate = DateTime.fromISO(date);

  if (!parsedDate.isValid) {
    return null;
  }

  return parsedDate.toFormat(format ?? DateFormat.DATE_SHORT);
};

export const sortAccountProfileData = (externalProfile: Profile) => {
  const sort = (d: Array<Email | Phone> | undefined) => d?.sort(m => m.main ? -1 : 1);
  const profile = cloneDeep(externalProfile);
  profile.emails = sort(profile.emails);
  profile.phones = sort(profile.phones);
  profile.healthcareInsurancePolicies = profile.healthcareInsurancePolicies?.sort(m => m.primary ? -1 : 1);
  return profile;
};

export const propsOnlyForStyled = (propNames: string[]) => ({shouldForwardProp: (propName: string) => !propNames.includes(propName)});

export const createRelationPath = (isMobile: boolean, id: string = '') => {
  const path = generatePath(ROUTERS_PATH.CONNECTIONS_RELATION, {
    id,
  });

  return isMobile ? path : `${path}/about`;
};

export const useTrackIntersection = ({
  reference,
  callBack,
  threshold,
  rootMargin,
  rootElement,
  dependence,
}: {
  reference: MutableRefObject<HTMLElement | null>
  callBack?: (param: boolean) => void
  threshold?: number
  rootMargin?: string
  rootElement?: Element | Document | null
  dependence?: any
}) => {
  React.useEffect(() => {
    if (!callBack) return;
    const {current} = reference;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) {
          callBack(entry.isIntersecting);
        }
      },
      {
        threshold: threshold ?? 0.9,
        rootMargin: rootMargin ?? '0px',
      },
    );

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [callBack, reference, rootElement, rootMargin, threshold, dependence]);
};

export const makeArrayForSelect = (array: any) => {
  if (!array) return null;
  return array.map((name: any) => ({name}));
};

const BYTES_IN_KB = 1024;

export const formatSize = (fileSizeBytes: number) => {
  let fileSize = 0;
  let isMb = false;

  if (fileSizeBytes < BYTES_IN_KB) {
    return `${fileSizeBytes} B`;
  }

  if (fileSizeBytes >= (BYTES_IN_KB * BYTES_IN_KB)) {
    fileSize = fileSizeBytes / (BYTES_IN_KB * BYTES_IN_KB);
    isMb = true;
  } else {
    fileSize = fileSizeBytes / BYTES_IN_KB;
  }

  let formattedSize = '';
  if (fileSize % 1 === 0) {
    formattedSize = fileSize.toFixed(0);
  } else {
    formattedSize = fileSize.toFixed(1).replace(/\.0$/, '').replace(/(\.\d*[^0])0+/g, '$1');
  }

  const MbOrKb = isMb ? ' Mb' : ' Kb';

  return formattedSize.replace('.', ',') + MbOrKb;
};

export const getFileNameAndExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');

  if (lastDotIndex === -1) {
    return {name: fileName, extension: ''};
  }

  const name = fileName.slice(0, lastDotIndex);
  const extension = fileName.slice(lastDotIndex + 1);

  return {name, extension};
};

export const encodeImageFileAsURL = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    let base64 = '';
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        base64 = reader.result;
        resolve(reader.result);
      }
    };
    reader.readAsDataURL(file);

    return base64;
  });
};

export const removeDataTypePrefix = (base64: string) => {
  const commaIndex = base64.indexOf(',');
  if (commaIndex === -1) {
    return base64;
  }
  return base64.slice(commaIndex + 1);
};
