import {BAR_SIZE_DEFAULT, BAR_SIZE_DESK_DEFAULT, BAR_SIZE_DESK_OPEN, BAR_SIZE_DESK_OPEN_XL, BAR_SIZE_MOBILE, BAR_SIZE_TABLET} from '../constants';

export const getBarSize = (mobile: boolean, desktop: boolean, lg: boolean, xl: boolean, tablet: boolean, isSidebarOpen: boolean): number => {
  const deskOpen = (desktop && isSidebarOpen) || lg;
  if (desktop) {
    if (deskOpen) {
      if (xl && isSidebarOpen) {
        return BAR_SIZE_DESK_OPEN_XL;
      }
      if (lg) {
        if (lg && isSidebarOpen) {
          return BAR_SIZE_DESK_DEFAULT;
        }
        return BAR_SIZE_DESK_OPEN;
      }
      return 65;
    } else {
      if (xl) {
        return BAR_SIZE_DESK_DEFAULT;
      }
      return BAR_SIZE_DEFAULT;
    }
  } else {
    if (tablet) {
      return BAR_SIZE_TABLET;
    }
    if (mobile) {
      return BAR_SIZE_MOBILE;
    }
    return BAR_SIZE_DEFAULT;
  }
};
