import {getRecaptchaToken} from '@src/shared/hooks/useRecaptcha';
import {UUID} from '@src/types';
import {apiClient} from 'client/ApiClient';

import {Result, ResultDetail, DynamicsTest, ResultsSearch, DynamicResultType} from './models';
export * from './models';

export const getResults = async () => {
  const response = await apiClient.get<Result[]>('/api/results');
  return response.data;
};

export const getResultByid = async ({resultId, companyId}: {resultId: UUID, companyId: UUID}) => {
  const response = await apiClient.get<ResultDetail>(
    `/api/results/${resultId}`, {params: {companyId}},
  );
  return response.data;
};

export const sendResultsByEmail = async ({resultId, data}: {resultId: UUID, data: {email: string}}) => {
  void await apiClient.post(
    `/api/results/${resultId}/fn/send`,
    data,
  );
};

export const getDynamics = async () => {
  const response = await apiClient.post<DynamicsTest[]>('/api/dynamics/search', {
    type: DynamicResultType.All,
  });
  return response.data;
};

export const searchResults = async ({key, lastName}: {key: string, lastName?: string | null}) => {
  const recaptcha = await getRecaptchaToken();
  const response = await apiClient.post<ResultsSearch>(
    '/api/anonymous/results/search',
    {
      key,
      lastName,
      recaptcha,
    },
  );
  return response;
};

export * as resultsApi from './';
