import {styled} from '@mui/material';
import {IconColorProps} from '@src/shared/assets/icons/types';

import {ReactComponent as X24} from '../download.svg';

const X18Component = styled(X24)<IconColorProps>(({theme, color = 'primary'}) => {
  return {
    '& path': {
      fill: theme.palette.icon[color],
    },
  };
});

export const Download = {
  X18: X18Component,
};
