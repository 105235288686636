import {
  Cross,
  Calendar,
  Bell,
  MenuIcons,
  Info,
  Message,
  Arrow,
  Plus,
  Check,
  Alert,
  Search,
  Print,
  Time,
  Settings,
  Trash,
  Notification,
  Priority,
  User,
  Logout,
  Star,
  Download,
} from './components';

export const Icons = {

  Search,

  Cross,

  Calendar,

  Bell,

  MenuIcons,

  Info,

  Alert,

  Message,

  Arrow,

  Plus,

  Check,

  Print,

  Time,

  Settings,

  Trash,

  Notification,

  Priority,

  User,

  Logout,

  Star,

  Download,
};
