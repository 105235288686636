import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    paddingBottom: {
      xs: 18,
      sm: 40,
      lg: 24,
    },
    overflow: 'auto',
  },
  requestAvatar: (t) => ({
    width: {xs: 24, sm: 40},
    height: {xs: 24, sm: 40},
    marginRight: 10,
    backgroundColor: {
      xs: 'transparent',
      sm: t.palette.secondary._21,
    },
    color: {
      xs: 'initial',
      sm: t.palette.common.white,
    },
  }),
  requestType: (t) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    typography: '14_20_500',
    [t.breakpoints.only('xs')]: {
      typography: '12_20_600',
    },
  }),
  trashButton: {
    p: 0,
  },
  emptyRequests: (t) => ({
    color: {
      xs: t.palette.grey[500],
      sm: t.palette.grey[600],
    },
    p: {
      xs: '0px 18px 18px 18px',
      sm: '0px 18px',
      lg: '0px 24px',
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  }),
  boxContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  flexContainer: {
    flex: '1',
    minWidth: '10%',
    gap: 6,
  },
  iconBthAndDateContainer: {
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  date: {
    color: t => t.palette.grey[500],
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
};
