import {TKeys, useTranslate} from '@src/i18n/useTranslate';

export const useTranslationMap = () => {
  const {t} = useTranslate('connections');

  const mapTranslation = (value: string, type: 'gender' | 'race' | 'sexualOrientation') => {
    if (type === 'gender') {
      if (value === 'ASKU') return t('CHOOSE_NOT_TO_DISCLOSE');
      if (value === 'OTH') return t('OTHER');
    } else if (type === 'race') {
      if (value === 'OTHER') return t('OTH');
    } else if (type === 'sexualOrientation') {
      if (value === 'ASKU') return t('CHOOSE_NOT_TO_DISCLOSE');
      if (value === 'UNK') return t('DONT_KNOW');
      if (value === 'OTH') return t('SOMETHING_ELSE_PLEASE_DESCRIBE');
    }
    return t(value as TKeys<'connections'>);
  };

  return {
    mapValue: mapTranslation,
  };
};
