import {Box} from '@mui/material';
import {COMMON_US_DATE_FORMAT} from '@src/components/DatePicker/DatePickerRange/DatePickerRange';
import {HeadlessDatePicker} from '@src/components/form/DateControl/DateInput';
import {TDateInputSynteticHandler} from '@src/components/form/DateControl/DateInput/types';
import {ReactComponent as CalendarIcon} from '@src/shared/assets/icons/calendar_secondary.svg';
import {endOfDay, startOfDay} from 'date-fns';
import {DateTime} from 'luxon';
import {FC, useMemo} from 'react';
import {IconButton} from 'ui-kit';

import {DateRangeButtonProps} from './types';

export const DateRangeButton: FC<DateRangeButtonProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  clearDate,
  startRangeWithCurrentMonth,
}) => {
  const range = useMemo<[string | null, string | null]>(() => {
    const startDateFormatted = startDate
      ? DateTime.fromJSDate(startDate).toFormat(COMMON_US_DATE_FORMAT)
      : null;
    const endDateFormatted = endDate
      ? DateTime.fromJSDate(endDate).toFormat(COMMON_US_DATE_FORMAT)
      : null;
    return [startDateFormatted, endDateFormatted];
  }, [endDate, startDate]);

  const onChange: TDateInputSynteticHandler = (_, value) => {
    const [start, end] = value;

    if (!start && !end) {
      clearDate();
      return;
    }

    setStartDate(startOfDay(new Date(start)));
    setEndDate(endOfDay(new Date(end)));
  };

  return (
    <Box>
      <HeadlessDatePicker
        name={'date-button'}
        type={'range'}
        range={range}
        startRangeWithCurrentMonth={startRangeWithCurrentMonth}
        onChange={onChange}>
        {() => (
          <IconButton strokeIcon color={'secondary-light'}>
            <CalendarIcon />
          </IconButton>
        )}
      </HeadlessDatePicker>
    </Box>
  );
};
