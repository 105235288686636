import {Box, SxProps, Theme, styled} from '@mui/material';

const LoaderElement = styled('div')(({theme}) => ({
  '@keyframes pulse': {
    '0%': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
      transform: 'scale(0)',
    },
    '20%': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
      transform: 'scale(0.5)',
    },
    '60%': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      transform: 'scale(1)',
    },
    '80%': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
      transform: 'scale(0.5)',
    },
    '100%': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
      transform: 'scale(0)',
    },
  },
  width: 18,
  height: 18,
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.secondary.dark,
  transform: 'scale(1)',
  animation: 'pulse 1.5s infinite ease',
  animationDelay: '0.1s',
  ':nth-child(1)': {
    animationDelay: '0s',
  },
  ':nth-child(2)': {
    animationDelay: '0.5s',
  },
  ':nth-child(3)': {
    animationDelay: '1s',
  },
}));

const sx: Partial<Record<string, SxProps<Theme>>> = {
  loader: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    height: 15,
  },
};

export const PulseLoader = ({loading}: {loading: boolean}) => {
  return (
    <>
      {loading && (
        <Box sx={sx.loader} >
          <LoaderElement />
          <LoaderElement />
          <LoaderElement />
        </Box>
      )}
    </>
  )
  ;
};
