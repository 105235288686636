import {apiClient} from 'client/ApiClient';
import {MessengerStatistic} from './models';

export const getMessengerStatistic = async () => {
  const response = await apiClient.get<MessengerStatistic>('/api/messenger/unread/count');
  return response.data;
};

export * as messengerApi from './';
export * from './models';
