import {FC} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {SelectControl} from 'ui-kit';

import {FormSelectProps} from './type';

export const FormSelect: FC<FormSelectProps> = ({
  name,
  children,
  showErrorText = true,
  ...restProps
}) => {
  const formContext = useFormContext();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({
    control: formContext.control,
    name,
  });

  const errorTextHandler = () => {
    if (showErrorText) {
      return error?.message;
    }
    return error?.message ? ' ' : error?.message;
  };

  return (
    <SelectControl
      name={name}
      value={value}
      onChange={onChange}
      error={errorTextHandler()}
      {...restProps}
    >
      {children}
    </SelectControl>
  );
};
