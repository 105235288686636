export const fieldAccesses: Record<string, string> = {
  relationType: '',
  firstName: '',
  lastName: '',
  middleName: '',
  birthDate: '',
  birthSex: '',
  address1: '',
  address2: '',
  city: '',
  phone: '',
  ssnTail: '',
  state: '',
  postalCode: '',
};

export const defaultColumns = {
  sm: 6,
};

export const customColumns = {
  sm: 12,
};
