import {DialogContent, DialogTitle, styled} from '@mui/material';

interface IStyledButtonProps {
  width?: string | number
}

export const StyledDialogTitle = styled(DialogTitle)`
    font-size: 18px; 
    line-height: 24px;
    font-weight: 700; 
    padding: 0 70px 0 24px;
    border-bottom: 1px solid ${({theme}) => theme.colors.borderBlueLight};
    display: flex;
    align-items: center;
    position: relative;
    height: 48px;
`;

export const StyledDialogContent = styled(DialogContent)`
    padding: 24px 24px 24px 24px;
    padding-top: 24px !important;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    text-align: start!important;
`;

export const StyledButton = styled('button')<IStyledButtonProps>(({width}) => ({
  border: 'none',
  background: 'none',
  width: width || '50px',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  right: 0,
  top: 0,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
}));
