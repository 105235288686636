import {BMIDataType} from '@src/api';
import {DateTime} from 'luxon';

export const findFirstAndLastDataDate = (data: BMIDataType[]): [DateTime | null, DateTime | null] => {
  if (data.length > 0) {
    const firstDate = DateTime.fromISO(data[0].createdTime);
    const lastDate = DateTime.fromISO(data[data.length - 1].createdTime);
    return [firstDate, lastDate];
  }
  return [null, null];
};
