import {useMemo} from 'react';

export const useSortOfDate = <T extends Record<string, any>>(data: T[] | null, dateField: keyof T): T[] => {
  const sortedData = useMemo(() => {
    if (!data) return [];

    return [...data].sort((a, b) => {
      const dateA = a[dateField] ? new Date(a[dateField]).getTime() : 0;
      const dateB = b[dateField] ? new Date(b[dateField]).getTime() : 0;
      return dateB - dateA;
    });
  }, [data, dateField]);

  return sortedData;
};
