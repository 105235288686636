import {Box, styled} from '@mui/material';

interface BoxWrapperProps {
  withPadding?: boolean
}

export const BoxWrapper = styled(Box)<BoxWrapperProps>(({theme, withPadding = true}) => ({
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
  ...(withPadding && {padding: '32px'}),
}));
