import styled from '@emotion/styled';
import {Badge, BadgeProps, Theme} from '@mui/material';
import {DRAWER_WIDTH_CLOSE, DRAWER_WIDTH_OPEN} from './SideMenuContainer';

const property = 'width 0.3s';

export const classes = {
  drawer: (open: boolean, theme: Theme) => ({
    overflowX: 'hidden',
    width: open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE,
    transition: property,
    '.mobile-layout &, .tablet-layout & ': {
      width: '100%',
    },
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH_OPEN,
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: '12px',
      boxSizing: 'border-box',
      background: open ? theme.palette.background.paper : theme.palette.secondary.main,
      borderRight: open ? `1px solid ${theme.colors.borderBlueLight}` : 'none',
      '.mobile-layout &, .tablet-layout & ': {
        width: '100%',
        background: '#FFFFFF',
      },
    },
  }),
};

export const MenuList = styled('nav')`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
`;

export const StyledBadge = styled(Badge)<BadgeProps & { open: boolean }>(({theme, open}) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiBadge-badge': {
    right: open ? -16 : 95,
    top: open ? 16 : 8,
    border: '2px solid white',
    borderRadius: '50%',
    padding: 0,
    zIndex: 0,
    backgroundColor: theme.palette.error.main,
    minHeight: open ? '24px' : '12px',
    minWidth: open ? '24px' : '12px',
    fontSize: '10px',
    lineHeight: 16,
  },
}));
