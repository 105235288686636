// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Typography, Box, Stack} from '@mui/material';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ErrorIcon} from '@src/shared/assets/icons/error.svg';
import {ReactComponent as SuccessIcon} from '@src/shared/assets/icons/success.svg';
import {useMQuery} from '@src/shared/hooks';
import {Steps} from '@src/types';
import React, {FC, useEffect, useState} from 'react';
import {Button, InputControl} from 'ui-kit';

import {Dialog, DialogTitle, DialogContent} from '../Dialog';

import {sx} from './styles';
import {ISendResultProps} from './types';

export const SendResult: FC<ISendResultProps> = ({onSubmit, step, close}) => {
  const {t} = useTranslate('details');
  const {mobile: isMobile} = useMQuery();

  const [email, setEmail] = useState(() => localStorage.getItem('email') || '');
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<TKeys<'details'> | null>(null);

  const isModalOpened = step === Steps.SUCCESS || step === Steps.ERROR;
  const modalSize = isMobile ? 'xs' : '400px';

  const toggleSendResultWindow = () => {
    setIsOpen((prev) => !prev);
    setError(null);
    setEmail('');
  };

  const closeModal = () => {
    close();
    localStorage.removeItem('email');
    setEmail('');
  };

  const handleChangeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    localStorage.setItem('email', value);
    setError(null);
  };

  const toggleConfirm = () => {
    if (!isOpen) {
      return;
    }
    if (!email.length) {
      setError('REQUIRED_FIELD');
      return;
    }
    const reg = /\S+@\S+\.\S+/;
    if (!reg.test(email)) {
      setError('ENTER_A_VALID_EMAIL_ADDRESS');
      return;
    }

    onSubmit(email);
    toggleSendResultWindow();
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('email');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Button
        onClick={toggleSendResultWindow}
        color="secondary"
        variant="contained"
        size="lg">
        {t('SEND')}
      </Button>
      <Dialog
        open={isOpen}
        title="Send Results"
        onClose={toggleSendResultWindow}
        sx={{backgroundColor: 'rgb(55 65 81 / 50%)'}}
        size={modalSize}
        border={'none'}
      >
        <DialogTitle onClose={toggleSendResultWindow} width='64px'>{t('SEND_RESULTS')}</DialogTitle>
        <Box sx={sx.emailContainer}>
          <InputControl
            placeholder="your.address@domain.com"
            label={t('BY_EMAIL')}
            error={t(error)}
            onChange={handleChangeMail}
            value={email}
            compact={isMobile}
          />
        </Box>
        <Stack sx={sx.actionButtons}>
          <Button variant="outlined" color="secondary" onClick={toggleSendResultWindow}>
            {t('CANCEL')}
          </Button>
          <Button variant="contained" color="secondary" onClick={toggleConfirm}>
            {t('SUBMIT')}
          </Button>
        </Stack>
      </Dialog>
      {step === Steps.ERROR && (
        <Dialog
          sx={sx.dialogError}
          open={isModalOpened}
          onClose={closeModal}
          size={modalSize}>
          <DialogTitle
            width="64px"
            onClose={closeModal}
            noContent={false}
            sx={sx.dialogTitleError}>
            <ErrorIcon />
            {t('ERROR')}
          </DialogTitle>
          <DialogContent sx={sx.confirmDeleteContent}>
            <Typography variant="14_18_500">
              {t('RESULT_HAS_NOT_BEEN_SUCCESSFULLY_SENT', {email})}
            </Typography>
          </DialogContent>
          <Stack sx={sx.dialogActionsError}>
            <Button
              sx={sx.bth}
              variant="contained"
              color="inherit"
              onClick={closeModal}
            >
              {t('OK')}
            </Button>
          </Stack>
        </Dialog>
      )}
      {step === Steps.SUCCESS && (
        <Dialog open={isModalOpened} onClose={closeModal} size={modalSize}>
          <DialogTitle
            width="64px"
            sx={sx.dialogTitleSuccess}
            onClose={closeModal}
            noContent={false}
          >
            <SuccessIcon />
            {t('SUCCESS')}
          </DialogTitle>
          <DialogContent sx={sx.confirmDeleteContent}>
            <Typography variant="14_18_500">
              {t('RESULT_HAS_BEEN_SUCCESSFULLY_SENT', {email})}
            </Typography>
          </DialogContent>
          <Stack sx={sx.dialogActionsSuccess} >
            <Button
              sx={sx.bth}
              variant="contained"
              color="inherit"
              onClick={closeModal}
            >
              {t('OK')}
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );
};
