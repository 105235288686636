import {HealthCaseData} from '../types';

export const getDefaultValues = (values?: HealthCaseData) => {
  if (!values) {
    return;
  }

  const {name, description, caseDate} = values;
  return {
    name,
    caseDate,
    description,
  };
};
