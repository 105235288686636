import {Box, Stack} from '@mui/material';
import {DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {modalSize, modalType} from '@src/pages/Connections/utils/functionHelpers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Button} from 'ui-kit';

import {sx, StyledModal} from './styles';
import {AccessesDeleteModalProps} from './types';

export const ChangeAccessModal = ({isOpen, onClose}: AccessesDeleteModalProps) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile, tablet: isTablet} = useMQuery();

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      size={modalSize(isTablet, isMobile)}
    >
      <DialogTitle width='60px' onClose={onClose} noContent={false}>{t('CHANGE_ACCESS')}</DialogTitle>
      <Stack sx={{alignItems: 'center'}}>
        {modalType(isTablet, isMobile)}
      </Stack>
      <Box sx={sx.modalButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          sx={{...sx.modalButton, width: {xs: '100%', sm: 102}}}
          onClick={onClose}
        >
          {t('BACK')}
        </Button>
      </Box>
    </StyledModal>
  );
};
