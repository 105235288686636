import {RelationContactSearchResult} from '@src/api/relations';

export const calculateMaxHeight = (isUniqueConfirmedPhoneError: boolean, foundPatient: RelationContactSearchResult[]) => {
  const baseHeight = {xs: 266, sm: 596};
  return isUniqueConfirmedPhoneError && foundPatient.length <= 1
    ? {...baseHeight, xs: 278}
    : baseHeight;
};

export const calculatePaddings = (isUniqueConfirmedPhoneError: boolean, foundPatient: RelationContactSearchResult[]) => {
  if (isUniqueConfirmedPhoneError) {
    if (foundPatient.length > 1) {
      return {xs: '0 18px 12px 18px', sm: '0 24px 50px 24px'};
    } else {
      return {xs: '0 18px 12px 18px', sm: '0 24px 24px 24px'};
    }
  } else {
    if (foundPatient.length > 1) {
      return {xs: '0 18px 12px 18px', sm: '0 24px 48px 24px'};
    } else {
      return {xs: '0 18px 12px 18px', sm: '0 24px 24px 24px'};
    }
  }
};
