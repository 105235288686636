import {css as cssClassName} from '@emotion/css';
import {SxProps, Theme} from '@mui/material';
import {paletteColors} from '@src/theme';

const baseToastStyles = cssClassName`
  width: 336px;
`;

export const customErrorStyles = cssClassName`
${baseToastStyles};
border: 1px solid ${paletteColors.error[200]};
.Toastify {
    &__progress-bar {
        background: ${paletteColors.error[200]};
        height: 9px;
    }
}
`;

export const customWarnStyles = cssClassName`
${baseToastStyles};
border: 1px solid ${paletteColors.warning[200]};
.Toastify {
    &__progress-bar {
        background: ${paletteColors.warning[200]};
    }
}
`;

export const customSuccessStyles = cssClassName`
${baseToastStyles};
border: 1px solid ${paletteColors.success[200]};
.Toastify {
    &__progress-bar {
        background: ${paletteColors.success[200]};
        height: 5px;
    }
}
`;

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  closeButton: {
    height: 24,
    width: 24,
    position: 'relative',
    bottom: 0,
  },
};
