import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    gap: 42,
  },
  whiteBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      lg: 42,
      xs: 60,
    },
    justifyContent: 'space-between',
    padding: {
      lg: 36,
      sm: 24,
      xs: 18,
    },
    flexGrow: 1,
  },
  mainTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsWrapper: (t) => ({
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 12,
    [t.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  }),
  button: (t) => ({
    [t.breakpoints.down('sm')]: {
      flex: 1,
    },
  }),
};

export const StyledForm = styled(Form)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  flexGrow: 1,
  [theme.breakpoints.only('xs')]: {
    gap: 18,
  },
}));
