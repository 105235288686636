import {apiClient} from '@src/client/ApiClient';
import {Access, AccessRequests} from '@src/api/accesses/models';
import {UUID} from '@src/types';

export const getAccesses = async () => {
  const response = await apiClient.get<Access>('/api/accesses');
  return response.data;
};

export const getAccessesRequests = async () => {
  const response = await apiClient.get<AccessRequests>('/api/accesses/requests');
  return response.data;
};

export const approveAccessesRequest = async ({requestId}: {requestId: UUID}) => {
  const response = await apiClient.post(`/api/accesses/requests/${requestId}/approve`);
  return response.data;
};

export const declineAccessesRequest = async ({requestId}: {requestId: UUID}) => {
  const response = await apiClient.post(`/api/accesses/requests/${requestId}/decline`);
  return response.data;
};

export const deleteAccessesRequest = async ({requestId}: {requestId: UUID}) => {
  const response = await apiClient.post(`/api/accesses/requests/${requestId}/delete`);
  return response.data;
};

export * as accessesApi from './';
export * from './models';
