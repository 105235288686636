import {AccountType} from '@src/types';

export interface IMobileTabsProps {
  accessesTypeForMobile: AccountType
  setAccessesTypeForMobile: (type: AccountType) => void
}

export enum TabTypes {
  Requests = 1,
  Accepted,
}
