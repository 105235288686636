import {AccountAccess, CompanyAccess, isAccountAccesses} from '@src/api/accesses';
import {getName} from '@src/shared/utils/getName';

export const cardSpacing = (isDesktop: boolean, isMobile: boolean) => {
  if (isDesktop) return 24;
  if (!isMobile) return 12;
  return 0;
};

export const accessesCardTitle = (account: CompanyAccess | AccountAccess) => {
  return isAccountAccesses(account) ? getName(account?.contact, {short: true}) : account.company.name;
};
