import {accessesApi} from '@src/api/accesses';
import {accessesActions as actions} from '@src/store/accesses/slice';
import {all, call, put, SagaReturnType, takeEvery, takeLeading} from 'redux-saga/effects';

import {errorHandler} from '../errorHandler';
import {SagaPayload, SagaReturn} from '../types';

export function * accessesSaga () {
  yield all([
    takeLeading(actions.requestAccesses, requestAccesses),
    takeLeading(actions.requestAccessesRequests, requestAccessesRequests),
    takeLeading(actions.approveAccessesRequest, approveAccessesRequest),
    takeLeading(actions.declineAccessesRequest, declineAccessesRequest),
    takeEvery(actions.deleteAccessesRequest, deleteAccessesRequest),
  ]);
}

export function * requestAccesses (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({accesses: true}));
    const accesses: Awaited<ReturnType<typeof accessesApi.getAccesses>> = yield call(accessesApi.getAccesses);
    yield put(actions.setAccesses({
      accesses,
    }));
  } catch (e) {
    errorHandler(e);
  } finally {
    yield put(actions.setFetching({accesses: false}));
  }
}

export function * requestAccessesRequests (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({accessesRequests: true}));
    const accessesRequests: Awaited<ReturnType<typeof accessesApi.getAccessesRequests>> = yield call(accessesApi.getAccessesRequests);
    yield put(actions.setAccessesRequests({accessesRequests}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({accessesRequests: false}));
  }
}

function * approveAccessesRequest ({payload}: SagaPayload<typeof actions.approveAccessesRequest>): SagaReturn {
  try {
    yield call(accessesApi.approveAccessesRequest, payload);
    const relations: Awaited<ReturnType<typeof accessesApi.getAccesses>> = yield call(accessesApi.getAccesses);
    yield put(actions.setAccesses({relations}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * declineAccessesRequest ({payload}: SagaPayload<typeof actions.declineAccessesRequest>): SagaReturn {
  try {
    yield call(accessesApi.declineAccessesRequest, payload);
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * deleteAccessesRequest ({payload}: SagaPayload<typeof actions.deleteAccessesRequest>): SagaReturn {
  try {
    yield call(accessesApi.deleteAccessesRequest, payload);
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
