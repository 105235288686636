import {MutableRefObject, useEffect, useRef} from 'react';

export const useOnClickOutside = (refs: Array<MutableRefObject<Element | null>>, handler: (e: MouseEvent) => void) => {
  const ref = useRef<any>();
  ref.current = refs;
  useEffect(
    () => {
      const listener = (event: MouseEvent) => {
        if (ref.current.some((ref: any) => !ref.current || ref.current.contains(event.target as Node))) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener as any);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener as any);
      };
    },
    [handler],
  );
};
