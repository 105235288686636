import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  IconButton,
  styled,
} from '@mui/material';
import {ReactComponent as ArrowGoIcon} from '@src/shared/assets/icons/arrow.svg';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {initialColors} from '@src/theme';

import {iconBtnSx} from './styles';

const IconContainer = () => {
  return (
    <IconButton sx={iconBtnSx}>
      <ArrowGoIcon />
    </IconButton>
  );
};

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)<{ isSidebarOpen: boolean }>(
  ({theme, isSidebarOpen}) => ({
    flexDirection: 'row-reverse',
    minHeight: '48px',
    paddingLeft: '20px',
    borderBottom: `1px solid ${initialColors.blueLight}`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '11px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '29px',
      [theme.breakpoints.only('lg')]: {
        minWidth: isSidebarOpen ? '1034px' : 'initial',
      },
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg) translateX(6px) translateY(4px)',
    },
  }),
);

export const AccordionSummary = (props: AccordionSummaryProps) => {
  const {isSidebarOpen} = useSidebarClassObserver();

  return (
    <StyledMuiAccordionSummary
      isSidebarOpen={isSidebarOpen}
      {...props}
      expandIcon={<IconContainer />}
    >
      {props.children}
    </StyledMuiAccordionSummary>
  );
};

export const Accordion = styled(MuiAccordion)(() => ({
  '&:before': {
    background: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));
Accordion.defaultProps = {
  elevation: 0,
};

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
