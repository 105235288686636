import {Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  wrapper: (t) => ({
    position: 'absolute',
    top: {xs: 0, md: 78, lg: 74},
    right: {xs: 0, md: 24, lg: 36},
    width: {xs: '100%', md: 500},
    backgroundColor: t.palette.common.white,
    boxShadow: '0px 3px 10px 0px #00000026',
  }),
  header: (t) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${t.palette.secondary[300] || ''}`,
    height: {xs: 52, sm: 59, md: 47},
    p: '2px 12px',
    '#goBackButton': {
      position: 'absolute',
      left: 12,
    },
    '#closeButton': {
      position: 'absolute',
      right: 12,
    },
  }),
  flexContainer: {
    height: {
      xs: 'calc(100vh - 46px)',
      md: 732,
    },
    overflow: 'auto',
  },
  contentContainer: (t) => ({
    cursor: 'pointer',
    p: {
      xs: '12px 18px 18px 12px',
      sm: '18px 24px 24px 18px',
    },
    flexDirection: 'row',
    borderBottom: `1px solid ${t.palette.secondary[300] || ''}`,
    gap: 6,
    '& svg': {
      width: 24,
      height: 24,
      flexShrink: 0,
      color: t.palette.grey[700],
    },
    '&:hover': {
      backgroundColor: t.palette.grey[100],
    },
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    mt: 1,
    gap: 12,
  },
  subject: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  emptyList: (t) => ({
    p: 24,
    color: t.palette.grey[500],
  }),
};
