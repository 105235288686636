import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  checkbox: {
    transform: 'translate(-20px, -15px)',
    mr: 0,
    height: 'unset',
  },
  name: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  healthCaseText: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    b: {
      mr: 12,
    },
  },
  checkboxContainer: {
    minWidth: 34,
    pt: 17,
    label: {
      justifyContent: 'flex-end',
    },
  },
};
