/* eslint-disable no-comments/disallowComments */
import {AccessesResults} from '@src/pages/Connections/fragments/Accesses/AccessesResults';
import {ROUTERS_PATH} from '@src/routers';
import React from 'react';
import './i18n/i18n';
import {Routes, Route, Navigate} from 'react-router-dom';

import {useCheckAcceptedCookies} from './features/cookies/redux/hooks';
import {MainLayout} from './features/layouts/MainLayout';
import {
  Results,
  NotFound,
  Messages,
  Result,
  Dynamics,
  Insurance,
  Overview,
  Settings,
  Connections,
  Tasks,
  AddInsurance,
  EditInsurance,
  Storage,
  HealthCases,
} from './pages';
import {CalculatorBMI} from './pages/BMI';
import {Accesses} from './pages/Connections/fragments/Accesses';
import {AccessesAcceptedMobileLayout} from './pages/Connections/fragments/Accesses/fragments/AccessesAcceptedMobileLayout';
import {AccessesRequestsMobileLayout} from './pages/Connections/fragments/Accesses/fragments/AccessesRequestsMobileLayout';
import {Relations} from './pages/Connections/fragments/Relations';
import {AddRelation} from './pages/Connections/fragments/Relations/AddRelation';
import {CreateNewPatient} from './pages/Connections/fragments/Relations/CreateNewPatient/CreateNewPatient';
import {RelationAbout} from './pages/Connections/fragments/Relations/fragments/RelationAbout/RelationAbout';
import {RelationContacts} from './pages/Connections/fragments/Relations/fragments/RelationContacts';
import {RelationAboutEdit} from './pages/Connections/fragments/Relations/fragments/RelationEditLayouts/RelationAboutEdit/RelationAboutEdit';
import {RelationContactsEdit} from './pages/Connections/fragments/Relations/fragments/RelationEditLayouts/RelationContactsEdit/RelationContactsEdit';
import {RelationHealthPoliciesEdit} from './pages/Connections/fragments/Relations/fragments/RelationEditLayouts/RelationHealthPoliciesEdit/RelationHealthPoliciesEdit';
import {RelationHealthPolicies} from './pages/Connections/fragments/Relations/fragments/RelationHealthPolicies/RelationHealthPolicies';
import {RelationHealthPoliciesAdd} from './pages/Connections/fragments/Relations/fragments/RelationHealthPolicies/RelationHealthPoliciesAdd/RelationHealthPoliciesAdd';
import {RelationMain} from './pages/Connections/fragments/Relations/fragments/RelationMain';
import {ChangeAccess} from './pages/Connections/fragments/Relations/fragments/RelationMain/fragments/ChangeAccess';
import {RelationLayoutView} from './pages/Connections/fragments/Relations/fragments/RelationMain/fragments/RelationLayoutView';
import {RelationRelations} from './pages/Connections/fragments/Relations/fragments/RelationRelations';
import {RelationResults} from './pages/Connections/fragments/Relations/fragments/RelationResults';
import {ResultDetails} from './pages/Connections/fragments/Relations/fragments/ResultDetails/ResultDetails';
import {ResultDynamics} from './pages/Connections/fragments/Relations/fragments/ResultDynamics';
import {Forbidden} from './pages/Forbidden';
import {HealthCase} from './pages/HealthCase';
import {Journal} from './pages/Journal';
import {PdfResults} from './pages/PdfResults/PdfResults';
import {StorageDocument} from './pages/StorageDocument';

// NOTE temporary hide import {MobileAppointmentCard} from './pages/Appointments/AppointmentCard/MobileAppointmentCard';

// NOTE temporary hide import {MobileAppointmentCard} from './pages/Appointments/AppointmentCard/MobileAppointmentCard';

export const App: React.FC = () => {
  useCheckAcceptedCookies();
  return (
    <Routes>
      <Route path={ROUTERS_PATH.QR_RESULTS} element={<PdfResults />} />
      <Route element={<MainLayout />}>
        <Route path="/" element={<Navigate to={ROUTERS_PATH.OVERVIEW} />} />
        <Route path={ROUTERS_PATH.RESULTS}>
          <Route path="" element={<Results />} />
          <Route path={ROUTERS_PATH.RESULT_DETAILS}>
            <Route path="" element={<Result />} />
            <Route path={ROUTERS_PATH.DYNAMICS} element={<Dynamics />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={ROUTERS_PATH.MESSAGES} element={<Messages />} />
        <Route path={ROUTERS_PATH.JOURNAL} element={<Journal />} />
        <Route path={ROUTERS_PATH.OVERVIEW} element={<Overview />} />
        <Route path={ROUTERS_PATH.BMI} element={<CalculatorBMI />} />
        {/* NOTE temporary hide <Route path={ROUTERS_PATH.APPOINTMENTS} element={<Appointments />} /> */}
        {/* NOTE temporary hide <Route path={ROUTERS_PATH.APPOINTMENT_CARD} element={<MobileAppointmentCard />} /> */}
        <Route path={ROUTERS_PATH.TASKS} element={<Tasks />} />
        <Route path={ROUTERS_PATH.HEALTH_CASES} element={<HealthCases />} />
        <Route path={ROUTERS_PATH.HEALTH_CASE} element={<HealthCase />} />
        <Route path={ROUTERS_PATH.HEALTH_CASE_ADD} element={<HealthCase />} />
        <Route path={ROUTERS_PATH.CONNECTIONS_ADD_RELATION} element={<AddRelation />} />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_CREATE_A_NEW_PATIENT}
          element={<CreateNewPatient />}
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ACCESSES_REQUESTS_ACCESS}
          element={<AccessesResults />}
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ACCESSES_REQUESTS}
          element={<AccessesRequestsMobileLayout />}
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ACCESSES_ACCEPTED}
          element={<AccessesAcceptedMobileLayout />}
        />
        <Route path={ROUTERS_PATH.CONNECTIONS_ACCESSES}>
          <Route element={<Connections />}>
            <Route path="" element={<Accesses />} />
          </Route>
        </Route>
        <Route path={ROUTERS_PATH.CONNECTIONS_RELATIONS}>
          <Route element={<Connections />}>
            <Route path="" element={<Relations />} />
          </Route>
          <Route path={ROUTERS_PATH.CONNECTIONS_RELATION}>
            <Route path={ROUTERS_PATH.CONNECTIONS_RELATION_CHANGE_ACCESS} element={<ChangeAccess />} />
            <Route path="" element={<RelationMain />}>
              <Route path="" element={<RelationLayoutView />} />
              <Route path={ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT} element={<RelationAbout />} />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT_EDIT}
                element={<RelationAboutEdit />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS}
                element={<RelationContacts />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS_EDIT}
                element={<RelationContactsEdit />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES}
                element={<RelationHealthPolicies />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES_EDIT}
                element={<RelationHealthPoliciesEdit />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES_ADD}
                element={<RelationHealthPoliciesAdd />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RELATIONS}
                element={<RelationRelations />}
              />
              {/* NOTE temporary hide <Route path={ROUTERS_PATH.CONNECTIONS_RELATION_ORDERS} element={<RelationOrders />} /> */}
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS}
                element={<RelationResults />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DETAILS}
                element={<ResultDetails />}
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DYNAMICS}
                element={<ResultDynamics />}
              />
            </Route>
          </Route>
        </Route>
        <Route path={ROUTERS_PATH.INSURANCE} element={<Insurance />} />
        <Route path={ROUTERS_PATH.ADD_INSURANCE} element={<AddInsurance />} />
        <Route path={ROUTERS_PATH.EDIT_INSURANCE} element={<EditInsurance />} />
        {/* NOTE temporary hide <Route path={ROUTERS_PATH.FAVORITES} element={<Favorites />} /> */}
        <Route path={ROUTERS_PATH.SETTINGS} element={<Settings />} />
        <Route path={ROUTERS_PATH.STORAGE} element={<Storage />} />
        <Route path={ROUTERS_PATH.STORAGE_DOCUMENT} element={<StorageDocument />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/403" element={<Forbidden />} />
      </Route>
    </Routes>
  );
};
