/* eslint-disable react-hooks/exhaustive-deps */
import {WBox} from '@src/components/WhiteBox';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {PageHeader} from './fragments/Relations/fragments/PageHeader';
import {sx} from './styles';

export const Connections = () => {
  const {mobile, tablet, desktop} = useMQuery();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [isIncomingRequest, setIsIncomingRequest] = useState<boolean>(true);

  useEffect(() => {
    setValue(Number(window.localStorage.getItem('value')));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('value', JSON.stringify(value));
  }, [value]);

  const isAccesses = location.pathname.includes('accesses');

  return (
    <>
      <PageHeader />
      {(desktop) && (
        <WBox
          noPaddings
          sx={{
            height: 'calc(100vh - 210px)',
            pr: (isAccesses || desktop) ? 0 : 16,
          }}>
          <Outlet context={{isIncomingRequest, setIsIncomingRequest}} />
        </WBox>
      )}
      {(tablet || mobile) && (
        <WBox
          sx={[
            ...spreadSx(sx.contentContainer),
            {overflowX: mobile && isAccesses ? 'hidden' : 'inherit'},
          ]}
        >
          <Outlet context={{isIncomingRequest, setIsIncomingRequest}} />
        </WBox>
      )}
    </>
  );
};
