import {useTranslate} from '@src/i18n/useTranslate';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';

import {IUseModalContentMap} from './types';

export const useModalContentMap = ({email, phone, fullName}: IUseModalContentMap) => {
  const {t} = useTranslate('connections');

  const sentenceWithEmail = email && fullName && `${t('WE_SENT_THE_INVITATION_TO_VIVICA_TO')} ${fullName} ${t('VIA_EMAIL')} ${email}.`;
  const sentenceWithPhone = phone && fullName && `${t('WE_SENT_THE_INVITATION_TO_VIVICA_TO')} ${fullName} ${t('VIA_SMS_USING_THE_PHONE_NUMBER')} ${formatPhoneNumber(phone) || ''}.`;
  const sentenceWithUnsent = fullName && `${t('PATIENT')} ${fullName} ${t('UNFORTUNATELY_WE_CANNOT_PROCESS_THIS_REQUEST_FOR_A_RELATION_IMMEDIATELY')}`;

  const modalContentMap = {
    UNSENT: sentenceWithUnsent,
    EMAIL: sentenceWithEmail,
    PHONE: sentenceWithPhone,
  };

  return {
    modalContentMap,
  };
};
