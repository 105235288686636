import {BirthSex} from '@src/api/account';

export const getSex = (accountSex: BirthSex | undefined, urlSex: BirthSex | null) => {
  if (accountSex && accountSex !== BirthSex.Unknown) {
    return accountSex;
  }
  if (urlSex) {
    return urlSex;
  }
  return BirthSex.Unknown;
};
