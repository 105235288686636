
import {Typography} from '@mui/material';
import {Checkbox} from '@src/components/form/CheckboxControl';
import {useMQuery} from '@src/shared/hooks';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';

import {Accordion, AccordionDetails, AccordionSummary} from '../StyledAccordionSummary/StyledAccordionSummary';
import {TableRow} from '../TableRow/TableRow';

import {stylesSx} from './styles';
import {RowTableProps} from './types';

export const ExpandableTableRowContainer: React.FC<RowTableProps> = ({
  className,
  data,
  gridTemplateColumns,
  padding = 0,
  changeCodesForDynamics,
  changePanel,
  indexRow,
  childrenPanel,
  selectAll,
  selectRest,
  sx,
}) => {
  const {length} = data.tests || data.products;
  const {tabletPortrait, mobile} = useMQuery();

  const [childrenStatus, setChildrenSelection] = useState<boolean[]>(new Array(length).fill(false));

  useLayoutEffect(() => {
    let flag = true;
    if (data.tests) {
      childrenStatus.forEach((item) => {
        if (!item) flag = false;
      });
    }
    if (childrenPanel || selectAll) {
      setChildrenSelection((prev) => [...Array(prev.length)].fill(true));
    } else if (
      (childrenPanel !== undefined || (selectAll !== undefined && selectRest)) &&
      (!data.tests || flag)
    ) {
      setChildrenSelection((prev) => [...Array(prev.length)].fill(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenPanel, selectAll, selectRest]);

  useEffect(() => {
    if (changePanel && typeof indexRow === 'number') {
      if (childrenStatus.every(Boolean)) {
        changePanel(indexRow, true);
      } else {
        changePanel(indexRow, false);
      }
    }
  }, [changePanel, childrenStatus, indexRow]);

  const changeChildrenSelection = useCallback((index: number, status: boolean) => {
    setChildrenSelection((prev) => {
      prev[index] = status;
      return [...prev];
    });
  }, []);

  const handleChangeSelection = (_: React.ChangeEvent, checked: boolean) => {
    setChildrenSelection((prev) => [...Array(prev.length)].fill(checked));

    if (!changeCodesForDynamics) {
      return;
    }

    const tests = data.tests
      ? data.tests
      : data.products
        ? data.products?.map((product) => product.tests).flat()
        : null;
    if (tests) {
      changeCodesForDynamics(tests, checked, true);
    }
  };

  const getChildrenPadding = () => {
    if (tabletPortrait) {
      return 28;
    }
    if (mobile) {
      return 8;
    }
    return 34;
  };

  const expandArrowSize = 16;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          paddingX: {
            xs: `${32 - expandArrowSize + padding}px`,
            lg: `${44 - expandArrowSize + padding}px`,
          },
        }}>
        <Checkbox
          sx={{ml: -6, mr: 3}}
          checked={childrenStatus.every(Boolean)}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={handleChangeSelection}
          color="secondary"
        />
        <Typography sx={stylesSx.testsGroupTypography}>
          {data?.name || ''}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data?.tests
          ? (
            <TableRow
              changeChildrenStatus={changeChildrenSelection}
              childrenStatus={childrenStatus}
              changeCodesForDynamics={changeCodesForDynamics}
              padding={padding}
              resultTests={data?.tests}
              className={className}
              gridTemplateColumns={gridTemplateColumns}
              sx={sx}
            />
          )
          : (
            data.products?.map((product, index) => (
              <ExpandableTableRowContainer
                childrenPanel={childrenStatus[index]}
                indexRow={index}
                changePanel={changeChildrenSelection}
                changeCodesForDynamics={changeCodesForDynamics}
                padding={padding + getChildrenPadding()}
                key={`${product.code}/special-row`}
                className={className}
                data={product}
                gridTemplateColumns={gridTemplateColumns}
                sx={sx}
              />
            ))
          )}
      </AccordionDetails>
    </Accordion>
  );
};
