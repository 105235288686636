import {SxProps, Theme, Typography, styled} from '@mui/material';
import {Button} from 'ui-kit';

export const TypographyStyled = styled(Typography)(({theme}) => ({
  color: theme.palette.grey[500],
}));

export const StyledLinkButton = styled(Button)(({theme}) => ({
  paddingLeft: 0,
  paddingTop: 0,
  height: 25,
  color: theme.palette.grey[800],
  justifyContent: 'flex-start',
}));

export const calculatorBMISx = (isChangeGrid: boolean): Partial<Record<string, SxProps<Theme>>> => ({
  topContainer: {
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    justifyContent: 'space-between',
    alignItems: {
      xxs: 'flex-start',
      sm: 'center',
    },
    gap: {
      xs: 12,
    },
    marginBottom: {
      xs: 18,
      sm: 0,
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: {
      xs: 'flex-start',
      sm: 'center',
    },
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: {
      xs: 18,
    },
  },
  breadCrumbs: {
    typography: {
      xs: '14_18_600',
      md: '14_18_600',
      xxl: '16_20_500',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: isChangeGrid ? 'row' : 'column',
  },
  wrapper: {
    flexGrow: 1,
    gap: {
      sm: 24,
      xs: 12,
    },
  },
  recomendationsBox: {
    width: isChangeGrid ? '100%' : 'inherit',
    display: isChangeGrid ? 'contents' : 'block',
    order: isChangeGrid ? 3 : 2,
  },
  calculatorBox: {
    display: isChangeGrid ? 'flex' : 'contents',
    width: '100%',
    position: isChangeGrid ? 'sticky' : 'relative',
    alignSelf: 'flex-start',
    top: 0,
    gap: 24,
    maxWidth: {
      xl: 788,
      xxl: '100%',
    },
  },
  bmi: {
    typography: {
      xs: '24_32_700',
      sm: '36_38_700',
    },
  },
  weightTitle: {
    typography: {
      xs: '24_32_700',
      sm: '36_38_700',
    },
    marginLeft: {
      sm: 24,
      xs: 12,
    },
  },
  bmiBox: {
    display: 'flex',
    alignSelf: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
  },
});
